define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "accept-new-client": {
      "actions": {
        "error": "Something went wrong, please contact customer support.",
        "success": "New client has been added to your available clients!"
      }
    },
    "appTitle": {
      "acronym": "TMS",
      "full": "Tribute Management Software"
    },
    "authenticated": {
      "accounting": {
        "exports": {
          "index": {
            "buttons": {
              "new": "Create New"
            },
            "lists": {
              "noExports": "No Exports"
            },
            "pageTitle": "Exports"
          }
        }
      },
      "admin": {
        "custom-fields": {
          "buttons": {
            "addNew": "Add New"
          },
          "edit": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!",
              "validate": "One of the fields is invalid"
            },
            "allLocationsLabel": "All Locations",
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationLabel": "Location: ",
            "pageTitle": "Custom Field Configuration"
          },
          "locationSelect": {
            "placeholder": "Location",
            "placeholderLabel": "All Locations"
          },
          "new": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!"
            },
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationSelect": {
              "placeholder": "Location",
              "placeholderLabel": "Select Location"
            },
            "noCustomFields": "No custom fields have been created.",
            "pageTitle": "New Custom Field Configuration"
          },
          "pageTitle": "Custom Field Configurations",
          "table": {
            "editLink": "Edit",
            "header": "Location",
            "mobileHeader": "Location Name: "
          }
        },
        "data": {
          "case-tags": {
            "buttons": {
              "addTag": "Add Tag"
            },
            "fields": {
              "input": {
                "name": "Name"
              },
              "select": {
                "options": {
                  "default": "Select One"
                },
                "placeholder": "Disposition Method"
              }
            },
            "labels": {
              "dispositionMethod": "Disposition Method:"
            },
            "list": {
              "empty": "No Case Tags"
            }
          },
          "poems-and-prayers": {
            "actions": {
              "delete": "Poem successfully removed!",
              "save": {
                "error": "An error occured while saving.",
                "success": "Poem successfully saved!"
              }
            },
            "newPoem": {
              "buttons": {
                "add": "Add Poem or Prayer",
                "cancel": "Cancel",
                "save": "Save"
              },
              "name": "Name",
              "text": "Verbiage"
            },
            "noPoems": "No Poems or Prayers",
            "poems": {
              "edit": {
                "name": "Name",
                "text": "Verbiage"
              }
            },
            "search": "Name"
          }
        },
        "financial-configurations": {
          "edit": {
            "additional-settings": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "messages": {
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "sageIntacct": {
                "buttons": {
                  "add": "Add"
                },
                "header": "Sage Intacct Configuration",
                "inputs": {
                  "glEntryCode": {
                    "placeholder": "GL Entry Code"
                  },
                  "journalCode": {
                    "placeholder": "Journal Code"
                  },
                  "locationCode": {
                    "placeholder": "Location Code"
                  }
                },
                "selectors": {
                  "locations": {
                    "placeholder": "Location",
                    "placeholderLabel": "Select One"
                  }
                }
              }
            },
            "adjustments": {
              "data": {
                "account": "Account:"
              },
              "ids": {
                "netsuite": "Netsuite ID:",
                "tms": "TMS ID:"
              }
            },
            "discounts": {
              "buttons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "new": "New",
                "save": "Save"
              },
              "information": {
                "account": {
                  "label": "Account:"
                },
                "netsuiteID": {
                  "label": "Netsuite ID:"
                },
                "tmsID": {
                  "label": "TMS ID:"
                }
              },
              "inputFields": {
                "discountTypeName": "Discount Type Name"
              },
              "messages": {
                "delete": {
                  "error": "There was an error removing this record.",
                  "success": "Record has been removed!"
                },
                "save": {
                  "error": "Sorry, something went wrong. Please try again.",
                  "success": "Discount type successfully saved!"
                }
              },
              "pageTitle": "Discounts",
              "selectFields": {
                "account": "Account"
              }
            },
            "overview": {
              "accountDropdowns": {
                "discount": "Discount Account",
                "receivables": "Receivables Account",
                "refund": "Refund Account",
                "taxPayable": "Tax Payable Account"
              },
              "buttons": {
                "cancel": "Cancel",
                "importQuickbooks": "Import Quickbooks",
                "importQuickbooksOnline": "Import Quickbooks Online",
                "save": "Save"
              },
              "fieldInput": {
                "name": "Name"
              },
              "messages": {
                "iifUpload": {
                  "error": "Error uploading file.",
                  "success": "File successfully uploaded."
                },
                "import": {
                  "error": "Import failed. Please try again.",
                  "success": "Import started. Please wait for QuickBooks to sync."
                },
                "save": {
                  "error": "Something went wrong. Please try again.",
                  "success": "Accounts successfully saved!"
                }
              },
              "pageNotValidText": "This Chart of Accounts is not yet valid. Please check your accounts.",
              "pageTitle": "Overview",
              "pageValidText": "This Chart of Accounts is valid.",
              "secondaryTitle": "Tax Configuration",
              "taxItemDropdowns": {
                "nonTaxItemName": "Non Tax Item Name",
                "taxItemName": "Tax Item Name"
              }
            },
            "pageTitle": "Financial Configurations",
            "payments": {
              "information": {
                "tmsID": "TMS ID:"
              }
            },
            "posting-periods": {
              "buttons": {
                "addNew": "Add New",
                "archive": "Archive",
                "clear": "Clear",
                "savePostingPeriod": "Save Posting Period",
                "unarchive": "Unarchive"
              },
              "confirmDialog": {
                "archive": "The selected posting period will be archived. Are you sure?",
                "unarchive": "The selected posting period will be unarchived. Are you sure?"
              },
              "inputs": {
                "archived": {
                  "label": "View Archived"
                },
                "pikaday": {
                  "endsAt": {
                    "label": "Ending At"
                  },
                  "startsAt": {
                    "label": "Starting From"
                  }
                },
                "pikadayDateTime": {
                  "dateLabel": "Lock Date",
                  "timeLabel": "Lock Time"
                }
              },
              "pageTitle": "Posting Periods",
              "table": {
                "createdOn": {
                  "label": "Created On: "
                },
                "headers": {
                  "createdOn": "Created On",
                  "lockDate": "Lock Date",
                  "lockedBy": "Locked By"
                },
                "lockDate": {
                  "label": "Lock Date: "
                },
                "lockedBy": {
                  "label": "Locked By: "
                }
              }
            },
            "sideNavigationLabels": {
              "accounts": "Accounts",
              "adjustments": "Adjustments",
              "backToAdmin": "Back to Admin",
              "discounts": "Discounts",
              "interests": "Interests",
              "overview": "Overview",
              "payments": "Payments",
              "postingPeriods": "Posting Periods",
              "settings": "Settings",
              "specialTaxes": "Special Taxes",
              "sync": "Sync",
              "taxItems": "Tax Items"
            },
            "sync": {
              "buttons": {
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save"
              },
              "inputFields": {
                "companyFilePath": "Company File Path"
              },
              "links": {
                "downloadConfigurationFile": "Download Configuration File"
              },
              "pageTitle": "Sync",
              "sections": {
                "accessToken": {
                  "title": "Access Token",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Access Tokens can only be viewed once",
                    "noAccessToken": "No Access Token",
                    "writeThisDown": "Write this down!"
                  }
                },
                "accountNotSetup": {
                  "mainText": "Your account has not been setup for syncing. Please contact",
                  "supportEmail": "support@tributetms.com"
                },
                "filePath": {
                  "title": "File Path"
                },
                "quickBooksDesktop": {
                  "instructions": {
                    "mainText": "Follow these steps to sync Tribute Management Software with QuickBooks Desktop running on your computer:",
                    "step1": {
                      "linkText": "QuickBooks Web Connector",
                      "mainText": "Download and install the"
                    },
                    "step2": "Enter the File Path to your Quick Book Company File (.qbw) below",
                    "step3": "Generate an access token (below)",
                    "step4": "Download and open the Configuration File (below) and open it with QuickBooks Web Connector",
                    "step5": "Select the QuickBooks company file you'd like Tribute Management Software to sync to",
                    "step6": "Enter your Tribute Management Software password in QuickBooks Web Connector"
                  },
                  "title": "QuickBooks Desktop"
                }
              }
            }
          }
        },
        "general-price-lists": {
          "edit": {
            "buttons": {
              "add": "Add Statement Category"
            },
            "cost-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Cost updates saved!"
              },
              "pageTitle": "Cost Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "costHeader": "Cost",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "header": {
              "subtitle": "Edit Statement Categories that you can assign to Product Categories",
              "title": "General Price List Statement Categories"
            },
            "list": {
              "noStatementCategories": "No Statement Categories"
            },
            "messages": {
              "error": "Make sure all of the information is correct and/or inputted.",
              "save": {
                "catch": "Something went wrong.",
                "success": "Statement Category successfully created!"
              }
            },
            "nav": {
              "backToAdmin": "Back to Admin",
              "costUpdates": "Cost Updates",
              "import": "CSV Updates",
              "overview": "Overview",
              "packages": "Packages",
              "preneedCategories": "Preneed Categories",
              "priceListDates": "Price List Dates",
              "pricingUpdates": "Pricing Updates",
              "productCategories": "Product Categories",
              "products": "Products",
              "statementCategories": "Statement Categories"
            },
            "overview": {
              "buttons": {
                "cancel": "Cancel",
                "export": "Create CSV Export",
                "save": "Save"
              },
              "inputs": {
                "chartOfAccountsName": "Chart of Accounts Name",
                "name": "Name"
              },
              "messages": {
                "save": {
                  "error": "There was a problem saving this general price list.",
                  "success": "General price list saved!"
                }
              },
              "pageTitle": "Overview"
            },
            "packages": {
              "edit": {
                "buttons": {
                  "addItem": "+ Add Item",
                  "cancel": "Cancel",
                  "save": "Save"
                },
                "fields": {
                  "checkboxes": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "amount": "Amount ({currency})",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID"
                  },
                  "select": {
                    "addProduct": {
                      "disclaimers": {
                        "alreadyInPackage": "(Already in Package)"
                      },
                      "placeholder": "Add Product"
                    },
                    "invoiceCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Invoice Category"
                    },
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currency})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "information": {
                  "netsuite": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "lists": {
                  "discount": {
                    "label": "Discount"
                  },
                  "finalTotal": {
                    "label": "Final Total",
                    "validations": {
                      "error": {
                        "subtotal": {
                          "main": "{type} is greater than sub total.",
                          "types": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      }
                    }
                  },
                  "flexibleItems": {
                    "none": {
                      "disclaimer": "Please use the field above to add flexible items to this package.",
                      "title": "No Assigned Flexible Items"
                    }
                  },
                  "items": {
                    "none": {
                      "disclaimer": "Please use the field above to add items to this package.",
                      "title": "No Assigned Items"
                    }
                  },
                  "subTotal": {
                    "label": "Sub Total"
                  }
                },
                "manage-products": {
                  "buttons": {
                    "backToPackage": "Back to Package",
                    "clear": "Clear",
                    "clearAllSelections": "Clear All Selections",
                    "selectAllOnCategory": "Select All on Category",
                    "selectAllOnPage": "Select All on Page"
                  },
                  "description": "Select products from this category to enable for the flexible item.",
                  "fields": {
                    "input": {
                      "search": "Search"
                    }
                  },
                  "table": {
                    "headers": {
                      "description": "Description",
                      "name": "Name",
                      "price": "Price"
                    }
                  }
                },
                "sections": {
                  "flexibleItems": "Flexible Items",
                  "items": "Items",
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "title": "Edit Package"
              },
              "index": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                },
                "table": {
                  "actions": {
                    "edit": "Edit"
                  },
                  "headers": {
                    "items": "Items"
                  },
                  "noPackages": "No packages to display for this GPL.",
                  "responsiveHeaders": {
                    "description": "Description: ",
                    "name": "Name: ",
                    "packageContents": "Package Contents: "
                  }
                },
                "title": "Packages"
              },
              "new": {
                "alerts": {
                  "save": {
                    "error": "Something went wrong. Please try again.",
                    "success": "Package saved!"
                  }
                },
                "buttons": {
                  "cancel": "Cancel",
                  "save": "Save & Continue"
                },
                "fields": {
                  "checkbox": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "addProduct": "Add Product",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID",
                    "pricingAmount": "Amount ({currencySymbol})"
                  },
                  "select": {
                    "invoiceCategory": "Invoice Category",
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currencySymbol})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "headings": {
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "lists": {
                  "ids": {
                    "netsuiteId": "NetSuite ID:",
                    "tmsId": "TMS ID:"
                  },
                  "pricingDetails": {
                    "items": {
                      "discount": "Discount",
                      "finalTotal": {
                        "label": "Final Total",
                        "text": {
                          "main": "{amount} is greater than sub total",
                          "options": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      },
                      "subtotal": "Sub Total"
                    }
                  },
                  "products": {
                    "disclaimers": {
                      "alreadyInPackage": "Already In Package",
                      "noAssignedItems": "No Assigned Items",
                      "useFieldAbove": "Please use the field above to add items to the package."
                    }
                  }
                },
                "title": "Create New Package"
              }
            },
            "placeholders": {
              "name": "Name"
            },
            "pricing-imports": {
              "addNew": "How to add a new product to your GPL via CSV",
              "addrule1": "To create a new product in your GPL, enter \"new\" in the Item ID column.",
              "addrule2": "Enter product details in the appropriate columns.",
              "addrule3": "Product Category Names are case sensitive and must match exactly.",
              "howTo": "How to edit a GPL via a CSV Upload",
              "inProgress": "Products update in progress",
              "rule1": "Click the “Create CSV Export” link above to download a formatted CSV file of your General Price List.",
              "rule2": "Open the file in Excel or other spreadsheet application to make your changes.",
              "rule3": "Do not delete any columns, or change the column order. Do not modify the column headers.",
              "rule4": "Do not edit Item ID's of existing Items.",
              "rule5": "Remove any rows that do not need to be updated.",
              "rule6": "Save file as a CSV (Comma Separated Values) and upload here.",
              "status": {
                "complete": "Completed",
                "inProgress": "In Progress",
                "started": "Started"
              },
              "table": {
                "Created": "Created",
                "Errors": "Errors",
                "file": "File Name",
                "noRecords": "No records found",
                "status": "Status"
              },
              "title": "CSV Updates"
            },
            "pricing-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter by Product Category",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Pricing updates saved!"
              },
              "pageTitle": "Pricing Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name",
                  "priceHeader": "Price"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "products": {
              "edit": {
                "headers": {
                  "costChanges": "Cost History"
                },
                "information": {
                  "netsuiteID": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "tables": {
                  "costChanges": {
                    "headers": {
                      "newCost": "New Cost",
                      "previousCost": "Previous Cost",
                      "totalChange": "Total Change",
                      "updatedAt": "Updated At"
                    }
                  }
                }
              }
            },
            "title": "General Price Lists"
          }
        },
        "integrations": {
          "asd": {
            "buttons": {
              "linkASDAccount": "Link ASD Account",
              "linkASDLocation": "Link ASD Location",
              "linkLocations": "Link Locations"
            },
            "sections": {
              "asdLocations": {
                "title": "ASD Locations"
              },
              "settings": {
                "information": {
                  "asdLink": {
                    "allLocationsLinked": "All TMS Locations have been linked.",
                    "clientIsLinked": "This client is linked with ASD.",
                    "clientIsNotLinked": "Please click the link below to retreive the location remote ID(s) and assign them to the appropriate location(s) in Tribute Management Software."
                  },
                  "autoKaseExport": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Auto Case Export?:"
                  },
                  "importBeginningTime": "Imports will begin one hour after",
                  "importCases": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Import Cases?:"
                  },
                  "importCasesAfter": "Import Cases After:",
                  "locations": {
                    "label": "Location(s):",
                    "noData": "No locations linked."
                  }
                },
                "title": "Settings"
              }
            },
            "selectFields": {
              "location": {
                "defaultSelection": "Select One"
              }
            }
          },
          "links": {
            "aftercare": "Aftercare",
            "asd": "ASD",
            "backToAdmin": "Back to Admin",
            "bassMollett": "Bass-Mollett",
            "batesville": "Batesville",
            "bestWishes": "Best Wishes",
            "cfs": "CFS",
            "ciclops": "Ciclops",
            "domaniCare": "DomaniCare",
            "forethought": "Forethought",
            "frazer": "Frazer",
            "frontRunner": "Front Runner",
            "funeralDecisions": "Funeral Decisions",
            "funeralOne": "FuneralOne",
            "funeralTech": "FuneralTech",
            "messenger": "Messenger",
            "ohioEDRS": "Ohio EDRS",
            "overview": "Overview",
            "precoa": "Precoa",
            "tco": "Tribute Center Online",
            "tcoV2": "Tribute Center Online V2",
            "transNational": "TransNational",
            "tributePrint": "Tribute Print",
            "tributeVideo": "Tribute Video",
            "tukios": "Tukios",
            "tukiosV2": "Tukios V2",
            "viewlogies": "Viewlogies"
          },
          "pageTitle": "Integrations",
          "tco": {
            "buttons": {
              "linkTcoLocation": "Link TCO Location"
            },
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "linkFailure": "TMS Location is already linked to a TCO Location",
            "linkSuccess": "TCO ID successfully added to location",
            "linkUpdated": "TCO Location ID Updated",
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online",
            "selector": {
              "placeholder": "Select a Matching Location"
            },
            "tcoLink": {
              "button": {
                "linkLocations": "Link TCO Locations"
              },
              "locationsLinked": "All TMS locations have been linked to TCO"
            }
          },
          "tco-v2": {
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online V2"
          },
          "tribute-print": {
            "pageTitle": "Tribute Print"
          }
        },
        "locations": {
          "edit": {
            "overview": {
              "information": {
                "netsuiteID": "Netsuite ID:",
                "tmsID": "TMS ID:"
              },
              "placeholders": {
                "asdRemoteId": "ASD Remote ID",
                "tcoLocationId": "TCO Location ID"
              },
              "sections": {
                "organizations": {
                  "header": "Organizations",
                  "table": {
                    "columns": {
                      "name": "Name"
                    }
                  },
                  "tooltips": {
                    "edit": "Edit"
                  }
                }
              }
            }
          }
        },
        "manage": {
          "ar-adjustments": {
            "actions": {
              "revert": "Revert"
            },
            "pageTitle": "A/R Adjustments",
            "revertConfirm": "Are you sure you want to revert this adjustment?",
            "revertError": "An error occurred while reverting the adjustment. Please try again.",
            "revertSuccess": "The job to revert the adjustment has been queued. Please check back later.",
            "reverted": "Reverted",
            "table": {
              "columns": {
                "actions": "Actions",
                "errors": "Errors",
                "ran": "Date ran",
                "range": "Date range",
                "requested": "Requested by",
                "status": "Status"
              }
            }
          },
          "cemsites": {
            "index": {
              "confirmDialog": {
                "promptMessage": "Are you sure you want to unlink this Tribute Management Software Place with CemSites?"
              }
            },
            "new": {
              "error": {
                "uuid": "There was a problem returning CemSites places. If this continues, please contact support."
              },
              "selectFields": {
                "tmsPlace": {
                  "placeholder": "TMS Place"
                }
              }
            }
          },
          "financial-configurations": {
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "inputPlaceholder": "Name",
              "messages": {
                "error": "Oops! There was an error saving this Chart of Account.",
                "success": "Chart of Accounts save successful request submitted!"
              },
              "pageTitle": "Add Financial Configuration"
            }
          },
          "forms": {
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "success": {
                "delete": "Successfully removed form.",
                "save": "Successfully added form."
              }
            },
            "pageTitle": "Enabled Forms",
            "searchField": {
              "placeholder": "Search"
            },
            "text": {
              "alreadySelected": "(Already Selected)",
              "enableFormsText": "Please use the search field above to enable forms for future use.",
              "noFormsSelected": "No Forms Selected"
            }
          },
          "general-price-lists": {
            "new": {
              "buttons": {
                "text": {
                  "cancel": "Cancel",
                  "create": "Create"
                }
              },
              "fields": {
                "placeholders": {
                  "financialConfiguration": "Financial Configuration",
                  "name": "Name",
                  "selectOne": "Select One"
                }
              },
              "messages": {
                "error": "Oops! There was an error saving this GPL.",
                "success": "General Price List save successfully submitted!"
              },
              "pageTitle": "Add General Price List"
            }
          },
          "info": {
            "button": {
              "save": {
                "errorMessage": "Oops, something went wrong! Please try again.",
                "label": "Save",
                "successMessage": "Info saved!"
              }
            },
            "dropdown": {
              "packageItemFormat": {
                "label": "Package Item Format",
                "optionIncluded": "Included",
                "optionShowPricing": "Show Pricing",
                "selectOneLabel": "Choose One"
              },
              "sfgsFontSize": {
                "label": "Statement Font Size",
                "optionDefault": "Default",
                "optionLarger": "Larger",
                "selectOneLabel": "Choose One"
              }
            },
            "pageText": "The following are the warranties, terms, disclosures and acknowledgement and agreement that will appear on your printed Statement of Funeral Goods and Services.",
            "pageTitle": "Client Info",
            "secondaryTitle": "Billing",
            "textarea": {
              "acknowledgementAndAgreement": "Acknowledgements and Agreements",
              "disclosures": "Disclosures",
              "terms": "Terms",
              "warranties": "Warranties"
            }
          },
          "navigation": {
            "links": {
              "arAdjustments": "A/R Adjustments",
              "forms": "Forms",
              "tributePay": "Tribute Pay"
            }
          },
          "online-planner": {
            "index": {
              "clearSearchButton": "Clear",
              "copyUrlButton": "Copy URL",
              "defaultPlannerLabel": "Default Planner",
              "editPlannerButton": "Edit",
              "newButton": "New",
              "newPlanner": {
                "actions": {
                  "archive": {
                    "error": "There was a problem while trying to archive this planner. If this continues, please contact support.",
                    "message": "Are you sure you want to archive this planner?",
                    "no": "No",
                    "success": "Planner archived successfully",
                    "yes": "Yes"
                  },
                  "save": {
                    "error": "Something went wrong, please try again.",
                    "success": "Online planner configuration successfully created."
                  },
                  "unArchive": {
                    "error": "There was a problem un archiving the planner. If this continues, please contact support."
                  }
                },
                "cancelButton": "Cancel",
                "createButton": "Create",
                "namePlaceholder": "Name",
                "urlPlaceholder": "URL Prefix"
              },
              "pageTitle": "Online Planner",
              "tableHeaders": {
                "actions": "Actions",
                "plannerName": "Name",
                "urlPrefix": "URL Prefix"
              },
              "viewArchived": "View Archived?"
            }
          },
          "organizations": {
            "edit": {
              "assignOrganizationToLocation": {
                "addLocation": {
                  "button": "Add Location",
                  "errorMessage": "Whoops! That location is already assigned to this organization."
                },
                "dropdown": {
                  "label": "Assign Organization to Location",
                  "placeholderLabel": "Select One"
                }
              },
              "buttons": {
                "close": "Close",
                "errorMessage": "Oh No! There was an error.",
                "save": "Save & Close",
                "successMessage": "Successfully saved!"
              },
              "fields": {
                "input": {
                  "website": "Website"
                },
                "select": {
                  "contactPerson": "Contact Person"
                }
              },
              "misc": {
                "notApplicable": "N/A"
              },
              "organizationNameLabel": "Name",
              "pageTitle": "Edit Organization",
              "sections": {
                "contactPerson": {
                  "email": "Email Address:",
                  "phone": "Phone Number:",
                  "title": "Contact Person:"
                },
                "locations": {
                  "emptyList": "This organization is not currently assigned to a location.",
                  "title": "Locations:"
                }
              }
            },
            "index": {
              "addNewOrganization": {
                "buttons": {
                  "cancel": "Cancel",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully saved!"
                  },
                  "save": "Save"
                },
                "placeholderLabel": "Organization Name"
              },
              "pageTitle": "Organizations",
              "searchOrganizations": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                }
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "name": "Name"
                },
                "headersAtSmallerScreenSizes": {
                  "name": "Name:"
                },
                "links": {
                  "delete": "Delete",
                  "edit": "Edit",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully removed."
                  }
                }
              }
            }
          },
          "pageTitle": "Admin Settings",
          "tfe": {
            "forms": {
              "buttons": {
                "cancel": "Cancel",
                "clear": "Clear",
                "enableForm": "Enable Form"
              },
              "formVisibility": {
                "labelOptions": {
                  "all": "All",
                  "private": "Private",
                  "public": "Public"
                },
                "placeholder": "Form Visibility"
              },
              "messages": {
                "error": "Oops, something went wrong! Please try again.",
                "success": {
                  "delete": "Successfully removed form.",
                  "save": "Successfully enabled form."
                }
              },
              "pageTitle": "Enabled Forms",
              "searchField": {
                "placeholder": "Search"
              },
              "text": {
                "alreadySelected": "(Already Selected)",
                "enableFormsText": "Please use the button above to enable forms for future use.",
                "noFormsSelected": "No Forms Selected"
              }
            }
          },
          "users": {
            "index": {
              "save": {
                "ssoUserExists": "User saved! This email is already tied to an existing sso account. They must first verify their email address before they can log in.",
                "success": "User saved!"
              }
            }
          },
          "whiteboard": {
            "buttons": {
              "generateSecurePin": {
                "label": "Generate Secure Pin"
              }
            },
            "information": {
              "contactUs": "Contact us to issue a new pin.",
              "createdAt": "Created at:",
              "developerMessage": "Superusers cannot generate pins. To generate a whiteboard pin, create a new user account with administrator permissions for the required client.",
              "instructions": "Click the button below to generate your unique login pin for the TMS Whiteboard Apple TV App."
            },
            "pageSubtitle": "Secure Pin",
            "pageTitle": "Whiteboard"
          }
        },
        "online-planner": {
          "config": {
            "edit-category": {
              "backToPlanner": "Back to Online Planner",
              "clearSearch": "Clear",
              "clearSelections": "Clear All Selections",
              "saveButton": "Save",
              "saveError": "There was an error while saving the products to the online planner category. Please try again.",
              "saveSuccess": "Category saved successfully!",
              "selectAllInCategory": "Select All in Category",
              "selectAllOnPage": "Select All on Page",
              "subtitle": "Select products in this category to enable in the planner.",
              "table": {
                "columns": {
                  "description": "Description",
                  "name": "Name",
                  "price": "Price"
                },
                "isTaxed": "tax"
              }
            }
          },
          "edit": {
            "backButton": "Back to Online Planner",
            "notification-settings": {
              "alerts": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              },
              "buttons": {
                "addRecipient": "+ Add New Notification Recipient",
                "cancel": "Cancel",
                "clear": "Clear",
                "deleteRecipient": "Delete",
                "save": "Save"
              },
              "inputFields": {
                "search": {
                  "placeholder": "Search"
                },
                "staffMember": {
                  "placeholder": "Staff Member"
                }
              },
              "lists": {
                "notifications": {
                  "empty": "No Notifications Added"
                }
              },
              "pageTitle": "Notification Settings"
            },
            "notificationSettingsButton": "Notification Settings",
            "overview": {
              "buttons": {
                "back": "Back to Online Planner",
                "copyPlannerURL": {
                  "tooltip": "Copy planner URL"
                },
                "save": "Save"
              },
              "errors": {
                "name": "You can't save the planner without a Name.",
                "pageName": "You can't save a page without a Page name.",
                "urlPrefix": "You can't save the planner without a URL Name."
              },
              "fields": {
                "checkbox": {
                  "defaultPlanner": {
                    "label": "Default Planner?",
                    "validation": {
                      "allLocations": "Allow this planner to be available for all locations.",
                      "toMakeDefault": "To make this the default, please update this setting in the current default planner."
                    }
                  }
                },
                "input": {
                  "pageIsHidden": "Hide this page?",
                  "pageName": "Page Name",
                  "plannerName": "Name",
                  "plannerURL": "URL Prefix",
                  "primaryColor": {
                    "info": "Primary color is used for header background.",
                    "placeholder": "Primary Color"
                  },
                  "secondaryColor": {
                    "info": "Secondary color is used for buttons and accents throughout the planner.",
                    "placeholder": "Secondary Color"
                  },
                  "subPageisHidden": "Hide this subpage?"
                },
                "select": {
                  "bodyFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Body Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration body font."
                    }
                  },
                  "country": {
                    "info": "Country fields will default to this value.",
                    "options": {
                      "canada": "Canada",
                      "unitedStates": "United States"
                    },
                    "placeholder": "Country"
                  },
                  "defaultTaxLocation": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Default Tax Location",
                    "validation": {
                      "disabled": "A GPL is required to set the default tax location."
                    }
                  },
                  "fontType": {
                    "placeholder": "Font Type",
                    "systemFonts": "High-performance System Fonts",
                    "webFonts": "Web Fonts"
                  },
                  "generalPriceList": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "General Price List",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration general price list."
                    }
                  },
                  "headerFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Header Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration header font."
                    }
                  },
                  "loadingFonts": "Loading Fonts...",
                  "logoAlignment": {
                    "placeholder": "Logo Alignment"
                  },
                  "logoSize": {
                    "placeholder": "Logo Size"
                  }
                }
              },
              "headerLogo": {
                "actions": {
                  "archive": {
                    "error": "An error has occurred.",
                    "success": "Logo Successfully Archived!"
                  },
                  "create": {
                    "error": "An Error occured while uploading your logo.",
                    "success": "Logo Successfully uploaded!"
                  }
                },
                "header": "Header Logo",
                "placeholder": "Online Planner Logo"
              },
              "locations": {
                "addLocation": "+ Add Location",
                "defaultConfigMessage": "Individual locations cannot be added to a default configuration.",
                "delete": {
                  "error": "There was an error removing this location. If this continues, please contact support.",
                  "success": "Location was successfully removed."
                },
                "noGeneralPriceList": "Select a General Price List to add locations to this configuration.",
                "noLocations": "No locations have been added to this configuration.",
                "select": {
                  "placeholder": "Online Planner Location",
                  "placeholderLabel": "Select One"
                },
                "title": "Locations"
              },
              "notifications": {
                "email": "Email",
                "label": "Send notifications when plans are submitted",
                "title": "Notifications"
              },
              "save": {
                "copySuccess": "Planner url copied to clipboard successfully!",
                "error": "There was a problem while saving. If this continues, please contact support.",
                "success": "Planner saved successfully!"
              },
              "section": {
                "fontType": {
                  "info": "Using system fonts will reduce loading time and rendering speed, ensure consistency across devices and browsers, and improve accessibility."
                },
                "heading": {
                  "pages": "Pages",
                  "plannerDetails": "Planner Details",
                  "theme": "Theme"
                }
              },
              "theme": {
                "secondary": "Secondary Color"
              },
              "validation": {
                "country": "You must select a country",
                "name": "Name cannot be blank",
                "pageName": "Page Name can't be blank",
                "prefixCheck": "Please only use letters, numbers, dashes or underscores.",
                "primaryColor": "Primary color must be a valid hex code",
                "secondaryColor": "Secondary color must be a valid hex code",
                "url": "URL Prefix cannot be blank"
              }
            },
            "overviewButton": "Overview",
            "page": {
              "buttons": {
                "addCustomField": "Add",
                "save": "Save"
              },
              "errors": {
                "customFieldAdd": "You have already added this field."
              },
              "fields": {
                "customFieldItems": {
                  "options": {
                    "boolean": "Checkbox",
                    "date": "Date Picker",
                    "input": "Text",
                    "select": "Drop Down"
                  },
                  "placeholder": "Label",
                  "size": {
                    "large": "Large",
                    "medium": "Medium",
                    "small": "Small"
                  },
                  "type": {
                    "placeholder": "Type"
                  }
                },
                "customFieldSelect": {
                  "noOptionSelected": "Select One",
                  "placeholder": "Custom Fields"
                },
                "defaultValueField": {
                  "error": "You must select a default value for the overview question pages you have hidden.",
                  "label": "Default Value",
                  "placeholder": "Select One",
                  "warning": "You must select a value."
                },
                "gplURL": "General Price List URL",
                "gplUrlDescription": "This pricelist will be presented to users before they select items in the planner.",
                "gplUrlError": "URL not valid",
                "media": {
                  "image": {
                    "delete": "Delete",
                    "droboxText": "Drag and drop images here to upload them",
                    "placeholder": "Image",
                    "uploadedBy": "Uploaded By:",
                    "uploadedOn": "on"
                  },
                  "video": {
                    "placeholder": "Video"
                  }
                },
                "pageIsHidden": "Hide this page?",
                "pageName": "Page Name",
                "planner": {
                  "blankError": "Field cant be blank",
                  "hideLabel": "Hide?",
                  "label": "Label",
                  "pageDescription": "Page Description",
                  "requiredLabel": "Required?",
                  "verbiageLabel": "Verbiage"
                },
                "subPageisHidden": "Hide this subpage?"
              },
              "section": {
                "subPages": "Subpages"
              },
              "title": {
                "isHidden": "(Hidden)"
              }
            },
            "pageTitle": "Online Planner",
            "users": {
              "leftNav": {
                "back": "Back to Online Planner",
                "overview": "Overview"
              },
              "search": {
                "clearButton": "Clear",
                "newButton": "New",
                "placeholder": "Seach"
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "email": "Email",
                  "lastAccess": "Last Updated",
                  "view": "View Plans"
                }
              },
              "title": "Users"
            },
            "usersButton": "Users"
          },
          "users": {
            "overview": {
              "fields": {
                "email": {
                  "placeholder": "Email"
                }
              },
              "table": {
                "buttons": {
                  "actions": "Actions",
                  "archive": "Archive",
                  "create": "Create Case"
                },
                "headers": {
                  "created": "Created At",
                  "session": "Session ID"
                }
              },
              "title": "User"
            }
          }
        },
        "tribute-pay": {
          "email-templates": {
            "defaultData": {
              "email": {
                "body": "<p>Our funeral home staff appreciates the opportunity to support your family during your time of need.\nFor your convenience, we offer a simple method of paying for services online. Simply click the button below to access our secure site.</p>\n<br />\n<p>If you have questions, please contact us anytime.</p>",
                "name": "Payment Email Template",
                "subject": "Make a Payment Online for [Decedent Name]"
              }
            },
            "edit": {
              "alerts": {
                "missingData": {
                  "body": "The body can't be blank"
                },
                "save": {
                  "error": "There was an error when attempting to update this email template.",
                  "success": "This email template has been updated successfully!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "inputFields": {
                "emailSubject": "Email Subject"
              },
              "pageTitle": "Edit {templateTitle}",
              "textareas": {
                "emailBody": "Email Body"
              }
            },
            "pageTitle": "Email Templates",
            "tabTitle": "Email Templates",
            "table": {
              "body": {
                "edit": "Edit"
              },
              "headers": {
                "actions": "Actions",
                "templateName": "Template Name"
              }
            }
          },
          "navigation": {
            "links": {
              "backToAdmin": "Back to Admin",
              "emailTemplates": "Email Templates",
              "notificationSettings": "Notification Settings",
              "overview": "Overview",
              "payments": "Payments"
            }
          },
          "notification-settings": {
            "alerts": {
              "userNotification": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              }
            },
            "buttons": {
              "addRecipient": "+ Add New Notification Recipient",
              "cancel": "Cancel",
              "clear": "Clear",
              "save": "Save"
            },
            "inputFields": {
              "search": {
                "placeholder": "Search"
              },
              "staffMember": {
                "placeholder": "Staff Member"
              }
            },
            "lists": {
              "notifications": {
                "empty": "No Notifications Added"
              }
            },
            "pageTitle": "Notification Settings",
            "selectFields": {
              "organizationLocation": {
                "options": {
                  "all": "All"
                }
              }
            }
          },
          "overview": {
            "pageTitle": "Tribute Pay Overview",
            "sections": {
              "getStarted": {
                "mainText": {
                  "email": "Sales@TributePay.com",
                  "line1": "To start the enrollment process, click Payments from the menu on the left.",
                  "line2": "For any questions or for assistance enrolling, please contact us at"
                },
                "title": "Get Started"
              },
              "meetTributePay": {
                "mainText": "Tribute Pay’s suite of products gives families multiple ways to pay at your funeral home through emailed invoices via credit card, ACH/e-check, Apple Pay, and more directly through your software. With Tribute Pay’s competitive rates, superior customer service, and no monthly fees, you receive quick deposits with minimal effort.",
                "title": "Meet Tribute Pay: Your All-in-One Payment Solution"
              },
              "tributeFundCrowdfunding": {
                "title": "Tribute Fund Crowdfunding – Coming Soon!"
              },
              "tributeInsuranceAssignments": {
                "title": "Tribute Insurance Assignments – Coming Soon!"
              },
              "tributePaymentProcessing": {
                "mainText": "With no annual or monthly fees, funeral homes can take payments knowing the exact cost of each transaction.",
                "subSection": {
                  "list": {
                    "item1": "2.9% + $0.30 card and digital wallet transactions.",
                    "item2": "1.0% + $0.30 ACH transactions.",
                    "item3": "Accept payments by Visa, Mastercard, Discover, American Express, Google Pay, and Apple Pay."
                  },
                  "title": "Details"
                },
                "title": "Tribute Payment Processing"
              }
            },
            "tabTitle": "Overview"
          },
          "pageTitle": "Admin Settings"
        },
        "tribute-pay-payments": {
          "accounts": {
            "buttons": {
              "addNew": "Add New",
              "clear": "Clear"
            },
            "checkboxes": {
              "viewArchived": "View Archived?"
            },
            "edit": {
              "alerts": {
                "locations": {
                  "deleting": {
                    "error": "There was an error when attempting to remove one of the Locations."
                  },
                  "saving": {
                    "error": "There was an error when attempting to assign one of the Locations."
                  }
                },
                "paymentTypes": {
                  "deleting": {
                    "error": "There was an error when attempting to delete one of the Payment Types."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create one of the new Payment Types."
                  }
                },
                "settingChartOfAccounts": {
                  "prompts": {
                    "confirm": "Once assigned, the Financial Configuration cannot be changed. Are you sure?"
                  },
                  "saving": {
                    "error": "There was an issue assigning the Financial Configuration.",
                    "success": "The Financial Configuration was assigned successfully."
                  }
                },
                "transactionFee": {
                  "missingData": {
                    "financialConfiguration": "To set the transaction fee, please assign a Financial Configuration."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create the Transaction Fee Adjustment Type."
                  }
                },
                "tributePayAccount": {
                  "saving": {
                    "error": "There was an error when trying to save the Tribute Pay account.",
                    "success": "The Tribute Pay account was saved successfully!"
                  }
                }
              },
              "buttons": {
                "cancel": "Cancel",
                "continueEnrollment": "Continue Enrollment Information",
                "editEnrollment": "Edit Enrollment Information",
                "saveAndClose": "Save & Close",
                "saveFinancialConfiguration": "Save Financial Configuration"
              },
              "headings": {
                "financialConfiguration": "Financial Configuration",
                "transactionFee": "Transaction Fee"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "transactionFeeName": "Transaction Fee Name"
              },
              "pageTitle": "Edit Account",
              "selectFields": {
                "financialConfiguration": "Financial Configuration",
                "options": {
                  "selectOne": "Select One"
                },
                "transactionFeeGLAccount": "GL Account"
              }
            },
            "new": {
              "buttons": {
                "beginEnrollment": "Begin Enrollment",
                "cancel": "Cancel"
              },
              "disclaimers": {
                "statementDescriptor": {
                  "characterLimit": "5-22 characters, including at least one letter",
                  "excludedSpecialCharacters": "Does not contain any of the following special characters",
                  "latinOnly": "Latin characters only",
                  "text": "This is the business name that appears on your customer's credit card statements. This field should be a short but recognizable indication of your Doing Business As (DBA) name."
                }
              },
              "errors": {
                "beginEnrollment": "There was an issue attempting to begin enrollment."
              },
              "headings": {
                "mainLocation": "Main Location"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "statementDescriptor": "Statement Descriptor"
              },
              "pageTitle": "New Tribute Pay Account",
              "selectFields": {
                "location": "Location",
                "options": {
                  "selectOne": "Select One"
                }
              }
            },
            "pageTitle": "Tribute Pay Accounts",
            "table": {
              "body": {
                "noAccounts": "No Tribute Pay accounts to display."
              },
              "headers": {
                "accountName": "Account Name",
                "actions": "Actions",
                "numberOfLocations": "Number of Locations",
                "status": "Status"
              }
            }
          },
          "navigation": {
            "links": {
              "accounts": "Tribute Pay Accounts",
              "backToTributePay": "Back to Tribute Payments"
            }
          },
          "pageTitle": "Admin Settings",
          "tabTitle": "Payments"
        },
        "users": {
          "edit": {
            "feature-configuration": {
              "actions": {
                "savingUser": {
                  "errorMessage": "Oops! There was an error saving this user.",
                  "successMessage": "User saved!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "categories": {
                "adminNavigation": "Admin Navigation",
                "all": "All",
                "caseSectionNavigation": "Case Section Navigation",
                "globalNavigation": "Global Navigation"
              },
              "checkboxes": {
                "canSetupTributePay": "Can Setup Tribute Pay?",
                "canViewAccounting": "Can View Accounting?",
                "canViewCalendar": "Can View Calendar?",
                "canViewContacts": "Can View Contacts?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewData": "Can View Data?",
                "canViewDisposition": "Can View Disposition?",
                "canViewDocuments": "Can View Documents?",
                "canViewEvents": "Can View Events?",
                "canViewFamilyFriends": "Can View Family & Friends?",
                "canViewFamilyPortal": "Can View Family Portal?",
                "canViewFinancialConfigurations": "Can View Financial Configurations?",
                "canViewFinancialDetail": "Can View Financial Detail?",
                "canViewForms": "Can View Forms?",
                "canViewFormsAdmin": "Can View Admin Forms?",
                "canViewGeneralPriceLists": "Can View General Price Lists?",
                "canViewGroups": "Can View Groups?",
                "canViewGrowthEngine": "Can View Growth Engine?",
                "canViewInfo": "Can View Info?",
                "canViewInquiries": "Can View Inquiries?",
                "canViewInquiriesCase": "Can View Case Inquiries?",
                "canViewInquiriesGlobal": "Can View Inquiries?",
                "canViewKases": "Can View Cases?",
                "canViewLocations": "Can View Locations?",
                "canViewMiscTasks": "Can View Tasks?",
                "canViewObituary": "Can View Obituary?",
                "canViewObituaryGenerator": "Can View Obituary Generator?",
                "canViewOhioEdrs": "Can View Ohio EDRS?",
                "canViewOnlinePlannerPlans": "Can View Online Planner Plans?",
                "canViewOrganizations": "Can View Organizations?",
                "canViewPayments": "Can View Payments?",
                "canViewPlaces": "Can View Places?",
                "canViewPreneed": "Can View Preneed?",
                "canViewPreparations": "Can View Preparations?",
                "canViewPrintables": "Can View Printables?",
                "canViewReferralInfo": "Can View Referral Info?",
                "canViewReports": "Can View Reports?",
                "canViewServiceInfo": "Can View Service Info?",
                "canViewTCO": "Can View Websites?",
                "canViewTransportation": "Can View Transportation?",
                "canViewTributeVideo": "Can View Tribute Video?",
                "canViewUsers": "Can View Users?",
                "canViewVehicles": "Can View Vehicles?",
                "canViewVeteranInfo": "Can View Veteran Info?",
                "canViewWhiteboard": "Can View Whiteboard?"
              },
              "searchField": {
                "labelText": "Feature Name",
                "noResults": "No results found"
              }
            },
            "links": {
              "backToAdmin": "Back to Admin",
              "featureConfiguration": "Feature Configuration",
              "overView": "Overview"
            },
            "overview": {
              "buttons": {
                "backToUsers": "Back to Users Index",
                "passwordEmail": "Send Setup Password Email",
                "save": "Save"
              },
              "checkboxes": {
                "adjustInventory": "Can Adjust Inventory?",
                "auditLogPermission": "Audit Log Permission?",
                "canEditChainOfCustody": "Can Edit Chain of Custody?",
                "canPostContractLabel": {
                  "contractAddon": "Can Post Contract/Add-on?",
                  "invoiceAddon": "Can Post Invoice/Add-on?"
                },
                "canSetCaseNumbering": "Can Set Case Numbering?",
                "canTurnOffTributeStorePermission": "Can Turn Off Tribute Store?",
                "canUnlockCases": "Can Unlock Cases",
                "canUnpostContractLabel": {
                  "contractAddon": "Can Unpost Contract/Add-on?",
                  "invoiceAddon": "Can Unpost Invoice/Add-on?"
                },
                "canViewContactManager": "Can View Contact Manager?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewDebugging": "Can View Debugging?",
                "canViewDoNotExport": "Can View Do Not Export?",
                "canViewHideTributeBookPermission": "Can View Hide Tribute Book Setting?",
                "casesWidget": "Show Cases Widget?",
                "chainOfCustodyWidget": "Show Chain of Custody Widget?",
                "dashboardNavigationWidget": "Show Dashboard Navigation Widget?",
                "editCaseCustomID": "Edit Case Custom ID Permission?",
                "embalmer": "Embalmer",
                "eventsWidget": "Show Events Widget?",
                "financialPermission": "Financial Permission",
                "financialReportPermission": "Financial Report Permission",
                "funeralDirector": "Funeral Director",
                "inventoryPermission": "Has Inventory Permission?",
                "miscSalesPermission": "Has Misc Sales Permission?",
                "pastDueWidget": "Show Past Due Widget?",
                "tasksWidget": "Show Tasks Widget?"
              },
              "information": {
                "netsuiteID": "Netsuite ID:",
                "netsuiteSubsidiaryID": "Netsuite Subsidiary ID:",
                "tmsID": "TMS ID:"
              },
              "inputFields": {
                "email": "Email",
                "embalmerLicense": "Embalmer License #",
                "firstName": "First Name",
                "funeralDecisionsID": "Funeral Decisions ID",
                "funeralDirectorLicense": "Funeral Director License #",
                "lastName": "Last Name",
                "middleName": "Middle Name",
                "netsuiteID": "NetSuite ID",
                "netsuiteSubsidiaryID": "NetSuite Subsidiary ID",
                "phone": "Mobile Phone"
              },
              "save": {
                "emailExists": "The email address you entered is already assigned. Please enter a different email address.",
                "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                "matchingEmail": "The email address you entered is already in use. Please enter a different email address.",
                "noEmail": "You cannot save this user without an email address."
              },
              "sectionHeaders": {
                "dashboardConfiguration": "Dashboard Configuration",
                "editUser": "Edit User",
                "permissionSlider": "Permission Slider",
                "roles": "Roles"
              },
              "selectFields": {
                "communicationPreference": {
                  "label": "Communication Preference",
                  "options": {
                    "both": "Both",
                    "email": "Email",
                    "sms": "SMS"
                  }
                },
                "filterCasesByStatus": {
                  "label": "Filter Cases by Status"
                },
                "state": {
                  "options": {
                    "defaultProvince": "Default Province",
                    "defaultRegion": "Default Region",
                    "defaultState": "Default State",
                    "none": "None"
                  }
                }
              },
              "userTypes": {
                "administrator": {
                  "description": "These users can do everything except create administrator level users.",
                  "titles": {
                    "list": {
                      "officeManagement": "Office Management",
                      "seniorFuneralDirector": "Senior Level Funeral Director"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Administrator"
                },
                "noPermissions": {
                  "description": "Cannot access Tribute Management Software. These users are for notification purpose only.",
                  "type": "No Permissions Set"
                },
                "ownerManager": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "managerOfLocation": "Manager of Location",
                      "owner": "Owner"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Owner/Manager"
                },
                "staff": {
                  "description": "These users have full access to Tribute Management Software except other user accounts.",
                  "titles": {
                    "list": {
                      "apprentices": "Apprentices",
                      "embalmer": "Embalmer",
                      "funeralDirector": "Funeral Director",
                      "sales": "Sales"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Staff"
                },
                "superuser": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "crakn": "CRäKN Employee",
                      "tms": "Tribute Management Software Employee",
                      "tributeTech": "Tribute Technology Employee"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Superuser"
                },
                "supportStaff": {
                  "description": "These users have read-only access to Tribute Management Software.",
                  "titles": {
                    "list": {
                      "carWashers": "Car Washers",
                      "hairDressers": "Hair Dressers",
                      "janitors": "Janitors",
                      "officeSupportStaff": "Office Support Staff",
                      "valets": "Valets",
                      "visitationAssistants": "Visitation Assistants"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Support Staff"
                }
              }
            },
            "pageTitle": "User Settings",
            "permissions": {
              "canViewCaseSettings": "Can View Case Settings?"
            }
          },
          "permissions": {
            "can_view_tribute_book_setting": {
              "label": "Can View Tribute Book Setting?"
            }
          }
        }
      },
      "calendar": {
        "new-event": {
          "messages": {
            "delete": {
              "error": "Something went wrong deleting this event, let's try that again.",
              "success": "You successfully deleted your event."
            },
            "save": {
              "error": "Sorry, but there was an error saving this event.",
              "success": "Yay! You saved an event!"
            }
          },
          "pageTitle": {
            "edit": "Edit",
            "event": "Event",
            "new": "New"
          }
        }
      },
      "contacts": {
        "edit": {
          "headers": {
            "history": "History"
          },
          "information": {
            "affiliation": "Affiliation",
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        }
      },
      "crakn": {
        "clients": {
          "edit": {
            "feature-config": {
              "checkboxes": {
                "canSeparateTaxCalculations": {
                  "label": "Can Separate Tax Calculations?"
                },
                "canViewCustomSelectionSorting": {
                  "label": "Can View Custom Selection Sorting?"
                },
                "canViewEnhancedPackages": {
                  "label": "Can View Enhanced Packages?"
                },
                "canViewStatementCategories": {
                  "label": "Can View Statement Categories?"
                },
                "includePostingDateInFinancialDetailsExport": {
                  "label": "Include Posting Date In Financial Details Export?"
                },
                "requireReasonForUnposting": {
                  "label": "Require Reason for Unposting?"
                }
              }
            },
            "forms-config": {
              "banners": {
                "error": "Error! TFE migration failed.",
                "migrationStarted": "Migration started",
                "success": "Success! TFE migration is complete."
              },
              "buttons": {
                "close": "Close",
                "migrateFormsToTfe": "Migrate Client to TFE",
                "save": "Save"
              },
              "checkboxes": {
                "canUseTfeEsign": "Can Use Forms with eSign",
                "canViewEsignDashboard": "Can View eSign Dashboard",
                "canViewFormInTfe": "Can View Forms in Tribute Forms Engine",
                "shouldFlattenForms": "Should Flatten Forms"
              },
              "migrationText": {
                "completed": "TFE migration for this client is complete.",
                "confirmPrompt": "This action cannot be reversed. Are you sure you want to migrate this client to TFE?",
                "inProgress": "TFE migration in progress. This might take a few seconds, hang tight!",
                "warning": "Once this client has migrated to TFE, the action cannot be reversed."
              },
              "pageTitle": "Forms Configuration",
              "tfeMigrationTitle": "TFE Migration"
            },
            "reports-config": {
              "checkboxes": {
                "includeContractPostingDate": {
                  "label": "Include Contract Posting Date?"
                },
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG Preneed Report",
                    "jcgReport": "JCG Report",
                    "preneedToAtneedConversionReport": "Preneed to At-Need Conversion Report",
                    "preneedToAtneedRatioReport": "Preneed to At-Need Ratio Report"
                  }
                }
              }
            },
            "tfe": {
              "edit": {
                "addToClients": {
                  "enabledHeader": "Clients using this form",
                  "header": "Add Form to Client(s)",
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Successfully added form to client"
                  },
                  "placeholder": "Client List"
                },
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Update"
                },
                "downloadTestForm": {
                  "messages": {
                    "failure": "An error occurred while downloading the form with test data. Please try again."
                  }
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "description": "Description",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name",
                  "replacePdf": "Replace existing PDF:"
                },
                "header": "Edit Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully updated form"
                },
                "removeFromClient": {
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Form successfully removed from client"
                  }
                },
                "search": {
                  "alreadySelected": "(Already Selected)"
                }
              },
              "index": {
                "buttons": {
                  "clear": "Clear",
                  "new": "Add New"
                },
                "header": "Forms",
                "noForms": "No Forms Found",
                "search": {
                  "placeholder": "Search"
                },
                "text": {
                  "clientSpecific": "(Client Specific)"
                }
              },
              "new": {
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Create"
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name"
                },
                "header": "New Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully created form"
                }
              }
            }
          }
        },
        "manage": {
          "demos": {
            "edit": {
              "checkboxes": {
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG PN Report",
                    "jcgReport": "JCG Report"
                  }
                }
              }
            },
            "index": {
              "inputFields": {
                "tmsPassword": "Password (To Log Into TMS)"
              }
            }
          },
          "forms": {
            "edit": {
              "buttons": {
                "update": "Update"
              },
              "headers": {
                "addFormToClient": "Add Form to Client(s)",
                "clientsUsingThisForm": "Clients using this form",
                "editForm": "Edit Form"
              },
              "labels": {
                "downloadBlankPdf": "Download blank PDF",
                "downloadWithTestData": "Download with test data",
                "replaceExistingPdf": "Replace existing PDF"
              },
              "placeholders": {
                "clientList": "Client List"
              }
            },
            "fields": {
              "caseContactDropdown": "Case Contact Dropdown?",
              "esignEnabled": "eSign Enabled?",
              "eventDropdown": "Event Dropdown?",
              "inquiryDropdown": "Inquiry Dropdown?",
              "locationDropdown": "Location Dropdown?",
              "name": "Name",
              "searchable": "Searchable?"
            },
            "header": "New Form",
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "eSign": {
                "loading": "Document is being analyzed for eSign compatibility",
                "notCompatible": "Form not compatible with eSign"
              },
              "headers": {
                "new_form": "New Form"
              }
            }
          },
          "global-settings": {
            "pageTitle": "Global Settings"
          },
          "links": {
            "clients": "Clients",
            "demos": "Demos",
            "forms": "Forms",
            "global-settings": "Global Settings",
            "migrations": "Migrations"
          },
          "migrations": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "restartMigration": "Try Again",
              "start": "Start Migration"
            },
            "checkboxes": {
              "autoPost": "Auto Post Contracts"
            },
            "datePickers": {
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "missingFiles": "Missing Files",
                "of": "of",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for"
          },
          "pageTitle": "TMS Settings"
        }
      },
      "esignatures": {
        "actions": {
          "viewEsignDetails": "View eSign Details"
        },
        "notifications": {
          "formCanceled": {
            "failure": "Failed to cancel the form. Please try again.",
            "success": "Form successfully canceled!"
          },
          "resentToSigner": {
            "failure": "Failed to resend the form to signer.",
            "success": "Form successfully resent to signer!"
          },
          "resentToSigners": {
            "failure": "Failed to resend the form to pending signers.",
            "success": "Form successfully resent to pending signers!"
          }
        },
        "pageTitle": "eSignatures",
        "placeholders": {
          "location": "Location"
        },
        "tables": {
          "headers": {
            "actions": "Actions",
            "case": "Case",
            "location": "Location"
          }
        }
      },
      "inventory": {
        "edit": {
          "error": {
            "permissions": "You do not have permission to adjust inventory, please ask an administrator.",
            "save": "Inventory change failed to save!"
          },
          "selectFields": {
            "receiveForContract": {
              "label": "Receive for Contract"
            },
            "receiveForStock": {
              "label": "Receive for Stock"
            }
          }
        }
      },
      "kases": {
        "events": {
          "edit": {
            "messages": {
              "deleteEvent": {
                "error": "Something went wrong, please try again.",
                "success": "Successfully removed the event."
              },
              "saveAnotherEvent": {
                "success": "Yay! You saved an event."
              },
              "saveEvent": {
                "error": "There was an error saving this event, please try again.",
                "success": "Yay! You saved an event."
              },
              "unsavedChanges": "You have unsaved changes. Are you sure?"
            },
            "pageTitles": {
              "edit": "Edit",
              "event": "Event",
              "new": "New"
            },
            "secondaryTitles": {
              "case": "Case",
              "location": "Location"
            },
            "warnings": {
              "tcoEmptyPlaceName": "To export to your website, This Event must have a Place with a name.",
              "tcoEventTypeEmpty": "To export to your website, This Event must have an Event Type.",
              "tcoNameInvalid": "To export to your website, the Event Name must be between 2 and 100 characters."
            }
          }
        },
        "manage": {
          "documents": {
            "albums": {
              "manage": {
                "importTributeWall": {
                  "error": "Something went wrong, please try again.",
                  "label": "Import Tribute Wall",
                  "success": "Album successfully imported."
                }
              }
            }
          },
          "edit": {
            "custom-fields": {
              "actions": {
                "create": {
                  "error": "No custom field configuration has been created that can be used with this case."
                },
                "error": "There was an error when saving these custom fields.",
                "save": "Custom fields have been saved!"
              },
              "buttons": {
                "back": "Back to Overview",
                "save": "Save"
              },
              "pageTitle": "Custom Fields",
              "select": {
                "options": {
                  "defaultValue": "Select One"
                }
              }
            },
            "disposition": {
              "checkboxes": {
                "crematedRemainsMailed": {
                  "label": "Cremated Remains Mailed?"
                },
                "crematedRemainsPresentForService": {
                  "label": "Cremated Remains Present for Service?"
                },
                "graveUnknown": {
                  "label": "Grave Unknown?"
                },
                "headstoneDesired": {
                  "label": "Headstone Desired?"
                }
              },
              "headers": {
                "crematedRemainsAuthorizedPickup": "Person(s) Authorized to Pick up Cremated Remains:",
                "graveOwner": "Grave Owner",
                "title": "Disposition"
              },
              "input": {
                "block": {
                  "placeholder": "Block"
                },
                "burialPermitNumber": {
                  "placeholder": "Burial Permit Number"
                },
                "cremationPermitNumber": {
                  "placeholder": "Cremation Permit Number"
                },
                "cremationTagNumber": {
                  "placeholder": "Cremation Tag Number"
                },
                "crypt": {
                  "placeholder": "Crypt"
                },
                "discNumber": {
                  "placeholder": "Disc Number"
                },
                "grave": {
                  "placeholder": "Grave/Plot"
                },
                "lot": {
                  "placeholder": "Lot"
                },
                "mausoleum": {
                  "placeholder": "Mausoleum"
                },
                "niche": {
                  "placeholder": "Niche"
                },
                "plot": {
                  "placeholder": "Plot"
                },
                "secondaryContainer": {
                  "placeholder": "Secondary Container"
                },
                "section": {
                  "placeholder": "Section"
                },
                "tier": {
                  "placeholder": "Tier"
                },
                "timeOfCremation": {
                  "placeholder": "Time of Cremation"
                },
                "urnTempContainer": {
                  "placeholder": "Urn or Temporary Container"
                }
              },
              "pikaday": {
                "crematedRemainsBackBy": {
                  "placeholder": "Cremated Remains Back By"
                },
                "dateOfCremation": {
                  "placeholder": "Date of Cremation"
                },
                "dateOfDisposition": {
                  "placeholder": "Date of Disposition"
                },
                "disintermentDate": {
                  "placeholder": "Date of Disinterment"
                },
                "remainsPickupDate": {
                  "placeholder": "Date of Remains Pick Up/Drop Off"
                }
              },
              "select": {
                "crematedRemainsPickupPerson": {
                  "placeholder": "Cremated Remains Picked Up By"
                },
                "cremationDirector": {
                  "placeholder": "Cremation Director"
                },
                "crematory": {
                  "placeholder": "Crematory"
                },
                "dispositionMethod": {
                  "placeholder": "Disposition Method",
                  "placeholderLabel": "Select One"
                },
                "dispositionPlace": {
                  "placeholder": "Place of Final Disposition"
                },
                "place": {
                  "placeholder": "Place"
                }
              },
              "textareas": {
                "dispositionNotes": {
                  "placeholder": "Disposition Notes"
                },
                "monumentNotes": {
                  "placeholder": "Monument Notes"
                }
              }
            },
            "family-portal": {
              "buttons": {
                "backToOverview": {
                  "text": "Back to Overview"
                },
                "copyInformation": {
                  "errorMessage": "Unable to copy this information to the case",
                  "successMessage": "Successfully copied selected information to case!"
                },
                "createNewInvite": {
                  "errorMessage": "There was an issue creating a Family Portal invite",
                  "successMessage": "Your Family Portal invite has been created. The recipient has been notified.",
                  "text": "Create New Invitation"
                },
                "removeRecipient": {
                  "confirmationMessage": "Are you sure you want to remove this invitation? The action cannot be undone.",
                  "successMessage": "Your Family Portal invite has been removed!"
                }
              },
              "newInvite": {
                "missingEmailText": "Please enter a recipient that has a valid E-mail address.",
                "placeholder": "Recipient's Name",
                "sectionTitle": "New Invite"
              },
              "pageDescription": "Allow family members to submit basic case information through our Family Portal. Once an invite is created, the recipient will receive an E-mail with a link to the Family Portal.",
              "pageTitle": "Family Portal"
            },
            "info": {
              "supportMessage": "If a Location is not listed, please contact us for support.",
              "validations": {
                "archivedLocation": "Assigned to archived location: {location}",
                "archivedUser": "Assigned to archived user: {user}",
                "immutableLocation": "This case has a posted {contractText}. Location cannot be changed.",
                "nonFuneralDirectorUser": "Assigned to user without funeral director role: {user}"
              }
            },
            "navigationItems": {
              "customFields": "Custom Fields",
              "forms": "Forms",
              "settings": "Settings"
            },
            "printables": {
              "categorySelect": {
                "placeholder": "Category",
                "placeholderLabel": "All"
              },
              "noPrintables": "No printables available",
              "pageTitle": "Available Printables",
              "search": "Name",
              "subcategorySelect": {
                "placeholder": "Subcategory",
                "placeholderLabel": "All"
              }
            },
            "service-info": {
              "serviceNotes": {
                "musicNotes": "Music Notes",
                "scriptureNotes": "Scripture Notes"
              }
            },
            "settings": {
              "addError": "Failed to sync custom fields.",
              "addSuccess": "Custom fields synced successfully.",
              "caseSent": "Case Successfully sent to TCO!",
              "deleteDuplicateContract": "Delete Duplicate Contract",
              "deleteDuplicateContractError": "Oh No! There was an error.",
              "idSuccess": "TCO ID saved successfully.",
              "includeInVirginiaVitalStatsReport": "Include in Virginia FD Monthly Vital Statistics Report",
              "jcgReportSendSurveyOptOut": "JCG Report - Send Survey Opt Out",
              "resendError": "Oh No! There was an error while trying to resend this case to TCO.",
              "resetCocError": "Oh No! There was a problem while trying to reset Chain of Custody for this case.",
              "resetCocSuccess": "Chain of Custody reset successfully.",
              "saveButton": "Save",
              "saveKaseError": "Something went wrong, please try again!",
              "saveKaseSuccess": "Case has been updated!",
              "sendCaseButton": "Send Case to TCO",
              "tcoFieldMessge": "Use the TCO ID field to link this case to an existing case in the TCO system.",
              "tcoSendCaseMessage": "Use the Send Case to TCO button for creating a new case in the TCO system and linking it to this case. This button should only ever be clicked once to avoid creating duplicate cases in the TCO system.",
              "title": "Settings",
              "unlockChainOfCustody": "Unlock Chain of Custody"
            },
            "tfe": {
              "forms": {
                "filled": {
                  "button": {
                    "refillAll": "Refill All Forms"
                  },
                  "formLibrary": "Form Library",
                  "messages": {
                    "delete": {
                      "success": "Form successfully deleted!"
                    },
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "refillAllEsignForm": "Refilling all forms will cancel eSignature requests for all forms. Are you sure?"
                  },
                  "text": {
                    "noFormsFilled": "No Forms Filled",
                    "pleaseSelectAForm": "Please select a form from the list of available forms to fill."
                  },
                  "title": "Filled Forms"
                },
                "index": {
                  "downloadTestForm": {
                    "messages": {
                      "failure": "An error occurred while downloading the form with test data. Please try again."
                    }
                  },
                  "filledForms": "Filled Forms",
                  "messages": {
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "success": "Filling process has begun!"
                  },
                  "text": {
                    "dontSeeForm": "Don't see a form you are looking for?",
                    "enableFormsFirst": "Please be sure to select forms to enable in the admin area.",
                    "noFormsEnabled": "No Forms Enabled",
                    "requestForm": "Request one."
                  },
                  "title": "Form Library"
                }
              }
            },
            "veteran-info": {
              "alertMessages": {
                "removeRecordSuccess": "Successfully Removed.",
                "saveError": "Oh No! There was an error.",
                "saveRecordSuccess": "Great job! You saved a Service Record.",
                "updateCaseSuccess": "Great job! You updated the case information."
              },
              "buttons": {
                "addNewServiceRecord": "Add New Service Record",
                "clickToAddRecordLink": "Click here",
                "onDelete": "Remove Record",
                "onSave": "Save Record"
              },
              "checkboxes": {
                "airForceCrossAward": "Air Force Cross",
                "bronzeStarAward": "Bronze Star",
                "dischargePapersReceived": "Discharge Papers Received?",
                "dstFlyingCrossAward": "DST Flying Cross",
                "dstServiceCrossAward": "DST Service Cross",
                "flag": "Flag?",
                "headstoneDesired": "Headstone Desired?",
                "isVeteran": "Veteran?",
                "medalOfHonorAward": "Medal of Honor",
                "militaryHonors": "Military Honors?",
                "navyCrossAward": "Navy Cross",
                "purpleHeartAward": "Purple Heart",
                "receivedAwards": "Received Awards?",
                "remainsNotBuried": "Remains Not Buried?",
                "requestedPresence": "Requested Presence?",
                "selectedMonument": "Selected Monument?",
                "silverStarAward": "Silver Star",
                "temporaryMarker": "Temporary Marker?"
              },
              "completableHeaderLabels": {
                "federalFormInfo": "Federal Form Info",
                "generalInfo": "General Info",
                "headstoneInfo": "Headstone Info",
                "serviceRecords": "Service Records"
              },
              "dateTimeSelectors": {
                "labels": {
                  "dischargedDate": "Date Discharged",
                  "enteredDate": "Date Entered",
                  "headstoneOrderDate": "Date Headstone Ordered",
                  "honorsEventDate": "Event Start Date",
                  "honorsEventTime": "Start Time"
                }
              },
              "descriptionText": {
                "noRecordsAvailableText": "No Service Record Information Available.",
                "toAddTextForAfterAddRecordLink": "to add",
                "vaFormText": "Some of these values come from the VA Form 40-1330"
              },
              "dropdowns": {
                "placeholderLabel": "Select One",
                "placeholders": {
                  "branch": "Military Branch",
                  "headstoneEmblem": "Emblem",
                  "headstoneMaterial": "Type of Stone",
                  "rank": "Highest Rank / Rate Attained",
                  "rating": "Rating / Job / Specialty",
                  "war": "War Fought In"
                }
              },
              "federalFormQuestions": {
                "actualBurialCost": "If Yes, Provide Actual Burial Cost",
                "burialContributionAmount": "If Yes, Provide Amount",
                "hasGovernmentOrEmployerBurialContribution": "Did a Federal/State Government or the Veteran's Employer Contribute to the Burial?",
                "hasIncurredBurialExpenses": "Did you Incur Expenses for the Veteran's Burial or Interment?",
                "hasIncurredExpensesForRemainsTransportation": "Were Expenses Incurred for the Transportation of the Veteran's Remains from the Place of Death to the Final Resting Place?",
                "hasPreviouslyReceivedAllowance": "If Deceased Veteran's Spouse, Did You Previously Receive a VA Burial Allowance?",
                "incurredExpensesForRemainsTransportationAmount": "If Yes, Provide Amount",
                "isBuriedInNationalCemetery": "Was Veteran Buried in a National Cemetery or One Owned by the Federal Government?",
                "isSeekingNonServiceConnectedBenefits": "Are You Seeking Burial Benefits for a Veteran's Non-Service-Connected Death Occurring at a VA Medical Center, Nursing Home under VA Contract, or Other VA Facility?",
                "isServiceConnectedDeath": "Is This Service-Connected Death?"
              },
              "inputFields": {
                "branchRequested": "Branch",
                "dischargeType": "Discharge Type",
                "flagInstructions": "Flag Instructions",
                "markerNotes": "Marker Notes",
                "otherBranch": "Other Branch",
                "otherWar": "Other War",
                "placeDischarged": "Place Discharged",
                "placeEntered": "Place Entered",
                "serviceNumber": "Service Number",
                "vaNumber": "VA File Number",
                "xcNumber": "XC Number"
              },
              "personSelectors": {
                "personPresentedTo": "Presented To"
              },
              "placeSelectors": {
                "placeSelector": "Place"
              },
              "textareas": {
                "headstoneEngraving": {
                  "label": "Headstone Engraving",
                  "placeholder": "Engraving"
                },
                "militaryOrganizations": {
                  "label": "Military Organizations",
                  "placeholder": "Organizations"
                },
                "notes": {
                  "label": "Notes",
                  "placeholder": "Notes"
                }
              },
              "title": "Veteran Info"
            }
          },
          "financials": {
            "adjustments": {
              "errors": {
                "postingPeriodAdd": "Cannot add an adjustment in a locked posting period. You must unlock the posting period first."
              }
            },
            "discounts": {
              "errors": {
                "postingPeriodAdd": "Cannot add a discount in a locked posting period. You must unlock the posting period first."
              }
            },
            "payments": {
              "buttons": {
                "collectionAttempt": {
                  "close": "Close",
                  "sendEmail": "Send Email"
                }
              },
              "dropdownSelection": {
                "insurancePolicy": {
                  "label": "Insurance Policy",
                  "selectionLabel": "Select a Policy"
                }
              },
              "edit": {
                "errors": {
                  "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
                  "postingPeriodAdd": "Cannot add a payment in a locked posting period. You must unlock the posting period first."
                }
              },
              "headers": {
                "paymentEmailLinkHistory": "Payment Email Link History",
                "paymentLinkEmail": "Payment Link Email",
                "personSelector": "Recipient(s)",
                "recipients": "Recipients"
              },
              "labels": {
                "personSelector": {
                  "placeholder": "Name"
                }
              },
              "messages": {
                "paymentLink": {
                  "error": "Error sending payment link email.",
                  "success": "Payment link email sent successfully."
                },
                "receiptEmail": {
                  "notSend": "A receipt will not be emailed to the payer.",
                  "send": "A receipt will be emailed to the payer."
                }
              },
              "tables": {
                "paymentLinkHistory": {
                  "headers": {
                    "dateSent": "Date Sent",
                    "linkActive": "Link Active",
                    "previousEmailLinkHistory": "Previous Email Link History",
                    "recipientEmail": "Recipient Email",
                    "recipientName": "Recipient Name"
                  },
                  "noPreviousEmailLinkHistory": "No previous payment link emails sent."
                }
              },
              "toolTips": {
                "active": "Active",
                "expired": "Expired"
              },
              "validations": {
                "collectionAttempt": {
                  "disablePaymentPostingPeriod": "Cannot add payment in a locked posting period.",
                  "paymentTypeRequired": "Payment Type is required.",
                  "peopleRequired": "At least one recipient is required.",
                  "recipientEmailRequired": "Email address is required for recipients."
                },
                "emailPaymentLink": {
                  "greaterThanOrEqualTo": "The balance must be greater than $0.50 to send a payment link."
                }
              }
            },
            "refunds": {
              "errors": {
                "postingPeriodAdd": "Cannot add a refund in a locked posting period. You must unlock the posting period first."
              },
              "inputField": {
                "refundDescription": "Refund Description"
              }
            },
            "selection": {
              "buttons": {
                "placeholders": {
                  "backToOverview": "Back to Overview",
                  "cancelUnpost": "Cancel Unposting",
                  "invalidateSignatures": {
                    "invalidate": "Invalidate ",
                    "signatures": " Signatures"
                  },
                  "save": "Save",
                  "saveAndPost": "Save and Post ",
                  "saveUnpostingReason": "Save Unposting Reason",
                  "unpost": "Unpost"
                }
              },
              "errors": {
                "missingReason": "You must provide a reason to unpost",
                "postingPeriodLockedContract": "Cannot post a {name} in a locked posting period. You must unlock the posting period first.",
                "saving": "There was a problem saving. Please contact us for support.",
                "unlockContractPostingPeriod": "Cannot unpost a {name} in a locked posting period. You must unlock the posting period first.",
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "information": {
                "badDebtAt": "on",
                "badDebtBy": "Marked as bad debt by:",
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite Invoice ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              },
              "inputs": {
                "placeholders": {
                  "purchaserRelationship": "Purchaser Relationship",
                  "reasonForUnposting": "Reason for Unposting"
                }
              },
              "successful": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "unposted": "Selection successfully unposted"
              }
            }
          },
          "integrations": {
            "maintenanceMode": "MAINTENANCE MODE",
            "maintenanceModeLong": "This service is in maintenance mode.  All communication has been paused.",
            "tco": {
              "case": {
                "errors": {
                  "deathDateInFuture": "Date of death cannot be more than 1 day in the future.",
                  "guestbookEmail": {
                    "format": "Must be a valid email address",
                    "presence": "Email cannot be blank"
                  },
                  "missingDateOfDeath": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website."
                },
                "pdfUpload": {
                  "button": "Upload PDF",
                  "label": "PDF File",
                  "viewDocument": "View File"
                },
                "webcast": {
                  "pin": {
                    "label": "Webcast PIN"
                  },
                  "url": {
                    "label": "Webcast URL"
                  }
                }
              },
              "checkboxes": {
                "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                "enableExitPops": "Enable Exit-Pops?",
                "makeGuestbookPrivate": "Make Guestbook Private?",
                "refreshOgImage": "Refresh og:image?",
                "turnOffTributeStore": "Turn Off Tribute Store?"
              },
              "facebook": {
                "errors": {
                  "locationNotSet": "You must first link this case to a service location to post to facebook.",
                  "urlNotSet": "Environment URL not set up, please contact support."
                },
                "warningMessage": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
              },
              "legacyTouch": {
                "addPin": {
                  "error": "There was a problem while adding the pin.",
                  "success": "Pin added successfully."
                },
                "confirm": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, add PIN",
                  "message": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?",
                  "title": "Confirm PIN"
                },
                "deletePin": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, delete PIN",
                  "error": "There was a problem while deleting the pin.",
                  "message": "Are you sure you want to delete this pin?",
                  "removeButtonToolTip": "Remove PIN",
                  "success": "Pin deleted successfully.",
                  "title": "Delete PIN"
                },
                "pinsForCase": "PIN's Avaliable:",
                "title": "Legacy Touch",
                "validate": {
                  "error": "Invalid PIN - please double check for typos, or reach out to ",
                  "helpCenterName": "Legacy Touch Help Center",
                  "validateButton": "Validate PIN"
                }
              },
              "placeholders": {
                "privateGuestbookEmail": "Private Guestbook Email"
              },
              "previewSection": {
                "button": "Preview",
                "description": "See how this page will look on your website before you publish it.",
                "title": "Preview"
              },
              "save": {
                "error": "Oops! There was an error saving this information to your website.",
                "success": "Website information saved successfully!"
              },
              "videoTribute": {
                "label": "Video Tribute URL"
              }
            },
            "tco-v2": {
              "buttons": {
                "backToIntegrations": "Back to Integrations",
                "save": "Save"
              },
              "errors": {
                "description": "Please add the following in order to export.",
                "labels": {
                  "failedExport": "Last Failed Export"
                },
                "list": {
                  "deathDateInTheFuture": "Date of death cannot be more than 1 day in the future.",
                  "missingDeathDate": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website.",
                  "unableToSyncWebsiteProvider": "Unable to sync with website provider. Please try again in a few minutes. If this issue persists, please contact support."
                },
                "save": "Oops! There was an error saving this information to your website."
              },
              "maintenanceMode": {
                "title": "MAINTENANCE MODE"
              },
              "sections": {
                "headerTheme": {
                  "title": "Header Theme"
                },
                "legacyTouch": {
                  "buttons": {
                    "validatePin": "Validate PIN"
                  },
                  "dialogModals": {
                    "confirm": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, add PIN"
                      },
                      "error": "There was a problem while adding the PIN.",
                      "messages": {
                        "matchingPins": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?"
                      },
                      "success": "Pin added successfully.",
                      "title": "Confirm"
                    },
                    "delete": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, delete PIN"
                      },
                      "error": "There was a problem while deleting the PIN.",
                      "success": "PIN deleted successfully.",
                      "title": "Delete PIN",
                      "warning": "Are you sure you want to delete this PIN?"
                    }
                  },
                  "errors": {
                    "invalidPin": {
                      "contact": {
                        "legacyTouchHelpCenter": "Legacy Touch Help Center"
                      },
                      "text": "Invalid PIN - Please double check for typos, or reach out to"
                    }
                  },
                  "inputFields": {
                    "pin": "PIN"
                  },
                  "pinsAvailable": {
                    "buttons": {
                      "removePin": "Remove PIN"
                    },
                    "label": "PINs Available:"
                  },
                  "title": "Legacy Touch"
                },
                "pdfFile": {
                  "buttons": {
                    "uploadPdf": "Upload PDF"
                  },
                  "links": {
                    "viewFile": "View File"
                  },
                  "title": "PDF File"
                },
                "preview": {
                  "buttons": {
                    "preview": "Preview"
                  },
                  "description": "See how this page will look on your website before you publish it.",
                  "title": "Preview"
                },
                "socialSharing": {
                  "buttons": {
                    "connectToFacebook": "Connect to Facebook"
                  },
                  "checkboxes": {
                    "postToFacebook": "Post to Facebook?"
                  },
                  "description": "Increase Traffic to your website by sharing your obituaries to social media. Connect to your company accounts now.",
                  "errors": {
                    "environmentUrlNotSet": "Environment URL not set up, please contact support.",
                    "missingServiceLocation": "You must first link this case to a service location to post to Facebook."
                  },
                  "title": "Social Sharing",
                  "warning": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
                },
                "tributes": {
                  "title": "Tributes"
                },
                "websiteSettings": {
                  "checkboxes": {
                    "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                    "disableTreeAddOn": "Disable Tree Add On?",
                    "enableExitPops": "Enable Exit-Pops?",
                    "hideBirthDate": "Hide Birth Date?",
                    "hideDeathDate": "Hide Death Date?",
                    "hideDeathNotice": "Hide Death Notice?",
                    "hideObituary": "Hide Obituary on Tribute Archive?",
                    "hideTributeBook": "Hide Tribute Book?",
                    "hideTributeWall": "Hide Tribute Wall?",
                    "makeGuestbookPrivate": "Make Guestbook Private?",
                    "refreshOgImage": "Refresh og:image?",
                    "turnOffTributeArchiveStore": "Turn Off Tribute Archive Store?",
                    "turnOffTributeStore": "Turn Off Tribute Store?"
                  },
                  "inputFields": {
                    "homepagePosition": "Homepage Position",
                    "privateGuestbookEmail": "Private Guestbook Email",
                    "videoTributeUrl": "Video Tribute URL",
                    "webcastPin": "Webcast PIN",
                    "webcastUrl": "Webcast URL"
                  },
                  "title": "Website Settings"
                },
                "websiteStatus": {
                  "subsections": {
                    "publishToWebsite": {
                      "buttons": {
                        "publish": "Publish",
                        "unpublish": "Unpublish"
                      },
                      "lastExport": {
                        "label": "Last Exported:"
                      },
                      "status": {
                        "label": "Status:",
                        "list": {
                          "notPublished": "Not Published",
                          "published": "Published"
                        }
                      },
                      "title": "Publish to Website"
                    }
                  },
                  "title": "Website Status"
                }
              },
              "successes": {
                "save": "Website information saved successfully!"
              },
              "validations": {
                "email": {
                  "cannotBeBlank": "Email cannot be blank.",
                  "mustBeValidEmail": "Must be a valid email address."
                }
              }
            },
            "tribute-print": {
              "pageTitle": "Tribute Print",
              "status": {
                "label": "Tribute Print Status:"
              },
              "storeLink": "Go to store"
            },
            "tribute-video": {
              "buttons": {
                "backToIntegrations": {
                  "label": "Back to Integrations"
                }
              },
              "fields": {
                "select": {
                  "photoAlbums": {
                    "label": "Photo Albums",
                    "options": {
                      "noAlbum": "No Album",
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "instructions": {
                "missingData": {
                  "location": "To export this case, please make sure that the {location} location has address details and an email assigned."
                },
                "noAlbumSelection": "No photos will be sent from Tribute Management Software to Tribute Video. Photos added through Tribute Video will still sync back into Tribute Management Software.",
                "videoDownload": {
                  "browserOptions": {
                    "chromeEdgeFirefox": {
                      "label": "Chrome, Edge, and Firefox:",
                      "optionText": "Save Link As..."
                    },
                    "safari": {
                      "label": "Safari:",
                      "optionText": {
                        "option1": "Download Linked File",
                        "option2": "Download Linked File As...",
                        "or": "or"
                      }
                    }
                  },
                  "label": "Video Download Instructions:",
                  "step1": "Right-click on \"Download Video\"",
                  "step2": "Choose the download option presented by your browser:"
                }
              },
              "links": {
                "downloadVideo": {
                  "label": "Download Video"
                },
                "editVideo": {
                  "errorMessage": "Oops! There was an error when attempting to edit the video.",
                  "label": "Edit Video"
                },
                "export": {
                  "label": "Export"
                },
                "getVideoStatus": {
                  "label": "Get Video Status"
                },
                "viewVideo": {
                  "label": "View Video"
                }
              },
              "pageTitle": "Tribute Video",
              "statuses": {
                "export": {
                  "label": "Status:",
                  "listOfStatuses": {
                    "exported": "Exported",
                    "notExported": "Not Exported"
                  }
                },
                "lastExported": {
                  "label": "Last Exported:"
                },
                "video": {
                  "label": "Video Status:",
                  "listOfStatuses": {
                    "error": "Error",
                    "noStatus": "Please click the link below to update the status.",
                    "published": "Published"
                  }
                }
              }
            },
            "tukios-v2": {
              "backLabel": "Back to Integrations",
              "coverPhotos": {
                "label": "Select a Tukios Cover Photo",
                "placeholder": "Tukios Cover Photo (Optional)"
              },
              "errors": {
                "emptyKaseDates": "Birth date and Death date are required.",
                "invalidContacts": "Family & friends must have a last name.",
                "invalidDeathDate": "Error: Death date is in the future."
              },
              "eventsLabel": "Events:",
              "exportButton": {
                "export": "Export",
                "resync": "Resync"
              },
              "status": {
                "exported": "Exported",
                "label": "Status:",
                "lastExported": "Last Exported:",
                "notExported": "Not Exported"
              },
              "title": "Tukios V2"
            }
          }
        },
        "overview": {
          "pendingEsignatures": {
            "mutlipleDeclinedSigners": "signers have declined to sign.",
            "noPendingEsignatures": "No pending eSignatures",
            "oneDeclinedSigner": "1 signer has declined to sign.",
            "status": "Status",
            "title": "Pending eSignatures"
          }
        }
      },
      "misc-sales": {
        "create": {
          "errors": {
            "gplNotConfigured": "This location does not have a configured GPL. Please contact us for support."
          },
          "pageTitle": "New Misc Sale"
        },
        "edit": {
          "details": {
            "errors": {
              "postingPeriodLocked": "Cannot post a sale in a locked posting period. You must unlock the posting period first."
            },
            "inputs": {
              "placeholders": {
                "save": "Save",
                "saveAndPostSale": "Save and Post Sale",
                "unpostSale": "Unpost Sale"
              }
            },
            "messages": {
              "success": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "miscellaneousSaleUpdated": "Misc Sale has been updated.",
                "pulledFromInventory": "Product pulled from Inventory!",
                "returnedFromInventory": "Product returned to Inventory!"
              }
            },
            "pageTitle": "Misc Sale",
            "sections": {
              "location": {
                "disclaimer": "If a Location is not listed, please contact us for support."
              }
            },
            "selection": {
              "information": {
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              }
            }
          },
          "payments": {
            "disclaimers": {
              "receiptEmail": {
                "willSend": "A receipt will be emailed to the payer.",
                "wontSend": "A receipt will not be emailed to the payer."
              }
            },
            "errors": {
              "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us."
            },
            "sections": {
              "totals": {
                "header": "Totals",
                "subsections": {
                  "adjustments": "Adjustments Total",
                  "payments": "Payments Total",
                  "refunds": "Refunds Total",
                  "remaining": "Remaining Balance",
                  "selection": "Selection Total"
                }
              }
            }
          },
          "refunds": {
            "inputField": {
              "refundDescription": "Refund Description"
            }
          }
        }
      },
      "online-planner-plans": {
        "actions": {
          "archive": {
            "error": "There was a problem archiving that plan, please try again.",
            "success": "Plan archived successfully.",
            "text": "Archive"
          },
          "convert": "Promote to Case",
          "unarchive": {
            "error": "There was a problem unarchiving that plan, please try again.",
            "success": "Plan unarchived successfully.",
            "text": "Unarchive"
          },
          "view": "View Plan Details"
        },
        "clearSearch": "Clear",
        "dropdowns": {
          "atneedOption": "At Need",
          "caseOption": "Promoted to Case",
          "inProgressOption": "In Progress",
          "placeholder": "All",
          "plannerPlaceholder": "All Planners",
          "preneedOption": "Preneed",
          "statusPlaceholder": "All Statuses",
          "submittedOption": "Submitted",
          "typePlaceholder": "All Types"
        },
        "edit": {
          "backButton": "Back to Plans",
          "convertCaseButton": {
            "action": "Promote to Case",
            "ask": "Are you sure you want to promote this plan to a case?",
            "cancelButton": "Cancel",
            "chooseLocation": "Which location do you want assigned to this case?",
            "dropdown": {
              "label": "Location",
              "placeholder": "Select One"
            },
            "promoted": "This plan has been promoted to a case.",
            "submitButton": "Submit"
          },
          "documents": {
            "promotedCaseDocumentMessage": "This plan has already been promoted and any documents uploaded are only viewable in the case."
          },
          "exportPdfButton": "Export Plan to PDF",
          "payments": {
            "account": "Account",
            "amountPaid": "Amount Paid",
            "chargeId": "Charge ID",
            "endingIn": "ending in",
            "madeBy": "Payment Made By",
            "notCollected": "$0.00 - A payment was not collected.",
            "type": "Payment Type"
          },
          "people": {
            "nextOfKinLabel": "Next of kin",
            "otherContactsLabel": "Other contacts",
            "primaryContactLabel": "Primary contact"
          },
          "selections": {
            "discountLabel": "Discounts",
            "package": "Package",
            "products": "Products",
            "quantity": "Quantity",
            "shippingLabel": "Shipping Total",
            "subtotalAfterDiscountsLabel": "Subtotal",
            "subtotalLabel": "Selections Total",
            "taxLabel": "Taxes",
            "totalLabel": "Total",
            "totalPaid": "Total Paid"
          },
          "title": "Plan Details"
        },
        "promote": {
          "cancel": "Cancel",
          "error": "There was a problem promoting that plan to a case, please try again.",
          "location": "Which location do you want assigned to this case?",
          "locationDropOption": "Location",
          "submit": "Submit",
          "success": "Plan promoted to case successfully.",
          "toCase": "Are you sure you want to promote this plan to a case?"
        },
        "table": {
          "headers": {
            "actions": "Actions",
            "created": "Created By",
            "planner": "Planner",
            "planningFor": "Planning For",
            "status": "Status",
            "type": "Type",
            "updated": "Last Updated"
          }
        },
        "title": "Plans",
        "tooltips": {
          "case": "Promoted to Case",
          "inProgress": "In Progress",
          "submitted": "Submitted"
        }
      },
      "payments": {
        "adjustments": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "pageTitle": "Adjustments",
          "table": {
            "headers": {
              "adjustedBy": "Adjusted By",
              "amount": "Amount",
              "date": "Date",
              "kase": "Case",
              "lineItem": "Line Item",
              "type": "Type"
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addNew": "Add New",
            "cancelBatchPayment": "Cancel Batch Payment",
            "cancelBatchPaymentEdit": "Cancel Batch Payment Edit",
            "clear": "Clear",
            "clearLocation": "Clear Location",
            "saveBatchPayment": "Save Batch Payment",
            "submitBatchPayment": "Submit Batch Payment",
            "submitPayment": "Submit Payment"
          },
          "checkboxes": {
            "batchPayment": "Is this a Batch Payment?"
          },
          "errors": {
            "insuranceAssignmentsUnsopported": "Batch Payments currently do not support insurance assignments.",
            "missingInfo": "Make sure all of the information is correct and/or inputted",
            "selectDifferentPaymentType": "Please select a different Payment Type."
          },
          "labels": {
            "batchPaymentAmountRemaining": "Batch Payment Amount Remaining",
            "createNewBatchPayment": "Create New Batch Payment",
            "createNewPayment": "Create New Payment",
            "editBatchPayment": "Edit Batch Payment",
            "editPayment": "Edit Payment",
            "location": "Location",
            "paymentInfo": "Payment Information"
          },
          "pageTitle": {
            "batchPayments": "Batch Payments",
            "payments": "Payments"
          },
          "placeholders": {
            "amount": "Amount",
            "checkNumber": "Check Number",
            "endDate": "End Date",
            "filterByLocation": "Filter by Organization or Location",
            "name": "Name",
            "note": "Note",
            "otherPaymentSource": "Other Payment Source",
            "payer": "Payer Name",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type",
            "selectOne": "Select One",
            "startDate": "Start Date"
          },
          "table": {
            "headers": {
              "payer": "Payer"
            }
          }
        },
        "pageTitle": "Payments",
        "payouts": {
          "dateFields": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "inputFields": {
            "maximumAmount": "Maximum Amount",
            "minimumAmount": "Minimum Amount"
          },
          "links": {
            "downloadExcelReport": "Download Excel Report",
            "hideDetails": "Hide Details",
            "viewDetails": "View Details"
          },
          "pageTitle": "Payouts",
          "selectFields": {
            "tributePayAccount": {
              "label": "Tribute Pay Account",
              "options": {
                "all": "All"
              }
            }
          },
          "tables": {
            "details": {
              "data": {
                "charge": "Charge",
                "refund": "Refund",
                "transfer": "Transfer"
              },
              "headers": {
                "tpsId": "TPS ID",
                "transactionAmount": "Transaction Amount",
                "type": "Type"
              }
            },
            "main": {
              "headers": {
                "actions": "Actions",
                "bankAccount": "Bank Account",
                "date": "Date",
                "totalPayout": "Total Payout",
                "tributePayAccount": "Tribute Pay Account"
              },
              "missingData": {
                "payouts": "There are no payouts."
              }
            }
          }
        },
        "sideNav": {
          "batchPayments": "Batch Payments",
          "payments": "Payments",
          "payouts": "Payouts",
          "transactions": "Transactions"
        },
        "transactions": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "downloadCSV": "Download CSV"
          },
          "pageTitle": "Transactions",
          "selectFields": {
            "label": "Payment Type",
            "options": {
              "selectOne": "Select One"
            }
          },
          "statuses": {
            "failed": "Failed",
            "pending": "Pending",
            "successful": "Successful"
          },
          "table": {
            "headers": {
              "actions": "Actions",
              "date": "Date",
              "fee": "Fee",
              "grossAmount": "Gross Amount",
              "netAmount": "Net Amount",
              "paymentBy": "Payment By",
              "paymentFor": "Payment For",
              "saleId": "Sale ID",
              "status": "Status",
              "type": "Type"
            }
          }
        }
      },
      "places": {
        "edit": {
          "fields": {
            "select": {
              "general": {
                "defaultValue": "Select One"
              },
              "placeType": {
                "placeholder": "Place Type"
              }
            }
          }
        }
      },
      "websites": {
        "pageTitle": "Websites",
        "searchField": {
          "label": "Search",
          "resetButton": {
            "label": "Clear"
          }
        },
        "websiteTable": {
          "headers": {
            "website": "Website",
            "websiteDescription": "Description"
          },
          "warnings": {
            "noResults": "No websites found."
          }
        }
      }
    },
    "components": {
      "client-link-asd": {
        "buttons": {
          "linkAccount": "Link Account"
        },
        "checkboxes": {
          "disableImport": "Disable Import",
          "syncCasesAutomatically": "Sync Cases Automatically?"
        },
        "dateSelector": {
          "importAfterDate": "Import After Date (Optional)"
        },
        "descriptionWarning": "Required if multiple of the same integration",
        "disclaimer": "This client is linked with ASD. Please visit the ASD portal, retreive the location remote IDs, and enter them into the appropriate locations in Tribute Management Software.",
        "inputFields": {
          "asdPassword": "ASD Password",
          "mailboxID": "Mailbox ID"
        },
        "pageTitle": "Link ASD Account"
      },
      "contact-history": {
        "caseTable": {
          "headers": {
            "actions": "Actions",
            "caseStatus": "Case Status",
            "caseSubject": "Case Subject",
            "relationship": "Relationship"
          },
          "link": "View Case"
        },
        "headers": {
          "cases": "Cases",
          "inquiries": "Inquiries"
        },
        "inquiryTable": {
          "headers": {
            "actions": "Actions",
            "createdAt": "Created At",
            "createdBy": "Created By",
            "inquiryType": "Inquiry Type"
          },
          "link": "View Inquiry"
        }
      },
      "crakn-event-scheduler": {
        "checkboxes": {
          "export": {
            "bassMollett": "Export to Bass-Mollett?",
            "cfs": "Export to CFS?",
            "frazer": "Export to Frazer?",
            "frontRunner": "Export to Frontrunner?",
            "funeralOne": "Export to FuneralOne?",
            "funeralTech": "Export to FuneralTech?",
            "messenger": "Export to Messenger?",
            "tukios": "Export to Tukios?"
          },
          "hideTcoEndTime": "Hide End Time from Website?"
        },
        "warnings": {
          "addressPlace": "The selected place does not have an address or is missing required fields (i.e., address line 1, city, state/providence, zip/postal code). This could impact any flower or associated deliveries, including cancelation or inability to complete deliveries.",
          "bassMollett": "To export this event to Bass-Mollett, the Event Name and Place Name must be filled in.",
          "cfs": "To export to CFS, this Event must have a name.",
          "frazer": "To export to Frazer, this event must have a place with a valid address, phone number, and postal code.",
          "frontRunner": "To export this event to FrontRunner, this event must have a place with a valid address and FrontRunner Event Type must be filled in.",
          "funeralOne": "To export this event to FuneralOne, the Event Name and Place Name must be filled in.",
          "funeralTech": "To export this event to FuneralTech, this event must have a place with a valid address (Country, City, State, Zip code, Address), and the FuneralTech Event Type must be filled in.",
          "messenger": "To export to Messenger, the Event Place and Messenger Event Type must be filled in.",
          "tukios": "To export to Tukios, this Event must have a place name, full address, and duration."
        },
        "webcast": {
          "pin": {
            "label": "Webcast PIN"
          },
          "url": {
            "label": "Webcast URL"
          }
        }
      },
      "crakn-kase-contact-card": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "contactDeceased": "Deceased",
        "messages": {
          "relationship": {
            "save": {
              "error": "There was a problem saving this relationship.",
              "success": "Relationship saved!"
            }
          }
        },
        "tooltips": {
          "currentNextOfKin": "Current Next of Kin",
          "currentPrimaryContact": "Current Primary Contact",
          "editContact": "Edit Contact",
          "editRelationship": "Edit Relationship",
          "makeNextOfKin": "Make Next of Kin",
          "makePrimaryContact": "Make Primary Contact",
          "removeContact": "Remove Contact"
        }
      },
      "crakn-kase-form": {
        "errors": {
          "formProcessing": {
            "contactEmail": "support@tributetms.com",
            "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
          }
        }
      },
      "crakn-place-editor": {
        "fields": {
          "select": {
            "placeType": {
              "options": {
                "selectOne": "Select One"
              },
              "placeholder": "Place Type"
            }
          }
        }
      },
      "dashboard": {
        "dash-icon-nav": {
          "icons": {
            "placeholders": {
              "calendar": "Calendar",
              "cases": "Cases",
              "contacts": "Contacts",
              "inquiries": "Inquiries",
              "whiteboard": "Whiteboard"
            }
          }
        }
      },
      "esignatures": {
        "dashboard": {
          "form-table-row": {
            "actions": {
              "cancelForm": "Cancel Request",
              "resendToPendingSigners": "Resend to Pending Signers",
              "resendToSigner": "Resend to Signer"
            }
          },
          "kase-table": {
            "noResultsFound": "No results found",
            "table": {
              "headers": {
                "actions": "Actions",
                "case": "Case",
                "location": "Location"
              }
            }
          },
          "kase-table-row": {
            "actions": {
              "hidePendingForms": "Hide Forms",
              "showPendingForms": "Show Forms"
            }
          },
          "search": {
            "clear": "Clear",
            "placeholders": {
              "allLocations": "All",
              "placeholder": "Location",
              "searchCases": "Search Cases",
              "searchSigners": "Search Signers"
            }
          }
        }
      },
      "g": {
        "admin": {
          "ar-adjustment": {
            "create": {
              "add": "Add Adjustment",
              "cancel": "Cancel Adjustment",
              "confirm": "This will zero out all remaining balances for selections with a contract date between {start} and {end}. Are you sure?",
              "new": "New",
              "placeholders": {
                "endDate": "End date",
                "gainType": "Gain adjustment type",
                "location": "Select location",
                "lossType": "Loss adjustment type",
                "note": "Note",
                "requested": "Requested by",
                "selectOne": "Select one",
                "startDate": "Start date"
              },
              "saveError": "There was an error saving the adjustment. Please try again.",
              "saveSuccess": "The adjustment has been successfully saved.",
              "title": "Add new adjustment"
            }
          },
          "custom-field-config": {
            "create-edit-field": {
              "addOption": "+ Add Option",
              "fieldTextInput": {
                "placeholder": "Field Text",
                "validationMessages": "Field text is required"
              },
              "fieldTypeSelect": {
                "options": {
                  "boolean": "Checkbox",
                  "date": "Date Picker",
                  "select": "Drop Down",
                  "text": "Text"
                },
                "placeholder": "Field Type",
                "placeholderLabel": "Select One",
                "validationMessage": "A field type is required."
              },
              "footerButtons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "save": "Save"
              },
              "noOptions": "No options created."
            }
          },
          "general-price-lists": {
            "edit": {
              "packages": {
                "package-contents": {
                  "banners": {
                    "info": "A package may consist of items and/or flexible items. Items are specific products that can be removed, but not changed during the selection process. Flexible items allow for custom selections from a set product category."
                  },
                  "buttons": {
                    "cancel": "Cancel",
                    "delete": "Delete",
                    "editProducts": "Edit Products",
                    "save": "Save Item"
                  },
                  "checkboxes": {
                    "preventRemovalOfItem": "Prevent removal of item from package?",
                    "setAllowanceAmount": "Set allowance amount?",
                    "subtractFromDiscount": "If removed, subtract item value from package discount before package total?"
                  },
                  "fields": {
                    "input": {
                      "allowanceAmount": {
                        "placeholder": "Allowance Amount ($)",
                        "validationMessage": "Please enter a valid allowance amount."
                      }
                    },
                    "select": {
                      "product": {
                        "options": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product"
                      },
                      "productCategory": {
                        "disclaimers": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product Category"
                      },
                      "type": {
                        "options": {
                          "flexible_item": "Flexible Item",
                          "item": "Item"
                        },
                        "placeholder": "Type"
                      },
                      "unspentAllowanceAccount": {
                        "placeholder": "Unspent Allowance Account",
                        "validationMessage": "Please select an unspent allowance account."
                      }
                    }
                  },
                  "lists": {
                    "products": {
                      "all": {
                        "label": "Note:",
                        "text": "All products in this category will be enabled. You can edit products after saving the item."
                      },
                      "enabled": "{amount} products enabled in this category."
                    }
                  },
                  "sections": {
                    "products": "Products"
                  },
                  "title": {
                    "edit": "Edit Item",
                    "new": "Add New Item"
                  },
                  "tooltips": {
                    "allowanceAmount": "An allowance is a set amount customers can spend on products in this category. If the allowance is exceeded, the difference will be added to their package total."
                  },
                  "validations": {
                    "item": {
                      "delete": {
                        "success": "Item successfully removed from package!"
                      },
                      "save": {
                        "success": {
                          "exisitingItem": "Item saved!",
                          "newItem": "Item successfully added to package!"
                        }
                      }
                    }
                  }
                }
              }
            },
            "statement-categories": {
              "list": {
                "buttons": {
                  "text": {
                    "cancel": "Cancel",
                    "save": "Save"
                  }
                },
                "deleteDialog": {
                  "promptMessage": "Archive {name}?"
                },
                "fields": {
                  "placeholders": {
                    "name": "Name"
                  }
                },
                "messages": {
                  "delete": {
                    "success": "{statementCategoryName} was archived successfully!"
                  },
                  "error": "Something went wrong.",
                  "save": {
                    "success": "Statement Category successfully saved!"
                  }
                }
              }
            }
          },
          "integrations": {
            "disclaimers": {
              "ciclops": {
                "disclaimer": {
                  "list": {
                    "caseEvents": "Case Events",
                    "caseFamilyAndFriends": "Case Family & Friends",
                    "caseImage": "Case Image",
                    "deceasedName": "Deceased name",
                    "deceasedVitalStatistics": "Deceased vital statistics",
                    "obituary": "Obituary",
                    "pallbearers": "Pallbearer(s)",
                    "veteranInfo": "Veteran Info"
                  },
                  "mainText": "The following information may be sent to Ciclops:"
                }
              },
              "tribute-print": {
                "disclaimer": "The following information may be sent to Tribute Print:",
                "informationList": {
                  "caseEvents": "Case Events",
                  "caseFamilyAndFriends": "Case Family & Friends",
                  "caseImage": "Case Image",
                  "deceasedName": "Deceased Name",
                  "deceasedVitalStatistics": "Deceased Vital Statistics",
                  "obituary": "Obituary",
                  "pallbearers": "Pallbearer(s)",
                  "veteranInfo": "Veteran Info"
                }
              },
              "tribute-video": {
                "informationSentToTributeVideo": {
                  "disclaimer": "The following information may be sent to Tribute Video:",
                  "list": {
                    "casePhotos": "Case Photos",
                    "deceasedName": "Deceased name",
                    "deceasedVitalStatistics": "Deceased vital statistics",
                    "locationAddress": "Location address"
                  }
                },
                "privacyNoticeAndConditionsOfUse": {
                  "disclaimer": "By exporting to Tribute Video, you agree to their following policies and conditions:",
                  "list": {
                    "conditionsOfUse": "Conditions of Use",
                    "privacyNotice": "Privacy Notice"
                  }
                }
              }
            }
          },
          "online-planner": {
            "user-notification-list": {
              "alerts": {
                "remove": {
                  "error": "Oops! There was an error when removing the recipient.",
                  "success": "Success! The recipient was removed successfully."
                }
              }
            }
          },
          "tribute-pay": {
            "notification-settings": {
              "locations": {
                "buttons": {
                  "addLocation": "Add Location for Notifications"
                },
                "sections": {
                  "locationsList": {
                    "title": "Locations"
                  }
                }
              },
              "user-list": {
                "alerts": {
                  "location": {
                    "add": {
                      "error": "Oops! There was an error when adding the location.",
                      "success": "Success! The location was added successfully."
                    },
                    "remove": {
                      "error": "Oops! There was an error when removing the location.",
                      "success": "Success! The location was removed successfully."
                    }
                  },
                  "recipient": {
                    "remove": {
                      "error": "Oops! There was an error when removing the recipient.",
                      "success": "Success! The recipient was removed successfully."
                    }
                  }
                },
                "buttons": {
                  "delete": {
                    "tooltip": "Delete Recipient"
                  },
                  "edit": {
                    "tooltip": "Edit"
                  }
                },
                "list": {
                  "details": {
                    "locations": "Location(s):"
                  }
                }
              }
            }
          },
          "tribute-pay-payments": {
            "accounts": {
              "edit": {
                "locations": {
                  "alerts": {
                    "missingData": {
                      "locations": {
                        "noFinancialConfiguration": "To add a location, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no locations assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addLocation": "Add Location"
                  },
                  "list": {
                    "locations": {
                      "mainText": "Locations assigned to this Tribute Pay Account:"
                    }
                  },
                  "sectionTitle": "Locations",
                  "selectFields": {
                    "location": "Location",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                },
                "payment-types": {
                  "alerts": {
                    "missingData": {
                      "paymentTypes": {
                        "noFinancialConfiguration": "To add a payment type, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no payment types assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addPaymentType": "Add Payment Type"
                  },
                  "inputFields": {
                    "paymentType": "Payment Type"
                  },
                  "list": {
                    "paymentTypes": {
                      "account": "Account:",
                      "mainText": "Payment Types assigned to this Tribute Pay Account:",
                      "unsavedPaymentTypes": "Unsaved Payment Types:"
                    }
                  },
                  "sectionTitle": "Payment Types",
                  "selectFields": {
                    "glAccount": "GL Account",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "table-row": {
                "links": {
                  "archive": "Archive",
                  "edit": "Edit"
                },
                "messages": {
                  "error": "There was an error when attempting to archive the account.",
                  "success": "The account was successfully archived."
                },
                "statuses": {
                  "archived": "Archived"
                },
                "table": {
                  "body": {
                    "noAccounts": "No Tribute Pay accounts to display."
                  }
                }
              }
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addPayments": "Add Payment(s)",
            "clearCaseAndPayments": "Clear Case & Payments",
            "closeCaseAndPayments": "Close Case & Payments"
          },
          "errors": {
            "greaterThan": "Payment amount must be greater than 0.",
            "mustNotExceed": "Payment amount must not exceed the remaining batch payment amount."
          },
          "headers": {
            "amount": "Amount",
            "payer": "Payer",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type"
          },
          "labels": {
            "addPayments": "Add Payments",
            "balanceDue": "Balance Due",
            "individualPayments": "Individual Payments",
            "theFollowingPaymentsWillBeDeleted": "The following payments will be deleted:",
            "totalCaseBalance": "Total Case Balance"
          },
          "placeholders": {
            "isThisMiscSale": "Is it for a Misc Sale?",
            "paymentAmount": "Payment Amount",
            "selectACase": "Select a Case"
          },
          "summary": {
            "dialogBoxes": {
              "areYouSure": "Are you sure?"
            },
            "headers": {
              "paymentDetails": "Payment Details"
            },
            "labels": {
              "customField1": "Custom Field 1",
              "customField2": "Custom Field 2",
              "note": "Note",
              "payerEmail": "Payer Email",
              "paymentFor": "Payment For"
            }
          },
          "text": {
            "thereAreNoMiscSales": "There are no Misc Sales."
          }
        },
        "crakn": {
          "autocomplete": {
            "blank-form": {
              "placeholder": "Form"
            },
            "poem": {
              "placeholder": "Poem Or Prayer"
            }
          },
          "field": {
            "quill": {
              "hyperlink": {
                "termsOfUse": {
                  "accept": "Accept and Continue",
                  "deny": "Decline",
                  "error": "There was a problem agreeing to the hyperlink terms of use. If this continues please contact support.",
                  "terms": "This feature gives you the ability to hyperlink to third-party content (the “Hyperlink Feature”). By accessing the Hyperlink Feature, you (and the entity that you represent) agree that: (a) your use of the Hyperlink Feature shall not infringe upon any third party's intellectual property rights and (b) you shall not and will not permit any third party to use the Hyperlink Feature to connect to or direct individuals to a third-party e-commerce website that has not been previously approved by us, in our sole discretion. The foregoing sentence does not apply to websites that direct individuals to a website for charitable giving. We may at any time restrict or suspend your use of the Hyperlink Feature.",
                  "title": "Obituary Hyperlink Terms of Use"
                }
              }
            },
            "select": {
              "location": {
                "selectField": {
                  "options": {
                    "selectOne": "Select One"
                  },
                  "placeholder": "Locations"
                }
              },
              "organization-location": {
                "fields": {
                  "select": {
                    "organizationLocation": {
                      "options": {
                        "all": "All"
                      },
                      "placeholder": "Organization / Location"
                    }
                  }
                },
                "groups": {
                  "locations": "Locations",
                  "organizations": "Organizations"
                }
              },
              "statement-category": {
                "fields": {
                  "select": {
                    "statementCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Category"
                    },
                    "statementSubcategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Subcategory"
                    }
                  }
                }
              }
            }
          },
          "financials": {
            "beaconfp-resend": {
              "buttons": {
                "confirm": "Force Resend will clear all NetSuite ID's. Do you wish to continue?",
                "resend": "Resend To Netsuite"
              },
              "lists": {
                "on": "on",
                "resendListTitle": "Netsuite Resend History",
                "resentBy": "Resent by"
              }
            }
          },
          "integrations": {
            "edit-integration": {
              "buttons": {
                "cancel": "Cancel",
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save",
                "updateIntegration": "Update Integration"
              },
              "sections": {
                "locationBased": {
                  "missingLocations": "Please save the integration before modifying its locations."
                },
                "websitePaymentCredentials": {
                  "key": "Key: {key}",
                  "secret": "Secret: {secret}",
                  "title": "Website Payment Credentials",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Credentials can only be viewed once.",
                    "noCredentials": "No Credentials",
                    "writeThisDown": "Write this down!"
                  }
                },
                "websitePaymentType": {
                  "placeholder": "Website Payment Type",
                  "placeholderLabel": "Select a Payment Type",
                  "title": "Website Payment Type"
                },
                "websitePayments": {
                  "missingInfo": "You must have at least one tribute pay account approved and general payment enabled to set up website payments."
                }
              }
            },
            "form-fields": {
              "inputs": {
                "precoaNumberOfDays": {
                  "description": {
                    "defaultAmount": "Default = 7",
                    "main": "How many days do you want Precoa to wait before contact is initiated with exported TMS contacts?"
                  },
                  "placeholder": "Number of Days"
                }
              },
              "selectors": {
                "user": {
                  "importantInformation": "*If the desired user does not show up here, make sure they have a valid phone number.",
                  "placeholder": "User"
                }
              }
            },
            "integration-service-information": {
              "confirmDelete": {
                "prompt": "Are you sure you want to remove this integration?"
              },
              "sections": {
                "locationBased": "Location Based: {locationBased}",
                "locationNames": "Location(s): {locationNames}",
                "logo": "Logo: {logo}"
              }
            }
          },
          "list": {
            "packages": {
              "flexible-items": {
                "details": {
                  "numberOfProducts": "{amount} products"
                }
              },
              "item": {
                "disclaimers": {
                  "archived": "(Archived)",
                  "notInPackage": "(Not in Package)"
                }
              }
            }
          },
          "pagination-buttons": {
            "buttons": {
              "next": "Next",
              "previous": "Previous"
            },
            "text": {
              "currentPage": "Page",
              "currentPageOf": "of"
            }
          },
          "payments": {
            "transactions": {
              "table-row": {
                "alerts": {
                  "refund": {
                    "fail": "Oh no! There was an issue processing this refund.",
                    "success": "Success! The payment was refunded."
                  }
                },
                "buttons": {
                  "refund": "Refund",
                  "viewDetails": "View Details"
                },
                "sections": {
                  "refund": {
                    "buttons": {
                      "cancel": "Cancel",
                      "refund": "Refund"
                    },
                    "fields": {
                      "input": {
                        "refundAmount": {
                          "placeholder": "Refund Amount"
                        }
                      }
                    }
                  },
                  "transactionDetails": {
                    "data": {
                      "labels": {
                        "address": "Address",
                        "city": "City",
                        "country": "Country",
                        "email": "Email",
                        "name": "Name",
                        "phone": "Phone",
                        "state": "State",
                        "tpsId": "TPS ID",
                        "zipCode": "Zip"
                      }
                    },
                    "header": "Transaction Details",
                    "statuses": null
                  }
                }
              }
            }
          },
          "selector": {
            "account": {
              "placeholderLabel": "Select an Account"
            }
          },
          "v2": {
            "kase-typeahead": {
              "listHeading": "Case",
              "placeholder": "Case"
            },
            "person-selector": {
              "buttons": {
                "cancelContact": "Cancel Contact",
                "saveContact": "Save Contact"
              },
              "inputs": {
                "placeholders": {
                  "email": "Email",
                  "firstName": "First Name",
                  "lastName": "Last Name",
                  "middleName": "Middle Name",
                  "phonePrimary": "Phone Primary",
                  "phoneSecondary": "Phone Secondary"
                }
              },
              "saveWarning": "Please type an existing person, or create a new person by clicking the add button and then saving.",
              "validations": {
                "email": "Email is required.",
                "firstName": "First Name is required.",
                "lastName": "Last Name is required."
              }
            }
          }
        },
        "kases": {
          "create": {
            "check-duplicate-kase": {
              "description": "The deceased information matches previously created records. Please verify case is not a duplicate.",
              "table": {
                "header": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "labelsAtSmallerScreens": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "link": {
                  "viewCase": "View Case"
                }
              },
              "title": "Duplicate Case Warning"
            },
            "kase": {
              "button": {
                "createCase": "Create Case"
              },
              "checkbox": {
                "deathNotice": {
                  "label": "Hide Death Notice?",
                  "tooltip": "Death notices post deceased info to your website before the obituary is published."
                },
                "preneed": {
                  "label": "Is Preneed?"
                }
              },
              "input": {
                "socialInsuranceNumber": {
                  "label": "Social Insurance Number"
                },
                "socialSecurityNumber": {
                  "label": "Social Security Number"
                }
              },
              "link": {
                "backToCases": "Back to Cases"
              },
              "selector": {
                "caseName": {
                  "errorMessage": "This field can't be blank",
                  "label": "Case Full Name"
                },
                "datePicker": {
                  "birthDate": {
                    "label": "Birth Date"
                  },
                  "deathDate": {
                    "errorMessage": "Please enter a date on or after birth date",
                    "label": "Death Date"
                  }
                },
                "location": {
                  "errorMessage": "This field can't be blank",
                  "label": "Select One"
                }
              },
              "title": "Case Information"
            }
          },
          "manage": {
            "edit": {
              "deceased": {
                "death-info": {
                  "checkboxes": {
                    "saveToPlaces": {
                      "disclaimer": "Place Name is required to save this Place.",
                      "label": "Save To Places?"
                    }
                  },
                  "fields": {
                    "input": {
                      "placeName": {
                        "placeholder": "Place Name"
                      }
                    }
                  }
                }
              },
              "documents": {
                "album-edit": {
                  "album": {
                    "buttons": {
                      "add": {
                        "label": "Add Photo"
                      },
                      "cancel": {
                        "label": "Cancel"
                      },
                      "private": {
                        "label": "Private"
                      },
                      "public": {
                        "label": "Public"
                      },
                      "save": {
                        "label": "Save Album"
                      }
                    },
                    "description": {
                      "label": "Description"
                    },
                    "file-upload": {
                      "uploadText": "Uploading"
                    },
                    "name": {
                      "label": "Album Name"
                    },
                    "photos": {
                      "label": "Album Photos",
                      "message": "Select or upload photos."
                    }
                  },
                  "availablePhotos": {
                    "archived": "Archived",
                    "buttons": {
                      "add": "Add to album",
                      "expand": "View full size image"
                    },
                    "label": "All Photos",
                    "warning": "Select or upload photos."
                  },
                  "title": {
                    "edit": "Edit Album",
                    "new": "New Album"
                  }
                }
              },
              "printables": {
                "list-item": {
                  "personSelect": {
                    "placeholder": "Person",
                    "placeholderLabel": "Select One"
                  },
                  "poemSearch": {
                    "placeholder": "Poem or Prayer"
                  },
                  "useCustomPoem": "Custom Poem or Prayer?"
                }
              }
            },
            "financials": {
              "selection": {
                "package": {
                  "page": {
                    "backToSelection": {
                      "notSelectedPrompt": "Are you sure you want go to back? You have unsaved selections.",
                      "selectedPrompt": "Are you sure?"
                    }
                  },
                  "summary": {
                    "header": "Package Summary",
                    "itemSubtotal": "Subtotal",
                    "noSelection": "No selection made.",
                    "packageDiscount": "Package Discount",
                    "packageSubtotal": "Package Subtotal",
                    "packageTotal": "Package Total",
                    "save": {
                      "label": "Save package",
                      "notSelectedPrompt": "You are missing required selections that must be made before posting. Are you sure you want to save this package now?",
                      "selectedPrompt": "Are you sure?"
                    },
                    "tax": {
                      "label": "Tax",
                      "tbd": "TBD",
                      "tooltip": "Tax will be calculated after saving this package."
                    }
                  }
                }
              }
            }
          }
        },
        "legal": {
          "acknowledgement": {
            "button": {
              "accept": {
                "error": "To use this application, please accept Terms of Use and Privacy Policy.",
                "label": "Accept and Continue"
              },
              "decline": {
                "label": "Decline"
              }
            },
            "privacyPolicy": {
              "checkbox": {
                "error": "Must accept the Privacy Policy to use this application.",
                "label": "Accept Privacy Policy*"
              },
              "title": "Privacy Policy"
            },
            "termsOfUse": {
              "checkbox": {
                "error": "Must accept the Terms of Use to use this application.",
                "label": "Accept Terms of Use*"
              },
              "title": "Terms Of Use"
            }
          }
        },
        "migrations": {
          "new-migration": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "start": "Start Migration"
            },
            "checkboxes": {
              "archiveImportedUsers": "Auto Archive Users",
              "autoArchivePlaces": "Auto Archive Places",
              "autoCompleteKases": "Auto Complete Cases",
              "autoPost": "Auto Post Contracts",
              "caseDataImported": "Has case data already been imported?",
              "duplicateCheck": "Check for Duplicate Contacts (Multiple Imports Only)"
            },
            "datePickers": {
              "autoCompleteKasesCutOffDate": "All Cases With a Date Before will be set to Complete",
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "ofTotal": "of 13",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "allSourceLocationsRequireMapping": "All Source Locations require a mapping to a TMS Location",
              "autoCompleteKasesDateRequired": "If you have selected to auto complete cases, you must provide a cut off date",
              "caseDataImportedMustBeVerified": "You have chosen Import Type of Files Only. Please verify data has been imported by checking the box",
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "importTypeRequired": "Please select an Import Type",
              "multipleSourceLocationsMappedToOneTMSLocation": "Mapping multiple source locations to one TMS location may result in duplicate Case IDs.",
              "notificationUserRequired": "Please select a Notification User",
              "processingFilePleaseWait": "Processing file:",
              "restartMigrationFailed": "Error occurred restarting migration",
              "saveMigrationFailed": "Error occurred saving migration",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for",
            "rules": {
              "howTo": "How to migrate client data",
              "one": "Upload a file below. This may be a .json file, or a .zip file containing a single .json file.",
              "three": "Click Start Migration to begin the migration.",
              "two": "Map the source location to a TMS location."
            },
            "selects": {
              "noSelection": "Select One"
            },
            "tables": {
              "locationMappings": {
                "sourceLocation": "Source Location",
                "tmsLocation": "TMS Location"
              }
            },
            "waiting": {
              "error": "Oops, processing timed out. If this continues, please contact support.",
              "first": "This may take a few minutes, hang tight!",
              "second": "This is a larger file, we're still working on it!",
              "third": "We're still formatting your data, it shouldn't be too much longer!"
            }
          }
        },
        "online-planner": {
          "documents": {
            "actions": {
              "addForm": {
                "error": "Something went wrong, please try again.",
                "success": "Document has been added to the online planner!"
              },
              "remove": {
                "error": "Something went wrong removing this document.",
                "success": "Document successfully removed from configuration!"
              },
              "update": {
                "error": "Something went wrong updating this document.",
                "success": "Document successfully updated!"
              }
            },
            "addForm": {
              "addDocumentButton": "Add Document",
              "placeholder": "Form",
              "title": "Add Existing Form"
            },
            "documents": {
              "edit": {
                "placeholder": "Name"
              },
              "empty": "There are no documents assigned to this configuration.",
              "title": "Documents Added To This Planner"
            },
            "title": "Add Documents",
            "uploadDocument": {
              "fileUploadInstructions": "Drag and drop documents here to upload them",
              "title": "Upload New Document"
            }
          },
          "icon-select": {
            "options": {
              "bible-color": "Bible",
              "book": "Book",
              "bookColor": "Book 2",
              "buddhismColor": "Buddhism",
              "building": "Building",
              "canadaColor": "Canada",
              "candle": "Candle",
              "candleColor": "Candle 2",
              "car": "Car",
              "cashAdvance": "Cash Advance",
              "cashAdvanceColor": "Cash Advance 2",
              "casket": "Casket",
              "casket2": "Casket 2",
              "casketColor": "Casket 3",
              "cross": "Cross",
              "embalming": "Embalming",
              "facilitiesStaff": "Facilities and Staff",
              "flag": "Flag",
              "grave": "Grave",
              "graveColor": "Grave 2",
              "hearseColor": "Hearse Black",
              "hearseWhiteColor": "Hearse White",
              "hinduismColor": "Hinduism",
              "islamColor": "Islam",
              "judaismColor": "Judaism",
              "merchandise": "Merchandise",
              "musicColor": "Music",
              "necklaceColor": "Necklace",
              "obituaryColor": "Obituary",
              "paper": "Paper",
              "personHoldingBook": "Person Holding Book",
              "pictureFrameColor": "Picture Frame",
              "serviceColor": "Services 2",
              "services": "Services",
              "soldierColor": "Soldier",
              "standardCremation": "Standard Cremation Service",
              "stationary": "Stationary",
              "traditonalGraveside": "Traditional Graveside Service",
              "traditonalService": "Traditional Service at the Funeral Home or Church",
              "transport": "Transport",
              "urn": "Urn",
              "urn2": "Urn 2",
              "urnColor": "Urn 2",
              "usFlagColor": "US Flag",
              "vault": "Vault",
              "videoColor": "Video"
            },
            "removeIcon": "Remove Icon"
          },
          "media": {
            "video-display": {
              "buttons": {
                "delete": "Delete"
              },
              "details": {
                "uploadedBy": "Uploaded By: {name} on {date}",
                "videoTitle": {
                  "defaultTitle": "No name"
                }
              },
              "fields": {
                "input": {
                  "altText": {
                    "placeholder": "Alt Text"
                  },
                  "description": {
                    "disclaimer": "This description will display in the planner to inform families about the video content.",
                    "placeholder": "Video Description"
                  }
                }
              },
              "uploader": {
                "text": "Drag and drop a video or click to select one"
              },
              "validation": {
                "delete": {
                  "success": "Video removed."
                },
                "upload": {
                  "success": "Video successfully uploaded!"
                }
              }
            }
          },
          "packages": {
            "actions": {
              "addPackage": {
                "error": "Something went wrong, please try again.",
                "success": "Package has been added to the online planner!"
              },
              "deletePackage": {
                "error": "Something went wrong removing this Package.",
                "success": "Package successfully removed from configuration!"
              },
              "updatePackage": {
                "error": "Something went wrong updating this Package.",
                "success": "Package successfully updated!"
              }
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add packages to the planner.",
            "gplMissing": "A general price list is required to add packages to the planner.",
            "packageSearch": {
              "placeholder": "Package"
            },
            "packages": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "description": {
                "placeholder": "Description"
              },
              "empty": "There are no packages assigned to this configuration.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "price": {
                "description": "Package price set in the GPL (pricing method applied).",
                "placeholder": "Package Price"
              },
              "save": "Save",
              "sort": "Sort Packages",
              "value": {
                "description": "Total of all items included in the package (pricing method not applied).",
                "placeholder": "Package Value"
              }
            },
            "subtitle": "Add packages from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add packages to the planner.",
            "title": "Add Packages"
          },
          "payment-collection": {
            "pageCantBeHidden": "This page cannot be hidden if a payment is required.",
            "payLater": {
              "hide": "Hide this option?",
              "title": "Pay Later"
            },
            "payToday": {
              "enrollNow": "Enroll now",
              "enrollWarning": "Tribute Pay enrollment is required to use this payment option.",
              "gplRequired": "A general price list is required to setup payments for this online planner.",
              "hide": "Hide this option?",
              "hideWarning": "This option cannot be hidden if a payment is required.",
              "requireFullPayment": "Require full payment in order to submit a plan?",
              "requireFullPaymentWarning": "The Payments page, and the Pay Today option must be visible if a payment is required.",
              "title": "Pay Today",
              "tributePay": {
                "paymentTypeLabel": "Tribute Pay Payment Type",
                "placeholderLabel": "Select One"
              }
            },
            "title": "Payment Options"
          },
          "products": {
            "actions": {
              "addCategory": {
                "error": "Something went wrong, please try again.",
                "success": "Product category has been added to the online planner!"
              },
              "deleteCategory": {
                "error": "Something went wrong removing this product category.",
                "success": "Product category successfully removed from the online planner!"
              },
              "updateCategory": {
                "error": "Something went wrong updating this product category.",
                "success": "Product category successfully updated!"
              }
            },
            "categories": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "empty": "There are no product categories assigned to this the online planner.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "save": "Save",
              "sort": "Sort Product Categories"
            },
            "categorySearch": {
              "placeholder": "Product Category"
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add product categories to the planner.",
            "gplMissing": "A general price list is required to add product categories to the planner.",
            "products": {
              "editButton": "Edit Products",
              "itemTotal": "in planner",
              "name": "Products"
            },
            "subtitle": "Add product categories from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add product categories to the planner.",
            "title": "Add Product Categories"
          }
        },
        "price-list": {
          "import": {
            "fileUploader": {
              "accept": "Accept",
              "cancel": "Cancel",
              "error": "There was an error while uploading the file. Correct the errors, and please try again!",
              "fileErrors": "File errors found",
              "placeholder": "Drag and drop documents here to upload them",
              "review": "Review and accept the updates below to apply them to this GPL."
            }
          },
          "product-update": {
            "columns": {
              "cogs": "Track COGS?",
              "cost": "Cost",
              "current": "Current",
              "desc": "Description",
              "discretionary": "Is Discretionary?",
              "id": "ID",
              "invoiceCategory": "Invoice Category",
              "item": "Item",
              "local": "Local Tax?",
              "name": "Name",
              "preneedCategory": "Preneed Category",
              "price": "Price",
              "productCategory": "Product Category",
              "shipTotal": "Shipping Total",
              "shipping": "Include Shipping?",
              "short": "Short Description",
              "state": "State Tax?",
              "taxed": "Is Taxed?",
              "update": "Update"
            }
          }
        },
        "reports": {
          "detailed-items-report": {
            "dropdowns": {
              "reportDate": {
                "label": "Report Date",
                "optionContractDate": "Contract Date",
                "optionTransactionDate": "Transaction Date"
              }
            }
          }
        },
        "tco": {
          "comment": {
            "api": {
              "commentsFailed": "There was a problem retrieving comments.",
              "genericError": "Something went wrong, please try again."
            },
            "buttons": {
              "approve": {
                "label": "Approve"
              },
              "approved": {
                "label": "Approved"
              },
              "cancel": {
                "label": "Cancel"
              },
              "deleteMedia": {
                "label": "Delete media"
              },
              "deny": {
                "label": "Deny"
              },
              "edit": {
                "label": "Edit"
              },
              "newTab": {
                "label": "Open photo in new tab"
              },
              "play": {
                "label": "Play video in new tab"
              },
              "private": {
                "label": "Hide comment from Tribute Wall",
                "message": "Comment is hidden on Tribute Wall!"
              },
              "public": {
                "label": "Show comment on Tribute Wall",
                "message": "Comment is visible on Tribute Wall!"
              },
              "save": {
                "label": "Save"
              }
            },
            "fields": {
              "comment": {
                "label": "Message"
              },
              "name": {
                "label": "Name"
              }
            },
            "prompts": {
              "approve": {
                "message": {
                  "success": "Comment approved!"
                }
              },
              "delete": {
                "buttons": {
                  "confirm": "Yes",
                  "deny": "No"
                },
                "label": "Are you sure you want to delete this comment?",
                "message": {
                  "success": "Comment deleted!"
                }
              },
              "media": {
                "delete": {
                  "buttons": {
                    "confirm": "Yes",
                    "deny": "No"
                  },
                  "label": "Are you sure you want to delete this photo?",
                  "message": {
                    "success": "Photo has been removed successfully."
                  }
                }
              },
              "save": {
                "message": {
                  "success": "Comment saved successfully!"
                }
              }
            }
          },
          "custom-theme": {
            "prompts": {
              "fileUpload": {
                "dropbox": "Drag and drop an image or click to select one.",
                "error": "There was an error while saving the theme, please try again.",
                "message": "Upload an image!"
              },
              "hide": {
                "message": "Hide Custom Theme Uploader"
              },
              "show": {
                "message": "Upload Custom Theme"
              },
              "themeName": {
                "message": "1. Enter a theme name!",
                "textbox": {
                  "label": "Theme Name",
                  "warning": "Theme name can only contain letters, numbers, spaces, and underscores."
                }
              }
            }
          }
        },
        "tfe": {
          "filled-forms-list": {
            "esignature-requests": {
              "bannerMessages": {
                "cancel": {
                  "error": "eSignature request could not be canceled.",
                  "success": "eSignature request successfully canceled!"
                },
                "multipleSigners": {
                  "resendError": "Failed to resend the eSignature request to pending signers.",
                  "resendSuccess": "eSignature request successfully resent to pending signers!"
                },
                "singleSigner": {
                  "resendError": "Failed to resend the eSignature request to the signer.",
                  "resendSuccess": "eSignature request successfully resent to signer!"
                }
              },
              "button": {
                "cancel": "Cancel Request",
                "hide": "Hide",
                "hideSignerActivity": "Hide Recent Signer Activity",
                "resendAll": "Resend to Pending Signers",
                "resendSigner": "Resend to Signer",
                "viewSignerActivity": "View Recent Signer Activity"
              },
              "status": {
                "canceled": "The eSignature request was canceled.",
                "completed": " has completed the eSignature request.",
                "declined": " has declined the eSignature request.",
                "pending": "The eSignature request was sent to ",
                "resent": "The eSignature request was resent to "
              },
              "text": {
                "title": "eSignature Requests"
              }
            },
            "version-history": {
              "button": {
                "hide": "Hide"
              },
              "text": {
                "current": "Current",
                "title": "Version History"
              },
              "tooltipText": {
                "download": "Download"
              }
            }
          },
          "forms": {
            "admin-list-item": {
              "buttons": {
                "cancel": "Cancel",
                "createCopy": "Create Copy"
              },
              "placeholders": {
                "name": "Name"
              },
              "prompt": {
                "copyGlobalForm": "You cannot edit a global form. Would you like to make a copy?"
              },
              "text": {
                "copyForm": "(Copy)",
                "globalForm": "(Global Form)",
                "nameCopy": "Please enter a name for the copied form."
              },
              "tooltips": {
                "pending": "Form is currently processing and will be available shortly",
                "remove": "Remove",
                "setupEsign": "Setup eSign"
              },
              "validationMessage": {
                "name": "Form name can't be blank"
              }
            },
            "filled-list": {
              "actionsMenu": {
                "deleteForm": "Delete Filled Form",
                "hideEsignatureRequests": "Hide eSignature Requests",
                "hideVersionHistory": "Hide Version History",
                "refillForm": "Refill Form",
                "viewEsignatureRequests": "View eSignature Requests",
                "viewVersionHistory": "View Version History"
              },
              "cancel": "Are you sure you want to cancel this request?",
              "deleteEsignFormPrompt": "Deleting this form will cancel and remove eSignature requests for this form. Are you sure?",
              "deleteFormPrompt": "Are you sure?",
              "refillEsignFormPrompt": "Refilling this form will cancel eSignature requests for this form. Are you sure?",
              "signersTooltip": {
                "completed": "Completed Request with {signerCount} {signerPluralization}",
                "default": "Saved Request with {signerCount} {signerPluralization}",
                "pending": "Pending Request with {signerCount} {signerPluralization}",
                "signerCount": {
                  "one": "Signer",
                  "other": "Signers"
                }
              },
              "text": {
                "formProcessingError": {
                  "contactEmail": "support@tributetms.com.",
                  "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
                }
              },
              "tooltipText": {
                "canceledEsignatures": "This form has been canceled.",
                "completedEsignatures": "This form has a completed eSignature request.",
                "download": "Download",
                "moreActions": "More Actions",
                "pendingEsignatures": "This form has a pending eSignature request.",
                "requestEsignatures": "Request eSignatures"
              }
            },
            "list": {
              "buttons": {
                "cancel": "Cancel",
                "fill": "Fill Form"
              },
              "selectBox": {
                "placeholder": {
                  "event": "Event",
                  "familyOrFriend": "Family or Friend",
                  "inquiry": "Inquiry",
                  "location": "Location",
                  "selectOne": "Select One"
                }
              },
              "text": {
                "alreadyFilled": "(already filled)",
                "chooseEvent": "Please choose the event for this form:",
                "chooseInquiry": "Please choose the inquiry for this form:",
                "enterInfo": "Please enter the information below",
                "noName": "No name"
              },
              "tooltipText": {
                "download": "Download Blank Form",
                "favorite": "Favorite",
                "fillForm": "Fill Form",
                "unfavorite": "Unfavorite"
              }
            }
          }
        },
        "whiteboard": {
          "modal": {
            "whiteboard-details": {
              "fields": {
                "select": {
                  "caseStatus": {
                    "placeholder": "Case Status"
                  }
                }
              }
            }
          }
        }
      },
      "header-nav": {
        "clientMenu": {
          "viewAll": "View All"
        },
        "inquiry": {
          "active": "Active Inquiry",
          "new": "New Inquiry"
        },
        "userMenu": {
          "admin": "Admin",
          "logOut": "Log Out",
          "settings": "Settings"
        }
      },
      "kase": {
        "information": {
          "badDebtBy": {
            "hasBeenMarked": "has been marked as bad debt",
            "this": "This"
          }
        },
        "inputs": {
          "labels": {
            "isBadDebt": "Mark as Bad Debt?"
          }
        }
      },
      "list": {
        "adjustment-summary": {
          "errors": {
            "postingPeriod": "Cannot delete an adjustment in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        },
        "discount-summary": {
          "errors": {
            "paymentPostingPeriod": "Cannot delete a discount in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "tmsID": "TMS ID:",
            "transactionId": "Discount Id:"
          }
        },
        "payment-summary": {
          "buttons": {
            "cancel": "Cancel",
            "refund": "Refund"
          },
          "disclaimers": {
            "receiptEmail": {
              "notSend": "There is no email for {firstName} {lastName}.",
              "send": "A receipt will be emailed to {email}."
            }
          },
          "errors": {
            "paymentPostingPeriod": "Cannot delete a payment in a locked posting period. You must unlock the posting period first.",
            "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
            "refund": {
              "amount": "Refund amount must be less than the payment total.",
              "creation": "There was a problem processing the refund."
            }
          },
          "information": {
            "accountNumberLast4": "Account Number Last 4:",
            "accountType": "Account Type:",
            "bankName": "Bank Name:",
            "enteredBy": {
              "emailPaymentLink": "Email Payment Link",
              "label": "Entered By:",
              "onlinePlanner": "Online Planner"
            },
            "netsuiteID": "Netsuite ID:",
            "paymentProcessorStatus": {
              "label": "Payment Processor Status:",
              "statuses": {
                "awaitingClientFeedback": "Awaiting Client Feedback",
                "awaitingInternalFeedback": "Awaiting Internal Feedback",
                "failed": "Failed",
                "initiated": "Initiated",
                "pending": "Pending",
                "successful": "Successful"
              }
            },
            "paymentTotal": "Payment Total:",
            "processingFee": "Processing Fee:",
            "refundedBy": "Refunded By:",
            "routingNumber": "Routing Number:",
            "tmsID": "TMS ID:",
            "transactionId": "Transaction ID:"
          },
          "inputFields": {
            "refundAmount": "Refund Amount"
          },
          "successes": {
            "refund": {
              "creation": "The refund was created successfully!"
            }
          },
          "toolTips": {
            "printReceipt": "Print Receipt",
            "refund": "Refund"
          }
        },
        "refund-summary": {
          "errors": {
            "postingPeriod": "Cannot delete a refund in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:",
            "transactionId": "Refund Id:"
          }
        },
        "selection-package": {
          "packageContents": "Package Contents: ",
          "pills": {
            "selectionsComplete": "Selections Complete",
            "selectionsRequired": "Selections Required"
          }
        },
        "selection-product": {
          "label": {
            "pullFromBeaconfpInventory": "Pull from Inventory?"
          }
        }
      },
      "quick-add-user": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "inputFields": {
          "email": "Email",
          "embalmerLicense": "Embalmer License #",
          "firstName": "First Name",
          "funeralDirectorLicense": "Funeral Director License #",
          "lastName": "Last Name",
          "phone": "Mobile Phone"
        },
        "note": {
          "label": "Note:",
          "text": "In order for a user to be able to log in to Tribute Management Software, a user must have a valid email."
        },
        "pageTitle": "Add User"
      },
      "reports": {
        "ar-aging": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "ar-aging-by-kase": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging By Case Report"
          }
        },
        "ar-aging-by-location-and-case": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report - by Location and Case",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "beacon-monthly-reconciliation-report": {
          "checkboxes": {
            "includePostedDateRange": {
              "label": "Also include Selections by Posting Date"
            }
          },
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "postedDateEndsAt": {
              "placeholder": "End Date"
            },
            "postedDateStartsAt": {
              "placeholder": "Start Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "messages": {
            "missingEndDate": "Please enter an End Date",
            "missingPostedEndDate": "Please enter a Posted End Date",
            "missingPostedStartDate": "Please enter a Posted Start Date",
            "missingStartDate": "Please enter a Start Date"
          },
          "pageTitle": {
            "mainText": "Beacon Monthly Reconciliation Report"
          }
        },
        "case-count-by-case-tag-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Case Count By Case Tag Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "graystone-case-revenue": {
          "checkboxes": {
            "unpostedCases": "Include Unposted Cases?"
          },
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Case Revenue Report"
        },
        "graystone-survey": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Survey Report"
        },
        "inventory-as-of": {
          "datePicker": {
            "asOfDate": "As Of Date"
          },
          "link": {
            "generateReport": "Generate Report"
          },
          "title": "Inventory As Of Report"
        },
        "jcg-altmeyer": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG Report - Altmeyer",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "jcg-preneed": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG PN Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "merchandise-profit": {
          "checkboxes": {
            "displayProductCategory": {
              "label": "Display Product Category?"
            },
            "displayProductNotes": {
              "label": "Display Product Notes?"
            }
          },
          "dateSelectors": {
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": "Merchandise Profit Report"
        },
        "precoa-decedent-report": {
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "Precoa Decedent Report"
          }
        },
        "preneed-to-atneed-conversion-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Conversion Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "preneed-to-atneed-ratio-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Ratio Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        }
      },
      "sidebar-nav": {
        "accounting": "Accounting",
        "calendar": "Calendar",
        "cases": "Cases",
        "contacts": "Contacts",
        "dashboard": "Dashboard",
        "esignatures": "eSignatures",
        "financialDetail": "Financial Detail",
        "growthEngine": "Growth Engine",
        "inquiries": "Inquiries",
        "inventory": "Inventory",
        "legal": "Legal",
        "liveHelp": "Live Help",
        "miscSales": "Misc Sales",
        "ohioEdrs": "Ohio EDRS",
        "payments": "Payments",
        "places": "Places",
        "plans": "Plans",
        "reports": "Reports",
        "websites": "Websites",
        "whiteboard": "Whiteboard"
      }
    },
    "debug": {
      "copy": {
        "clipboard": "Copy to Clipboard",
        "message": "The debug info was successfully copied to your clipboard!"
      },
      "info": {
        "browser": "Browser",
        "client": "Client",
        "ipAddress": "IP Address",
        "notLoggedIn": "Not logged in",
        "user": "User"
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      }
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "login": {
      "browserIsPrivate": {
        "message": {
          "notice": {
            "boldText": "private browsing window",
            "text": "It appears you are trying to access this app from a"
          },
          "request": {
            "additionalText": ", so that we can ensure an",
            "boldText": "standard browsing window",
            "italicText": "optimal experience",
            "text": "Please try again from a"
          },
          "thankYou": {
            "text": "Thank you."
          },
          "title": "Notice"
        }
      },
      "form": {
        "button": {
          "continue": "Continue",
          "submit": "Log in!"
        },
        "email": {
          "error": "Valid email is required",
          "label": "Email"
        },
        "link": {
          "cancelLogin": "Cancel",
          "forgotPassword": "Forgot Password?"
        },
        "messages": {
          "announcementMessage": "Your account safety is our top priority. We’ve implemented additional measures to protect your information.",
          "announcementTitle": "We’ve updated your log in experience!",
          "invalidCredentials": "Login credentials are invalid"
        },
        "password": {
          "error": "This field can't be blank",
          "label": "Password"
        }
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      },
      "migrationResult": {
        "actionRequired": "Action Required",
        "failed": "Your user migration failed, please contact support.",
        "failedUserExisted": "This username already has a Tribute Technology Single Sign On (SSO) account.  To complete this upgrade and use your existing SSO login, please verify your account by clicking the link in the email we are sending you.  Once your email is verified, return to this application and start the login process over.",
        "migrationContinue": "Continue",
        "migrationStartOver": "Start Over",
        "success": "Your account is being upgraded to the new Tribute Technology login experience!",
        "successNoPassword": "To complete this upgrade, you must reset your password. Please check your email for a password reset link. Once complete, return to this page and click Continue to login with your updated credentials.",
        "successPassword": "To complete this upgrade, please click continue and log in again."
      },
      "successfulLogin": {
        "title": "Login Successful"
      },
      "tmsLearnMore": {
        "link": "Learn More",
        "text": "Curious about the logo change?"
      }
    },
    "model": {
      "tfe": {
        "generated-document": {
          "signingStatus": {
            "awaitingInitialization": "Awaiting Initialization",
            "canceled": "Canceled",
            "completed": "Completed",
            "notApplicable": "Not Applicable",
            "pending": "Pending"
          }
        },
        "signer": {
          "role": {
            "beneficiary": "Beneficiary",
            "executor": "Executor",
            "funeralDirector": "Funeral Director",
            "informant": "Informant",
            "nextOfKin": "Next of Kin",
            "primaryContact": "Primary Contact",
            "purchaser": "Purchaser"
          },
          "status": {
            "canceled": "Canceled",
            "completed": "Completed",
            "declined": "Declined",
            "pending": "Pending"
          }
        }
      }
    },
    "models": {
      "integration-service": {
        "displayName": {
          "asd": "ASD",
          "bassMollett": "Bass-Mollett",
          "cemSites": "CemSites",
          "cfs": "CFS",
          "domaniCare": "DomaniCare",
          "frontRunner": "Front Runner",
          "funeralOne": "FuneralOne",
          "funeralTech": "Funeral Tech",
          "ohioEdrs": "Ohio EDRS",
          "tms": "TMS",
          "tukiosV2": "Tukios V2",
          "unknown": "Unknown"
        }
      }
    },
    "pay-now": {
      "buttons": {
        "next": "Next"
      },
      "disclaimers": {
        "amountDue": {
          "label": "Amount Due:"
        },
        "payment": {
          "error": "We were unable to process your payment. Please try again."
        }
      },
      "errors": {
        "payment": {
          "numberValidation": {
            "withBalance": "This field must be less than or equal to ${lte}, but greater than or equal to $0.50.",
            "withoutBalance": "This field must be greater than $0.50."
          }
        }
      },
      "expired": {
        "bannerText": "This payment link has expired.",
        "mainText": {
          "contactFuneralHome": "This payment link is no longer available. Please contact {funeralHomeName} for a new link.",
          "visitWebsite": "This payment link is no longer available. Please visit the {funeralHomeName} website to initiate a new payment."
        },
        "pageTitle": "Expired Link"
      },
      "inputFields": {
        "amount": "Amount",
        "firstName": "First Name",
        "lastName": "Last Name"
      },
      "mainText": "To make a payment to {funeralHome}, please enter your name and the payment amount below.",
      "pageTitle": "Pay Now",
      "success": {
        "banners": {
          "balancePaid": "This balance has been paid in full.",
          "successfulPayment": "This payment was successful!"
        },
        "mainText": {
          "balancePaid": "This balance is currently $0.00. There is no need to make additional payments at this time.",
          "successfulPayment": "Thank you for submitting this payment to {funeralHomeName}. Your payment was successful. You may close this window."
        },
        "pageTitle": {
          "balancePaid": "Balance Paid",
          "successfulPayment": "Successful Payment"
        }
      }
    },
    "services": {
      "client-config": {
        "checkboxes": {
          "admin": {
            "canViewFinancialConfigurations": "Can View Financial Configurations?",
            "canViewGroups": "Can View Groups?",
            "canViewLocations": "Can View Locations?",
            "canViewOnlinePlanner": "Can View Online Planner?",
            "canViewOrganizations": "Can View Organizations?",
            "canViewSmsPaymentLink": "Enable SMS Payment Links",
            "canViewTributePay": "Can View Tribute Pay?",
            "canViewUsers": "Can View Users?",
            "financialConfiguration": {
              "accounts": "Can View Accounts?",
              "adjustments": "Can View Adjustments?",
              "discounts": "Can View Discounts?",
              "interests": "Can View Interests?",
              "payments": "Can View Payments?",
              "postingPeriods": "Can View Posting Periods?",
              "specialTaxes": "Can View Special Taxes?",
              "sync": "Can View Sync?",
              "taxItems": "Can View Tax Items?"
            }
          },
          "case": {
            "autoKaseNumbering": "Has Auto Case Numbering?",
            "blockEsignForUnpostedContracts": "Block eSignature for Unposted Contracts?",
            "canManageCaseTags": "Can Manage Case Tags?",
            "canSetKaseStatusFilter": "Users Can Set Case Status Filter?",
            "canUseSingleKaseTag": "One Case Tag Per Case?",
            "canViewCaseDispositionIcons": "Can View Disposition Icons on Cases?",
            "canViewCaseFinancial": "Can View Case Financial?",
            "canViewCaseInquiries": "Can View Case Inquiries?",
            "canViewCaseTags": "Can View Case Tags?",
            "canViewChainOfCustody": "Can View Chain of Custody?",
            "canViewContactManager": "Can View Contact Manager?",
            "canViewCremationAuthorization": "Can View Cremation Authorization?",
            "canViewCustomFields": "Can View Custom Fields?",
            "canViewDigitalSignatures": "Can View Digital Signature?",
            "canViewDisposition": "Can View Disposition?",
            "canViewDocuments": "Can View Documents?",
            "canViewEsignature": "Can View eSignature?",
            "canViewEvents": "Can View Events?",
            "canViewFamilyFriends": "Can View Family & Friends?",
            "canViewFamilyPortal": "Can View Family Portal?",
            "canViewForms": "Can View Forms?",
            "canViewIsCovid19Case": "Can View Is COVID-19 Case?",
            "canViewLinks": "Can View Links?",
            "canViewMimsStationary": "Can View MIMS Stationary?",
            "canViewObituary": "Can View Obituary?",
            "canViewObituaryGenerator": "Can View Obituary Generator?",
            "canViewPreneed": "Can View Preneed?",
            "canViewPreneedCategories": "Can View Preneed Categories?",
            "canViewPreneedInfoMessage": "Can View Preneed Info Message?",
            "canViewPreparations": "Can View Preparations?",
            "canViewPrintables": "Can View Printables?",
            "canViewReferralInfo": "Can View Referral Info?",
            "canViewServiceInfo": "Can View Service Info?",
            "canViewTasks": "Enable Task List",
            "canViewTasksAdmin": "Enable Task List Admin",
            "canViewTransportation": "Can View Transportation?",
            "canViewVeteranInfo": "Can View Veteran Info?",
            "forceAutoKaseNumbering": "Force Automatic Case Numbering?",
            "isEsignFieldTest": "Is eSign Field Test?",
            "onlyFuneralDirectorsCanBeLeadStaffMembers": "Only Funeral Directors Can Be Lead Staff Members",
            "sendFamilyPortalEmailInvite": "Automatically Send Family Portal Invite Email?"
          },
          "caseFinancial": {
            "canAssignInsuranceToPayments": "Can Assign Insurance Policies to Payments?",
            "canLockPostingPeriods": "Can Lock Posting Periods?",
            "canMarkAsBadDebt": "Can Mark as Bad Debt?",
            "canViewAdjustmentGains": "Can View Adjustment Gains?",
            "canViewBatchPayments": "Can View Batch Payments?",
            "canViewDiscounts": "Can View Discounts?",
            "canViewDoNotExport": "Can View Do Not Export?",
            "canViewGeneralPayments": "Can View General Payments?",
            "canViewGenerateSfgsButton": "Can View Generate SFGS Button?",
            "canViewInterests": "Can View Interests?",
            "canViewLineItemAdjustments": "Can View Line Item Adjustments?",
            "canViewMiscSales": "Can View Misc Sales?",
            "canViewSpecialTax": "Can View Special Tax?",
            "defaultDueDate": "Default Due Date?",
            "enforcePostingPeriodLocking": "Enforce Posting Period Locking?",
            "relabelContractToInvoice": "Relabel Contract to Invoice?",
            "setContractDateToPostedDateIfNoEvents": "If No Events, Contract Date Is Set To Posted Date?"
          },
          "events": {
            "canViewEmployeeArrivalTime": "Can View Employee Arrival Time?",
            "canViewFamilyArrivalTime": "Can View Family Arrival Time?",
            "canViewVehicles": "Can View Vehicles?",
            "eventStaffMemberInvites": "Event Staff Member Notification Accept / Decline?",
            "sendResponsesToCaseLead": "Send Responses to Case Lead?",
            "sendResponsesToOriginalAssigner": "Send Responses to Original Assigner?"
          },
          "globalNavigation": {
            "canViewAccounting": "Can View Accounting?",
            "canViewCalendar": "Can View Calendar?",
            "canViewCases": "Can View Cases?",
            "canViewContacts": "Can View Contacts?",
            "canViewFinancialDetail": "Can View Financial Detail?",
            "canViewInquiries": "Can View Inquiries?",
            "canViewPlaces": "Can View Places?",
            "canViewReports": "Can View Reports?",
            "canViewWhiteboard": "Can View Whiteboard?"
          },
          "integrations": {
            "canHideDeathNotice": "Can Hide Death Notice?",
            "canViewCemsites": "Can View CemSites?",
            "canViewGrowthEngine": "Can View Growth Engine?",
            "canViewOhioEdrs": "Can View Ohio EDRS?"
          },
          "postingValidations": {
            "caseTag": "Enable Case Tag Validator?",
            "disposition": "Enable Disposition Validator?",
            "enablePostingValidations": "Enable Posting Validations?",
            "postingPeriod": "Enable Posting Period Validator?",
            "purchaser": "Enable Purchaser Validator?",
            "purchaserRelationship": "Enable Purchaser Relationship Validator?",
            "staffMember": "Enable Staff Member Validator?"
          },
          "productAndInventory": {
            "canViewAdditionalProductInfo": "Can View Additional Product Info?",
            "canViewCogsCustomProducts": "Can View COGS on Custom Products?",
            "canViewInventory": "Can View Inventory?",
            "canViewPackageSlotAllowances": "Can View Package Slot Allowances?",
            "canViewPackageSlotFlexibleItems": "Can View Flexible Item(s) Package Slots?",
            "canViewPriceListDates": "Can View Price List Dates?",
            "canViewPullFromBeaconfpInventory": "Can View Pull From Beaconfp Inventory?",
            "canViewPullFromInventory": "Can View Pull From Inventory?",
            "canViewVendors": "Can View Vendors?",
            "hasDiscretionaryItems": "Has Discretionary Items?",
            "useCustomProducts": "Use Custom Products?"
          }
        },
        "disclaimers": {
          "packageSlotAllowances": "Verify client statement of account and statement of funeral goods and services have support for allowances before enabling."
        }
      }
    }
  }], ["en-us", {
    "appTitle": {
      "acronym": "TMS",
      "full": "Tribute Management Software"
    },
    "authenticated": {
      "accounting": {
        "exports": {
          "index": {
            "buttons": {
              "new": "Create New"
            },
            "lists": {
              "noExports": "No Exports"
            },
            "pageTitle": "Exports"
          }
        }
      },
      "admin": {
        "custom-fields": {
          "buttons": {
            "addNew": "Add New"
          },
          "edit": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!",
              "validate": "One of the fields is invalid"
            },
            "allLocationsLabel": "All Locations",
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationLabel": "Location: ",
            "pageTitle": "Custom Field Configuration"
          },
          "locationSelect": {
            "placeholder": "Location",
            "placeholderLabel": "All Locations"
          },
          "new": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!"
            },
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationSelect": {
              "placeholder": "Location",
              "placeholderLabel": "Select Location"
            },
            "noCustomFields": "No custom fields have been created.",
            "pageTitle": "New Custom Field Configuration"
          },
          "pageTitle": "Custom Field Configurations",
          "table": {
            "editLink": "Edit",
            "header": "Location",
            "mobileHeader": "Location Name: "
          }
        },
        "data": {
          "case-tags": {
            "buttons": {
              "addTag": "Add Tag"
            },
            "fields": {
              "input": {
                "name": "Name"
              },
              "select": {
                "options": {
                  "default": "Select One"
                },
                "placeholder": "Disposition Method"
              }
            },
            "labels": {
              "dispositionMethod": "Disposition Method:"
            },
            "list": {
              "empty": "No Case Tags"
            }
          },
          "poems-and-prayers": {
            "actions": {
              "delete": "Poem successfully removed!",
              "save": {
                "error": "An error occured while saving.",
                "success": "Poem successfully saved!"
              }
            },
            "newPoem": {
              "buttons": {
                "add": "Add Poem or Prayer",
                "cancel": "Cancel",
                "save": "Save"
              },
              "name": "Name",
              "text": "Verbiage"
            },
            "noPoems": "No Poems or Prayers",
            "poems": {
              "edit": {
                "name": "Name",
                "text": "Verbiage"
              }
            },
            "search": "Name"
          }
        },
        "financial-configurations": {
          "edit": {
            "additional-settings": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "messages": {
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "sageIntacct": {
                "buttons": {
                  "add": "Add"
                },
                "header": "Sage Intacct Configuration",
                "inputs": {
                  "glEntryCode": {
                    "placeholder": "GL Entry Code"
                  },
                  "journalCode": {
                    "placeholder": "Journal Code"
                  },
                  "locationCode": {
                    "placeholder": "Location Code"
                  }
                },
                "selectors": {
                  "locations": {
                    "placeholder": "Location",
                    "placeholderLabel": "Select One"
                  }
                }
              }
            },
            "adjustments": {
              "data": {
                "account": "Account:"
              },
              "ids": {
                "netsuite": "Netsuite ID:",
                "tms": "TMS ID:"
              }
            },
            "discounts": {
              "buttons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "new": "New",
                "save": "Save"
              },
              "information": {
                "account": {
                  "label": "Account:"
                },
                "netsuiteID": {
                  "label": "Netsuite ID:"
                },
                "tmsID": {
                  "label": "TMS ID:"
                }
              },
              "inputFields": {
                "discountTypeName": "Discount Type Name"
              },
              "messages": {
                "delete": {
                  "error": "There was an error removing this record.",
                  "success": "Record has been removed!"
                },
                "save": {
                  "error": "Sorry, something went wrong. Please try again.",
                  "success": "Discount type successfully saved!"
                }
              },
              "pageTitle": "Discounts",
              "selectFields": {
                "account": "Account"
              }
            },
            "overview": {
              "accountDropdowns": {
                "discount": "Discount Account",
                "receivables": "Receivables Account",
                "refund": "Refund Account",
                "taxPayable": "Tax Payable Account"
              },
              "buttons": {
                "cancel": "Cancel",
                "importQuickbooks": "Import Quickbooks",
                "importQuickbooksOnline": "Import Quickbooks Online",
                "save": "Save"
              },
              "fieldInput": {
                "name": "Name"
              },
              "messages": {
                "iifUpload": {
                  "error": "Error uploading file.",
                  "success": "File successfully uploaded."
                },
                "import": {
                  "error": "Import failed. Please try again.",
                  "success": "Import started. Please wait for QuickBooks to sync."
                },
                "save": {
                  "error": "Something went wrong. Please try again.",
                  "success": "Accounts successfully saved!"
                }
              },
              "pageNotValidText": "This Chart of Accounts is not yet valid. Please check your accounts.",
              "pageTitle": "Overview",
              "pageValidText": "This Chart of Accounts is valid.",
              "secondaryTitle": "Tax Configuration",
              "taxItemDropdowns": {
                "nonTaxItemName": "Non Tax Item Name",
                "taxItemName": "Tax Item Name"
              }
            },
            "pageTitle": "Financial Configurations",
            "payments": {
              "information": {
                "tmsID": "TMS ID:"
              }
            },
            "posting-periods": {
              "buttons": {
                "addNew": "Add New",
                "archive": "Archive",
                "clear": "Clear",
                "savePostingPeriod": "Save Posting Period",
                "unarchive": "Unarchive"
              },
              "confirmDialog": {
                "archive": "The selected posting period will be archived. Are you sure?",
                "unarchive": "The selected posting period will be unarchived. Are you sure?"
              },
              "inputs": {
                "archived": {
                  "label": "View Archived"
                },
                "pikaday": {
                  "endsAt": {
                    "label": "Ending At"
                  },
                  "startsAt": {
                    "label": "Starting From"
                  }
                },
                "pikadayDateTime": {
                  "dateLabel": "Lock Date",
                  "timeLabel": "Lock Time"
                }
              },
              "pageTitle": "Posting Periods",
              "table": {
                "createdOn": {
                  "label": "Created On: "
                },
                "headers": {
                  "createdOn": "Created On",
                  "lockDate": "Lock Date",
                  "lockedBy": "Locked By"
                },
                "lockDate": {
                  "label": "Lock Date: "
                },
                "lockedBy": {
                  "label": "Locked By: "
                }
              }
            },
            "sideNavigationLabels": {
              "accounts": "Accounts",
              "adjustments": "Adjustments",
              "backToAdmin": "Back to Admin",
              "discounts": "Discounts",
              "interests": "Interests",
              "overview": "Overview",
              "payments": "Payments",
              "postingPeriods": "Posting Periods",
              "settings": "Settings",
              "specialTaxes": "Special Taxes",
              "sync": "Sync",
              "taxItems": "Tax Items"
            },
            "sync": {
              "buttons": {
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save"
              },
              "inputFields": {
                "companyFilePath": "Company File Path"
              },
              "links": {
                "downloadConfigurationFile": "Download Configuration File"
              },
              "pageTitle": "Sync",
              "sections": {
                "accessToken": {
                  "title": "Access Token",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Access Tokens can only be viewed once",
                    "noAccessToken": "No Access Token",
                    "writeThisDown": "Write this down!"
                  }
                },
                "accountNotSetup": {
                  "mainText": "Your account has not been setup for syncing. Please contact",
                  "supportEmail": "support@tributetms.com"
                },
                "filePath": {
                  "title": "File Path"
                },
                "quickBooksDesktop": {
                  "instructions": {
                    "mainText": "Follow these steps to sync Tribute Management Software with QuickBooks Desktop running on your computer:",
                    "step1": {
                      "linkText": "QuickBooks Web Connector",
                      "mainText": "Download and install the"
                    },
                    "step2": "Enter the File Path to your Quick Book Company File (.qbw) below",
                    "step3": "Generate an access token (below)",
                    "step4": "Download and open the Configuration File (below) and open it with QuickBooks Web Connector",
                    "step5": "Select the QuickBooks company file you'd like Tribute Management Software to sync to",
                    "step6": "Enter your Tribute Management Software password in QuickBooks Web Connector"
                  },
                  "title": "QuickBooks Desktop"
                }
              }
            }
          }
        },
        "general-price-lists": {
          "edit": {
            "buttons": {
              "add": "Add Statement Category"
            },
            "cost-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Cost updates saved!"
              },
              "pageTitle": "Cost Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "costHeader": "Cost",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "header": {
              "subtitle": "Edit Statement Categories that you can assign to Product Categories",
              "title": "General Price List Statement Categories"
            },
            "list": {
              "noStatementCategories": "No Statement Categories"
            },
            "messages": {
              "error": "Make sure all of the information is correct and/or inputted.",
              "save": {
                "catch": "Something went wrong.",
                "success": "Statement Category successfully created!"
              }
            },
            "nav": {
              "backToAdmin": "Back to Admin",
              "costUpdates": "Cost Updates",
              "import": "CSV Updates",
              "overview": "Overview",
              "packages": "Packages",
              "preneedCategories": "Preneed Categories",
              "priceListDates": "Price List Dates",
              "pricingUpdates": "Pricing Updates",
              "productCategories": "Product Categories",
              "products": "Products",
              "statementCategories": "Statement Categories"
            },
            "overview": {
              "buttons": {
                "cancel": "Cancel",
                "export": "Create CSV Export",
                "save": "Save"
              },
              "inputs": {
                "chartOfAccountsName": "Chart of Accounts Name",
                "name": "Name"
              },
              "messages": {
                "save": {
                  "error": "There was a problem saving this general price list.",
                  "success": "General price list saved!"
                }
              },
              "pageTitle": "Overview"
            },
            "packages": {
              "edit": {
                "buttons": {
                  "addItem": "+ Add Item",
                  "cancel": "Cancel",
                  "save": "Save"
                },
                "fields": {
                  "checkboxes": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "amount": "Amount ({currency})",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID"
                  },
                  "select": {
                    "addProduct": {
                      "disclaimers": {
                        "alreadyInPackage": "(Already in Package)"
                      },
                      "placeholder": "Add Product"
                    },
                    "invoiceCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Invoice Category"
                    },
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currency})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "information": {
                  "netsuite": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "lists": {
                  "discount": {
                    "label": "Discount"
                  },
                  "finalTotal": {
                    "label": "Final Total",
                    "validations": {
                      "error": {
                        "subtotal": {
                          "main": "{type} is greater than sub total.",
                          "types": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      }
                    }
                  },
                  "flexibleItems": {
                    "none": {
                      "disclaimer": "Please use the field above to add flexible items to this package.",
                      "title": "No Assigned Flexible Items"
                    }
                  },
                  "items": {
                    "none": {
                      "disclaimer": "Please use the field above to add items to this package.",
                      "title": "No Assigned Items"
                    }
                  },
                  "subTotal": {
                    "label": "Sub Total"
                  }
                },
                "manage-products": {
                  "buttons": {
                    "backToPackage": "Back to Package",
                    "clear": "Clear",
                    "clearAllSelections": "Clear All Selections",
                    "selectAllOnCategory": "Select All on Category",
                    "selectAllOnPage": "Select All on Page"
                  },
                  "description": "Select products from this category to enable for the flexible item.",
                  "fields": {
                    "input": {
                      "search": "Search"
                    }
                  },
                  "table": {
                    "headers": {
                      "description": "Description",
                      "name": "Name",
                      "price": "Price"
                    }
                  }
                },
                "sections": {
                  "flexibleItems": "Flexible Items",
                  "items": "Items",
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "title": "Edit Package"
              },
              "index": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                },
                "table": {
                  "actions": {
                    "edit": "Edit"
                  },
                  "headers": {
                    "items": "Items"
                  },
                  "noPackages": "No packages to display for this GPL.",
                  "responsiveHeaders": {
                    "description": "Description: ",
                    "name": "Name: ",
                    "packageContents": "Package Contents: "
                  }
                },
                "title": "Packages"
              },
              "new": {
                "alerts": {
                  "save": {
                    "error": "Something went wrong. Please try again.",
                    "success": "Package saved!"
                  }
                },
                "buttons": {
                  "cancel": "Cancel",
                  "save": "Save & Continue"
                },
                "fields": {
                  "checkbox": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "addProduct": "Add Product",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID",
                    "pricingAmount": "Amount ({currencySymbol})"
                  },
                  "select": {
                    "invoiceCategory": "Invoice Category",
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currencySymbol})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "headings": {
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "lists": {
                  "ids": {
                    "netsuiteId": "NetSuite ID:",
                    "tmsId": "TMS ID:"
                  },
                  "pricingDetails": {
                    "items": {
                      "discount": "Discount",
                      "finalTotal": {
                        "label": "Final Total",
                        "text": {
                          "main": "{amount} is greater than sub total",
                          "options": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      },
                      "subtotal": "Sub Total"
                    }
                  },
                  "products": {
                    "disclaimers": {
                      "alreadyInPackage": "Already In Package",
                      "noAssignedItems": "No Assigned Items",
                      "useFieldAbove": "Please use the field above to add items to the package."
                    }
                  }
                },
                "title": "Create New Package"
              }
            },
            "placeholders": {
              "name": "Name"
            },
            "pricing-imports": {
              "addNew": "How to add a new product to your GPL via CSV",
              "addrule1": "To create a new product in your GPL, enter \"new\" in the Item ID column.",
              "addrule2": "Enter product details in the appropriate columns.",
              "addrule3": "Product Category Names are case sensitive and must match exactly.",
              "howTo": "How to edit a GPL via a CSV Upload",
              "inProgress": "Products update in progress",
              "rule1": "Click the “Create CSV Export” link above to download a formatted CSV file of your General Price List.",
              "rule2": "Open the file in Excel or other spreadsheet application to make your changes.",
              "rule3": "Do not delete any columns, or change the column order. Do not modify the column headers.",
              "rule4": "Do not edit Item ID's of existing Items.",
              "rule5": "Remove any rows that do not need to be updated.",
              "rule6": "Save file as a CSV (Comma Separated Values) and upload here.",
              "status": {
                "complete": "Completed",
                "inProgress": "In Progress",
                "started": "Started"
              },
              "table": {
                "Created": "Created",
                "Errors": "Errors",
                "file": "File Name",
                "noRecords": "No records found",
                "status": "Status"
              },
              "title": "CSV Updates"
            },
            "pricing-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter by Product Category",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Pricing updates saved!"
              },
              "pageTitle": "Pricing Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name",
                  "priceHeader": "Price"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "products": {
              "edit": {
                "headers": {
                  "costChanges": "Cost History"
                },
                "information": {
                  "netsuiteID": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "tables": {
                  "costChanges": {
                    "headers": {
                      "newCost": "New Cost",
                      "previousCost": "Previous Cost",
                      "totalChange": "Total Change",
                      "updatedAt": "Updated At"
                    }
                  }
                }
              }
            },
            "title": "General Price Lists"
          }
        },
        "integrations": {
          "asd": {
            "buttons": {
              "linkASDAccount": "Link ASD Account",
              "linkASDLocation": "Link ASD Location",
              "linkLocations": "Link Locations"
            },
            "sections": {
              "asdLocations": {
                "title": "ASD Locations"
              },
              "settings": {
                "information": {
                  "asdLink": {
                    "allLocationsLinked": "All TMS Locations have been linked.",
                    "clientIsLinked": "This client is linked with ASD.",
                    "clientIsNotLinked": "Please click the link below to retreive the location remote ID(s) and assign them to the appropriate location(s) in Tribute Management Software."
                  },
                  "autoKaseExport": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Auto Case Export?:"
                  },
                  "importBeginningTime": "Imports will begin one hour after",
                  "importCases": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Import Cases?:"
                  },
                  "importCasesAfter": "Import Cases After:",
                  "locations": {
                    "label": "Location(s):",
                    "noData": "No locations linked."
                  }
                },
                "title": "Settings"
              }
            },
            "selectFields": {
              "location": {
                "defaultSelection": "Select One"
              }
            }
          },
          "links": {
            "aftercare": "Aftercare",
            "asd": "ASD",
            "backToAdmin": "Back to Admin",
            "bassMollett": "Bass-Mollett",
            "batesville": "Batesville",
            "bestWishes": "Best Wishes",
            "cfs": "CFS",
            "ciclops": "Ciclops",
            "domaniCare": "DomaniCare",
            "forethought": "Forethought",
            "frazer": "Frazer",
            "frontRunner": "Front Runner",
            "funeralDecisions": "Funeral Decisions",
            "funeralOne": "FuneralOne",
            "funeralTech": "FuneralTech",
            "messenger": "Messenger",
            "ohioEDRS": "Ohio EDRS",
            "overview": "Overview",
            "precoa": "Precoa",
            "tco": "Tribute Center Online",
            "tcoV2": "Tribute Center Online V2",
            "transNational": "TransNational",
            "tributePrint": "Tribute Print",
            "tributeVideo": "Tribute Video",
            "tukios": "Tukios",
            "tukiosV2": "Tukios V2",
            "viewlogies": "Viewlogies"
          },
          "pageTitle": "Integrations",
          "tco": {
            "buttons": {
              "linkTcoLocation": "Link TCO Location"
            },
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "linkFailure": "TMS Location is already linked to a TCO Location",
            "linkSuccess": "TCO ID successfully added to location",
            "linkUpdated": "TCO Location ID Updated",
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online",
            "selector": {
              "placeholder": "Select a Matching Location"
            },
            "tcoLink": {
              "button": {
                "linkLocations": "Link TCO Locations"
              },
              "locationsLinked": "All TMS locations have been linked to TCO"
            }
          },
          "tco-v2": {
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online V2"
          },
          "tribute-print": {
            "pageTitle": "Tribute Print"
          }
        },
        "locations": {
          "edit": {
            "overview": {
              "information": {
                "netsuiteID": "Netsuite ID:",
                "tmsID": "TMS ID:"
              },
              "placeholders": {
                "asdRemoteId": "ASD Remote ID",
                "tcoLocationId": "TCO Location ID"
              },
              "sections": {
                "organizations": {
                  "header": "Organizations",
                  "table": {
                    "columns": {
                      "name": "Name"
                    }
                  },
                  "tooltips": {
                    "edit": "Edit"
                  }
                }
              }
            }
          }
        },
        "manage": {
          "ar-adjustments": {
            "actions": {
              "revert": "Revert"
            },
            "pageTitle": "A/R Adjustments",
            "revertConfirm": "Are you sure you want to revert this adjustment?",
            "revertError": "An error occurred while reverting the adjustment. Please try again.",
            "revertSuccess": "The job to revert the adjustment has been queued. Please check back later.",
            "reverted": "Reverted",
            "table": {
              "columns": {
                "actions": "Actions",
                "errors": "Errors",
                "ran": "Date ran",
                "range": "Date range",
                "requested": "Requested by",
                "status": "Status"
              }
            }
          },
          "cemsites": {
            "index": {
              "confirmDialog": {
                "promptMessage": "Are you sure you want to unlink this Tribute Management Software Place with CemSites?"
              }
            },
            "new": {
              "error": {
                "uuid": "There was a problem returning CemSites places. If this continues, please contact support."
              },
              "selectFields": {
                "tmsPlace": {
                  "placeholder": "TMS Place"
                }
              }
            }
          },
          "financial-configurations": {
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "inputPlaceholder": "Name",
              "messages": {
                "error": "Oops! There was an error saving this Chart of Account.",
                "success": "Chart of Accounts save successful request submitted!"
              },
              "pageTitle": "Add Financial Configuration"
            }
          },
          "forms": {
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "success": {
                "delete": "Successfully removed form.",
                "save": "Successfully added form."
              }
            },
            "pageTitle": "Enabled Forms",
            "searchField": {
              "placeholder": "Search"
            },
            "text": {
              "alreadySelected": "(Already Selected)",
              "enableFormsText": "Please use the search field above to enable forms for future use.",
              "noFormsSelected": "No Forms Selected"
            }
          },
          "general-price-lists": {
            "new": {
              "buttons": {
                "text": {
                  "cancel": "Cancel",
                  "create": "Create"
                }
              },
              "fields": {
                "placeholders": {
                  "financialConfiguration": "Financial Configuration",
                  "name": "Name",
                  "selectOne": "Select One"
                }
              },
              "messages": {
                "error": "Oops! There was an error saving this GPL.",
                "success": "General Price List save successfully submitted!"
              },
              "pageTitle": "Add General Price List"
            }
          },
          "info": {
            "button": {
              "save": {
                "errorMessage": "Oops, something went wrong! Please try again.",
                "label": "Save",
                "successMessage": "Info saved!"
              }
            },
            "dropdown": {
              "packageItemFormat": {
                "label": "Package Item Format",
                "optionIncluded": "Included",
                "optionShowPricing": "Show Pricing",
                "selectOneLabel": "Choose One"
              },
              "sfgsFontSize": {
                "label": "Statement Font Size",
                "optionDefault": "Default",
                "optionLarger": "Larger",
                "selectOneLabel": "Choose One"
              }
            },
            "pageText": "The following are the warranties, terms, disclosures and acknowledgement and agreement that will appear on your printed Statement of Funeral Goods and Services.",
            "pageTitle": "Client Info",
            "secondaryTitle": "Billing",
            "textarea": {
              "acknowledgementAndAgreement": "Acknowledgements and Agreements",
              "disclosures": "Disclosures",
              "terms": "Terms",
              "warranties": "Warranties"
            }
          },
          "navigation": {
            "links": {
              "arAdjustments": "A/R Adjustments",
              "forms": "Forms",
              "tributePay": "Tribute Pay"
            }
          },
          "online-planner": {
            "index": {
              "clearSearchButton": "Clear",
              "copyUrlButton": "Copy URL",
              "defaultPlannerLabel": "Default Planner",
              "editPlannerButton": "Edit",
              "newButton": "New",
              "newPlanner": {
                "actions": {
                  "archive": {
                    "error": "There was a problem while trying to archive this planner. If this continues, please contact support.",
                    "message": "Are you sure you want to archive this planner?",
                    "no": "No",
                    "success": "Planner archived successfully",
                    "yes": "Yes"
                  },
                  "save": {
                    "error": "Something went wrong, please try again.",
                    "success": "Online planner configuration successfully created."
                  },
                  "unArchive": {
                    "error": "There was a problem un archiving the planner. If this continues, please contact support."
                  }
                },
                "cancelButton": "Cancel",
                "createButton": "Create",
                "namePlaceholder": "Name",
                "urlPlaceholder": "URL Prefix"
              },
              "pageTitle": "Online Planner",
              "tableHeaders": {
                "actions": "Actions",
                "plannerName": "Name",
                "urlPrefix": "URL Prefix"
              },
              "viewArchived": "View Archived?"
            }
          },
          "organizations": {
            "edit": {
              "assignOrganizationToLocation": {
                "addLocation": {
                  "button": "Add Location",
                  "errorMessage": "Whoops! That location is already assigned to this organization."
                },
                "dropdown": {
                  "label": "Assign Organization to Location",
                  "placeholderLabel": "Select One"
                }
              },
              "buttons": {
                "close": "Close",
                "errorMessage": "Oh No! There was an error.",
                "save": "Save & Close",
                "successMessage": "Successfully saved!"
              },
              "fields": {
                "input": {
                  "website": "Website"
                },
                "select": {
                  "contactPerson": "Contact Person"
                }
              },
              "misc": {
                "notApplicable": "N/A"
              },
              "organizationNameLabel": "Name",
              "pageTitle": "Edit Organization",
              "sections": {
                "contactPerson": {
                  "email": "Email Address:",
                  "phone": "Phone Number:",
                  "title": "Contact Person:"
                },
                "locations": {
                  "emptyList": "This organization is not currently assigned to a location.",
                  "title": "Locations:"
                }
              }
            },
            "index": {
              "addNewOrganization": {
                "buttons": {
                  "cancel": "Cancel",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully saved!"
                  },
                  "save": "Save"
                },
                "placeholderLabel": "Organization Name"
              },
              "pageTitle": "Organizations",
              "searchOrganizations": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                }
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "name": "Name"
                },
                "headersAtSmallerScreenSizes": {
                  "name": "Name:"
                },
                "links": {
                  "delete": "Delete",
                  "edit": "Edit",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully removed."
                  }
                }
              }
            }
          },
          "pageTitle": "Admin Settings",
          "tfe": {
            "forms": {
              "buttons": {
                "cancel": "Cancel",
                "clear": "Clear",
                "enableForm": "Enable Form"
              },
              "formVisibility": {
                "labelOptions": {
                  "all": "All",
                  "private": "Private",
                  "public": "Public"
                },
                "placeholder": "Form Visibility"
              },
              "messages": {
                "error": "Oops, something went wrong! Please try again.",
                "success": {
                  "delete": "Successfully removed form.",
                  "save": "Successfully enabled form."
                }
              },
              "pageTitle": "Enabled Forms",
              "searchField": {
                "placeholder": "Search"
              },
              "text": {
                "alreadySelected": "(Already Selected)",
                "enableFormsText": "Please use the button above to enable forms for future use.",
                "noFormsSelected": "No Forms Selected"
              }
            }
          },
          "users": {
            "index": {
              "save": {
                "ssoUserExists": "User saved! This email is already tied to an existing sso account. They must first verify their email address before they can log in.",
                "success": "User saved!"
              }
            }
          },
          "whiteboard": {
            "buttons": {
              "generateSecurePin": {
                "label": "Generate Secure Pin"
              }
            },
            "information": {
              "contactUs": "Contact us to issue a new pin.",
              "createdAt": "Created at:",
              "developerMessage": "Superusers cannot generate pins. To generate a whiteboard pin, create a new user account with administrator permissions for the required client.",
              "instructions": "Click the button below to generate your unique login pin for the TMS Whiteboard Apple TV App."
            },
            "pageSubtitle": "Secure Pin",
            "pageTitle": "Whiteboard"
          }
        },
        "online-planner": {
          "config": {
            "edit-category": {
              "backToPlanner": "Back to Online Planner",
              "clearSearch": "Clear",
              "clearSelections": "Clear All Selections",
              "saveButton": "Save",
              "saveError": "There was an error while saving the products to the online planner category. Please try again.",
              "saveSuccess": "Category saved successfully!",
              "selectAllInCategory": "Select All in Category",
              "selectAllOnPage": "Select All on Page",
              "subtitle": "Select products in this category to enable in the planner.",
              "table": {
                "columns": {
                  "description": "Description",
                  "name": "Name",
                  "price": "Price"
                },
                "isTaxed": "tax"
              }
            }
          },
          "edit": {
            "backButton": "Back to Online Planner",
            "notification-settings": {
              "alerts": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              },
              "buttons": {
                "addRecipient": "+ Add New Notification Recipient",
                "cancel": "Cancel",
                "clear": "Clear",
                "deleteRecipient": "Delete",
                "save": "Save"
              },
              "inputFields": {
                "search": {
                  "placeholder": "Search"
                },
                "staffMember": {
                  "placeholder": "Staff Member"
                }
              },
              "lists": {
                "notifications": {
                  "empty": "No Notifications Added"
                }
              },
              "pageTitle": "Notification Settings"
            },
            "notificationSettingsButton": "Notification Settings",
            "overview": {
              "buttons": {
                "back": "Back to Online Planner",
                "copyPlannerURL": {
                  "tooltip": "Copy planner URL"
                },
                "save": "Save"
              },
              "errors": {
                "name": "You can't save the planner without a Name.",
                "pageName": "You can't save a page without a Page name.",
                "urlPrefix": "You can't save the planner without a URL Name."
              },
              "fields": {
                "checkbox": {
                  "defaultPlanner": {
                    "label": "Default Planner?",
                    "validation": {
                      "allLocations": "Allow this planner to be available for all locations.",
                      "toMakeDefault": "To make this the default, please update this setting in the current default planner."
                    }
                  }
                },
                "input": {
                  "pageIsHidden": "Hide this page?",
                  "pageName": "Page Name",
                  "plannerName": "Name",
                  "plannerURL": "URL Prefix",
                  "primaryColor": {
                    "info": "Primary color is used for header background.",
                    "placeholder": "Primary Color"
                  },
                  "secondaryColor": {
                    "info": "Secondary color is used for buttons and accents throughout the planner.",
                    "placeholder": "Secondary Color"
                  },
                  "subPageisHidden": "Hide this subpage?"
                },
                "select": {
                  "bodyFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Body Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration body font."
                    }
                  },
                  "country": {
                    "info": "Country fields will default to this value.",
                    "options": {
                      "canada": "Canada",
                      "unitedStates": "United States"
                    },
                    "placeholder": "Country"
                  },
                  "defaultTaxLocation": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Default Tax Location",
                    "validation": {
                      "disabled": "A GPL is required to set the default tax location."
                    }
                  },
                  "fontType": {
                    "placeholder": "Font Type",
                    "systemFonts": "High-performance System Fonts",
                    "webFonts": "Web Fonts"
                  },
                  "generalPriceList": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "General Price List",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration general price list."
                    }
                  },
                  "headerFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Header Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration header font."
                    }
                  },
                  "loadingFonts": "Loading Fonts...",
                  "logoAlignment": {
                    "placeholder": "Logo Alignment"
                  },
                  "logoSize": {
                    "placeholder": "Logo Size"
                  }
                }
              },
              "headerLogo": {
                "actions": {
                  "archive": {
                    "error": "An error has occurred.",
                    "success": "Logo Successfully Archived!"
                  },
                  "create": {
                    "error": "An Error occured while uploading your logo.",
                    "success": "Logo Successfully uploaded!"
                  }
                },
                "header": "Header Logo",
                "placeholder": "Online Planner Logo"
              },
              "locations": {
                "addLocation": "+ Add Location",
                "defaultConfigMessage": "Individual locations cannot be added to a default configuration.",
                "delete": {
                  "error": "There was an error removing this location. If this continues, please contact support.",
                  "success": "Location was successfully removed."
                },
                "noGeneralPriceList": "Select a General Price List to add locations to this configuration.",
                "noLocations": "No locations have been added to this configuration.",
                "select": {
                  "placeholder": "Online Planner Location",
                  "placeholderLabel": "Select One"
                },
                "title": "Locations"
              },
              "notifications": {
                "email": "Email",
                "label": "Send notifications when plans are submitted",
                "title": "Notifications"
              },
              "save": {
                "copySuccess": "Planner url copied to clipboard successfully!",
                "error": "There was a problem while saving. If this continues, please contact support.",
                "success": "Planner saved successfully!"
              },
              "section": {
                "fontType": {
                  "info": "Using system fonts will reduce loading time and rendering speed, ensure consistency across devices and browsers, and improve accessibility."
                },
                "heading": {
                  "pages": "Pages",
                  "plannerDetails": "Planner Details",
                  "theme": "Theme"
                }
              },
              "theme": {
                "secondary": "Secondary Color"
              },
              "validation": {
                "country": "You must select a country",
                "name": "Name cannot be blank",
                "pageName": "Page Name can't be blank",
                "prefixCheck": "Please only use letters, numbers, dashes or underscores.",
                "primaryColor": "Primary color must be a valid hex code",
                "secondaryColor": "Secondary color must be a valid hex code",
                "url": "URL Prefix cannot be blank"
              }
            },
            "overviewButton": "Overview",
            "page": {
              "buttons": {
                "addCustomField": "Add",
                "save": "Save"
              },
              "errors": {
                "customFieldAdd": "You have already added this field."
              },
              "fields": {
                "customFieldItems": {
                  "options": {
                    "boolean": "Checkbox",
                    "date": "Date Picker",
                    "input": "Text",
                    "select": "Drop Down"
                  },
                  "placeholder": "Label",
                  "size": {
                    "large": "Large",
                    "medium": "Medium",
                    "small": "Small"
                  },
                  "type": {
                    "placeholder": "Type"
                  }
                },
                "customFieldSelect": {
                  "noOptionSelected": "Select One",
                  "placeholder": "Custom Fields"
                },
                "defaultValueField": {
                  "error": "You must select a default value for the overview question pages you have hidden.",
                  "label": "Default Value",
                  "placeholder": "Select One",
                  "warning": "You must select a value."
                },
                "gplURL": "General Price List URL",
                "gplUrlDescription": "This pricelist will be presented to users before they select items in the planner.",
                "gplUrlError": "URL not valid",
                "media": {
                  "image": {
                    "delete": "Delete",
                    "droboxText": "Drag and drop images here to upload them",
                    "placeholder": "Image",
                    "uploadedBy": "Uploaded By:",
                    "uploadedOn": "on"
                  },
                  "video": {
                    "placeholder": "Video"
                  }
                },
                "pageIsHidden": "Hide this page?",
                "pageName": "Page Name",
                "planner": {
                  "blankError": "Field cant be blank",
                  "hideLabel": "Hide?",
                  "label": "Label",
                  "pageDescription": "Page Description",
                  "requiredLabel": "Required?",
                  "verbiageLabel": "Verbiage"
                },
                "subPageisHidden": "Hide this subpage?"
              },
              "section": {
                "subPages": "Subpages"
              },
              "title": {
                "isHidden": "(Hidden)"
              }
            },
            "pageTitle": "Online Planner",
            "users": {
              "leftNav": {
                "back": "Back to Online Planner",
                "overview": "Overview"
              },
              "search": {
                "clearButton": "Clear",
                "newButton": "New",
                "placeholder": "Seach"
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "email": "Email",
                  "lastAccess": "Last Updated",
                  "view": "View Plans"
                }
              },
              "title": "Users"
            },
            "usersButton": "Users"
          },
          "users": {
            "overview": {
              "fields": {
                "email": {
                  "placeholder": "Email"
                }
              },
              "table": {
                "buttons": {
                  "actions": "Actions",
                  "archive": "Archive",
                  "create": "Create Case"
                },
                "headers": {
                  "created": "Created At",
                  "session": "Session ID"
                }
              },
              "title": "User"
            }
          }
        },
        "tribute-pay": {
          "email-templates": {
            "defaultData": {
              "email": {
                "body": "<p>Our funeral home staff appreciates the opportunity to support your family during your time of need.\nFor your convenience, we offer a simple method of paying for services online. Simply click the button below to access our secure site.</p>\n<br />\n<p>If you have questions, please contact us anytime.</p>",
                "name": "Payment Email Template",
                "subject": "Make a Payment Online for [Decedent Name]"
              }
            },
            "edit": {
              "alerts": {
                "missingData": {
                  "body": "The body can't be blank"
                },
                "save": {
                  "error": "There was an error when attempting to update this email template.",
                  "success": "This email template has been updated successfully!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "inputFields": {
                "emailSubject": "Email Subject"
              },
              "pageTitle": "Edit {templateTitle}",
              "textareas": {
                "emailBody": "Email Body"
              }
            },
            "pageTitle": "Email Templates",
            "tabTitle": "Email Templates",
            "table": {
              "body": {
                "edit": "Edit"
              },
              "headers": {
                "actions": "Actions",
                "templateName": "Template Name"
              }
            }
          },
          "navigation": {
            "links": {
              "backToAdmin": "Back to Admin",
              "emailTemplates": "Email Templates",
              "notificationSettings": "Notification Settings",
              "overview": "Overview",
              "payments": "Payments"
            }
          },
          "notification-settings": {
            "alerts": {
              "userNotification": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              }
            },
            "buttons": {
              "addRecipient": "+ Add New Notification Recipient",
              "cancel": "Cancel",
              "clear": "Clear",
              "save": "Save"
            },
            "inputFields": {
              "search": {
                "placeholder": "Search"
              },
              "staffMember": {
                "placeholder": "Staff Member"
              }
            },
            "lists": {
              "notifications": {
                "empty": "No Notifications Added"
              }
            },
            "pageTitle": "Notification Settings",
            "selectFields": {
              "organizationLocation": {
                "options": {
                  "all": "All"
                }
              }
            }
          },
          "overview": {
            "pageTitle": "Tribute Pay Overview",
            "sections": {
              "getStarted": {
                "mainText": {
                  "email": "Sales@TributePay.com",
                  "line1": "To start the enrollment process, click Payments from the menu on the left.",
                  "line2": "For any questions or for assistance enrolling, please contact us at"
                },
                "title": "Get Started"
              },
              "meetTributePay": {
                "mainText": "Tribute Pay’s suite of products gives families multiple ways to pay at your funeral home through emailed invoices via credit card, ACH/e-check, Apple Pay, and more directly through your software. With Tribute Pay’s competitive rates, superior customer service, and no monthly fees, you receive quick deposits with minimal effort.",
                "title": "Meet Tribute Pay: Your All-in-One Payment Solution"
              },
              "tributeFundCrowdfunding": {
                "title": "Tribute Fund Crowdfunding – Coming Soon!"
              },
              "tributeInsuranceAssignments": {
                "title": "Tribute Insurance Assignments – Coming Soon!"
              },
              "tributePaymentProcessing": {
                "mainText": "With no annual or monthly fees, funeral homes can take payments knowing the exact cost of each transaction.",
                "subSection": {
                  "list": {
                    "item1": "2.9% + $0.30 card and digital wallet transactions.",
                    "item2": "1.0% + $0.30 ACH transactions.",
                    "item3": "Accept payments by Visa, Mastercard, Discover, American Express, Google Pay, and Apple Pay."
                  },
                  "title": "Details"
                },
                "title": "Tribute Payment Processing"
              }
            },
            "tabTitle": "Overview"
          },
          "pageTitle": "Admin Settings"
        },
        "tribute-pay-payments": {
          "accounts": {
            "buttons": {
              "addNew": "Add New",
              "clear": "Clear"
            },
            "checkboxes": {
              "viewArchived": "View Archived?"
            },
            "edit": {
              "alerts": {
                "locations": {
                  "deleting": {
                    "error": "There was an error when attempting to remove one of the Locations."
                  },
                  "saving": {
                    "error": "There was an error when attempting to assign one of the Locations."
                  }
                },
                "paymentTypes": {
                  "deleting": {
                    "error": "There was an error when attempting to delete one of the Payment Types."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create one of the new Payment Types."
                  }
                },
                "settingChartOfAccounts": {
                  "prompts": {
                    "confirm": "Once assigned, the Financial Configuration cannot be changed. Are you sure?"
                  },
                  "saving": {
                    "error": "There was an issue assigning the Financial Configuration.",
                    "success": "The Financial Configuration was assigned successfully."
                  }
                },
                "transactionFee": {
                  "missingData": {
                    "financialConfiguration": "To set the transaction fee, please assign a Financial Configuration."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create the Transaction Fee Adjustment Type."
                  }
                },
                "tributePayAccount": {
                  "saving": {
                    "error": "There was an error when trying to save the Tribute Pay account.",
                    "success": "The Tribute Pay account was saved successfully!"
                  }
                }
              },
              "buttons": {
                "cancel": "Cancel",
                "continueEnrollment": "Continue Enrollment Information",
                "editEnrollment": "Edit Enrollment Information",
                "saveAndClose": "Save & Close",
                "saveFinancialConfiguration": "Save Financial Configuration"
              },
              "headings": {
                "financialConfiguration": "Financial Configuration",
                "transactionFee": "Transaction Fee"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "transactionFeeName": "Transaction Fee Name"
              },
              "pageTitle": "Edit Account",
              "selectFields": {
                "financialConfiguration": "Financial Configuration",
                "options": {
                  "selectOne": "Select One"
                },
                "transactionFeeGLAccount": "GL Account"
              }
            },
            "new": {
              "buttons": {
                "beginEnrollment": "Begin Enrollment",
                "cancel": "Cancel"
              },
              "disclaimers": {
                "statementDescriptor": {
                  "characterLimit": "5-22 characters, including at least one letter",
                  "excludedSpecialCharacters": "Does not contain any of the following special characters",
                  "latinOnly": "Latin characters only",
                  "text": "This is the business name that appears on your customer's credit card statements. This field should be a short but recognizable indication of your Doing Business As (DBA) name."
                }
              },
              "errors": {
                "beginEnrollment": "There was an issue attempting to begin enrollment."
              },
              "headings": {
                "mainLocation": "Main Location"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "statementDescriptor": "Statement Descriptor"
              },
              "pageTitle": "New Tribute Pay Account",
              "selectFields": {
                "location": "Location",
                "options": {
                  "selectOne": "Select One"
                }
              }
            },
            "pageTitle": "Tribute Pay Accounts",
            "table": {
              "body": {
                "noAccounts": "No Tribute Pay accounts to display."
              },
              "headers": {
                "accountName": "Account Name",
                "actions": "Actions",
                "numberOfLocations": "Number of Locations",
                "status": "Status"
              }
            }
          },
          "navigation": {
            "links": {
              "accounts": "Tribute Pay Accounts",
              "backToTributePay": "Back to Tribute Payments"
            }
          },
          "pageTitle": "Admin Settings",
          "tabTitle": "Payments"
        },
        "users": {
          "edit": {
            "feature-configuration": {
              "actions": {
                "savingUser": {
                  "errorMessage": "Oops! There was an error saving this user.",
                  "successMessage": "User saved!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "categories": {
                "adminNavigation": "Admin Navigation",
                "all": "All",
                "caseSectionNavigation": "Case Section Navigation",
                "globalNavigation": "Global Navigation"
              },
              "checkboxes": {
                "canSetupTributePay": "Can Setup Tribute Pay?",
                "canViewAccounting": "Can View Accounting?",
                "canViewCalendar": "Can View Calendar?",
                "canViewContacts": "Can View Contacts?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewData": "Can View Data?",
                "canViewDisposition": "Can View Disposition?",
                "canViewDocuments": "Can View Documents?",
                "canViewEvents": "Can View Events?",
                "canViewFamilyFriends": "Can View Family & Friends?",
                "canViewFamilyPortal": "Can View Family Portal?",
                "canViewFinancialConfigurations": "Can View Financial Configurations?",
                "canViewFinancialDetail": "Can View Financial Detail?",
                "canViewForms": "Can View Forms?",
                "canViewFormsAdmin": "Can View Admin Forms?",
                "canViewGeneralPriceLists": "Can View General Price Lists?",
                "canViewGroups": "Can View Groups?",
                "canViewGrowthEngine": "Can View Growth Engine?",
                "canViewInfo": "Can View Info?",
                "canViewInquiries": "Can View Inquiries?",
                "canViewInquiriesCase": "Can View Case Inquiries?",
                "canViewInquiriesGlobal": "Can View Inquiries?",
                "canViewKases": "Can View Cases?",
                "canViewLocations": "Can View Locations?",
                "canViewMiscTasks": "Can View Tasks?",
                "canViewObituary": "Can View Obituary?",
                "canViewObituaryGenerator": "Can View Obituary Generator?",
                "canViewOhioEdrs": "Can View Ohio EDRS?",
                "canViewOnlinePlannerPlans": "Can View Online Planner Plans?",
                "canViewOrganizations": "Can View Organizations?",
                "canViewPayments": "Can View Payments?",
                "canViewPlaces": "Can View Places?",
                "canViewPreneed": "Can View Preneed?",
                "canViewPreparations": "Can View Preparations?",
                "canViewPrintables": "Can View Printables?",
                "canViewReferralInfo": "Can View Referral Info?",
                "canViewReports": "Can View Reports?",
                "canViewServiceInfo": "Can View Service Info?",
                "canViewTCO": "Can View Websites?",
                "canViewTransportation": "Can View Transportation?",
                "canViewTributeVideo": "Can View Tribute Video?",
                "canViewUsers": "Can View Users?",
                "canViewVehicles": "Can View Vehicles?",
                "canViewVeteranInfo": "Can View Veteran Info?",
                "canViewWhiteboard": "Can View Whiteboard?"
              },
              "searchField": {
                "labelText": "Feature Name",
                "noResults": "No results found"
              }
            },
            "links": {
              "backToAdmin": "Back to Admin",
              "featureConfiguration": "Feature Configuration",
              "overView": "Overview"
            },
            "overview": {
              "buttons": {
                "backToUsers": "Back to Users Index",
                "passwordEmail": "Send Setup Password Email",
                "save": "Save"
              },
              "checkboxes": {
                "adjustInventory": "Can Adjust Inventory?",
                "auditLogPermission": "Audit Log Permission?",
                "canEditChainOfCustody": "Can Edit Chain of Custody?",
                "canPostContractLabel": {
                  "contractAddon": "Can Post Contract/Add-on?",
                  "invoiceAddon": "Can Post Invoice/Add-on?"
                },
                "canSetCaseNumbering": "Can Set Case Numbering?",
                "canTurnOffTributeStorePermission": "Can Turn Off Tribute Store?",
                "canUnlockCases": "Can Unlock Cases",
                "canUnpostContractLabel": {
                  "contractAddon": "Can Unpost Contract/Add-on?",
                  "invoiceAddon": "Can Unpost Invoice/Add-on?"
                },
                "canViewContactManager": "Can View Contact Manager?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewDebugging": "Can View Debugging?",
                "canViewDoNotExport": "Can View Do Not Export?",
                "canViewHideTributeBookPermission": "Can View Hide Tribute Book Setting?",
                "casesWidget": "Show Cases Widget?",
                "chainOfCustodyWidget": "Show Chain of Custody Widget?",
                "dashboardNavigationWidget": "Show Dashboard Navigation Widget?",
                "editCaseCustomID": "Edit Case Custom ID Permission?",
                "embalmer": "Embalmer",
                "eventsWidget": "Show Events Widget?",
                "financialPermission": "Financial Permission",
                "financialReportPermission": "Financial Report Permission",
                "funeralDirector": "Funeral Director",
                "inventoryPermission": "Has Inventory Permission?",
                "miscSalesPermission": "Has Misc Sales Permission?",
                "pastDueWidget": "Show Past Due Widget?",
                "tasksWidget": "Show Tasks Widget?"
              },
              "information": {
                "netsuiteID": "Netsuite ID:",
                "netsuiteSubsidiaryID": "Netsuite Subsidiary ID:",
                "tmsID": "TMS ID:"
              },
              "inputFields": {
                "email": "Email",
                "embalmerLicense": "Embalmer License #",
                "firstName": "First Name",
                "funeralDecisionsID": "Funeral Decisions ID",
                "funeralDirectorLicense": "Funeral Director License #",
                "lastName": "Last Name",
                "middleName": "Middle Name",
                "netsuiteID": "NetSuite ID",
                "netsuiteSubsidiaryID": "NetSuite Subsidiary ID",
                "phone": "Mobile Phone"
              },
              "save": {
                "emailExists": "The email address you entered is already assigned. Please enter a different email address.",
                "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                "matchingEmail": "The email address you entered is already in use. Please enter a different email address.",
                "noEmail": "You cannot save this user without an email address."
              },
              "sectionHeaders": {
                "dashboardConfiguration": "Dashboard Configuration",
                "editUser": "Edit User",
                "permissionSlider": "Permission Slider",
                "roles": "Roles"
              },
              "selectFields": {
                "communicationPreference": {
                  "label": "Communication Preference",
                  "options": {
                    "both": "Both",
                    "email": "Email",
                    "sms": "SMS"
                  }
                },
                "filterCasesByStatus": {
                  "label": "Filter Cases by Status"
                },
                "state": {
                  "options": {
                    "defaultProvince": "Default Province",
                    "defaultRegion": "Default Region",
                    "defaultState": "Default State",
                    "none": "None"
                  }
                }
              },
              "userTypes": {
                "administrator": {
                  "description": "These users can do everything except create administrator level users.",
                  "titles": {
                    "list": {
                      "officeManagement": "Office Management",
                      "seniorFuneralDirector": "Senior Level Funeral Director"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Administrator"
                },
                "noPermissions": {
                  "description": "Cannot access Tribute Management Software. These users are for notification purpose only.",
                  "type": "No Permissions Set"
                },
                "ownerManager": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "managerOfLocation": "Manager of Location",
                      "owner": "Owner"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Owner/Manager"
                },
                "staff": {
                  "description": "These users have full access to Tribute Management Software except other user accounts.",
                  "titles": {
                    "list": {
                      "apprentices": "Apprentices",
                      "embalmer": "Embalmer",
                      "funeralDirector": "Funeral Director",
                      "sales": "Sales"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Staff"
                },
                "superuser": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "crakn": "CRäKN Employee",
                      "tms": "Tribute Management Software Employee",
                      "tributeTech": "Tribute Technology Employee"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Superuser"
                },
                "supportStaff": {
                  "description": "These users have read-only access to Tribute Management Software.",
                  "titles": {
                    "list": {
                      "carWashers": "Car Washers",
                      "hairDressers": "Hair Dressers",
                      "janitors": "Janitors",
                      "officeSupportStaff": "Office Support Staff",
                      "valets": "Valets",
                      "visitationAssistants": "Visitation Assistants"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Support Staff"
                }
              }
            },
            "pageTitle": "User Settings",
            "permissions": {
              "canViewCaseSettings": "Can View Case Settings?"
            }
          },
          "permissions": {
            "can_view_tribute_book_setting": {
              "label": "Can View Tribute Book Setting?"
            }
          }
        }
      },
      "calendar": {
        "new-event": {
          "messages": {
            "delete": {
              "error": "Something went wrong deleting this event, let's try that again.",
              "success": "You successfully deleted your event."
            },
            "save": {
              "error": "Sorry, but there was an error saving this event.",
              "success": "Yay! You saved an event!"
            }
          },
          "pageTitle": {
            "edit": "Edit",
            "event": "Event",
            "new": "New"
          }
        }
      },
      "contacts": {
        "edit": {
          "headers": {
            "history": "History"
          },
          "information": {
            "affiliation": "Affiliation",
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        }
      },
      "crakn": {
        "clients": {
          "edit": {
            "feature-config": {
              "checkboxes": {
                "canSeparateTaxCalculations": {
                  "label": "Can Separate Tax Calculations?"
                },
                "canViewCustomSelectionSorting": {
                  "label": "Can View Custom Selection Sorting?"
                },
                "canViewEnhancedPackages": {
                  "label": "Can View Enhanced Packages?"
                },
                "canViewStatementCategories": {
                  "label": "Can View Statement Categories?"
                },
                "includePostingDateInFinancialDetailsExport": {
                  "label": "Include Posting Date In Financial Details Export?"
                },
                "requireReasonForUnposting": {
                  "label": "Require Reason for Unposting?"
                }
              }
            },
            "forms-config": {
              "banners": {
                "error": "Error! TFE migration failed.",
                "migrationStarted": "Migration started",
                "success": "Success! TFE migration is complete."
              },
              "buttons": {
                "close": "Close",
                "migrateFormsToTfe": "Migrate Client to TFE",
                "save": "Save"
              },
              "checkboxes": {
                "canUseTfeEsign": "Can Use Forms with eSign",
                "canViewEsignDashboard": "Can View eSign Dashboard",
                "canViewFormInTfe": "Can View Forms in Tribute Forms Engine",
                "shouldFlattenForms": "Should Flatten Forms"
              },
              "migrationText": {
                "completed": "TFE migration for this client is complete.",
                "confirmPrompt": "This action cannot be reversed. Are you sure you want to migrate this client to TFE?",
                "inProgress": "TFE migration in progress. This might take a few seconds, hang tight!",
                "warning": "Once this client has migrated to TFE, the action cannot be reversed."
              },
              "pageTitle": "Forms Configuration",
              "tfeMigrationTitle": "TFE Migration"
            },
            "reports-config": {
              "checkboxes": {
                "includeContractPostingDate": {
                  "label": "Include Contract Posting Date?"
                },
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG Preneed Report",
                    "jcgReport": "JCG Report",
                    "preneedToAtneedConversionReport": "Preneed to At-Need Conversion Report",
                    "preneedToAtneedRatioReport": "Preneed to At-Need Ratio Report"
                  }
                }
              }
            },
            "tfe": {
              "edit": {
                "addToClients": {
                  "enabledHeader": "Clients using this form",
                  "header": "Add Form to Client(s)",
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Successfully added form to client"
                  },
                  "placeholder": "Client List"
                },
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Update"
                },
                "downloadTestForm": {
                  "messages": {
                    "failure": "An error occurred while downloading the form with test data. Please try again."
                  }
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "description": "Description",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name",
                  "replacePdf": "Replace existing PDF:"
                },
                "header": "Edit Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully updated form"
                },
                "removeFromClient": {
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Form successfully removed from client"
                  }
                },
                "search": {
                  "alreadySelected": "(Already Selected)"
                }
              },
              "index": {
                "buttons": {
                  "clear": "Clear",
                  "new": "Add New"
                },
                "header": "Forms",
                "noForms": "No Forms Found",
                "search": {
                  "placeholder": "Search"
                },
                "text": {
                  "clientSpecific": "(Client Specific)"
                }
              },
              "new": {
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Create"
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name"
                },
                "header": "New Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully created form"
                }
              }
            }
          }
        },
        "manage": {
          "demos": {
            "edit": {
              "checkboxes": {
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG PN Report",
                    "jcgReport": "JCG Report"
                  }
                }
              }
            },
            "index": {
              "inputFields": {
                "tmsPassword": "Password (To Log Into TMS)"
              }
            }
          },
          "forms": {
            "edit": {
              "buttons": {
                "update": "Update"
              },
              "headers": {
                "addFormToClient": "Add Form to Client(s)",
                "clientsUsingThisForm": "Clients using this form",
                "editForm": "Edit Form"
              },
              "labels": {
                "downloadBlankPdf": "Download blank PDF",
                "downloadWithTestData": "Download with test data",
                "replaceExistingPdf": "Replace existing PDF"
              },
              "placeholders": {
                "clientList": "Client List"
              }
            },
            "fields": {
              "caseContactDropdown": "Case Contact Dropdown?",
              "esignEnabled": "eSign Enabled?",
              "eventDropdown": "Event Dropdown?",
              "inquiryDropdown": "Inquiry Dropdown?",
              "locationDropdown": "Location Dropdown?",
              "name": "Name",
              "searchable": "Searchable?"
            },
            "header": "New Form",
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "eSign": {
                "loading": "Document is being analyzed for eSign compatibility",
                "notCompatible": "Form not compatible with eSign"
              },
              "headers": {
                "new_form": "New Form"
              }
            }
          },
          "global-settings": {
            "pageTitle": "Global Settings"
          },
          "links": {
            "clients": "Clients",
            "demos": "Demos",
            "forms": "Forms",
            "global-settings": "Global Settings",
            "migrations": "Migrations"
          },
          "migrations": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "restartMigration": "Try Again",
              "start": "Start Migration"
            },
            "checkboxes": {
              "autoPost": "Auto Post Contracts"
            },
            "datePickers": {
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "missingFiles": "Missing Files",
                "of": "of",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for"
          },
          "pageTitle": "TMS Settings"
        }
      },
      "esignatures": {
        "actions": {
          "viewEsignDetails": "View eSign Details"
        },
        "notifications": {
          "formCanceled": {
            "failure": "Failed to cancel the form. Please try again.",
            "success": "Form successfully canceled!"
          },
          "resentToSigner": {
            "failure": "Failed to resend the form to signer.",
            "success": "Form successfully resent to signer!"
          },
          "resentToSigners": {
            "failure": "Failed to resend the form to pending signers.",
            "success": "Form successfully resent to pending signers!"
          }
        },
        "pageTitle": "eSignatures",
        "placeholders": {
          "location": "Location"
        },
        "tables": {
          "headers": {
            "actions": "Actions",
            "case": "Case",
            "location": "Location"
          }
        }
      },
      "inventory": {
        "edit": {
          "error": {
            "permissions": "You do not have permission to adjust inventory, please ask an administrator.",
            "save": "Inventory change failed to save!"
          },
          "selectFields": {
            "receiveForContract": {
              "label": "Receive for Contract"
            },
            "receiveForStock": {
              "label": "Receive for Stock"
            }
          }
        }
      },
      "kases": {
        "events": {
          "edit": {
            "messages": {
              "deleteEvent": {
                "error": "Something went wrong, please try again.",
                "success": "Successfully removed the event."
              },
              "saveAnotherEvent": {
                "success": "Yay! You saved an event."
              },
              "saveEvent": {
                "error": "There was an error saving this event, please try again.",
                "success": "Yay! You saved an event."
              },
              "unsavedChanges": "You have unsaved changes. Are you sure?"
            },
            "pageTitles": {
              "edit": "Edit",
              "event": "Event",
              "new": "New"
            },
            "secondaryTitles": {
              "case": "Case",
              "location": "Location"
            },
            "warnings": {
              "tcoEmptyPlaceName": "To export to your website, This Event must have a Place with a name.",
              "tcoEventTypeEmpty": "To export to your website, This Event must have an Event Type.",
              "tcoNameInvalid": "To export to your website, the Event Name must be between 2 and 100 characters."
            }
          }
        },
        "manage": {
          "documents": {
            "albums": {
              "manage": {
                "importTributeWall": {
                  "error": "Something went wrong, please try again.",
                  "label": "Import Tribute Wall",
                  "success": "Album successfully imported."
                }
              }
            }
          },
          "edit": {
            "custom-fields": {
              "actions": {
                "create": {
                  "error": "No custom field configuration has been created that can be used with this case."
                },
                "error": "There was an error when saving these custom fields.",
                "save": "Custom fields have been saved!"
              },
              "buttons": {
                "back": "Back to Overview",
                "save": "Save"
              },
              "pageTitle": "Custom Fields",
              "select": {
                "options": {
                  "defaultValue": "Select One"
                }
              }
            },
            "disposition": {
              "checkboxes": {
                "crematedRemainsMailed": {
                  "label": "Cremated Remains Mailed?"
                },
                "crematedRemainsPresentForService": {
                  "label": "Cremated Remains Present for Service?"
                },
                "graveUnknown": {
                  "label": "Grave Unknown?"
                },
                "headstoneDesired": {
                  "label": "Headstone Desired?"
                }
              },
              "headers": {
                "crematedRemainsAuthorizedPickup": "Person(s) Authorized to Pick up Cremated Remains:",
                "graveOwner": "Grave Owner",
                "title": "Disposition"
              },
              "input": {
                "block": {
                  "placeholder": "Block"
                },
                "burialPermitNumber": {
                  "placeholder": "Burial Permit Number"
                },
                "cremationPermitNumber": {
                  "placeholder": "Cremation Permit Number"
                },
                "cremationTagNumber": {
                  "placeholder": "Cremation Tag Number"
                },
                "crypt": {
                  "placeholder": "Crypt"
                },
                "discNumber": {
                  "placeholder": "Disc Number"
                },
                "grave": {
                  "placeholder": "Grave/Plot"
                },
                "lot": {
                  "placeholder": "Lot"
                },
                "mausoleum": {
                  "placeholder": "Mausoleum"
                },
                "niche": {
                  "placeholder": "Niche"
                },
                "plot": {
                  "placeholder": "Plot"
                },
                "secondaryContainer": {
                  "placeholder": "Secondary Container"
                },
                "section": {
                  "placeholder": "Section"
                },
                "tier": {
                  "placeholder": "Tier"
                },
                "timeOfCremation": {
                  "placeholder": "Time of Cremation"
                },
                "urnTempContainer": {
                  "placeholder": "Urn or Temporary Container"
                }
              },
              "pikaday": {
                "crematedRemainsBackBy": {
                  "placeholder": "Cremated Remains Back By"
                },
                "dateOfCremation": {
                  "placeholder": "Date of Cremation"
                },
                "dateOfDisposition": {
                  "placeholder": "Date of Disposition"
                },
                "disintermentDate": {
                  "placeholder": "Date of Disinterment"
                },
                "remainsPickupDate": {
                  "placeholder": "Date of Remains Pick Up/Drop Off"
                }
              },
              "select": {
                "crematedRemainsPickupPerson": {
                  "placeholder": "Cremated Remains Picked Up By"
                },
                "cremationDirector": {
                  "placeholder": "Cremation Director"
                },
                "crematory": {
                  "placeholder": "Crematory"
                },
                "dispositionMethod": {
                  "placeholder": "Disposition Method",
                  "placeholderLabel": "Select One"
                },
                "dispositionPlace": {
                  "placeholder": "Place of Final Disposition"
                },
                "place": {
                  "placeholder": "Place"
                }
              },
              "textareas": {
                "dispositionNotes": {
                  "placeholder": "Disposition Notes"
                },
                "monumentNotes": {
                  "placeholder": "Monument Notes"
                }
              }
            },
            "family-portal": {
              "buttons": {
                "backToOverview": {
                  "text": "Back to Overview"
                },
                "copyInformation": {
                  "errorMessage": "Unable to copy this information to the case",
                  "successMessage": "Successfully copied selected information to case!"
                },
                "createNewInvite": {
                  "errorMessage": "There was an issue creating a Family Portal invite",
                  "successMessage": "Your Family Portal invite has been created. The recipient has been notified.",
                  "text": "Create New Invitation"
                },
                "removeRecipient": {
                  "confirmationMessage": "Are you sure you want to remove this invitation? The action cannot be undone.",
                  "successMessage": "Your Family Portal invite has been removed!"
                }
              },
              "newInvite": {
                "missingEmailText": "Please enter a recipient that has a valid E-mail address.",
                "placeholder": "Recipient's Name",
                "sectionTitle": "New Invite"
              },
              "pageDescription": "Allow family members to submit basic case information through our Family Portal. Once an invite is created, the recipient will receive an E-mail with a link to the Family Portal.",
              "pageTitle": "Family Portal"
            },
            "info": {
              "supportMessage": "If a Location is not listed, please contact us for support.",
              "validations": {
                "archivedLocation": "Assigned to archived location: {location}",
                "archivedUser": "Assigned to archived user: {user}",
                "immutableLocation": "This case has a posted {contractText}. Location cannot be changed.",
                "nonFuneralDirectorUser": "Assigned to user without funeral director role: {user}"
              }
            },
            "navigationItems": {
              "customFields": "Custom Fields",
              "forms": "Forms",
              "settings": "Settings"
            },
            "printables": {
              "categorySelect": {
                "placeholder": "Category",
                "placeholderLabel": "All"
              },
              "noPrintables": "No printables available",
              "pageTitle": "Available Printables",
              "search": "Name",
              "subcategorySelect": {
                "placeholder": "Subcategory",
                "placeholderLabel": "All"
              }
            },
            "service-info": {
              "serviceNotes": {
                "musicNotes": "Music Notes",
                "scriptureNotes": "Scripture Notes"
              }
            },
            "settings": {
              "addError": "Failed to sync custom fields.",
              "addSuccess": "Custom fields synced successfully.",
              "caseSent": "Case Successfully sent to TCO!",
              "deleteDuplicateContract": "Delete Duplicate Contract",
              "deleteDuplicateContractError": "Oh No! There was an error.",
              "idSuccess": "TCO ID saved successfully.",
              "includeInVirginiaVitalStatsReport": "Include in Virginia FD Monthly Vital Statistics Report",
              "jcgReportSendSurveyOptOut": "JCG Report - Send Survey Opt Out",
              "resendError": "Oh No! There was an error while trying to resend this case to TCO.",
              "resetCocError": "Oh No! There was a problem while trying to reset Chain of Custody for this case.",
              "resetCocSuccess": "Chain of Custody reset successfully.",
              "saveButton": "Save",
              "saveKaseError": "Something went wrong, please try again!",
              "saveKaseSuccess": "Case has been updated!",
              "sendCaseButton": "Send Case to TCO",
              "tcoFieldMessge": "Use the TCO ID field to link this case to an existing case in the TCO system.",
              "tcoSendCaseMessage": "Use the Send Case to TCO button for creating a new case in the TCO system and linking it to this case. This button should only ever be clicked once to avoid creating duplicate cases in the TCO system.",
              "title": "Settings",
              "unlockChainOfCustody": "Unlock Chain of Custody"
            },
            "tfe": {
              "forms": {
                "filled": {
                  "button": {
                    "refillAll": "Refill All Forms"
                  },
                  "formLibrary": "Form Library",
                  "messages": {
                    "delete": {
                      "success": "Form successfully deleted!"
                    },
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "refillAllEsignForm": "Refilling all forms will cancel eSignature requests for all forms. Are you sure?"
                  },
                  "text": {
                    "noFormsFilled": "No Forms Filled",
                    "pleaseSelectAForm": "Please select a form from the list of available forms to fill."
                  },
                  "title": "Filled Forms"
                },
                "index": {
                  "downloadTestForm": {
                    "messages": {
                      "failure": "An error occurred while downloading the form with test data. Please try again."
                    }
                  },
                  "filledForms": "Filled Forms",
                  "messages": {
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "success": "Filling process has begun!"
                  },
                  "text": {
                    "dontSeeForm": "Don't see a form you are looking for?",
                    "enableFormsFirst": "Please be sure to select forms to enable in the admin area.",
                    "noFormsEnabled": "No Forms Enabled",
                    "requestForm": "Request one."
                  },
                  "title": "Form Library"
                }
              }
            },
            "veteran-info": {
              "alertMessages": {
                "removeRecordSuccess": "Successfully Removed.",
                "saveError": "Oh No! There was an error.",
                "saveRecordSuccess": "Great job! You saved a Service Record.",
                "updateCaseSuccess": "Great job! You updated the case information."
              },
              "buttons": {
                "addNewServiceRecord": "Add New Service Record",
                "clickToAddRecordLink": "Click here",
                "onDelete": "Remove Record",
                "onSave": "Save Record"
              },
              "checkboxes": {
                "airForceCrossAward": "Air Force Cross",
                "bronzeStarAward": "Bronze Star",
                "dischargePapersReceived": "Discharge Papers Received?",
                "dstFlyingCrossAward": "DST Flying Cross",
                "dstServiceCrossAward": "DST Service Cross",
                "flag": "Flag?",
                "headstoneDesired": "Headstone Desired?",
                "isVeteran": "Veteran?",
                "medalOfHonorAward": "Medal of Honor",
                "militaryHonors": "Military Honors?",
                "navyCrossAward": "Navy Cross",
                "purpleHeartAward": "Purple Heart",
                "receivedAwards": "Received Awards?",
                "remainsNotBuried": "Remains Not Buried?",
                "requestedPresence": "Requested Presence?",
                "selectedMonument": "Selected Monument?",
                "silverStarAward": "Silver Star",
                "temporaryMarker": "Temporary Marker?"
              },
              "completableHeaderLabels": {
                "federalFormInfo": "Federal Form Info",
                "generalInfo": "General Info",
                "headstoneInfo": "Headstone Info",
                "serviceRecords": "Service Records"
              },
              "dateTimeSelectors": {
                "labels": {
                  "dischargedDate": "Date Discharged",
                  "enteredDate": "Date Entered",
                  "headstoneOrderDate": "Date Headstone Ordered",
                  "honorsEventDate": "Event Start Date",
                  "honorsEventTime": "Start Time"
                }
              },
              "descriptionText": {
                "noRecordsAvailableText": "No Service Record Information Available.",
                "toAddTextForAfterAddRecordLink": "to add",
                "vaFormText": "Some of these values come from the VA Form 40-1330"
              },
              "dropdowns": {
                "placeholderLabel": "Select One",
                "placeholders": {
                  "branch": "Military Branch",
                  "headstoneEmblem": "Emblem",
                  "headstoneMaterial": "Type of Stone",
                  "rank": "Highest Rank / Rate Attained",
                  "rating": "Rating / Job / Specialty",
                  "war": "War Fought In"
                }
              },
              "federalFormQuestions": {
                "actualBurialCost": "If Yes, Provide Actual Burial Cost",
                "burialContributionAmount": "If Yes, Provide Amount",
                "hasGovernmentOrEmployerBurialContribution": "Did a Federal/State Government or the Veteran's Employer Contribute to the Burial?",
                "hasIncurredBurialExpenses": "Did you Incur Expenses for the Veteran's Burial or Interment?",
                "hasIncurredExpensesForRemainsTransportation": "Were Expenses Incurred for the Transportation of the Veteran's Remains from the Place of Death to the Final Resting Place?",
                "hasPreviouslyReceivedAllowance": "If Deceased Veteran's Spouse, Did You Previously Receive a VA Burial Allowance?",
                "incurredExpensesForRemainsTransportationAmount": "If Yes, Provide Amount",
                "isBuriedInNationalCemetery": "Was Veteran Buried in a National Cemetery or One Owned by the Federal Government?",
                "isSeekingNonServiceConnectedBenefits": "Are You Seeking Burial Benefits for a Veteran's Non-Service-Connected Death Occurring at a VA Medical Center, Nursing Home under VA Contract, or Other VA Facility?",
                "isServiceConnectedDeath": "Is This Service-Connected Death?"
              },
              "inputFields": {
                "branchRequested": "Branch",
                "dischargeType": "Discharge Type",
                "flagInstructions": "Flag Instructions",
                "markerNotes": "Marker Notes",
                "otherBranch": "Other Branch",
                "otherWar": "Other War",
                "placeDischarged": "Place Discharged",
                "placeEntered": "Place Entered",
                "serviceNumber": "Service Number",
                "vaNumber": "VA File Number",
                "xcNumber": "XC Number"
              },
              "personSelectors": {
                "personPresentedTo": "Presented To"
              },
              "placeSelectors": {
                "placeSelector": "Place"
              },
              "textareas": {
                "headstoneEngraving": {
                  "label": "Headstone Engraving",
                  "placeholder": "Engraving"
                },
                "militaryOrganizations": {
                  "label": "Military Organizations",
                  "placeholder": "Organizations"
                },
                "notes": {
                  "label": "Notes",
                  "placeholder": "Notes"
                }
              },
              "title": "Veteran Info"
            }
          },
          "financials": {
            "adjustments": {
              "errors": {
                "postingPeriodAdd": "Cannot add an adjustment in a locked posting period. You must unlock the posting period first."
              }
            },
            "discounts": {
              "errors": {
                "postingPeriodAdd": "Cannot add a discount in a locked posting period. You must unlock the posting period first."
              }
            },
            "payments": {
              "buttons": {
                "collectionAttempt": {
                  "close": "Close",
                  "sendEmail": "Send Email"
                }
              },
              "dropdownSelection": {
                "insurancePolicy": {
                  "label": "Insurance Policy",
                  "selectionLabel": "Select a Policy"
                }
              },
              "edit": {
                "errors": {
                  "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
                  "postingPeriodAdd": "Cannot add a payment in a locked posting period. You must unlock the posting period first."
                }
              },
              "headers": {
                "paymentEmailLinkHistory": "Payment Email Link History",
                "paymentLinkEmail": "Payment Link Email",
                "personSelector": "Recipient(s)",
                "recipients": "Recipients"
              },
              "labels": {
                "personSelector": {
                  "placeholder": "Name"
                }
              },
              "messages": {
                "paymentLink": {
                  "error": "Error sending payment link email.",
                  "success": "Payment link email sent successfully."
                },
                "receiptEmail": {
                  "notSend": "A receipt will not be emailed to the payer.",
                  "send": "A receipt will be emailed to the payer."
                }
              },
              "tables": {
                "paymentLinkHistory": {
                  "headers": {
                    "dateSent": "Date Sent",
                    "linkActive": "Link Active",
                    "previousEmailLinkHistory": "Previous Email Link History",
                    "recipientEmail": "Recipient Email",
                    "recipientName": "Recipient Name"
                  },
                  "noPreviousEmailLinkHistory": "No previous payment link emails sent."
                }
              },
              "toolTips": {
                "active": "Active",
                "expired": "Expired"
              },
              "validations": {
                "collectionAttempt": {
                  "disablePaymentPostingPeriod": "Cannot add payment in a locked posting period.",
                  "paymentTypeRequired": "Payment Type is required.",
                  "peopleRequired": "At least one recipient is required.",
                  "recipientEmailRequired": "Email address is required for recipients."
                },
                "emailPaymentLink": {
                  "greaterThanOrEqualTo": "The balance must be greater than $0.50 to send a payment link."
                }
              }
            },
            "refunds": {
              "errors": {
                "postingPeriodAdd": "Cannot add a refund in a locked posting period. You must unlock the posting period first."
              },
              "inputField": {
                "refundDescription": "Refund Description"
              }
            },
            "selection": {
              "buttons": {
                "placeholders": {
                  "backToOverview": "Back to Overview",
                  "cancelUnpost": "Cancel Unposting",
                  "invalidateSignatures": {
                    "invalidate": "Invalidate ",
                    "signatures": " Signatures"
                  },
                  "save": "Save",
                  "saveAndPost": "Save and Post ",
                  "saveUnpostingReason": "Save Unposting Reason",
                  "unpost": "Unpost"
                }
              },
              "errors": {
                "missingReason": "You must provide a reason to unpost",
                "postingPeriodLockedContract": "Cannot post a {name} in a locked posting period. You must unlock the posting period first.",
                "saving": "There was a problem saving. Please contact us for support.",
                "unlockContractPostingPeriod": "Cannot unpost a {name} in a locked posting period. You must unlock the posting period first.",
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "information": {
                "badDebtAt": "on",
                "badDebtBy": "Marked as bad debt by:",
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite Invoice ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              },
              "inputs": {
                "placeholders": {
                  "purchaserRelationship": "Purchaser Relationship",
                  "reasonForUnposting": "Reason for Unposting"
                }
              },
              "successful": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "unposted": "Selection successfully unposted"
              }
            }
          },
          "integrations": {
            "maintenanceMode": "MAINTENANCE MODE",
            "maintenanceModeLong": "This service is in maintenance mode.  All communication has been paused.",
            "tco": {
              "case": {
                "errors": {
                  "deathDateInFuture": "Date of death cannot be more than 1 day in the future.",
                  "guestbookEmail": {
                    "format": "Must be a valid email address",
                    "presence": "Email cannot be blank"
                  },
                  "missingDateOfDeath": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website."
                },
                "pdfUpload": {
                  "button": "Upload PDF",
                  "label": "PDF File",
                  "viewDocument": "View File"
                },
                "webcast": {
                  "pin": {
                    "label": "Webcast PIN"
                  },
                  "url": {
                    "label": "Webcast URL"
                  }
                }
              },
              "checkboxes": {
                "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                "enableExitPops": "Enable Exit-Pops?",
                "makeGuestbookPrivate": "Make Guestbook Private?",
                "refreshOgImage": "Refresh og:image?",
                "turnOffTributeStore": "Turn Off Tribute Store?"
              },
              "facebook": {
                "errors": {
                  "locationNotSet": "You must first link this case to a service location to post to facebook.",
                  "urlNotSet": "Environment URL not set up, please contact support."
                },
                "warningMessage": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
              },
              "legacyTouch": {
                "addPin": {
                  "error": "There was a problem while adding the pin.",
                  "success": "Pin added successfully."
                },
                "confirm": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, add PIN",
                  "message": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?",
                  "title": "Confirm PIN"
                },
                "deletePin": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, delete PIN",
                  "error": "There was a problem while deleting the pin.",
                  "message": "Are you sure you want to delete this pin?",
                  "removeButtonToolTip": "Remove PIN",
                  "success": "Pin deleted successfully.",
                  "title": "Delete PIN"
                },
                "pinsForCase": "PIN's Avaliable:",
                "title": "Legacy Touch",
                "validate": {
                  "error": "Invalid PIN - please double check for typos, or reach out to ",
                  "helpCenterName": "Legacy Touch Help Center",
                  "validateButton": "Validate PIN"
                }
              },
              "placeholders": {
                "privateGuestbookEmail": "Private Guestbook Email"
              },
              "previewSection": {
                "button": "Preview",
                "description": "See how this page will look on your website before you publish it.",
                "title": "Preview"
              },
              "save": {
                "error": "Oops! There was an error saving this information to your website.",
                "success": "Website information saved successfully!"
              },
              "videoTribute": {
                "label": "Video Tribute URL"
              }
            },
            "tco-v2": {
              "buttons": {
                "backToIntegrations": "Back to Integrations",
                "save": "Save"
              },
              "errors": {
                "description": "Please add the following in order to export.",
                "labels": {
                  "failedExport": "Last Failed Export"
                },
                "list": {
                  "deathDateInTheFuture": "Date of death cannot be more than 1 day in the future.",
                  "missingDeathDate": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website.",
                  "unableToSyncWebsiteProvider": "Unable to sync with website provider. Please try again in a few minutes. If this issue persists, please contact support."
                },
                "save": "Oops! There was an error saving this information to your website."
              },
              "maintenanceMode": {
                "title": "MAINTENANCE MODE"
              },
              "sections": {
                "headerTheme": {
                  "title": "Header Theme"
                },
                "legacyTouch": {
                  "buttons": {
                    "validatePin": "Validate PIN"
                  },
                  "dialogModals": {
                    "confirm": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, add PIN"
                      },
                      "error": "There was a problem while adding the PIN.",
                      "messages": {
                        "matchingPins": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?"
                      },
                      "success": "Pin added successfully.",
                      "title": "Confirm"
                    },
                    "delete": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, delete PIN"
                      },
                      "error": "There was a problem while deleting the PIN.",
                      "success": "PIN deleted successfully.",
                      "title": "Delete PIN",
                      "warning": "Are you sure you want to delete this PIN?"
                    }
                  },
                  "errors": {
                    "invalidPin": {
                      "contact": {
                        "legacyTouchHelpCenter": "Legacy Touch Help Center"
                      },
                      "text": "Invalid PIN - Please double check for typos, or reach out to"
                    }
                  },
                  "inputFields": {
                    "pin": "PIN"
                  },
                  "pinsAvailable": {
                    "buttons": {
                      "removePin": "Remove PIN"
                    },
                    "label": "PINs Available:"
                  },
                  "title": "Legacy Touch"
                },
                "pdfFile": {
                  "buttons": {
                    "uploadPdf": "Upload PDF"
                  },
                  "links": {
                    "viewFile": "View File"
                  },
                  "title": "PDF File"
                },
                "preview": {
                  "buttons": {
                    "preview": "Preview"
                  },
                  "description": "See how this page will look on your website before you publish it.",
                  "title": "Preview"
                },
                "socialSharing": {
                  "buttons": {
                    "connectToFacebook": "Connect to Facebook"
                  },
                  "checkboxes": {
                    "postToFacebook": "Post to Facebook?"
                  },
                  "description": "Increase Traffic to your website by sharing your obituaries to social media. Connect to your company accounts now.",
                  "errors": {
                    "environmentUrlNotSet": "Environment URL not set up, please contact support.",
                    "missingServiceLocation": "You must first link this case to a service location to post to Facebook."
                  },
                  "title": "Social Sharing",
                  "warning": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
                },
                "tributes": {
                  "title": "Tributes"
                },
                "websiteSettings": {
                  "checkboxes": {
                    "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                    "disableTreeAddOn": "Disable Tree Add On?",
                    "enableExitPops": "Enable Exit-Pops?",
                    "hideBirthDate": "Hide Birth Date?",
                    "hideDeathDate": "Hide Death Date?",
                    "hideDeathNotice": "Hide Death Notice?",
                    "hideObituary": "Hide Obituary on Tribute Archive?",
                    "hideTributeBook": "Hide Tribute Book?",
                    "hideTributeWall": "Hide Tribute Wall?",
                    "makeGuestbookPrivate": "Make Guestbook Private?",
                    "refreshOgImage": "Refresh og:image?",
                    "turnOffTributeArchiveStore": "Turn Off Tribute Archive Store?",
                    "turnOffTributeStore": "Turn Off Tribute Store?"
                  },
                  "inputFields": {
                    "homepagePosition": "Homepage Position",
                    "privateGuestbookEmail": "Private Guestbook Email",
                    "videoTributeUrl": "Video Tribute URL",
                    "webcastPin": "Webcast PIN",
                    "webcastUrl": "Webcast URL"
                  },
                  "title": "Website Settings"
                },
                "websiteStatus": {
                  "subsections": {
                    "publishToWebsite": {
                      "buttons": {
                        "publish": "Publish",
                        "unpublish": "Unpublish"
                      },
                      "lastExport": {
                        "label": "Last Exported:"
                      },
                      "status": {
                        "label": "Status:",
                        "list": {
                          "notPublished": "Not Published",
                          "published": "Published"
                        }
                      },
                      "title": "Publish to Website"
                    }
                  },
                  "title": "Website Status"
                }
              },
              "successes": {
                "save": "Website information saved successfully!"
              },
              "validations": {
                "email": {
                  "cannotBeBlank": "Email cannot be blank.",
                  "mustBeValidEmail": "Must be a valid email address."
                }
              }
            },
            "tribute-print": {
              "pageTitle": "Tribute Print",
              "status": {
                "label": "Tribute Print Status:"
              },
              "storeLink": "Go to store"
            },
            "tribute-video": {
              "buttons": {
                "backToIntegrations": {
                  "label": "Back to Integrations"
                }
              },
              "fields": {
                "select": {
                  "photoAlbums": {
                    "label": "Photo Albums",
                    "options": {
                      "noAlbum": "No Album",
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "instructions": {
                "missingData": {
                  "location": "To export this case, please make sure that the {location} location has address details and an email assigned."
                },
                "noAlbumSelection": "No photos will be sent from Tribute Management Software to Tribute Video. Photos added through Tribute Video will still sync back into Tribute Management Software.",
                "videoDownload": {
                  "browserOptions": {
                    "chromeEdgeFirefox": {
                      "label": "Chrome, Edge, and Firefox:",
                      "optionText": "Save Link As..."
                    },
                    "safari": {
                      "label": "Safari:",
                      "optionText": {
                        "option1": "Download Linked File",
                        "option2": "Download Linked File As...",
                        "or": "or"
                      }
                    }
                  },
                  "label": "Video Download Instructions:",
                  "step1": "Right-click on \"Download Video\"",
                  "step2": "Choose the download option presented by your browser:"
                }
              },
              "links": {
                "downloadVideo": {
                  "label": "Download Video"
                },
                "editVideo": {
                  "errorMessage": "Oops! There was an error when attempting to edit the video.",
                  "label": "Edit Video"
                },
                "export": {
                  "label": "Export"
                },
                "getVideoStatus": {
                  "label": "Get Video Status"
                },
                "viewVideo": {
                  "label": "View Video"
                }
              },
              "pageTitle": "Tribute Video",
              "statuses": {
                "export": {
                  "label": "Status:",
                  "listOfStatuses": {
                    "exported": "Exported",
                    "notExported": "Not Exported"
                  }
                },
                "lastExported": {
                  "label": "Last Exported:"
                },
                "video": {
                  "label": "Video Status:",
                  "listOfStatuses": {
                    "error": "Error",
                    "noStatus": "Please click the link below to update the status.",
                    "published": "Published"
                  }
                }
              }
            },
            "tukios-v2": {
              "backLabel": "Back to Integrations",
              "coverPhotos": {
                "label": "Select a Tukios Cover Photo",
                "placeholder": "Tukios Cover Photo (Optional)"
              },
              "errors": {
                "emptyKaseDates": "Birth date and Death date are required.",
                "invalidContacts": "Family & friends must have a last name.",
                "invalidDeathDate": "Error: Death date is in the future."
              },
              "eventsLabel": "Events:",
              "exportButton": {
                "export": "Export",
                "resync": "Resync"
              },
              "status": {
                "exported": "Exported",
                "label": "Status:",
                "lastExported": "Last Exported:",
                "notExported": "Not Exported"
              },
              "title": "Tukios V2"
            }
          }
        },
        "overview": {
          "pendingEsignatures": {
            "mutlipleDeclinedSigners": "signers have declined to sign.",
            "noPendingEsignatures": "No pending eSignatures",
            "oneDeclinedSigner": "1 signer has declined to sign.",
            "status": "Status",
            "title": "Pending eSignatures"
          }
        }
      },
      "misc-sales": {
        "create": {
          "errors": {
            "gplNotConfigured": "This location does not have a configured GPL. Please contact us for support."
          },
          "pageTitle": "New Misc Sale"
        },
        "edit": {
          "details": {
            "errors": {
              "postingPeriodLocked": "Cannot post a sale in a locked posting period. You must unlock the posting period first."
            },
            "inputs": {
              "placeholders": {
                "save": "Save",
                "saveAndPostSale": "Save and Post Sale",
                "unpostSale": "Unpost Sale"
              }
            },
            "messages": {
              "success": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "miscellaneousSaleUpdated": "Misc Sale has been updated.",
                "pulledFromInventory": "Product pulled from Inventory!",
                "returnedFromInventory": "Product returned to Inventory!"
              }
            },
            "pageTitle": "Misc Sale",
            "sections": {
              "location": {
                "disclaimer": "If a Location is not listed, please contact us for support."
              }
            },
            "selection": {
              "information": {
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              }
            }
          },
          "payments": {
            "disclaimers": {
              "receiptEmail": {
                "willSend": "A receipt will be emailed to the payer.",
                "wontSend": "A receipt will not be emailed to the payer."
              }
            },
            "errors": {
              "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us."
            },
            "sections": {
              "totals": {
                "header": "Totals",
                "subsections": {
                  "adjustments": "Adjustments Total",
                  "payments": "Payments Total",
                  "refunds": "Refunds Total",
                  "remaining": "Remaining Balance",
                  "selection": "Selection Total"
                }
              }
            }
          },
          "refunds": {
            "inputField": {
              "refundDescription": "Refund Description"
            }
          }
        }
      },
      "online-planner-plans": {
        "actions": {
          "archive": {
            "error": "There was a problem archiving that plan, please try again.",
            "success": "Plan archived successfully.",
            "text": "Archive"
          },
          "convert": "Promote to Case",
          "unarchive": {
            "error": "There was a problem unarchiving that plan, please try again.",
            "success": "Plan unarchived successfully.",
            "text": "Unarchive"
          },
          "view": "View Plan Details"
        },
        "clearSearch": "Clear",
        "dropdowns": {
          "atneedOption": "At Need",
          "caseOption": "Promoted to Case",
          "inProgressOption": "In Progress",
          "placeholder": "All",
          "plannerPlaceholder": "All Planners",
          "preneedOption": "Preneed",
          "statusPlaceholder": "All Statuses",
          "submittedOption": "Submitted",
          "typePlaceholder": "All Types"
        },
        "edit": {
          "backButton": "Back to Plans",
          "convertCaseButton": {
            "action": "Promote to Case",
            "ask": "Are you sure you want to promote this plan to a case?",
            "cancelButton": "Cancel",
            "chooseLocation": "Which location do you want assigned to this case?",
            "dropdown": {
              "label": "Location",
              "placeholder": "Select One"
            },
            "promoted": "This plan has been promoted to a case.",
            "submitButton": "Submit"
          },
          "documents": {
            "promotedCaseDocumentMessage": "This plan has already been promoted and any documents uploaded are only viewable in the case."
          },
          "exportPdfButton": "Export Plan to PDF",
          "payments": {
            "account": "Account",
            "amountPaid": "Amount Paid",
            "chargeId": "Charge ID",
            "endingIn": "ending in",
            "madeBy": "Payment Made By",
            "notCollected": "$0.00 - A payment was not collected.",
            "type": "Payment Type"
          },
          "people": {
            "nextOfKinLabel": "Next of kin",
            "otherContactsLabel": "Other contacts",
            "primaryContactLabel": "Primary contact"
          },
          "selections": {
            "discountLabel": "Discounts",
            "package": "Package",
            "products": "Products",
            "quantity": "Quantity",
            "shippingLabel": "Shipping Total",
            "subtotalAfterDiscountsLabel": "Subtotal",
            "subtotalLabel": "Selections Total",
            "taxLabel": "Taxes",
            "totalLabel": "Total",
            "totalPaid": "Total Paid"
          },
          "title": "Plan Details"
        },
        "promote": {
          "cancel": "Cancel",
          "error": "There was a problem promoting that plan to a case, please try again.",
          "location": "Which location do you want assigned to this case?",
          "locationDropOption": "Location",
          "submit": "Submit",
          "success": "Plan promoted to case successfully.",
          "toCase": "Are you sure you want to promote this plan to a case?"
        },
        "table": {
          "headers": {
            "actions": "Actions",
            "created": "Created By",
            "planner": "Planner",
            "planningFor": "Planning For",
            "status": "Status",
            "type": "Type",
            "updated": "Last Updated"
          }
        },
        "title": "Plans",
        "tooltips": {
          "case": "Promoted to Case",
          "inProgress": "In Progress",
          "submitted": "Submitted"
        }
      },
      "payments": {
        "adjustments": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "pageTitle": "Adjustments",
          "table": {
            "headers": {
              "adjustedBy": "Adjusted By",
              "amount": "Amount",
              "date": "Date",
              "kase": "Case",
              "lineItem": "Line Item",
              "type": "Type"
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addNew": "Add New",
            "cancelBatchPayment": "Cancel Batch Payment",
            "cancelBatchPaymentEdit": "Cancel Batch Payment Edit",
            "clear": "Clear",
            "clearLocation": "Clear Location",
            "saveBatchPayment": "Save Batch Payment",
            "submitBatchPayment": "Submit Batch Payment",
            "submitPayment": "Submit Payment"
          },
          "checkboxes": {
            "batchPayment": "Is this a Batch Payment?"
          },
          "errors": {
            "insuranceAssignmentsUnsopported": "Batch Payments currently do not support insurance assignments.",
            "missingInfo": "Make sure all of the information is correct and/or inputted",
            "selectDifferentPaymentType": "Please select a different Payment Type."
          },
          "labels": {
            "batchPaymentAmountRemaining": "Batch Payment Amount Remaining",
            "createNewBatchPayment": "Create New Batch Payment",
            "createNewPayment": "Create New Payment",
            "editBatchPayment": "Edit Batch Payment",
            "editPayment": "Edit Payment",
            "location": "Location",
            "paymentInfo": "Payment Information"
          },
          "pageTitle": {
            "batchPayments": "Batch Payments",
            "payments": "Payments"
          },
          "placeholders": {
            "amount": "Amount",
            "checkNumber": "Check Number",
            "endDate": "End Date",
            "filterByLocation": "Filter by Organization or Location",
            "name": "Name",
            "note": "Note",
            "otherPaymentSource": "Other Payment Source",
            "payer": "Payer Name",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type",
            "selectOne": "Select One",
            "startDate": "Start Date"
          },
          "table": {
            "headers": {
              "payer": "Payer"
            }
          }
        },
        "pageTitle": "Payments",
        "payouts": {
          "dateFields": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "inputFields": {
            "maximumAmount": "Maximum Amount",
            "minimumAmount": "Minimum Amount"
          },
          "links": {
            "downloadExcelReport": "Download Excel Report",
            "hideDetails": "Hide Details",
            "viewDetails": "View Details"
          },
          "pageTitle": "Payouts",
          "selectFields": {
            "tributePayAccount": {
              "label": "Tribute Pay Account",
              "options": {
                "all": "All"
              }
            }
          },
          "tables": {
            "details": {
              "data": {
                "charge": "Charge",
                "refund": "Refund",
                "transfer": "Transfer"
              },
              "headers": {
                "tpsId": "TPS ID",
                "transactionAmount": "Transaction Amount",
                "type": "Type"
              }
            },
            "main": {
              "headers": {
                "actions": "Actions",
                "bankAccount": "Bank Account",
                "date": "Date",
                "totalPayout": "Total Payout",
                "tributePayAccount": "Tribute Pay Account"
              },
              "missingData": {
                "payouts": "There are no payouts."
              }
            }
          }
        },
        "sideNav": {
          "batchPayments": "Batch Payments",
          "payments": "Payments",
          "payouts": "Payouts",
          "transactions": "Transactions"
        },
        "transactions": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "downloadCSV": "Download CSV"
          },
          "pageTitle": "Transactions",
          "selectFields": {
            "label": "Payment Type",
            "options": {
              "selectOne": "Select One"
            }
          },
          "statuses": {
            "failed": "Failed",
            "pending": "Pending",
            "successful": "Successful"
          },
          "table": {
            "headers": {
              "actions": "Actions",
              "date": "Date",
              "fee": "Fee",
              "grossAmount": "Gross Amount",
              "netAmount": "Net Amount",
              "paymentBy": "Payment By",
              "paymentFor": "Payment For",
              "saleId": "Sale ID",
              "status": "Status",
              "type": "Type"
            }
          }
        }
      },
      "places": {
        "edit": {
          "fields": {
            "select": {
              "general": {
                "defaultValue": "Select One"
              },
              "placeType": {
                "placeholder": "Place Type"
              }
            }
          }
        }
      },
      "websites": {
        "pageTitle": "Websites",
        "searchField": {
          "label": "Search",
          "resetButton": {
            "label": "Clear"
          }
        },
        "websiteTable": {
          "headers": {
            "website": "Website",
            "websiteDescription": "Description"
          },
          "warnings": {
            "noResults": "No websites found."
          }
        }
      }
    },
    "components": {
      "client-link-asd": {
        "buttons": {
          "linkAccount": "Link Account"
        },
        "checkboxes": {
          "disableImport": "Disable Import",
          "syncCasesAutomatically": "Sync Cases Automatically?"
        },
        "dateSelector": {
          "importAfterDate": "Import After Date (Optional)"
        },
        "descriptionWarning": "Required if multiple of the same integration",
        "disclaimer": "This client is linked with ASD. Please visit the ASD portal, retreive the location remote IDs, and enter them into the appropriate locations in Tribute Management Software.",
        "inputFields": {
          "asdPassword": "ASD Password",
          "mailboxID": "Mailbox ID"
        },
        "pageTitle": "Link ASD Account"
      },
      "contact-history": {
        "caseTable": {
          "headers": {
            "actions": "Actions",
            "caseStatus": "Case Status",
            "caseSubject": "Case Subject",
            "relationship": "Relationship"
          },
          "link": "View Case"
        },
        "headers": {
          "cases": "Cases",
          "inquiries": "Inquiries"
        },
        "inquiryTable": {
          "headers": {
            "actions": "Actions",
            "createdAt": "Created At",
            "createdBy": "Created By",
            "inquiryType": "Inquiry Type"
          },
          "link": "View Inquiry"
        }
      },
      "crakn-event-scheduler": {
        "checkboxes": {
          "export": {
            "bassMollett": "Export to Bass-Mollett?",
            "cfs": "Export to CFS?",
            "frazer": "Export to Frazer?",
            "frontRunner": "Export to Frontrunner?",
            "funeralOne": "Export to FuneralOne?",
            "funeralTech": "Export to FuneralTech?",
            "messenger": "Export to Messenger?",
            "tukios": "Export to Tukios?"
          },
          "hideTcoEndTime": "Hide End Time from Website?"
        },
        "warnings": {
          "addressPlace": "The selected place does not have an address or is missing required fields (i.e., address line 1, city, state/providence, zip/postal code). This could impact any flower or associated deliveries, including cancelation or inability to complete deliveries.",
          "bassMollett": "To export this event to Bass-Mollett, the Event Name and Place Name must be filled in.",
          "cfs": "To export to CFS, this Event must have a name.",
          "frazer": "To export to Frazer, this event must have a place with a valid address, phone number, and postal code.",
          "frontRunner": "To export this event to FrontRunner, this event must have a place with a valid address and FrontRunner Event Type must be filled in.",
          "funeralOne": "To export this event to FuneralOne, the Event Name and Place Name must be filled in.",
          "funeralTech": "To export this event to FuneralTech, this event must have a place with a valid address (Country, City, State, Zip code, Address), and the FuneralTech Event Type must be filled in.",
          "messenger": "To export to Messenger, the Event Place and Messenger Event Type must be filled in.",
          "tukios": "To export to Tukios, this Event must have a place name, full address, and duration."
        },
        "webcast": {
          "pin": {
            "label": "Webcast PIN"
          },
          "url": {
            "label": "Webcast URL"
          }
        }
      },
      "crakn-kase-contact-card": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "contactDeceased": "Deceased",
        "messages": {
          "relationship": {
            "save": {
              "error": "There was a problem saving this relationship.",
              "success": "Relationship saved!"
            }
          }
        },
        "tooltips": {
          "currentNextOfKin": "Current Next of Kin",
          "currentPrimaryContact": "Current Primary Contact",
          "editContact": "Edit Contact",
          "editRelationship": "Edit Relationship",
          "makeNextOfKin": "Make Next of Kin",
          "makePrimaryContact": "Make Primary Contact",
          "removeContact": "Remove Contact"
        }
      },
      "crakn-kase-form": {
        "errors": {
          "formProcessing": {
            "contactEmail": "support@tributetms.com",
            "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
          }
        }
      },
      "crakn-place-editor": {
        "fields": {
          "select": {
            "placeType": {
              "options": {
                "selectOne": "Select One"
              },
              "placeholder": "Place Type"
            }
          }
        }
      },
      "dashboard": {
        "dash-icon-nav": {
          "icons": {
            "placeholders": {
              "calendar": "Calendar",
              "cases": "Cases",
              "contacts": "Contacts",
              "inquiries": "Inquiries",
              "whiteboard": "Whiteboard"
            }
          }
        }
      },
      "esignatures": {
        "dashboard": {
          "form-table-row": {
            "actions": {
              "cancelForm": "Cancel Request",
              "resendToPendingSigners": "Resend to Pending Signers",
              "resendToSigner": "Resend to Signer"
            }
          },
          "kase-table": {
            "noResultsFound": "No results found",
            "table": {
              "headers": {
                "actions": "Actions",
                "case": "Case",
                "location": "Location"
              }
            }
          },
          "kase-table-row": {
            "actions": {
              "hidePendingForms": "Hide Forms",
              "showPendingForms": "Show Forms"
            }
          },
          "search": {
            "clear": "Clear",
            "placeholders": {
              "allLocations": "All",
              "placeholder": "Location",
              "searchCases": "Search Cases",
              "searchSigners": "Search Signers"
            }
          }
        }
      },
      "g": {
        "admin": {
          "ar-adjustment": {
            "create": {
              "add": "Add Adjustment",
              "cancel": "Cancel Adjustment",
              "confirm": "This will zero out all remaining balances for selections with a contract date between {start} and {end}. Are you sure?",
              "new": "New",
              "placeholders": {
                "endDate": "End date",
                "gainType": "Gain adjustment type",
                "location": "Select location",
                "lossType": "Loss adjustment type",
                "note": "Note",
                "requested": "Requested by",
                "selectOne": "Select one",
                "startDate": "Start date"
              },
              "saveError": "There was an error saving the adjustment. Please try again.",
              "saveSuccess": "The adjustment has been successfully saved.",
              "title": "Add new adjustment"
            }
          },
          "custom-field-config": {
            "create-edit-field": {
              "addOption": "+ Add Option",
              "fieldTextInput": {
                "placeholder": "Field Text",
                "validationMessages": "Field text is required"
              },
              "fieldTypeSelect": {
                "options": {
                  "boolean": "Checkbox",
                  "date": "Date Picker",
                  "select": "Drop Down",
                  "text": "Text"
                },
                "placeholder": "Field Type",
                "placeholderLabel": "Select One",
                "validationMessage": "A field type is required."
              },
              "footerButtons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "save": "Save"
              },
              "noOptions": "No options created."
            }
          },
          "general-price-lists": {
            "edit": {
              "packages": {
                "package-contents": {
                  "banners": {
                    "info": "A package may consist of items and/or flexible items. Items are specific products that can be removed, but not changed during the selection process. Flexible items allow for custom selections from a set product category."
                  },
                  "buttons": {
                    "cancel": "Cancel",
                    "delete": "Delete",
                    "editProducts": "Edit Products",
                    "save": "Save Item"
                  },
                  "checkboxes": {
                    "preventRemovalOfItem": "Prevent removal of item from package?",
                    "setAllowanceAmount": "Set allowance amount?",
                    "subtractFromDiscount": "If removed, subtract item value from package discount before package total?"
                  },
                  "fields": {
                    "input": {
                      "allowanceAmount": {
                        "placeholder": "Allowance Amount ($)",
                        "validationMessage": "Please enter a valid allowance amount."
                      }
                    },
                    "select": {
                      "product": {
                        "options": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product"
                      },
                      "productCategory": {
                        "disclaimers": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product Category"
                      },
                      "type": {
                        "options": {
                          "flexible_item": "Flexible Item",
                          "item": "Item"
                        },
                        "placeholder": "Type"
                      },
                      "unspentAllowanceAccount": {
                        "placeholder": "Unspent Allowance Account",
                        "validationMessage": "Please select an unspent allowance account."
                      }
                    }
                  },
                  "lists": {
                    "products": {
                      "all": {
                        "label": "Note:",
                        "text": "All products in this category will be enabled. You can edit products after saving the item."
                      },
                      "enabled": "{amount} products enabled in this category."
                    }
                  },
                  "sections": {
                    "products": "Products"
                  },
                  "title": {
                    "edit": "Edit Item",
                    "new": "Add New Item"
                  },
                  "tooltips": {
                    "allowanceAmount": "An allowance is a set amount customers can spend on products in this category. If the allowance is exceeded, the difference will be added to their package total."
                  },
                  "validations": {
                    "item": {
                      "delete": {
                        "success": "Item successfully removed from package!"
                      },
                      "save": {
                        "success": {
                          "exisitingItem": "Item saved!",
                          "newItem": "Item successfully added to package!"
                        }
                      }
                    }
                  }
                }
              }
            },
            "statement-categories": {
              "list": {
                "buttons": {
                  "text": {
                    "cancel": "Cancel",
                    "save": "Save"
                  }
                },
                "deleteDialog": {
                  "promptMessage": "Archive {name}?"
                },
                "fields": {
                  "placeholders": {
                    "name": "Name"
                  }
                },
                "messages": {
                  "delete": {
                    "success": "{statementCategoryName} was archived successfully!"
                  },
                  "error": "Something went wrong.",
                  "save": {
                    "success": "Statement Category successfully saved!"
                  }
                }
              }
            }
          },
          "integrations": {
            "disclaimers": {
              "ciclops": {
                "disclaimer": {
                  "list": {
                    "caseEvents": "Case Events",
                    "caseFamilyAndFriends": "Case Family & Friends",
                    "caseImage": "Case Image",
                    "deceasedName": "Deceased name",
                    "deceasedVitalStatistics": "Deceased vital statistics",
                    "obituary": "Obituary",
                    "pallbearers": "Pallbearer(s)",
                    "veteranInfo": "Veteran Info"
                  },
                  "mainText": "The following information may be sent to Ciclops:"
                }
              },
              "tribute-print": {
                "disclaimer": "The following information may be sent to Tribute Print:",
                "informationList": {
                  "caseEvents": "Case Events",
                  "caseFamilyAndFriends": "Case Family & Friends",
                  "caseImage": "Case Image",
                  "deceasedName": "Deceased Name",
                  "deceasedVitalStatistics": "Deceased Vital Statistics",
                  "obituary": "Obituary",
                  "pallbearers": "Pallbearer(s)",
                  "veteranInfo": "Veteran Info"
                }
              },
              "tribute-video": {
                "informationSentToTributeVideo": {
                  "disclaimer": "The following information may be sent to Tribute Video:",
                  "list": {
                    "casePhotos": "Case Photos",
                    "deceasedName": "Deceased name",
                    "deceasedVitalStatistics": "Deceased vital statistics",
                    "locationAddress": "Location address"
                  }
                },
                "privacyNoticeAndConditionsOfUse": {
                  "disclaimer": "By exporting to Tribute Video, you agree to their following policies and conditions:",
                  "list": {
                    "conditionsOfUse": "Conditions of Use",
                    "privacyNotice": "Privacy Notice"
                  }
                }
              }
            }
          },
          "online-planner": {
            "user-notification-list": {
              "alerts": {
                "remove": {
                  "error": "Oops! There was an error when removing the recipient.",
                  "success": "Success! The recipient was removed successfully."
                }
              }
            }
          },
          "tribute-pay": {
            "notification-settings": {
              "locations": {
                "buttons": {
                  "addLocation": "Add Location for Notifications"
                },
                "sections": {
                  "locationsList": {
                    "title": "Locations"
                  }
                }
              },
              "user-list": {
                "alerts": {
                  "location": {
                    "add": {
                      "error": "Oops! There was an error when adding the location.",
                      "success": "Success! The location was added successfully."
                    },
                    "remove": {
                      "error": "Oops! There was an error when removing the location.",
                      "success": "Success! The location was removed successfully."
                    }
                  },
                  "recipient": {
                    "remove": {
                      "error": "Oops! There was an error when removing the recipient.",
                      "success": "Success! The recipient was removed successfully."
                    }
                  }
                },
                "buttons": {
                  "delete": {
                    "tooltip": "Delete Recipient"
                  },
                  "edit": {
                    "tooltip": "Edit"
                  }
                },
                "list": {
                  "details": {
                    "locations": "Location(s):"
                  }
                }
              }
            }
          },
          "tribute-pay-payments": {
            "accounts": {
              "edit": {
                "locations": {
                  "alerts": {
                    "missingData": {
                      "locations": {
                        "noFinancialConfiguration": "To add a location, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no locations assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addLocation": "Add Location"
                  },
                  "list": {
                    "locations": {
                      "mainText": "Locations assigned to this Tribute Pay Account:"
                    }
                  },
                  "sectionTitle": "Locations",
                  "selectFields": {
                    "location": "Location",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                },
                "payment-types": {
                  "alerts": {
                    "missingData": {
                      "paymentTypes": {
                        "noFinancialConfiguration": "To add a payment type, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no payment types assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addPaymentType": "Add Payment Type"
                  },
                  "inputFields": {
                    "paymentType": "Payment Type"
                  },
                  "list": {
                    "paymentTypes": {
                      "account": "Account:",
                      "mainText": "Payment Types assigned to this Tribute Pay Account:",
                      "unsavedPaymentTypes": "Unsaved Payment Types:"
                    }
                  },
                  "sectionTitle": "Payment Types",
                  "selectFields": {
                    "glAccount": "GL Account",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "table-row": {
                "links": {
                  "archive": "Archive",
                  "edit": "Edit"
                },
                "messages": {
                  "error": "There was an error when attempting to archive the account.",
                  "success": "The account was successfully archived."
                },
                "statuses": {
                  "archived": "Archived"
                },
                "table": {
                  "body": {
                    "noAccounts": "No Tribute Pay accounts to display."
                  }
                }
              }
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addPayments": "Add Payment(s)",
            "clearCaseAndPayments": "Clear Case & Payments",
            "closeCaseAndPayments": "Close Case & Payments"
          },
          "errors": {
            "greaterThan": "Payment amount must be greater than 0.",
            "mustNotExceed": "Payment amount must not exceed the remaining batch payment amount."
          },
          "headers": {
            "amount": "Amount",
            "payer": "Payer",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type"
          },
          "labels": {
            "addPayments": "Add Payments",
            "balanceDue": "Balance Due",
            "individualPayments": "Individual Payments",
            "theFollowingPaymentsWillBeDeleted": "The following payments will be deleted:",
            "totalCaseBalance": "Total Case Balance"
          },
          "placeholders": {
            "isThisMiscSale": "Is it for a Misc Sale?",
            "paymentAmount": "Payment Amount",
            "selectACase": "Select a Case"
          },
          "summary": {
            "dialogBoxes": {
              "areYouSure": "Are you sure?"
            },
            "headers": {
              "paymentDetails": "Payment Details"
            },
            "labels": {
              "customField1": "Custom Field 1",
              "customField2": "Custom Field 2",
              "note": "Note",
              "payerEmail": "Payer Email",
              "paymentFor": "Payment For"
            }
          },
          "text": {
            "thereAreNoMiscSales": "There are no Misc Sales."
          }
        },
        "crakn": {
          "autocomplete": {
            "blank-form": {
              "placeholder": "Form"
            },
            "poem": {
              "placeholder": "Poem Or Prayer"
            }
          },
          "field": {
            "quill": {
              "hyperlink": {
                "termsOfUse": {
                  "accept": "Accept and Continue",
                  "deny": "Decline",
                  "error": "There was a problem agreeing to the hyperlink terms of use. If this continues please contact support.",
                  "terms": "This feature gives you the ability to hyperlink to third-party content (the “Hyperlink Feature”). By accessing the Hyperlink Feature, you (and the entity that you represent) agree that: (a) your use of the Hyperlink Feature shall not infringe upon any third party's intellectual property rights and (b) you shall not and will not permit any third party to use the Hyperlink Feature to connect to or direct individuals to a third-party e-commerce website that has not been previously approved by us, in our sole discretion. The foregoing sentence does not apply to websites that direct individuals to a website for charitable giving. We may at any time restrict or suspend your use of the Hyperlink Feature.",
                  "title": "Obituary Hyperlink Terms of Use"
                }
              }
            },
            "select": {
              "location": {
                "selectField": {
                  "options": {
                    "selectOne": "Select One"
                  },
                  "placeholder": "Locations"
                }
              },
              "organization-location": {
                "fields": {
                  "select": {
                    "organizationLocation": {
                      "options": {
                        "all": "All"
                      },
                      "placeholder": "Organization / Location"
                    }
                  }
                },
                "groups": {
                  "locations": "Locations",
                  "organizations": "Organizations"
                }
              },
              "statement-category": {
                "fields": {
                  "select": {
                    "statementCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Category"
                    },
                    "statementSubcategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Subcategory"
                    }
                  }
                }
              }
            }
          },
          "financials": {
            "beaconfp-resend": {
              "buttons": {
                "confirm": "Force Resend will clear all NetSuite ID's. Do you wish to continue?",
                "resend": "Resend To Netsuite"
              },
              "lists": {
                "on": "on",
                "resendListTitle": "Netsuite Resend History",
                "resentBy": "Resent by"
              }
            }
          },
          "integrations": {
            "edit-integration": {
              "buttons": {
                "cancel": "Cancel",
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save",
                "updateIntegration": "Update Integration"
              },
              "sections": {
                "locationBased": {
                  "missingLocations": "Please save the integration before modifying its locations."
                },
                "websitePaymentCredentials": {
                  "key": "Key: {key}",
                  "secret": "Secret: {secret}",
                  "title": "Website Payment Credentials",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Credentials can only be viewed once.",
                    "noCredentials": "No Credentials",
                    "writeThisDown": "Write this down!"
                  }
                },
                "websitePaymentType": {
                  "placeholder": "Website Payment Type",
                  "placeholderLabel": "Select a Payment Type",
                  "title": "Website Payment Type"
                },
                "websitePayments": {
                  "missingInfo": "You must have at least one tribute pay account approved and general payment enabled to set up website payments."
                }
              }
            },
            "form-fields": {
              "inputs": {
                "precoaNumberOfDays": {
                  "description": {
                    "defaultAmount": "Default = 7",
                    "main": "How many days do you want Precoa to wait before contact is initiated with exported TMS contacts?"
                  },
                  "placeholder": "Number of Days"
                }
              },
              "selectors": {
                "user": {
                  "importantInformation": "*If the desired user does not show up here, make sure they have a valid phone number.",
                  "placeholder": "User"
                }
              }
            },
            "integration-service-information": {
              "confirmDelete": {
                "prompt": "Are you sure you want to remove this integration?"
              },
              "sections": {
                "locationBased": "Location Based: {locationBased}",
                "locationNames": "Location(s): {locationNames}",
                "logo": "Logo: {logo}"
              }
            }
          },
          "list": {
            "packages": {
              "flexible-items": {
                "details": {
                  "numberOfProducts": "{amount} products"
                }
              },
              "item": {
                "disclaimers": {
                  "archived": "(Archived)",
                  "notInPackage": "(Not in Package)"
                }
              }
            }
          },
          "pagination-buttons": {
            "buttons": {
              "next": "Next",
              "previous": "Previous"
            },
            "text": {
              "currentPage": "Page",
              "currentPageOf": "of"
            }
          },
          "payments": {
            "transactions": {
              "table-row": {
                "alerts": {
                  "refund": {
                    "fail": "Oh no! There was an issue processing this refund.",
                    "success": "Success! The payment was refunded."
                  }
                },
                "buttons": {
                  "refund": "Refund",
                  "viewDetails": "View Details"
                },
                "sections": {
                  "refund": {
                    "buttons": {
                      "cancel": "Cancel",
                      "refund": "Refund"
                    },
                    "fields": {
                      "input": {
                        "refundAmount": {
                          "placeholder": "Refund Amount"
                        }
                      }
                    }
                  },
                  "transactionDetails": {
                    "data": {
                      "labels": {
                        "address": "Address",
                        "city": "City",
                        "country": "Country",
                        "email": "Email",
                        "name": "Name",
                        "phone": "Phone",
                        "state": "State",
                        "tpsId": "TPS ID",
                        "zipCode": "Zip"
                      }
                    },
                    "header": "Transaction Details",
                    "statuses": null
                  }
                }
              }
            }
          },
          "selector": {
            "account": {
              "placeholderLabel": "Select an Account"
            }
          },
          "v2": {
            "kase-typeahead": {
              "listHeading": "Case",
              "placeholder": "Case"
            },
            "person-selector": {
              "buttons": {
                "cancelContact": "Cancel Contact",
                "saveContact": "Save Contact"
              },
              "inputs": {
                "placeholders": {
                  "email": "Email",
                  "firstName": "First Name",
                  "lastName": "Last Name",
                  "middleName": "Middle Name",
                  "phonePrimary": "Phone Primary",
                  "phoneSecondary": "Phone Secondary"
                }
              },
              "saveWarning": "Please type an existing person, or create a new person by clicking the add button and then saving.",
              "validations": {
                "email": "Email is required.",
                "firstName": "First Name is required.",
                "lastName": "Last Name is required."
              }
            }
          }
        },
        "kases": {
          "create": {
            "check-duplicate-kase": {
              "description": "The deceased information matches previously created records. Please verify case is not a duplicate.",
              "table": {
                "header": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "labelsAtSmallerScreens": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "link": {
                  "viewCase": "View Case"
                }
              },
              "title": "Duplicate Case Warning"
            },
            "kase": {
              "button": {
                "createCase": "Create Case"
              },
              "checkbox": {
                "deathNotice": {
                  "label": "Hide Death Notice?",
                  "tooltip": "Death notices post deceased info to your website before the obituary is published."
                },
                "preneed": {
                  "label": "Is Preneed?"
                }
              },
              "input": {
                "socialInsuranceNumber": {
                  "label": "Social Insurance Number"
                },
                "socialSecurityNumber": {
                  "label": "Social Security Number"
                }
              },
              "link": {
                "backToCases": "Back to Cases"
              },
              "selector": {
                "caseName": {
                  "errorMessage": "This field can't be blank",
                  "label": "Case Full Name"
                },
                "datePicker": {
                  "birthDate": {
                    "label": "Birth Date"
                  },
                  "deathDate": {
                    "errorMessage": "Please enter a date on or after birth date",
                    "label": "Death Date"
                  }
                },
                "location": {
                  "errorMessage": "This field can't be blank",
                  "label": "Select One"
                }
              },
              "title": "Case Information"
            }
          },
          "manage": {
            "edit": {
              "deceased": {
                "death-info": {
                  "checkboxes": {
                    "saveToPlaces": {
                      "disclaimer": "Place Name is required to save this Place.",
                      "label": "Save To Places?"
                    }
                  },
                  "fields": {
                    "input": {
                      "placeName": {
                        "placeholder": "Place Name"
                      }
                    }
                  }
                }
              },
              "documents": {
                "album-edit": {
                  "album": {
                    "buttons": {
                      "add": {
                        "label": "Add Photo"
                      },
                      "cancel": {
                        "label": "Cancel"
                      },
                      "private": {
                        "label": "Private"
                      },
                      "public": {
                        "label": "Public"
                      },
                      "save": {
                        "label": "Save Album"
                      }
                    },
                    "description": {
                      "label": "Description"
                    },
                    "file-upload": {
                      "uploadText": "Uploading"
                    },
                    "name": {
                      "label": "Album Name"
                    },
                    "photos": {
                      "label": "Album Photos",
                      "message": "Select or upload photos."
                    }
                  },
                  "availablePhotos": {
                    "archived": "Archived",
                    "buttons": {
                      "add": "Add to album",
                      "expand": "View full size image"
                    },
                    "label": "All Photos",
                    "warning": "Select or upload photos."
                  },
                  "title": {
                    "edit": "Edit Album",
                    "new": "New Album"
                  }
                }
              },
              "printables": {
                "list-item": {
                  "personSelect": {
                    "placeholder": "Person",
                    "placeholderLabel": "Select One"
                  },
                  "poemSearch": {
                    "placeholder": "Poem or Prayer"
                  },
                  "useCustomPoem": "Custom Poem or Prayer?"
                }
              }
            },
            "financials": {
              "selection": {
                "package": {
                  "page": {
                    "backToSelection": {
                      "notSelectedPrompt": "Are you sure you want go to back? You have unsaved selections.",
                      "selectedPrompt": "Are you sure?"
                    }
                  },
                  "summary": {
                    "header": "Package Summary",
                    "itemSubtotal": "Subtotal",
                    "noSelection": "No selection made.",
                    "packageDiscount": "Package Discount",
                    "packageSubtotal": "Package Subtotal",
                    "packageTotal": "Package Total",
                    "save": {
                      "label": "Save package",
                      "notSelectedPrompt": "You are missing required selections that must be made before posting. Are you sure you want to save this package now?",
                      "selectedPrompt": "Are you sure?"
                    },
                    "tax": {
                      "label": "Tax",
                      "tbd": "TBD",
                      "tooltip": "Tax will be calculated after saving this package."
                    }
                  }
                }
              }
            }
          }
        },
        "legal": {
          "acknowledgement": {
            "button": {
              "accept": {
                "error": "To use this application, please accept Terms of Use and Privacy Policy.",
                "label": "Accept and Continue"
              },
              "decline": {
                "label": "Decline"
              }
            },
            "privacyPolicy": {
              "checkbox": {
                "error": "Must accept the Privacy Policy to use this application.",
                "label": "Accept Privacy Policy*"
              },
              "title": "Privacy Policy"
            },
            "termsOfUse": {
              "checkbox": {
                "error": "Must accept the Terms of Use to use this application.",
                "label": "Accept Terms of Use*"
              },
              "title": "Terms Of Use"
            }
          }
        },
        "migrations": {
          "new-migration": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "start": "Start Migration"
            },
            "checkboxes": {
              "archiveImportedUsers": "Auto Archive Users",
              "autoArchivePlaces": "Auto Archive Places",
              "autoCompleteKases": "Auto Complete Cases",
              "autoPost": "Auto Post Contracts",
              "caseDataImported": "Has case data already been imported?",
              "duplicateCheck": "Check for Duplicate Contacts (Multiple Imports Only)"
            },
            "datePickers": {
              "autoCompleteKasesCutOffDate": "All Cases With a Date Before will be set to Complete",
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "ofTotal": "of 13",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "allSourceLocationsRequireMapping": "All Source Locations require a mapping to a TMS Location",
              "autoCompleteKasesDateRequired": "If you have selected to auto complete cases, you must provide a cut off date",
              "caseDataImportedMustBeVerified": "You have chosen Import Type of Files Only. Please verify data has been imported by checking the box",
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "importTypeRequired": "Please select an Import Type",
              "multipleSourceLocationsMappedToOneTMSLocation": "Mapping multiple source locations to one TMS location may result in duplicate Case IDs.",
              "notificationUserRequired": "Please select a Notification User",
              "processingFilePleaseWait": "Processing file:",
              "restartMigrationFailed": "Error occurred restarting migration",
              "saveMigrationFailed": "Error occurred saving migration",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for",
            "rules": {
              "howTo": "How to migrate client data",
              "one": "Upload a file below. This may be a .json file, or a .zip file containing a single .json file.",
              "three": "Click Start Migration to begin the migration.",
              "two": "Map the source location to a TMS location."
            },
            "selects": {
              "noSelection": "Select One"
            },
            "tables": {
              "locationMappings": {
                "sourceLocation": "Source Location",
                "tmsLocation": "TMS Location"
              }
            },
            "waiting": {
              "error": "Oops, processing timed out. If this continues, please contact support.",
              "first": "This may take a few minutes, hang tight!",
              "second": "This is a larger file, we're still working on it!",
              "third": "We're still formatting your data, it shouldn't be too much longer!"
            }
          }
        },
        "online-planner": {
          "documents": {
            "actions": {
              "addForm": {
                "error": "Something went wrong, please try again.",
                "success": "Document has been added to the online planner!"
              },
              "remove": {
                "error": "Something went wrong removing this document.",
                "success": "Document successfully removed from configuration!"
              },
              "update": {
                "error": "Something went wrong updating this document.",
                "success": "Document successfully updated!"
              }
            },
            "addForm": {
              "addDocumentButton": "Add Document",
              "placeholder": "Form",
              "title": "Add Existing Form"
            },
            "documents": {
              "edit": {
                "placeholder": "Name"
              },
              "empty": "There are no documents assigned to this configuration.",
              "title": "Documents Added To This Planner"
            },
            "title": "Add Documents",
            "uploadDocument": {
              "fileUploadInstructions": "Drag and drop documents here to upload them",
              "title": "Upload New Document"
            }
          },
          "icon-select": {
            "options": {
              "bible-color": "Bible",
              "book": "Book",
              "bookColor": "Book 2",
              "buddhismColor": "Buddhism",
              "building": "Building",
              "canadaColor": "Canada",
              "candle": "Candle",
              "candleColor": "Candle 2",
              "car": "Car",
              "cashAdvance": "Cash Advance",
              "cashAdvanceColor": "Cash Advance 2",
              "casket": "Casket",
              "casket2": "Casket 2",
              "casketColor": "Casket 3",
              "cross": "Cross",
              "embalming": "Embalming",
              "facilitiesStaff": "Facilities and Staff",
              "flag": "Flag",
              "grave": "Grave",
              "graveColor": "Grave 2",
              "hearseColor": "Hearse Black",
              "hearseWhiteColor": "Hearse White",
              "hinduismColor": "Hinduism",
              "islamColor": "Islam",
              "judaismColor": "Judaism",
              "merchandise": "Merchandise",
              "musicColor": "Music",
              "necklaceColor": "Necklace",
              "obituaryColor": "Obituary",
              "paper": "Paper",
              "personHoldingBook": "Person Holding Book",
              "pictureFrameColor": "Picture Frame",
              "serviceColor": "Services 2",
              "services": "Services",
              "soldierColor": "Soldier",
              "standardCremation": "Standard Cremation Service",
              "stationary": "Stationary",
              "traditonalGraveside": "Traditional Graveside Service",
              "traditonalService": "Traditional Service at the Funeral Home or Church",
              "transport": "Transport",
              "urn": "Urn",
              "urn2": "Urn 2",
              "urnColor": "Urn 2",
              "usFlagColor": "US Flag",
              "vault": "Vault",
              "videoColor": "Video"
            },
            "removeIcon": "Remove Icon"
          },
          "media": {
            "video-display": {
              "buttons": {
                "delete": "Delete"
              },
              "details": {
                "uploadedBy": "Uploaded By: {name} on {date}",
                "videoTitle": {
                  "defaultTitle": "No name"
                }
              },
              "fields": {
                "input": {
                  "altText": {
                    "placeholder": "Alt Text"
                  },
                  "description": {
                    "disclaimer": "This description will display in the planner to inform families about the video content.",
                    "placeholder": "Video Description"
                  }
                }
              },
              "uploader": {
                "text": "Drag and drop a video or click to select one"
              },
              "validation": {
                "delete": {
                  "success": "Video removed."
                },
                "upload": {
                  "success": "Video successfully uploaded!"
                }
              }
            }
          },
          "packages": {
            "actions": {
              "addPackage": {
                "error": "Something went wrong, please try again.",
                "success": "Package has been added to the online planner!"
              },
              "deletePackage": {
                "error": "Something went wrong removing this Package.",
                "success": "Package successfully removed from configuration!"
              },
              "updatePackage": {
                "error": "Something went wrong updating this Package.",
                "success": "Package successfully updated!"
              }
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add packages to the planner.",
            "gplMissing": "A general price list is required to add packages to the planner.",
            "packageSearch": {
              "placeholder": "Package"
            },
            "packages": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "description": {
                "placeholder": "Description"
              },
              "empty": "There are no packages assigned to this configuration.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "price": {
                "description": "Package price set in the GPL (pricing method applied).",
                "placeholder": "Package Price"
              },
              "save": "Save",
              "sort": "Sort Packages",
              "value": {
                "description": "Total of all items included in the package (pricing method not applied).",
                "placeholder": "Package Value"
              }
            },
            "subtitle": "Add packages from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add packages to the planner.",
            "title": "Add Packages"
          },
          "payment-collection": {
            "pageCantBeHidden": "This page cannot be hidden if a payment is required.",
            "payLater": {
              "hide": "Hide this option?",
              "title": "Pay Later"
            },
            "payToday": {
              "enrollNow": "Enroll now",
              "enrollWarning": "Tribute Pay enrollment is required to use this payment option.",
              "gplRequired": "A general price list is required to setup payments for this online planner.",
              "hide": "Hide this option?",
              "hideWarning": "This option cannot be hidden if a payment is required.",
              "requireFullPayment": "Require full payment in order to submit a plan?",
              "requireFullPaymentWarning": "The Payments page, and the Pay Today option must be visible if a payment is required.",
              "title": "Pay Today",
              "tributePay": {
                "paymentTypeLabel": "Tribute Pay Payment Type",
                "placeholderLabel": "Select One"
              }
            },
            "title": "Payment Options"
          },
          "products": {
            "actions": {
              "addCategory": {
                "error": "Something went wrong, please try again.",
                "success": "Product category has been added to the online planner!"
              },
              "deleteCategory": {
                "error": "Something went wrong removing this product category.",
                "success": "Product category successfully removed from the online planner!"
              },
              "updateCategory": {
                "error": "Something went wrong updating this product category.",
                "success": "Product category successfully updated!"
              }
            },
            "categories": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "empty": "There are no product categories assigned to this the online planner.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "save": "Save",
              "sort": "Sort Product Categories"
            },
            "categorySearch": {
              "placeholder": "Product Category"
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add product categories to the planner.",
            "gplMissing": "A general price list is required to add product categories to the planner.",
            "products": {
              "editButton": "Edit Products",
              "itemTotal": "in planner",
              "name": "Products"
            },
            "subtitle": "Add product categories from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add product categories to the planner.",
            "title": "Add Product Categories"
          }
        },
        "price-list": {
          "import": {
            "fileUploader": {
              "accept": "Accept",
              "cancel": "Cancel",
              "error": "There was an error while uploading the file. Correct the errors, and please try again!",
              "fileErrors": "File errors found",
              "placeholder": "Drag and drop documents here to upload them",
              "review": "Review and accept the updates below to apply them to this GPL."
            }
          },
          "product-update": {
            "columns": {
              "cogs": "Track COGS?",
              "cost": "Cost",
              "current": "Current",
              "desc": "Description",
              "discretionary": "Is Discretionary?",
              "id": "ID",
              "invoiceCategory": "Invoice Category",
              "item": "Item",
              "local": "Local Tax?",
              "name": "Name",
              "preneedCategory": "Preneed Category",
              "price": "Price",
              "productCategory": "Product Category",
              "shipTotal": "Shipping Total",
              "shipping": "Include Shipping?",
              "short": "Short Description",
              "state": "State Tax?",
              "taxed": "Is Taxed?",
              "update": "Update"
            }
          }
        },
        "reports": {
          "detailed-items-report": {
            "dropdowns": {
              "reportDate": {
                "label": "Report Date",
                "optionContractDate": "Contract Date",
                "optionTransactionDate": "Transaction Date"
              }
            }
          }
        },
        "tco": {
          "comment": {
            "api": {
              "commentsFailed": "There was a problem retrieving comments.",
              "genericError": "Something went wrong, please try again."
            },
            "buttons": {
              "approve": {
                "label": "Approve"
              },
              "approved": {
                "label": "Approved"
              },
              "cancel": {
                "label": "Cancel"
              },
              "deleteMedia": {
                "label": "Delete media"
              },
              "deny": {
                "label": "Deny"
              },
              "edit": {
                "label": "Edit"
              },
              "newTab": {
                "label": "Open photo in new tab"
              },
              "play": {
                "label": "Play video in new tab"
              },
              "private": {
                "label": "Hide comment from Tribute Wall",
                "message": "Comment is hidden on Tribute Wall!"
              },
              "public": {
                "label": "Show comment on Tribute Wall",
                "message": "Comment is visible on Tribute Wall!"
              },
              "save": {
                "label": "Save"
              }
            },
            "fields": {
              "comment": {
                "label": "Message"
              },
              "name": {
                "label": "Name"
              }
            },
            "prompts": {
              "approve": {
                "message": {
                  "success": "Comment approved!"
                }
              },
              "delete": {
                "buttons": {
                  "confirm": "Yes",
                  "deny": "No"
                },
                "label": "Are you sure you want to delete this comment?",
                "message": {
                  "success": "Comment deleted!"
                }
              },
              "media": {
                "delete": {
                  "buttons": {
                    "confirm": "Yes",
                    "deny": "No"
                  },
                  "label": "Are you sure you want to delete this photo?",
                  "message": {
                    "success": "Photo has been removed successfully."
                  }
                }
              },
              "save": {
                "message": {
                  "success": "Comment saved successfully!"
                }
              }
            }
          },
          "custom-theme": {
            "prompts": {
              "fileUpload": {
                "dropbox": "Drag and drop an image or click to select one.",
                "error": "There was an error while saving the theme, please try again.",
                "message": "Upload an image!"
              },
              "hide": {
                "message": "Hide Custom Theme Uploader"
              },
              "show": {
                "message": "Upload Custom Theme"
              },
              "themeName": {
                "message": "1. Enter a theme name!",
                "textbox": {
                  "label": "Theme Name",
                  "warning": "Theme name can only contain letters, numbers, spaces, and underscores."
                }
              }
            }
          }
        },
        "tfe": {
          "filled-forms-list": {
            "esignature-requests": {
              "bannerMessages": {
                "cancel": {
                  "error": "eSignature request could not be canceled.",
                  "success": "eSignature request successfully canceled!"
                },
                "multipleSigners": {
                  "resendError": "Failed to resend the eSignature request to pending signers.",
                  "resendSuccess": "eSignature request successfully resent to pending signers!"
                },
                "singleSigner": {
                  "resendError": "Failed to resend the eSignature request to the signer.",
                  "resendSuccess": "eSignature request successfully resent to signer!"
                }
              },
              "button": {
                "cancel": "Cancel Request",
                "hide": "Hide",
                "hideSignerActivity": "Hide Recent Signer Activity",
                "resendAll": "Resend to Pending Signers",
                "resendSigner": "Resend to Signer",
                "viewSignerActivity": "View Recent Signer Activity"
              },
              "status": {
                "canceled": "The eSignature request was canceled.",
                "completed": " has completed the eSignature request.",
                "declined": " has declined the eSignature request.",
                "pending": "The eSignature request was sent to ",
                "resent": "The eSignature request was resent to "
              },
              "text": {
                "title": "eSignature Requests"
              }
            },
            "version-history": {
              "button": {
                "hide": "Hide"
              },
              "text": {
                "current": "Current",
                "title": "Version History"
              },
              "tooltipText": {
                "download": "Download"
              }
            }
          },
          "forms": {
            "admin-list-item": {
              "buttons": {
                "cancel": "Cancel",
                "createCopy": "Create Copy"
              },
              "placeholders": {
                "name": "Name"
              },
              "prompt": {
                "copyGlobalForm": "You cannot edit a global form. Would you like to make a copy?"
              },
              "text": {
                "copyForm": "(Copy)",
                "globalForm": "(Global Form)",
                "nameCopy": "Please enter a name for the copied form."
              },
              "tooltips": {
                "pending": "Form is currently processing and will be available shortly",
                "remove": "Remove",
                "setupEsign": "Setup eSign"
              },
              "validationMessage": {
                "name": "Form name can't be blank"
              }
            },
            "filled-list": {
              "actionsMenu": {
                "deleteForm": "Delete Filled Form",
                "hideEsignatureRequests": "Hide eSignature Requests",
                "hideVersionHistory": "Hide Version History",
                "refillForm": "Refill Form",
                "viewEsignatureRequests": "View eSignature Requests",
                "viewVersionHistory": "View Version History"
              },
              "cancel": "Are you sure you want to cancel this request?",
              "deleteEsignFormPrompt": "Deleting this form will cancel and remove eSignature requests for this form. Are you sure?",
              "deleteFormPrompt": "Are you sure?",
              "refillEsignFormPrompt": "Refilling this form will cancel eSignature requests for this form. Are you sure?",
              "signersTooltip": {
                "completed": "Completed Request with {signerCount} {signerPluralization}",
                "default": "Saved Request with {signerCount} {signerPluralization}",
                "pending": "Pending Request with {signerCount} {signerPluralization}",
                "signerCount": {
                  "one": "Signer",
                  "other": "Signers"
                }
              },
              "text": {
                "formProcessingError": {
                  "contactEmail": "support@tributetms.com.",
                  "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
                }
              },
              "tooltipText": {
                "canceledEsignatures": "This form has been canceled.",
                "completedEsignatures": "This form has a completed eSignature request.",
                "download": "Download",
                "moreActions": "More Actions",
                "pendingEsignatures": "This form has a pending eSignature request.",
                "requestEsignatures": "Request eSignatures"
              }
            },
            "list": {
              "buttons": {
                "cancel": "Cancel",
                "fill": "Fill Form"
              },
              "selectBox": {
                "placeholder": {
                  "event": "Event",
                  "familyOrFriend": "Family or Friend",
                  "inquiry": "Inquiry",
                  "location": "Location",
                  "selectOne": "Select One"
                }
              },
              "text": {
                "alreadyFilled": "(already filled)",
                "chooseEvent": "Please choose the event for this form:",
                "chooseInquiry": "Please choose the inquiry for this form:",
                "enterInfo": "Please enter the information below",
                "noName": "No name"
              },
              "tooltipText": {
                "download": "Download Blank Form",
                "favorite": "Favorite",
                "fillForm": "Fill Form",
                "unfavorite": "Unfavorite"
              }
            }
          }
        },
        "whiteboard": {
          "modal": {
            "whiteboard-details": {
              "fields": {
                "select": {
                  "caseStatus": {
                    "placeholder": "Case Status"
                  }
                }
              }
            }
          }
        }
      },
      "header-nav": {
        "clientMenu": {
          "viewAll": "View All"
        },
        "inquiry": {
          "active": "Active Inquiry",
          "new": "New Inquiry"
        },
        "userMenu": {
          "admin": "Admin",
          "logOut": "Log Out",
          "settings": "Settings"
        }
      },
      "kase": {
        "information": {
          "badDebtBy": {
            "hasBeenMarked": "has been marked as bad debt",
            "this": "This"
          }
        },
        "inputs": {
          "labels": {
            "isBadDebt": "Mark as Bad Debt?"
          }
        }
      },
      "list": {
        "adjustment-summary": {
          "errors": {
            "postingPeriod": "Cannot delete an adjustment in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        },
        "discount-summary": {
          "errors": {
            "paymentPostingPeriod": "Cannot delete a discount in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "tmsID": "TMS ID:",
            "transactionId": "Discount Id:"
          }
        },
        "payment-summary": {
          "buttons": {
            "cancel": "Cancel",
            "refund": "Refund"
          },
          "disclaimers": {
            "receiptEmail": {
              "notSend": "There is no email for {firstName} {lastName}.",
              "send": "A receipt will be emailed to {email}."
            }
          },
          "errors": {
            "paymentPostingPeriod": "Cannot delete a payment in a locked posting period. You must unlock the posting period first.",
            "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
            "refund": {
              "amount": "Refund amount must be less than the payment total.",
              "creation": "There was a problem processing the refund."
            }
          },
          "information": {
            "accountNumberLast4": "Account Number Last 4:",
            "accountType": "Account Type:",
            "bankName": "Bank Name:",
            "enteredBy": {
              "emailPaymentLink": "Email Payment Link",
              "label": "Entered By:",
              "onlinePlanner": "Online Planner"
            },
            "netsuiteID": "Netsuite ID:",
            "paymentProcessorStatus": {
              "label": "Payment Processor Status:",
              "statuses": {
                "awaitingClientFeedback": "Awaiting Client Feedback",
                "awaitingInternalFeedback": "Awaiting Internal Feedback",
                "failed": "Failed",
                "initiated": "Initiated",
                "pending": "Pending",
                "successful": "Successful"
              }
            },
            "paymentTotal": "Payment Total:",
            "processingFee": "Processing Fee:",
            "refundedBy": "Refunded By:",
            "routingNumber": "Routing Number:",
            "tmsID": "TMS ID:",
            "transactionId": "Transaction ID:"
          },
          "inputFields": {
            "refundAmount": "Refund Amount"
          },
          "successes": {
            "refund": {
              "creation": "The refund was created successfully!"
            }
          },
          "toolTips": {
            "printReceipt": "Print Receipt",
            "refund": "Refund"
          }
        },
        "refund-summary": {
          "errors": {
            "postingPeriod": "Cannot delete a refund in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:",
            "transactionId": "Refund Id:"
          }
        },
        "selection-package": {
          "packageContents": "Package Contents: ",
          "pills": {
            "selectionsComplete": "Selections Complete",
            "selectionsRequired": "Selections Required"
          }
        },
        "selection-product": {
          "label": {
            "pullFromBeaconfpInventory": "Pull from Inventory?"
          }
        }
      },
      "quick-add-user": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "inputFields": {
          "email": "Email",
          "embalmerLicense": "Embalmer License #",
          "firstName": "First Name",
          "funeralDirectorLicense": "Funeral Director License #",
          "lastName": "Last Name",
          "phone": "Mobile Phone"
        },
        "note": {
          "label": "Note:",
          "text": "In order for a user to be able to log in to Tribute Management Software, a user must have a valid email."
        },
        "pageTitle": "Add User"
      },
      "reports": {
        "ar-aging": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "ar-aging-by-kase": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging By Case Report"
          }
        },
        "ar-aging-by-location-and-case": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report - by Location and Case",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "beacon-monthly-reconciliation-report": {
          "checkboxes": {
            "includePostedDateRange": {
              "label": "Also include Selections by Posting Date"
            }
          },
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "postedDateEndsAt": {
              "placeholder": "End Date"
            },
            "postedDateStartsAt": {
              "placeholder": "Start Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "messages": {
            "missingEndDate": "Please enter an End Date",
            "missingPostedEndDate": "Please enter a Posted End Date",
            "missingPostedStartDate": "Please enter a Posted Start Date",
            "missingStartDate": "Please enter a Start Date"
          },
          "pageTitle": {
            "mainText": "Beacon Monthly Reconciliation Report"
          }
        },
        "case-count-by-case-tag-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Case Count By Case Tag Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "graystone-case-revenue": {
          "checkboxes": {
            "unpostedCases": "Include Unposted Cases?"
          },
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Case Revenue Report"
        },
        "graystone-survey": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Survey Report"
        },
        "inventory-as-of": {
          "datePicker": {
            "asOfDate": "As Of Date"
          },
          "link": {
            "generateReport": "Generate Report"
          },
          "title": "Inventory As Of Report"
        },
        "jcg-altmeyer": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG Report - Altmeyer",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "jcg-preneed": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG PN Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "merchandise-profit": {
          "checkboxes": {
            "displayProductCategory": {
              "label": "Display Product Category?"
            },
            "displayProductNotes": {
              "label": "Display Product Notes?"
            }
          },
          "dateSelectors": {
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": "Merchandise Profit Report"
        },
        "precoa-decedent-report": {
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "Precoa Decedent Report"
          }
        },
        "preneed-to-atneed-conversion-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Conversion Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "preneed-to-atneed-ratio-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Ratio Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        }
      },
      "sidebar-nav": {
        "accounting": "Accounting",
        "calendar": "Calendar",
        "cases": "Cases",
        "contacts": "Contacts",
        "dashboard": "Dashboard",
        "esignatures": "eSignatures",
        "financialDetail": "Financial Detail",
        "growthEngine": "Growth Engine",
        "inquiries": "Inquiries",
        "inventory": "Inventory",
        "legal": "Legal",
        "liveHelp": "Live Help",
        "miscSales": "Misc Sales",
        "ohioEdrs": "Ohio EDRS",
        "payments": "Payments",
        "places": "Places",
        "plans": "Plans",
        "reports": "Reports",
        "websites": "Websites",
        "whiteboard": "Whiteboard"
      }
    },
    "debug": {
      "copy": {
        "clipboard": "Copy to Clipboard",
        "message": "The debug info was successfully copied to your clipboard!"
      },
      "info": {
        "browser": "Browser",
        "client": "Client",
        "ipAddress": "IP Address",
        "notLoggedIn": "Not logged in",
        "user": "User"
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      }
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "login": {
      "browserIsPrivate": {
        "message": {
          "notice": {
            "boldText": "private browsing window",
            "text": "It appears you are trying to access this app from a"
          },
          "request": {
            "additionalText": ", so that we can ensure an",
            "boldText": "standard browsing window",
            "italicText": "optimal experience",
            "text": "Please try again from a"
          },
          "thankYou": {
            "text": "Thank you."
          },
          "title": "Notice"
        }
      },
      "form": {
        "button": {
          "continue": "Continue",
          "submit": "Log in!"
        },
        "email": {
          "error": "Valid email is required",
          "label": "Email"
        },
        "link": {
          "cancelLogin": "Cancel",
          "forgotPassword": "Forgot Password?"
        },
        "messages": {
          "announcementMessage": "Your account safety is our top priority. We’ve implemented additional measures to protect your information.",
          "announcementTitle": "We’ve updated your log in experience!",
          "invalidCredentials": "Login credentials are invalid"
        },
        "password": {
          "error": "This field can't be blank",
          "label": "Password"
        }
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      },
      "migrationResult": {
        "actionRequired": "Action Required",
        "failed": "Your user migration failed, please contact support.",
        "failedUserExisted": "This username already has a Tribute Technology Single Sign On (SSO) account.  To complete this upgrade and use your existing SSO login, please verify your account by clicking the link in the email we are sending you.  Once your email is verified, return to this application and start the login process over.",
        "migrationContinue": "Continue",
        "migrationStartOver": "Start Over",
        "success": "Your account is being upgraded to the new Tribute Technology login experience!",
        "successNoPassword": "To complete this upgrade, you must reset your password. Please check your email for a password reset link. Once complete, return to this page and click Continue to login with your updated credentials.",
        "successPassword": "To complete this upgrade, please click continue and log in again."
      },
      "successfulLogin": {
        "title": "Login Successful"
      },
      "tmsLearnMore": {
        "link": "Learn More",
        "text": "Curious about the logo change?"
      }
    },
    "model": {
      "tfe": {
        "generated-document": {
          "signingStatus": {
            "awaitingInitialization": "Awaiting Initialization",
            "canceled": "Canceled",
            "completed": "Completed",
            "notApplicable": "Not Applicable",
            "pending": "Pending"
          }
        },
        "signer": {
          "role": {
            "beneficiary": "Beneficiary",
            "executor": "Executor",
            "funeralDirector": "Funeral Director",
            "informant": "Informant",
            "nextOfKin": "Next of Kin",
            "primaryContact": "Primary Contact",
            "purchaser": "Purchaser"
          },
          "status": {
            "canceled": "Canceled",
            "completed": "Completed",
            "declined": "Declined",
            "pending": "Pending"
          }
        }
      }
    },
    "models": {
      "integration-service": {
        "displayName": {
          "asd": "ASD",
          "bassMollett": "Bass-Mollett",
          "cemSites": "CemSites",
          "cfs": "CFS",
          "domaniCare": "DomaniCare",
          "frontRunner": "Front Runner",
          "funeralOne": "FuneralOne",
          "funeralTech": "Funeral Tech",
          "ohioEdrs": "Ohio EDRS",
          "tms": "TMS",
          "tukiosV2": "Tukios V2",
          "unknown": "Unknown"
        }
      }
    },
    "pay-now": {
      "buttons": {
        "next": "Next"
      },
      "disclaimers": {
        "amountDue": {
          "label": "Amount Due:"
        },
        "payment": {
          "error": "We were unable to process your payment. Please try again."
        }
      },
      "errors": {
        "payment": {
          "numberValidation": {
            "withBalance": "This field must be less than or equal to ${lte}, but greater than or equal to $0.50.",
            "withoutBalance": "This field must be greater than $0.50."
          }
        }
      },
      "expired": {
        "bannerText": "This payment link has expired.",
        "mainText": {
          "contactFuneralHome": "This payment link is no longer available. Please contact {funeralHomeName} for a new link.",
          "visitWebsite": "This payment link is no longer available. Please visit the {funeralHomeName} website to initiate a new payment."
        },
        "pageTitle": "Expired Link"
      },
      "inputFields": {
        "amount": "Amount",
        "firstName": "First Name",
        "lastName": "Last Name"
      },
      "mainText": "To make a payment to {funeralHome}, please enter your name and the payment amount below.",
      "pageTitle": "Pay Now",
      "success": {
        "banners": {
          "balancePaid": "This balance has been paid in full.",
          "successfulPayment": "This payment was successful!"
        },
        "mainText": {
          "balancePaid": "This balance is currently $0.00. There is no need to make additional payments at this time.",
          "successfulPayment": "Thank you for submitting this payment to {funeralHomeName}. Your payment was successful. You may close this window."
        },
        "pageTitle": {
          "balancePaid": "Balance Paid",
          "successfulPayment": "Successful Payment"
        }
      }
    },
    "services": {
      "client-config": {
        "checkboxes": {
          "admin": {
            "canViewFinancialConfigurations": "Can View Financial Configurations?",
            "canViewGroups": "Can View Groups?",
            "canViewLocations": "Can View Locations?",
            "canViewOnlinePlanner": "Can View Online Planner?",
            "canViewOrganizations": "Can View Organizations?",
            "canViewSmsPaymentLink": "Enable SMS Payment Links",
            "canViewTributePay": "Can View Tribute Pay?",
            "canViewUsers": "Can View Users?",
            "financialConfiguration": {
              "accounts": "Can View Accounts?",
              "adjustments": "Can View Adjustments?",
              "discounts": "Can View Discounts?",
              "interests": "Can View Interests?",
              "payments": "Can View Payments?",
              "postingPeriods": "Can View Posting Periods?",
              "specialTaxes": "Can View Special Taxes?",
              "sync": "Can View Sync?",
              "taxItems": "Can View Tax Items?"
            }
          },
          "case": {
            "autoKaseNumbering": "Has Auto Case Numbering?",
            "blockEsignForUnpostedContracts": "Block eSignature for Unposted Contracts?",
            "canManageCaseTags": "Can Manage Case Tags?",
            "canSetKaseStatusFilter": "Users Can Set Case Status Filter?",
            "canUseSingleKaseTag": "One Case Tag Per Case?",
            "canViewCaseDispositionIcons": "Can View Disposition Icons on Cases?",
            "canViewCaseFinancial": "Can View Case Financial?",
            "canViewCaseInquiries": "Can View Case Inquiries?",
            "canViewCaseTags": "Can View Case Tags?",
            "canViewChainOfCustody": "Can View Chain of Custody?",
            "canViewContactManager": "Can View Contact Manager?",
            "canViewCremationAuthorization": "Can View Cremation Authorization?",
            "canViewCustomFields": "Can View Custom Fields?",
            "canViewDigitalSignatures": "Can View Digital Signature?",
            "canViewDisposition": "Can View Disposition?",
            "canViewDocuments": "Can View Documents?",
            "canViewEsignature": "Can View eSignature?",
            "canViewEvents": "Can View Events?",
            "canViewFamilyFriends": "Can View Family & Friends?",
            "canViewFamilyPortal": "Can View Family Portal?",
            "canViewForms": "Can View Forms?",
            "canViewIsCovid19Case": "Can View Is COVID-19 Case?",
            "canViewLinks": "Can View Links?",
            "canViewMimsStationary": "Can View MIMS Stationary?",
            "canViewObituary": "Can View Obituary?",
            "canViewObituaryGenerator": "Can View Obituary Generator?",
            "canViewPreneed": "Can View Preneed?",
            "canViewPreneedCategories": "Can View Preneed Categories?",
            "canViewPreneedInfoMessage": "Can View Preneed Info Message?",
            "canViewPreparations": "Can View Preparations?",
            "canViewPrintables": "Can View Printables?",
            "canViewReferralInfo": "Can View Referral Info?",
            "canViewServiceInfo": "Can View Service Info?",
            "canViewTasks": "Enable Task List",
            "canViewTasksAdmin": "Enable Task List Admin",
            "canViewTransportation": "Can View Transportation?",
            "canViewVeteranInfo": "Can View Veteran Info?",
            "forceAutoKaseNumbering": "Force Automatic Case Numbering?",
            "isEsignFieldTest": "Is eSign Field Test?",
            "onlyFuneralDirectorsCanBeLeadStaffMembers": "Only Funeral Directors Can Be Lead Staff Members",
            "sendFamilyPortalEmailInvite": "Automatically Send Family Portal Invite Email?"
          },
          "caseFinancial": {
            "canAssignInsuranceToPayments": "Can Assign Insurance Policies to Payments?",
            "canLockPostingPeriods": "Can Lock Posting Periods?",
            "canMarkAsBadDebt": "Can Mark as Bad Debt?",
            "canViewAdjustmentGains": "Can View Adjustment Gains?",
            "canViewBatchPayments": "Can View Batch Payments?",
            "canViewDiscounts": "Can View Discounts?",
            "canViewDoNotExport": "Can View Do Not Export?",
            "canViewGeneralPayments": "Can View General Payments?",
            "canViewGenerateSfgsButton": "Can View Generate SFGS Button?",
            "canViewInterests": "Can View Interests?",
            "canViewLineItemAdjustments": "Can View Line Item Adjustments?",
            "canViewMiscSales": "Can View Misc Sales?",
            "canViewSpecialTax": "Can View Special Tax?",
            "defaultDueDate": "Default Due Date?",
            "enforcePostingPeriodLocking": "Enforce Posting Period Locking?",
            "relabelContractToInvoice": "Relabel Contract to Invoice?",
            "setContractDateToPostedDateIfNoEvents": "If No Events, Contract Date Is Set To Posted Date?"
          },
          "events": {
            "canViewEmployeeArrivalTime": "Can View Employee Arrival Time?",
            "canViewFamilyArrivalTime": "Can View Family Arrival Time?",
            "canViewVehicles": "Can View Vehicles?",
            "eventStaffMemberInvites": "Event Staff Member Notification Accept / Decline?",
            "sendResponsesToCaseLead": "Send Responses to Case Lead?",
            "sendResponsesToOriginalAssigner": "Send Responses to Original Assigner?"
          },
          "globalNavigation": {
            "canViewAccounting": "Can View Accounting?",
            "canViewCalendar": "Can View Calendar?",
            "canViewCases": "Can View Cases?",
            "canViewContacts": "Can View Contacts?",
            "canViewFinancialDetail": "Can View Financial Detail?",
            "canViewInquiries": "Can View Inquiries?",
            "canViewPlaces": "Can View Places?",
            "canViewReports": "Can View Reports?",
            "canViewWhiteboard": "Can View Whiteboard?"
          },
          "integrations": {
            "canHideDeathNotice": "Can Hide Death Notice?",
            "canViewCemsites": "Can View CemSites?",
            "canViewGrowthEngine": "Can View Growth Engine?",
            "canViewOhioEdrs": "Can View Ohio EDRS?"
          },
          "postingValidations": {
            "caseTag": "Enable Case Tag Validator?",
            "disposition": "Enable Disposition Validator?",
            "enablePostingValidations": "Enable Posting Validations?",
            "postingPeriod": "Enable Posting Period Validator?",
            "purchaser": "Enable Purchaser Validator?",
            "purchaserRelationship": "Enable Purchaser Relationship Validator?",
            "staffMember": "Enable Staff Member Validator?"
          },
          "productAndInventory": {
            "canViewAdditionalProductInfo": "Can View Additional Product Info?",
            "canViewCogsCustomProducts": "Can View COGS on Custom Products?",
            "canViewInventory": "Can View Inventory?",
            "canViewPackageSlotAllowances": "Can View Package Slot Allowances?",
            "canViewPackageSlotFlexibleItems": "Can View Flexible Item(s) Package Slots?",
            "canViewPriceListDates": "Can View Price List Dates?",
            "canViewPullFromBeaconfpInventory": "Can View Pull From Beaconfp Inventory?",
            "canViewPullFromInventory": "Can View Pull From Inventory?",
            "canViewVendors": "Can View Vendors?",
            "hasDiscretionaryItems": "Has Discretionary Items?",
            "useCustomProducts": "Use Custom Products?"
          }
        },
        "disclaimers": {
          "packageSlotAllowances": "Verify client statement of account and statement of funeral goods and services have support for allowances before enabling."
        }
      }
    }
  }], ["es-us", {
    "appTitle": {
      "acronym": "TMS",
      "full": "Tribute Management Software"
    },
    "authenticated": {
      "accounting": {
        "exports": {
          "index": {
            "buttons": {
              "new": "Create New"
            },
            "lists": {
              "noExports": "No Exports"
            },
            "pageTitle": "Exports"
          }
        }
      },
      "admin": {
        "custom-fields": {
          "buttons": {
            "addNew": "Add New"
          },
          "edit": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!",
              "validate": "One of the fields is invalid"
            },
            "allLocationsLabel": "All Locations",
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationLabel": "Location: ",
            "pageTitle": "Custom Field Configuration"
          },
          "locationSelect": {
            "placeholder": "Location",
            "placeholderLabel": "All Locations"
          },
          "new": {
            "actions": {
              "close": "Custom field configuration has unsaved changes, are you sure?",
              "error": "Something went wrong. Please try again.",
              "save": "Custom field configuration saved!"
            },
            "buttons": {
              "close": "Close",
              "new": "New",
              "saveAndClose": "Save & Close"
            },
            "locationSelect": {
              "placeholder": "Location",
              "placeholderLabel": "Select Location"
            },
            "noCustomFields": "No custom fields have been created.",
            "pageTitle": "New Custom Field Configuration"
          },
          "pageTitle": "Custom Field Configurations",
          "table": {
            "editLink": "Edit",
            "header": "Location",
            "mobileHeader": "Location Name: "
          }
        },
        "data": {
          "case-tags": {
            "buttons": {
              "addTag": "Add Tag"
            },
            "fields": {
              "input": {
                "name": "Name"
              },
              "select": {
                "options": {
                  "default": "Select One"
                },
                "placeholder": "Disposition Method"
              }
            },
            "labels": {
              "dispositionMethod": "Disposition Method:"
            },
            "list": {
              "empty": "No Case Tags"
            }
          },
          "poems-and-prayers": {
            "actions": {
              "delete": "Poem successfully removed!",
              "save": {
                "error": "An error occured while saving.",
                "success": "Poem successfully saved!"
              }
            },
            "newPoem": {
              "buttons": {
                "add": "Add Poem or Prayer",
                "cancel": "Cancel",
                "save": "Save"
              },
              "name": "Name",
              "text": "Verbiage"
            },
            "noPoems": "No Poems or Prayers",
            "poems": {
              "edit": {
                "name": "Name",
                "text": "Verbiage"
              }
            },
            "search": "Name"
          }
        },
        "financial-configurations": {
          "edit": {
            "additional-settings": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "messages": {
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "sageIntacct": {
                "buttons": {
                  "add": "Add"
                },
                "header": "Sage Intacct Configuration",
                "inputs": {
                  "glEntryCode": {
                    "placeholder": "GL Entry Code"
                  },
                  "journalCode": {
                    "placeholder": "Journal Code"
                  },
                  "locationCode": {
                    "placeholder": "Location Code"
                  }
                },
                "selectors": {
                  "locations": {
                    "placeholder": "Location",
                    "placeholderLabel": "Select One"
                  }
                }
              }
            },
            "adjustments": {
              "data": {
                "account": "Account:"
              },
              "ids": {
                "netsuite": "Netsuite ID:",
                "tms": "TMS ID:"
              }
            },
            "discounts": {
              "buttons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "new": "New",
                "save": "Save"
              },
              "information": {
                "account": {
                  "label": "Account:"
                },
                "netsuiteID": {
                  "label": "Netsuite ID:"
                },
                "tmsID": {
                  "label": "TMS ID:"
                }
              },
              "inputFields": {
                "discountTypeName": "Discount Type Name"
              },
              "messages": {
                "delete": {
                  "error": "There was an error removing this record.",
                  "success": "Record has been removed!"
                },
                "save": {
                  "error": "Sorry, something went wrong. Please try again.",
                  "success": "Discount type successfully saved!"
                }
              },
              "pageTitle": "Discounts",
              "selectFields": {
                "account": "Account"
              }
            },
            "overview": {
              "accountDropdowns": {
                "discount": "Discount Account",
                "receivables": "Receivables Account",
                "refund": "Refund Account",
                "taxPayable": "Tax Payable Account"
              },
              "buttons": {
                "cancel": "Cancel",
                "importQuickbooks": "Import Quickbooks",
                "importQuickbooksOnline": "Import Quickbooks Online",
                "save": "Save"
              },
              "fieldInput": {
                "name": "Name"
              },
              "messages": {
                "iifUpload": {
                  "error": "Error uploading file.",
                  "success": "File successfully uploaded."
                },
                "import": {
                  "error": "Import failed. Please try again.",
                  "success": "Import started. Please wait for QuickBooks to sync."
                },
                "save": {
                  "error": "Something went wrong. Please try again.",
                  "success": "Accounts successfully saved!"
                }
              },
              "pageNotValidText": "This Chart of Accounts is not yet valid. Please check your accounts.",
              "pageTitle": "Overview",
              "pageValidText": "This Chart of Accounts is valid.",
              "secondaryTitle": "Tax Configuration",
              "taxItemDropdowns": {
                "nonTaxItemName": "Non Tax Item Name",
                "taxItemName": "Tax Item Name"
              }
            },
            "pageTitle": "Financial Configurations",
            "payments": {
              "information": {
                "tmsID": "TMS ID:"
              }
            },
            "posting-periods": {
              "buttons": {
                "addNew": "Add New",
                "archive": "Archive",
                "clear": "Clear",
                "savePostingPeriod": "Save Posting Period",
                "unarchive": "Unarchive"
              },
              "confirmDialog": {
                "archive": "The selected posting period will be archived. Are you sure?",
                "unarchive": "The selected posting period will be unarchived. Are you sure?"
              },
              "inputs": {
                "archived": {
                  "label": "View Archived"
                },
                "pikaday": {
                  "endsAt": {
                    "label": "Ending At"
                  },
                  "startsAt": {
                    "label": "Starting From"
                  }
                },
                "pikadayDateTime": {
                  "dateLabel": "Lock Date",
                  "timeLabel": "Lock Time"
                }
              },
              "pageTitle": "Posting Periods",
              "table": {
                "createdOn": {
                  "label": "Created On: "
                },
                "headers": {
                  "createdOn": "Created On",
                  "lockDate": "Lock Date",
                  "lockedBy": "Locked By"
                },
                "lockDate": {
                  "label": "Lock Date: "
                },
                "lockedBy": {
                  "label": "Locked By: "
                }
              }
            },
            "sideNavigationLabels": {
              "accounts": "Accounts",
              "adjustments": "Adjustments",
              "backToAdmin": "Back to Admin",
              "discounts": "Discounts",
              "interests": "Interests",
              "overview": "Overview",
              "payments": "Payments",
              "postingPeriods": "Posting Periods",
              "settings": "Settings",
              "specialTaxes": "Special Taxes",
              "sync": "Sync",
              "taxItems": "Tax Items"
            },
            "sync": {
              "buttons": {
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save"
              },
              "inputFields": {
                "companyFilePath": "Company File Path"
              },
              "links": {
                "downloadConfigurationFile": "Download Configuration File"
              },
              "pageTitle": "Sync",
              "sections": {
                "accessToken": {
                  "title": "Access Token",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Access Tokens can only be viewed once",
                    "noAccessToken": "No Access Token",
                    "writeThisDown": "Write this down!"
                  }
                },
                "accountNotSetup": {
                  "mainText": "Your account has not been setup for syncing. Please contact",
                  "supportEmail": "support@tributetms.com"
                },
                "filePath": {
                  "title": "File Path"
                },
                "quickBooksDesktop": {
                  "instructions": {
                    "mainText": "Follow these steps to sync Tribute Management Software with QuickBooks Desktop running on your computer:",
                    "step1": {
                      "linkText": "QuickBooks Web Connector",
                      "mainText": "Download and install the"
                    },
                    "step2": "Enter the File Path to your Quick Book Company File (.qbw) below",
                    "step3": "Generate an access token (below)",
                    "step4": "Download and open the Configuration File (below) and open it with QuickBooks Web Connector",
                    "step5": "Select the QuickBooks company file you'd like Tribute Management Software to sync to",
                    "step6": "Enter your Tribute Management Software password in QuickBooks Web Connector"
                  },
                  "title": "QuickBooks Desktop"
                }
              }
            }
          }
        },
        "general-price-lists": {
          "edit": {
            "buttons": {
              "add": "Add Statement Category"
            },
            "cost-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Cost updates saved!"
              },
              "pageTitle": "Cost Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "costHeader": "Cost",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "header": {
              "subtitle": "Edit Statement Categories that you can assign to Product Categories",
              "title": "General Price List Statement Categories"
            },
            "list": {
              "noStatementCategories": "No Statement Categories"
            },
            "messages": {
              "error": "Make sure all of the information is correct and/or inputted.",
              "save": {
                "catch": "Something went wrong.",
                "success": "Statement Category successfully created!"
              }
            },
            "nav": {
              "backToAdmin": "Back to Admin",
              "costUpdates": "Cost Updates",
              "import": "CSV Updates",
              "overview": "Overview",
              "packages": "Packages",
              "preneedCategories": "Preneed Categories",
              "priceListDates": "Price List Dates",
              "pricingUpdates": "Pricing Updates",
              "productCategories": "Product Categories",
              "products": "Products",
              "statementCategories": "Statement Categories"
            },
            "overview": {
              "buttons": {
                "cancel": "Cancel",
                "export": "Create CSV Export",
                "save": "Save"
              },
              "inputs": {
                "chartOfAccountsName": "Chart of Accounts Name",
                "name": "Name"
              },
              "messages": {
                "save": {
                  "error": "There was a problem saving this general price list.",
                  "success": "General price list saved!"
                }
              },
              "pageTitle": "Overview"
            },
            "packages": {
              "edit": {
                "buttons": {
                  "addItem": "+ Add Item",
                  "cancel": "Cancel",
                  "save": "Save"
                },
                "fields": {
                  "checkboxes": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "amount": "Amount ({currency})",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID"
                  },
                  "select": {
                    "addProduct": {
                      "disclaimers": {
                        "alreadyInPackage": "(Already in Package)"
                      },
                      "placeholder": "Add Product"
                    },
                    "invoiceCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Invoice Category"
                    },
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currency})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "information": {
                  "netsuite": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "lists": {
                  "discount": {
                    "label": "Discount"
                  },
                  "finalTotal": {
                    "label": "Final Total",
                    "validations": {
                      "error": {
                        "subtotal": {
                          "main": "{type} is greater than sub total.",
                          "types": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      }
                    }
                  },
                  "flexibleItems": {
                    "none": {
                      "disclaimer": "Please use the field above to add flexible items to this package.",
                      "title": "No Assigned Flexible Items"
                    }
                  },
                  "items": {
                    "none": {
                      "disclaimer": "Please use the field above to add items to this package.",
                      "title": "No Assigned Items"
                    }
                  },
                  "subTotal": {
                    "label": "Sub Total"
                  }
                },
                "manage-products": {
                  "buttons": {
                    "backToPackage": "Back to Package",
                    "clear": "Clear",
                    "clearAllSelections": "Clear All Selections",
                    "selectAllOnCategory": "Select All on Category",
                    "selectAllOnPage": "Select All on Page"
                  },
                  "description": "Select products from this category to enable for the flexible item.",
                  "fields": {
                    "input": {
                      "search": "Search"
                    }
                  },
                  "table": {
                    "headers": {
                      "description": "Description",
                      "name": "Name",
                      "price": "Price"
                    }
                  }
                },
                "sections": {
                  "flexibleItems": "Flexible Items",
                  "items": "Items",
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "title": "Edit Package"
              },
              "index": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                },
                "table": {
                  "actions": {
                    "edit": "Edit"
                  },
                  "headers": {
                    "items": "Items"
                  },
                  "noPackages": "No packages to display for this GPL.",
                  "responsiveHeaders": {
                    "description": "Description: ",
                    "name": "Name: ",
                    "packageContents": "Package Contents: "
                  }
                },
                "title": "Packages"
              },
              "new": {
                "alerts": {
                  "save": {
                    "error": "Something went wrong. Please try again.",
                    "success": "Package saved!"
                  }
                },
                "buttons": {
                  "cancel": "Cancel",
                  "save": "Save & Continue"
                },
                "fields": {
                  "checkbox": {
                    "isDiscretionary": "Is Discretionary?"
                  },
                  "input": {
                    "addProduct": "Add Product",
                    "name": "Name",
                    "netSuiteId": "NetSuite ID",
                    "pricingAmount": "Amount ({currencySymbol})"
                  },
                  "select": {
                    "invoiceCategory": "Invoice Category",
                    "pricingMethod": {
                      "options": {
                        "discount": "Discount ({currencySymbol})",
                        "fixed": "Fixed",
                        "normal": "Normal"
                      },
                      "placeholder": "Pricing Method"
                    }
                  },
                  "textarea": {
                    "description": "Description"
                  }
                },
                "headings": {
                  "packageContents": "Package Contents",
                  "pricingDetails": "Pricing Details"
                },
                "lists": {
                  "ids": {
                    "netsuiteId": "NetSuite ID:",
                    "tmsId": "TMS ID:"
                  },
                  "pricingDetails": {
                    "items": {
                      "discount": "Discount",
                      "finalTotal": {
                        "label": "Final Total",
                        "text": {
                          "main": "{amount} is greater than sub total",
                          "options": {
                            "discount": "Discount",
                            "finalTotal": "Final total"
                          }
                        }
                      },
                      "subtotal": "Sub Total"
                    }
                  },
                  "products": {
                    "disclaimers": {
                      "alreadyInPackage": "Already In Package",
                      "noAssignedItems": "No Assigned Items",
                      "useFieldAbove": "Please use the field above to add items to the package."
                    }
                  }
                },
                "title": "Create New Package"
              }
            },
            "placeholders": {
              "name": "Name"
            },
            "pricing-imports": {
              "addNew": "How to add a new product to your GPL via CSV",
              "addrule1": "To create a new product in your GPL, enter \"new\" in the Item ID column.",
              "addrule2": "Enter product details in the appropriate columns.",
              "addrule3": "Product Category Names are case sensitive and must match exactly.",
              "howTo": "How to edit a GPL via a CSV Upload",
              "inProgress": "Products update in progress",
              "rule1": "Click the “Create CSV Export” link above to download a formatted CSV file of your General Price List.",
              "rule2": "Open the file in Excel or other spreadsheet application to make your changes.",
              "rule3": "Do not delete any columns, or change the column order. Do not modify the column headers.",
              "rule4": "Do not edit Item ID's of existing Items.",
              "rule5": "Remove any rows that do not need to be updated.",
              "rule6": "Save file as a CSV (Comma Separated Values) and upload here.",
              "status": {
                "complete": "Completed",
                "inProgress": "In Progress",
                "started": "Started"
              },
              "table": {
                "Created": "Created",
                "Errors": "Errors",
                "file": "File Name",
                "noRecords": "No records found",
                "status": "Status"
              },
              "title": "CSV Updates"
            },
            "pricing-updates": {
              "buttons": {
                "cancel": "Cancel",
                "save": "Save"
              },
              "checkboxes": {
                "viewArchived": "View Archived?"
              },
              "dropdown": {
                "placeholder": "Filter by Product Category",
                "placeholderLabel": "All"
              },
              "messages": {
                "error": "Sorry, something went wrong. Please try again.",
                "success": "Pricing updates saved!"
              },
              "pageTitle": "Pricing Updates",
              "table": {
                "headers": {
                  "categoryHeader": "Category",
                  "descriptionHeader": "Description",
                  "nameHeader": "Name",
                  "priceHeader": "Price"
                },
                "tableText": "No products to display for this GPL."
              }
            },
            "products": {
              "edit": {
                "headers": {
                  "costChanges": "Cost History"
                },
                "information": {
                  "netsuiteID": "Netsuite ID:",
                  "tmsID": "TMS ID:"
                },
                "tables": {
                  "costChanges": {
                    "headers": {
                      "newCost": "New Cost",
                      "previousCost": "Previous Cost",
                      "totalChange": "Total Change",
                      "updatedAt": "Updated At"
                    }
                  }
                }
              }
            },
            "title": "General Price Lists"
          }
        },
        "integrations": {
          "asd": {
            "buttons": {
              "linkASDAccount": "Link ASD Account",
              "linkASDLocation": "Link ASD Location",
              "linkLocations": "Link Locations"
            },
            "sections": {
              "asdLocations": {
                "title": "ASD Locations"
              },
              "settings": {
                "information": {
                  "asdLink": {
                    "allLocationsLinked": "All TMS Locations have been linked.",
                    "clientIsLinked": "This client is linked with ASD.",
                    "clientIsNotLinked": "Please click the link below to retreive the location remote ID(s) and assign them to the appropriate location(s) in Tribute Management Software."
                  },
                  "autoKaseExport": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Auto Case Export?:"
                  },
                  "importBeginningTime": "Imports will begin one hour after",
                  "importCases": {
                    "disabled": "Disabled",
                    "enabled": "Enabled",
                    "label": "Import Cases?:"
                  },
                  "importCasesAfter": "Import Cases After:",
                  "locations": {
                    "label": "Location(s):",
                    "noData": "No locations linked."
                  }
                },
                "title": "Settings"
              }
            },
            "selectFields": {
              "location": {
                "defaultSelection": "Select One"
              }
            }
          },
          "links": {
            "aftercare": "Aftercare",
            "asd": "ASD",
            "backToAdmin": "Back to Admin",
            "bassMollett": "Bass-Mollett",
            "batesville": "Batesville",
            "bestWishes": "Best Wishes",
            "cfs": "CFS",
            "ciclops": "Ciclops",
            "domaniCare": "DomaniCare",
            "forethought": "Forethought",
            "frazer": "Frazer",
            "frontRunner": "Front Runner",
            "funeralDecisions": "Funeral Decisions",
            "funeralOne": "FuneralOne",
            "funeralTech": "FuneralTech",
            "messenger": "Messenger",
            "ohioEDRS": "Ohio EDRS",
            "overview": "Overview",
            "precoa": "Precoa",
            "tco": "Tribute Center Online",
            "tcoV2": "Tribute Center Online V2",
            "transNational": "TransNational",
            "tributePrint": "Tribute Print",
            "tributeVideo": "Tribute Video",
            "tukios": "Tukios",
            "tukiosV2": "Tukios V2",
            "viewlogies": "Viewlogies"
          },
          "pageTitle": "Integrations",
          "tco": {
            "buttons": {
              "linkTcoLocation": "Link TCO Location"
            },
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "linkFailure": "TMS Location is already linked to a TCO Location",
            "linkSuccess": "TCO ID successfully added to location",
            "linkUpdated": "TCO Location ID Updated",
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online",
            "selector": {
              "placeholder": "Select a Matching Location"
            },
            "tcoLink": {
              "button": {
                "linkLocations": "Link TCO Locations"
              },
              "locationsLinked": "All TMS locations have been linked to TCO"
            }
          },
          "tco-v2": {
            "disclaimers": {
              "onlyOneVersion": "You can only use one version of a Tribute Center Online integration."
            },
            "inputFields": {
              "password": {
                "placeholder": "Password"
              },
              "username": {
                "placeholder": "Username"
              }
            },
            "pageLoading": "Loading...",
            "pageTitle": "Tribute Center Online V2"
          },
          "tribute-print": {
            "pageTitle": "Tribute Print"
          }
        },
        "locations": {
          "edit": {
            "overview": {
              "information": {
                "netsuiteID": "Netsuite ID:",
                "tmsID": "TMS ID:"
              },
              "placeholders": {
                "asdRemoteId": "ASD Remote ID",
                "tcoLocationId": "TCO Location ID"
              },
              "sections": {
                "organizations": {
                  "header": "Organizations",
                  "table": {
                    "columns": {
                      "name": "Name"
                    }
                  },
                  "tooltips": {
                    "edit": "Edit"
                  }
                }
              }
            }
          }
        },
        "manage": {
          "ar-adjustments": {
            "actions": {
              "revert": "Revert"
            },
            "pageTitle": "A/R Adjustments",
            "revertConfirm": "Are you sure you want to revert this adjustment?",
            "revertError": "An error occurred while reverting the adjustment. Please try again.",
            "revertSuccess": "The job to revert the adjustment has been queued. Please check back later.",
            "reverted": "Reverted",
            "table": {
              "columns": {
                "actions": "Actions",
                "errors": "Errors",
                "ran": "Date ran",
                "range": "Date range",
                "requested": "Requested by",
                "status": "Status"
              }
            }
          },
          "cemsites": {
            "index": {
              "confirmDialog": {
                "promptMessage": "Are you sure you want to unlink this Tribute Management Software Place with CemSites?"
              }
            },
            "new": {
              "error": {
                "uuid": "There was a problem returning CemSites places. If this continues, please contact support."
              },
              "selectFields": {
                "tmsPlace": {
                  "placeholder": "TMS Place"
                }
              }
            }
          },
          "financial-configurations": {
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "inputPlaceholder": "Name",
              "messages": {
                "error": "Oops! There was an error saving this Chart of Account.",
                "success": "Chart of Accounts save successful request submitted!"
              },
              "pageTitle": "Add Financial Configuration"
            }
          },
          "forms": {
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "success": {
                "delete": "Successfully removed form.",
                "save": "Successfully added form."
              }
            },
            "pageTitle": "Enabled Forms",
            "searchField": {
              "placeholder": "Search"
            },
            "text": {
              "alreadySelected": "(Already Selected)",
              "enableFormsText": "Please use the search field above to enable forms for future use.",
              "noFormsSelected": "No Forms Selected"
            }
          },
          "general-price-lists": {
            "new": {
              "buttons": {
                "text": {
                  "cancel": "Cancel",
                  "create": "Create"
                }
              },
              "fields": {
                "placeholders": {
                  "financialConfiguration": "Financial Configuration",
                  "name": "Name",
                  "selectOne": "Select One"
                }
              },
              "messages": {
                "error": "Oops! There was an error saving this GPL.",
                "success": "General Price List save successfully submitted!"
              },
              "pageTitle": "Add General Price List"
            }
          },
          "info": {
            "button": {
              "save": {
                "errorMessage": "Oops, something went wrong! Please try again.",
                "label": "Save",
                "successMessage": "Info saved!"
              }
            },
            "dropdown": {
              "packageItemFormat": {
                "label": "Package Item Format",
                "optionIncluded": "Included",
                "optionShowPricing": "Show Pricing",
                "selectOneLabel": "Choose One"
              },
              "sfgsFontSize": {
                "label": "Statement Font Size",
                "optionDefault": "Default",
                "optionLarger": "Larger",
                "selectOneLabel": "Choose One"
              }
            },
            "pageText": "The following are the warranties, terms, disclosures and acknowledgement and agreement that will appear on your printed Statement of Funeral Goods and Services.",
            "pageTitle": "Client Info",
            "secondaryTitle": "Billing",
            "textarea": {
              "acknowledgementAndAgreement": "Acknowledgements and Agreements",
              "disclosures": "Disclosures",
              "terms": "Terms",
              "warranties": "Warranties"
            }
          },
          "navigation": {
            "links": {
              "arAdjustments": "A/R Adjustments",
              "forms": "Forms",
              "tributePay": "Tribute Pay"
            }
          },
          "online-planner": {
            "index": {
              "clearSearchButton": "Clear",
              "copyUrlButton": "Copy URL",
              "defaultPlannerLabel": "Default Planner",
              "editPlannerButton": "Edit",
              "newButton": "New",
              "newPlanner": {
                "actions": {
                  "archive": {
                    "error": "There was a problem while trying to archive this planner. If this continues, please contact support.",
                    "message": "Are you sure you want to archive this planner?",
                    "no": "No",
                    "success": "Planner archived successfully",
                    "yes": "Yes"
                  },
                  "save": {
                    "error": "Something went wrong, please try again.",
                    "success": "Online planner configuration successfully created."
                  },
                  "unArchive": {
                    "error": "There was a problem un archiving the planner. If this continues, please contact support."
                  }
                },
                "cancelButton": "Cancel",
                "createButton": "Create",
                "namePlaceholder": "Name",
                "urlPlaceholder": "URL Prefix"
              },
              "pageTitle": "Online Planner",
              "tableHeaders": {
                "actions": "Actions",
                "plannerName": "Name",
                "urlPrefix": "URL Prefix"
              },
              "viewArchived": "View Archived?"
            }
          },
          "organizations": {
            "edit": {
              "assignOrganizationToLocation": {
                "addLocation": {
                  "button": "Add Location",
                  "errorMessage": "Whoops! That location is already assigned to this organization."
                },
                "dropdown": {
                  "label": "Assign Organization to Location",
                  "placeholderLabel": "Select One"
                }
              },
              "buttons": {
                "close": "Close",
                "errorMessage": "Oh No! There was an error.",
                "save": "Save & Close",
                "successMessage": "Successfully saved!"
              },
              "fields": {
                "input": {
                  "website": "Website"
                },
                "select": {
                  "contactPerson": "Contact Person"
                }
              },
              "misc": {
                "notApplicable": "N/A"
              },
              "organizationNameLabel": "Name",
              "pageTitle": "Edit Organization",
              "sections": {
                "contactPerson": {
                  "email": "Email Address:",
                  "phone": "Phone Number:",
                  "title": "Contact Person:"
                },
                "locations": {
                  "emptyList": "This organization is not currently assigned to a location.",
                  "title": "Locations:"
                }
              }
            },
            "index": {
              "addNewOrganization": {
                "buttons": {
                  "cancel": "Cancel",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully saved!"
                  },
                  "save": "Save"
                },
                "placeholderLabel": "Organization Name"
              },
              "pageTitle": "Organizations",
              "searchOrganizations": {
                "buttons": {
                  "addNew": "Add New",
                  "clear": "Clear"
                }
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "name": "Name"
                },
                "headersAtSmallerScreenSizes": {
                  "name": "Name:"
                },
                "links": {
                  "delete": "Delete",
                  "edit": "Edit",
                  "messages": {
                    "error": "Oh No! There was an error.",
                    "success": "Successfully removed."
                  }
                }
              }
            }
          },
          "pageTitle": "Admin Settings",
          "tfe": {
            "forms": {
              "buttons": {
                "cancel": "Cancel",
                "clear": "Clear",
                "enableForm": "Enable Form"
              },
              "formVisibility": {
                "labelOptions": {
                  "all": "All",
                  "private": "Private",
                  "public": "Public"
                },
                "placeholder": "Form Visibility"
              },
              "messages": {
                "error": "Oops, something went wrong! Please try again.",
                "success": {
                  "delete": "Successfully removed form.",
                  "save": "Successfully enabled form."
                }
              },
              "pageTitle": "Enabled Forms",
              "searchField": {
                "placeholder": "Search"
              },
              "text": {
                "alreadySelected": "(Already Selected)",
                "enableFormsText": "Please use the button above to enable forms for future use.",
                "noFormsSelected": "No Forms Selected"
              }
            }
          },
          "users": {
            "index": {
              "save": {
                "ssoUserExists": "User saved! This email is already tied to an existing sso account. They must first verify their email address before they can log in.",
                "success": "User saved!"
              }
            }
          },
          "whiteboard": {
            "buttons": {
              "generateSecurePin": {
                "label": "Generate Secure Pin"
              }
            },
            "information": {
              "contactUs": "Contact us to issue a new pin.",
              "createdAt": "Created at:",
              "developerMessage": "Superusers cannot generate pins. To generate a whiteboard pin, create a new user account with administrator permissions for the required client.",
              "instructions": "Click the button below to generate your unique login pin for the TMS Whiteboard Apple TV App."
            },
            "pageSubtitle": "Secure Pin",
            "pageTitle": "Whiteboard"
          }
        },
        "online-planner": {
          "config": {
            "edit-category": {
              "backToPlanner": "Back to Online Planner",
              "clearSearch": "Clear",
              "clearSelections": "Clear All Selections",
              "saveButton": "Save",
              "saveError": "There was an error while saving the products to the online planner category. Please try again.",
              "saveSuccess": "Category saved successfully!",
              "selectAllInCategory": "Select All in Category",
              "selectAllOnPage": "Select All on Page",
              "subtitle": "Select products in this category to enable in the planner.",
              "table": {
                "columns": {
                  "description": "Description",
                  "name": "Name",
                  "price": "Price"
                },
                "isTaxed": "tax"
              }
            }
          },
          "edit": {
            "backButton": "Back to Online Planner",
            "notification-settings": {
              "alerts": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              },
              "buttons": {
                "addRecipient": "+ Add New Notification Recipient",
                "cancel": "Cancel",
                "clear": "Clear",
                "deleteRecipient": "Delete",
                "save": "Save"
              },
              "inputFields": {
                "search": {
                  "placeholder": "Search"
                },
                "staffMember": {
                  "placeholder": "Staff Member"
                }
              },
              "lists": {
                "notifications": {
                  "empty": "No Notifications Added"
                }
              },
              "pageTitle": "Notification Settings"
            },
            "notificationSettingsButton": "Notification Settings",
            "overview": {
              "buttons": {
                "back": "Back to Online Planner",
                "copyPlannerURL": {
                  "tooltip": "Copy planner URL"
                },
                "save": "Save"
              },
              "errors": {
                "name": "You can't save the planner without a Name.",
                "pageName": "You can't save a page without a Page name.",
                "urlPrefix": "You can't save the planner without a URL Name."
              },
              "fields": {
                "checkbox": {
                  "defaultPlanner": {
                    "label": "Default Planner?",
                    "validation": {
                      "allLocations": "Allow this planner to be available for all locations.",
                      "toMakeDefault": "To make this the default, please update this setting in the current default planner."
                    }
                  }
                },
                "input": {
                  "pageIsHidden": "Hide this page?",
                  "pageName": "Page Name",
                  "plannerName": "Name",
                  "plannerURL": "URL Prefix",
                  "primaryColor": {
                    "info": "Primary color is used for header background.",
                    "placeholder": "Primary Color"
                  },
                  "secondaryColor": {
                    "info": "Secondary color is used for buttons and accents throughout the planner.",
                    "placeholder": "Secondary Color"
                  },
                  "subPageisHidden": "Hide this subpage?"
                },
                "select": {
                  "bodyFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Body Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration body font."
                    }
                  },
                  "country": {
                    "info": "Country fields will default to this value.",
                    "options": {
                      "canada": "Canada",
                      "unitedStates": "United States"
                    },
                    "placeholder": "Country"
                  },
                  "defaultTaxLocation": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Default Tax Location",
                    "validation": {
                      "disabled": "A GPL is required to set the default tax location."
                    }
                  },
                  "fontType": {
                    "placeholder": "Font Type",
                    "systemFonts": "High-performance System Fonts",
                    "webFonts": "Web Fonts"
                  },
                  "generalPriceList": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "General Price List",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration general price list."
                    }
                  },
                  "headerFont": {
                    "options": {
                      "default": "Select One"
                    },
                    "placeholder": "Header Font",
                    "validation": {
                      "disabled": "All locations must be removed to change the configuration header font."
                    }
                  },
                  "loadingFonts": "Loading Fonts...",
                  "logoAlignment": {
                    "placeholder": "Logo Alignment"
                  },
                  "logoSize": {
                    "placeholder": "Logo Size"
                  }
                }
              },
              "headerLogo": {
                "actions": {
                  "archive": {
                    "error": "An error has occurred.",
                    "success": "Logo Successfully Archived!"
                  },
                  "create": {
                    "error": "An Error occured while uploading your logo.",
                    "success": "Logo Successfully uploaded!"
                  }
                },
                "header": "Header Logo",
                "placeholder": "Online Planner Logo"
              },
              "locations": {
                "addLocation": "+ Add Location",
                "defaultConfigMessage": "Individual locations cannot be added to a default configuration.",
                "delete": {
                  "error": "There was an error removing this location. If this continues, please contact support.",
                  "success": "Location was successfully removed."
                },
                "noGeneralPriceList": "Select a General Price List to add locations to this configuration.",
                "noLocations": "No locations have been added to this configuration.",
                "select": {
                  "placeholder": "Online Planner Location",
                  "placeholderLabel": "Select One"
                },
                "title": "Locations"
              },
              "notifications": {
                "email": "Email",
                "label": "Send notifications when plans are submitted",
                "title": "Notifications"
              },
              "save": {
                "copySuccess": "Planner url copied to clipboard successfully!",
                "error": "There was a problem while saving. If this continues, please contact support.",
                "success": "Planner saved successfully!"
              },
              "section": {
                "fontType": {
                  "info": "Using system fonts will reduce loading time and rendering speed, ensure consistency across devices and browsers, and improve accessibility."
                },
                "heading": {
                  "pages": "Pages",
                  "plannerDetails": "Planner Details",
                  "theme": "Theme"
                }
              },
              "theme": {
                "secondary": "Secondary Color"
              },
              "validation": {
                "country": "You must select a country",
                "name": "Name cannot be blank",
                "pageName": "Page Name can't be blank",
                "prefixCheck": "Please only use letters, numbers, dashes or underscores.",
                "primaryColor": "Primary color must be a valid hex code",
                "secondaryColor": "Secondary color must be a valid hex code",
                "url": "URL Prefix cannot be blank"
              }
            },
            "overviewButton": "Overview",
            "page": {
              "buttons": {
                "addCustomField": "Add",
                "save": "Save"
              },
              "errors": {
                "customFieldAdd": "You have already added this field."
              },
              "fields": {
                "customFieldItems": {
                  "options": {
                    "boolean": "Checkbox",
                    "date": "Date Picker",
                    "input": "Text",
                    "select": "Drop Down"
                  },
                  "placeholder": "Label",
                  "size": {
                    "large": "Large",
                    "medium": "Medium",
                    "small": "Small"
                  },
                  "type": {
                    "placeholder": "Type"
                  }
                },
                "customFieldSelect": {
                  "noOptionSelected": "Select One",
                  "placeholder": "Custom Fields"
                },
                "defaultValueField": {
                  "error": "You must select a default value for the overview question pages you have hidden.",
                  "label": "Default Value",
                  "placeholder": "Select One",
                  "warning": "You must select a value."
                },
                "gplURL": "General Price List URL",
                "gplUrlDescription": "This pricelist will be presented to users before they select items in the planner.",
                "gplUrlError": "URL not valid",
                "media": {
                  "image": {
                    "delete": "Delete",
                    "droboxText": "Drag and drop images here to upload them",
                    "placeholder": "Image",
                    "uploadedBy": "Uploaded By:",
                    "uploadedOn": "on"
                  },
                  "video": {
                    "placeholder": "Video"
                  }
                },
                "pageIsHidden": "Hide this page?",
                "pageName": "Page Name",
                "planner": {
                  "blankError": "Field cant be blank",
                  "hideLabel": "Hide?",
                  "label": "Label",
                  "pageDescription": "Page Description",
                  "requiredLabel": "Required?",
                  "verbiageLabel": "Verbiage"
                },
                "subPageisHidden": "Hide this subpage?"
              },
              "section": {
                "subPages": "Subpages"
              },
              "title": {
                "isHidden": "(Hidden)"
              }
            },
            "pageTitle": "Online Planner",
            "users": {
              "leftNav": {
                "back": "Back to Online Planner",
                "overview": "Overview"
              },
              "search": {
                "clearButton": "Clear",
                "newButton": "New",
                "placeholder": "Seach"
              },
              "table": {
                "headers": {
                  "actions": "Actions",
                  "email": "Email",
                  "lastAccess": "Last Updated",
                  "view": "View Plans"
                }
              },
              "title": "Users"
            },
            "usersButton": "Users"
          },
          "users": {
            "overview": {
              "fields": {
                "email": {
                  "placeholder": "Email"
                }
              },
              "table": {
                "buttons": {
                  "actions": "Actions",
                  "archive": "Archive",
                  "create": "Create Case"
                },
                "headers": {
                  "created": "Created At",
                  "session": "Session ID"
                }
              },
              "title": "User"
            }
          }
        },
        "tribute-pay": {
          "email-templates": {
            "defaultData": {
              "email": {
                "body": "<p>Our funeral home staff appreciates the opportunity to support your family during your time of need.\nFor your convenience, we offer a simple method of paying for services online. Simply click the button below to access our secure site.</p>\n<br />\n<p>If you have questions, please contact us anytime.</p>",
                "name": "Payment Email Template",
                "subject": "Make a Payment Online for [Decedent Name]"
              }
            },
            "edit": {
              "alerts": {
                "missingData": {
                  "body": "The body can't be blank"
                },
                "save": {
                  "error": "There was an error when attempting to update this email template.",
                  "success": "This email template has been updated successfully!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "inputFields": {
                "emailSubject": "Email Subject"
              },
              "pageTitle": "Edit {templateTitle}",
              "textareas": {
                "emailBody": "Email Body"
              }
            },
            "pageTitle": "Email Templates",
            "tabTitle": "Email Templates",
            "table": {
              "body": {
                "edit": "Edit"
              },
              "headers": {
                "actions": "Actions",
                "templateName": "Template Name"
              }
            }
          },
          "navigation": {
            "links": {
              "backToAdmin": "Back to Admin",
              "emailTemplates": "Email Templates",
              "notificationSettings": "Notification Settings",
              "overview": "Overview",
              "payments": "Payments"
            }
          },
          "notification-settings": {
            "alerts": {
              "userNotification": {
                "create": {
                  "error": "Oops! There was an error when setting up notifications for the user.",
                  "success": "Success! The notifications were set up successfully."
                }
              }
            },
            "buttons": {
              "addRecipient": "+ Add New Notification Recipient",
              "cancel": "Cancel",
              "clear": "Clear",
              "save": "Save"
            },
            "inputFields": {
              "search": {
                "placeholder": "Search"
              },
              "staffMember": {
                "placeholder": "Staff Member"
              }
            },
            "lists": {
              "notifications": {
                "empty": "No Notifications Added"
              }
            },
            "pageTitle": "Notification Settings",
            "selectFields": {
              "organizationLocation": {
                "options": {
                  "all": "All"
                }
              }
            }
          },
          "overview": {
            "pageTitle": "Tribute Pay Overview",
            "sections": {
              "getStarted": {
                "mainText": {
                  "email": "Sales@TributePay.com",
                  "line1": "To start the enrollment process, click Payments from the menu on the left.",
                  "line2": "For any questions or for assistance enrolling, please contact us at"
                },
                "title": "Get Started"
              },
              "meetTributePay": {
                "mainText": "Tribute Pay’s suite of products gives families multiple ways to pay at your funeral home through emailed invoices via credit card, ACH/e-check, Apple Pay, and more directly through your software. With Tribute Pay’s competitive rates, superior customer service, and no monthly fees, you receive quick deposits with minimal effort.",
                "title": "Meet Tribute Pay: Your All-in-One Payment Solution"
              },
              "tributeFundCrowdfunding": {
                "title": "Tribute Fund Crowdfunding – Coming Soon!"
              },
              "tributeInsuranceAssignments": {
                "title": "Tribute Insurance Assignments – Coming Soon!"
              },
              "tributePaymentProcessing": {
                "mainText": "With no annual or monthly fees, funeral homes can take payments knowing the exact cost of each transaction.",
                "subSection": {
                  "list": {
                    "item1": "2.9% + $0.30 card and digital wallet transactions.",
                    "item2": "1.0% + $0.30 ACH transactions.",
                    "item3": "Accept payments by Visa, Mastercard, Discover, American Express, Google Pay, and Apple Pay."
                  },
                  "title": "Details"
                },
                "title": "Tribute Payment Processing"
              }
            },
            "tabTitle": "Overview"
          },
          "pageTitle": "Admin Settings"
        },
        "tribute-pay-payments": {
          "accounts": {
            "buttons": {
              "addNew": "Add New",
              "clear": "Clear"
            },
            "checkboxes": {
              "viewArchived": "View Archived?"
            },
            "edit": {
              "alerts": {
                "locations": {
                  "deleting": {
                    "error": "There was an error when attempting to remove one of the Locations."
                  },
                  "saving": {
                    "error": "There was an error when attempting to assign one of the Locations."
                  }
                },
                "paymentTypes": {
                  "deleting": {
                    "error": "There was an error when attempting to delete one of the Payment Types."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create one of the new Payment Types."
                  }
                },
                "settingChartOfAccounts": {
                  "prompts": {
                    "confirm": "Once assigned, the Financial Configuration cannot be changed. Are you sure?"
                  },
                  "saving": {
                    "error": "There was an issue assigning the Financial Configuration.",
                    "success": "The Financial Configuration was assigned successfully."
                  }
                },
                "transactionFee": {
                  "missingData": {
                    "financialConfiguration": "To set the transaction fee, please assign a Financial Configuration."
                  },
                  "saving": {
                    "error": "There was an error when attempting to create the Transaction Fee Adjustment Type."
                  }
                },
                "tributePayAccount": {
                  "saving": {
                    "error": "There was an error when trying to save the Tribute Pay account.",
                    "success": "The Tribute Pay account was saved successfully!"
                  }
                }
              },
              "buttons": {
                "cancel": "Cancel",
                "continueEnrollment": "Continue Enrollment Information",
                "editEnrollment": "Edit Enrollment Information",
                "saveAndClose": "Save & Close",
                "saveFinancialConfiguration": "Save Financial Configuration"
              },
              "headings": {
                "financialConfiguration": "Financial Configuration",
                "transactionFee": "Transaction Fee"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "transactionFeeName": "Transaction Fee Name"
              },
              "pageTitle": "Edit Account",
              "selectFields": {
                "financialConfiguration": "Financial Configuration",
                "options": {
                  "selectOne": "Select One"
                },
                "transactionFeeGLAccount": "GL Account"
              }
            },
            "new": {
              "buttons": {
                "beginEnrollment": "Begin Enrollment",
                "cancel": "Cancel"
              },
              "disclaimers": {
                "statementDescriptor": {
                  "characterLimit": "5-22 characters, including at least one letter",
                  "excludedSpecialCharacters": "Does not contain any of the following special characters",
                  "latinOnly": "Latin characters only",
                  "text": "This is the business name that appears on your customer's credit card statements. This field should be a short but recognizable indication of your Doing Business As (DBA) name."
                }
              },
              "errors": {
                "beginEnrollment": "There was an issue attempting to begin enrollment."
              },
              "headings": {
                "mainLocation": "Main Location"
              },
              "inputFields": {
                "accountNickname": "Account Nickname",
                "statementDescriptor": "Statement Descriptor"
              },
              "pageTitle": "New Tribute Pay Account",
              "selectFields": {
                "location": "Location",
                "options": {
                  "selectOne": "Select One"
                }
              }
            },
            "pageTitle": "Tribute Pay Accounts",
            "table": {
              "body": {
                "noAccounts": "No Tribute Pay accounts to display."
              },
              "headers": {
                "accountName": "Account Name",
                "actions": "Actions",
                "numberOfLocations": "Number of Locations",
                "status": "Status"
              }
            }
          },
          "navigation": {
            "links": {
              "accounts": "Tribute Pay Accounts",
              "backToTributePay": "Back to Tribute Payments"
            }
          },
          "pageTitle": "Admin Settings",
          "tabTitle": "Payments"
        },
        "users": {
          "edit": {
            "feature-configuration": {
              "actions": {
                "savingUser": {
                  "errorMessage": "Oops! There was an error saving this user.",
                  "successMessage": "User saved!"
                }
              },
              "buttons": {
                "close": "Close",
                "save": "Save"
              },
              "categories": {
                "adminNavigation": "Admin Navigation",
                "all": "All",
                "caseSectionNavigation": "Case Section Navigation",
                "globalNavigation": "Global Navigation"
              },
              "checkboxes": {
                "canSetupTributePay": "Can Setup Tribute Pay?",
                "canViewAccounting": "Can View Accounting?",
                "canViewCalendar": "Can View Calendar?",
                "canViewContacts": "Can View Contacts?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewData": "Can View Data?",
                "canViewDisposition": "Can View Disposition?",
                "canViewDocuments": "Can View Documents?",
                "canViewEvents": "Can View Events?",
                "canViewFamilyFriends": "Can View Family & Friends?",
                "canViewFamilyPortal": "Can View Family Portal?",
                "canViewFinancialConfigurations": "Can View Financial Configurations?",
                "canViewFinancialDetail": "Can View Financial Detail?",
                "canViewForms": "Can View Forms?",
                "canViewFormsAdmin": "Can View Admin Forms?",
                "canViewGeneralPriceLists": "Can View General Price Lists?",
                "canViewGroups": "Can View Groups?",
                "canViewGrowthEngine": "Can View Growth Engine?",
                "canViewInfo": "Can View Info?",
                "canViewInquiries": "Can View Inquiries?",
                "canViewInquiriesCase": "Can View Case Inquiries?",
                "canViewInquiriesGlobal": "Can View Inquiries?",
                "canViewKases": "Can View Cases?",
                "canViewLocations": "Can View Locations?",
                "canViewMiscTasks": "Can View Tasks?",
                "canViewObituary": "Can View Obituary?",
                "canViewObituaryGenerator": "Can View Obituary Generator?",
                "canViewOhioEdrs": "Can View Ohio EDRS?",
                "canViewOnlinePlannerPlans": "Can View Online Planner Plans?",
                "canViewOrganizations": "Can View Organizations?",
                "canViewPayments": "Can View Payments?",
                "canViewPlaces": "Can View Places?",
                "canViewPreneed": "Can View Preneed?",
                "canViewPreparations": "Can View Preparations?",
                "canViewPrintables": "Can View Printables?",
                "canViewReferralInfo": "Can View Referral Info?",
                "canViewReports": "Can View Reports?",
                "canViewServiceInfo": "Can View Service Info?",
                "canViewTCO": "Can View Websites?",
                "canViewTransportation": "Can View Transportation?",
                "canViewTributeVideo": "Can View Tribute Video?",
                "canViewUsers": "Can View Users?",
                "canViewVehicles": "Can View Vehicles?",
                "canViewVeteranInfo": "Can View Veteran Info?",
                "canViewWhiteboard": "Can View Whiteboard?"
              },
              "searchField": {
                "labelText": "Feature Name",
                "noResults": "No results found"
              }
            },
            "links": {
              "backToAdmin": "Back to Admin",
              "featureConfiguration": "Feature Configuration",
              "overView": "Overview"
            },
            "overview": {
              "buttons": {
                "backToUsers": "Back to Users Index",
                "passwordEmail": "Send Setup Password Email",
                "save": "Save"
              },
              "checkboxes": {
                "adjustInventory": "Can Adjust Inventory?",
                "auditLogPermission": "Audit Log Permission?",
                "canEditChainOfCustody": "Can Edit Chain of Custody?",
                "canPostContractLabel": {
                  "contractAddon": "Can Post Contract/Add-on?",
                  "invoiceAddon": "Can Post Invoice/Add-on?"
                },
                "canSetCaseNumbering": "Can Set Case Numbering?",
                "canTurnOffTributeStorePermission": "Can Turn Off Tribute Store?",
                "canUnlockCases": "Can Unlock Cases",
                "canUnpostContractLabel": {
                  "contractAddon": "Can Unpost Contract/Add-on?",
                  "invoiceAddon": "Can Unpost Invoice/Add-on?"
                },
                "canViewContactManager": "Can View Contact Manager?",
                "canViewCustomCaseFields": "Can View Custom Case Fields?",
                "canViewDebugging": "Can View Debugging?",
                "canViewDoNotExport": "Can View Do Not Export?",
                "canViewHideTributeBookPermission": "Can View Hide Tribute Book Setting?",
                "casesWidget": "Show Cases Widget?",
                "chainOfCustodyWidget": "Show Chain of Custody Widget?",
                "dashboardNavigationWidget": "Show Dashboard Navigation Widget?",
                "editCaseCustomID": "Edit Case Custom ID Permission?",
                "embalmer": "Embalmer",
                "eventsWidget": "Show Events Widget?",
                "financialPermission": "Financial Permission",
                "financialReportPermission": "Financial Report Permission",
                "funeralDirector": "Funeral Director",
                "inventoryPermission": "Has Inventory Permission?",
                "miscSalesPermission": "Has Misc Sales Permission?",
                "pastDueWidget": "Show Past Due Widget?",
                "tasksWidget": "Show Tasks Widget?"
              },
              "information": {
                "netsuiteID": "Netsuite ID:",
                "netsuiteSubsidiaryID": "Netsuite Subsidiary ID:",
                "tmsID": "TMS ID:"
              },
              "inputFields": {
                "email": "Email",
                "embalmerLicense": "Embalmer License #",
                "firstName": "First Name",
                "funeralDecisionsID": "Funeral Decisions ID",
                "funeralDirectorLicense": "Funeral Director License #",
                "lastName": "Last Name",
                "middleName": "Middle Name",
                "netsuiteID": "NetSuite ID",
                "netsuiteSubsidiaryID": "NetSuite Subsidiary ID",
                "phone": "Mobile Phone"
              },
              "save": {
                "emailExists": "The email address you entered is already assigned. Please enter a different email address.",
                "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                "matchingEmail": "The email address you entered is already in use. Please enter a different email address.",
                "noEmail": "You cannot save this user without an email address."
              },
              "sectionHeaders": {
                "dashboardConfiguration": "Dashboard Configuration",
                "editUser": "Edit User",
                "permissionSlider": "Permission Slider",
                "roles": "Roles"
              },
              "selectFields": {
                "communicationPreference": {
                  "label": "Communication Preference",
                  "options": {
                    "both": "Both",
                    "email": "Email",
                    "sms": "SMS"
                  }
                },
                "filterCasesByStatus": {
                  "label": "Filter Cases by Status"
                },
                "state": {
                  "options": {
                    "defaultProvince": "Default Province",
                    "defaultRegion": "Default Region",
                    "defaultState": "Default State",
                    "none": "None"
                  }
                }
              },
              "userTypes": {
                "administrator": {
                  "description": "These users can do everything except create administrator level users.",
                  "titles": {
                    "list": {
                      "officeManagement": "Office Management",
                      "seniorFuneralDirector": "Senior Level Funeral Director"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Administrator"
                },
                "noPermissions": {
                  "description": "Cannot access Tribute Management Software. These users are for notification purpose only.",
                  "type": "No Permissions Set"
                },
                "ownerManager": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "managerOfLocation": "Manager of Location",
                      "owner": "Owner"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Owner/Manager"
                },
                "staff": {
                  "description": "These users have full access to Tribute Management Software except other user accounts.",
                  "titles": {
                    "list": {
                      "apprentices": "Apprentices",
                      "embalmer": "Embalmer",
                      "funeralDirector": "Funeral Director",
                      "sales": "Sales"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Staff"
                },
                "superuser": {
                  "description": "Unlimited authority.",
                  "titles": {
                    "list": {
                      "crakn": "CRäKN Employee",
                      "tms": "Tribute Management Software Employee",
                      "tributeTech": "Tribute Technology Employee"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Superuser"
                },
                "supportStaff": {
                  "description": "These users have read-only access to Tribute Management Software.",
                  "titles": {
                    "list": {
                      "carWashers": "Car Washers",
                      "hairDressers": "Hair Dressers",
                      "janitors": "Janitors",
                      "officeSupportStaff": "Office Support Staff",
                      "valets": "Valets",
                      "visitationAssistants": "Visitation Assistants"
                    },
                    "mainText": "Common titles found in this category:"
                  },
                  "type": "Support Staff"
                }
              }
            },
            "pageTitle": "User Settings",
            "permissions": {
              "canViewCaseSettings": "Can View Case Settings?"
            }
          },
          "permissions": {
            "can_view_tribute_book_setting": {
              "label": "Can View Tribute Book Setting?"
            }
          }
        }
      },
      "calendar": {
        "new-event": {
          "messages": {
            "delete": {
              "error": "Something went wrong deleting this event, let's try that again.",
              "success": "You successfully deleted your event."
            },
            "save": {
              "error": "Sorry, but there was an error saving this event.",
              "success": "Yay! You saved an event!"
            }
          },
          "pageTitle": {
            "edit": "Edit",
            "event": "Event",
            "new": "New"
          }
        }
      },
      "contacts": {
        "edit": {
          "headers": {
            "history": "History"
          },
          "information": {
            "affiliation": "Affiliation",
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        }
      },
      "crakn": {
        "clients": {
          "edit": {
            "feature-config": {
              "checkboxes": {
                "canSeparateTaxCalculations": {
                  "label": "Can Separate Tax Calculations?"
                },
                "canViewCustomSelectionSorting": {
                  "label": "Can View Custom Selection Sorting?"
                },
                "canViewEnhancedPackages": {
                  "label": "Can View Enhanced Packages?"
                },
                "canViewStatementCategories": {
                  "label": "Can View Statement Categories?"
                },
                "includePostingDateInFinancialDetailsExport": {
                  "label": "Include Posting Date In Financial Details Export?"
                },
                "requireReasonForUnposting": {
                  "label": "Require Reason for Unposting?"
                }
              }
            },
            "forms-config": {
              "banners": {
                "error": "Error! TFE migration failed.",
                "migrationStarted": "Migration started",
                "success": "Success! TFE migration is complete."
              },
              "buttons": {
                "close": "Close",
                "migrateFormsToTfe": "Migrate Client to TFE",
                "save": "Save"
              },
              "checkboxes": {
                "canUseTfeEsign": "Can Use Forms with eSign",
                "canViewEsignDashboard": "Can View eSign Dashboard",
                "canViewFormInTfe": "Can View Forms in Tribute Forms Engine",
                "shouldFlattenForms": "Should Flatten Forms"
              },
              "migrationText": {
                "completed": "TFE migration for this client is complete.",
                "confirmPrompt": "This action cannot be reversed. Are you sure you want to migrate this client to TFE?",
                "inProgress": "TFE migration in progress. This might take a few seconds, hang tight!",
                "warning": "Once this client has migrated to TFE, the action cannot be reversed."
              },
              "pageTitle": "Forms Configuration",
              "tfeMigrationTitle": "TFE Migration"
            },
            "reports-config": {
              "checkboxes": {
                "includeContractPostingDate": {
                  "label": "Include Contract Posting Date?"
                },
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG Preneed Report",
                    "jcgReport": "JCG Report",
                    "preneedToAtneedConversionReport": "Preneed to At-Need Conversion Report",
                    "preneedToAtneedRatioReport": "Preneed to At-Need Ratio Report"
                  }
                }
              }
            },
            "tfe": {
              "edit": {
                "addToClients": {
                  "enabledHeader": "Clients using this form",
                  "header": "Add Form to Client(s)",
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Successfully added form to client"
                  },
                  "placeholder": "Client List"
                },
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Update"
                },
                "downloadTestForm": {
                  "messages": {
                    "failure": "An error occurred while downloading the form with test data. Please try again."
                  }
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "description": "Description",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name",
                  "replacePdf": "Replace existing PDF:"
                },
                "header": "Edit Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully updated form"
                },
                "removeFromClient": {
                  "messages": {
                    "failure": "An error occurred",
                    "success": "Form successfully removed from client"
                  }
                },
                "search": {
                  "alreadySelected": "(Already Selected)"
                }
              },
              "index": {
                "buttons": {
                  "clear": "Clear",
                  "new": "Add New"
                },
                "header": "Forms",
                "noForms": "No Forms Found",
                "search": {
                  "placeholder": "Search"
                },
                "text": {
                  "clientSpecific": "(Client Specific)"
                }
              },
              "new": {
                "buttons": {
                  "cancel": "Cancel",
                  "create": "Create"
                },
                "fields": {
                  "caseContactDropdown": "Case Contact Dropdown?",
                  "esignEnabled": "eSign Enabled?",
                  "eventDropdown": "Event Dropdown?",
                  "inquiryDropdown": "Inquiry Dropdown?",
                  "isPublic": "Public?",
                  "locationDropdown": "Location Dropdown?",
                  "name": "Name"
                },
                "header": "New Form",
                "messages": {
                  "failure": "An error occurred",
                  "fieldValidation": "This field can't be blank",
                  "success": "Successfully created form"
                }
              }
            }
          }
        },
        "manage": {
          "demos": {
            "edit": {
              "checkboxes": {
                "reports": {
                  "labels": {
                    "arAgingByCase": "AR Aging By Case",
                    "arAgingByLocationAndCase": "AR Aging By Location and Case",
                    "arAgingByLocationAndCaseWithCredits": "AR Aging By Location and Case - with Credit Balances Included",
                    "beaconMonthlyReconciliationReport": "Beacon Monthly Reconciliation Report",
                    "inventoryAsOf": "Inventory As Of Report",
                    "jcgAltmeyerReport": "JCG Report - Altmeyer",
                    "jcgPreneedReport": "JCG PN Report",
                    "jcgReport": "JCG Report"
                  }
                }
              }
            },
            "index": {
              "inputFields": {
                "tmsPassword": "Password (To Log Into TMS)"
              }
            }
          },
          "forms": {
            "edit": {
              "buttons": {
                "update": "Update"
              },
              "headers": {
                "addFormToClient": "Add Form to Client(s)",
                "clientsUsingThisForm": "Clients using this form",
                "editForm": "Edit Form"
              },
              "labels": {
                "downloadBlankPdf": "Download blank PDF",
                "downloadWithTestData": "Download with test data",
                "replaceExistingPdf": "Replace existing PDF"
              },
              "placeholders": {
                "clientList": "Client List"
              }
            },
            "fields": {
              "caseContactDropdown": "Case Contact Dropdown?",
              "esignEnabled": "eSign Enabled?",
              "eventDropdown": "Event Dropdown?",
              "inquiryDropdown": "Inquiry Dropdown?",
              "locationDropdown": "Location Dropdown?",
              "name": "Name",
              "searchable": "Searchable?"
            },
            "header": "New Form",
            "new": {
              "buttons": {
                "cancel": "Cancel",
                "create": "Create"
              },
              "eSign": {
                "loading": "Document is being analyzed for eSign compatibility",
                "notCompatible": "Form not compatible with eSign"
              },
              "headers": {
                "new_form": "New Form"
              }
            }
          },
          "global-settings": {
            "pageTitle": "Global Settings"
          },
          "links": {
            "clients": "Clients",
            "demos": "Demos",
            "forms": "Forms",
            "global-settings": "Global Settings",
            "migrations": "Migrations"
          },
          "migrations": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "restartMigration": "Try Again",
              "start": "Start Migration"
            },
            "checkboxes": {
              "autoPost": "Auto Post Contracts"
            },
            "datePickers": {
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "missingFiles": "Missing Files",
                "of": "of",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for"
          },
          "pageTitle": "TMS Settings"
        }
      },
      "esignatures": {
        "actions": {
          "viewEsignDetails": "View eSign Details"
        },
        "notifications": {
          "formCanceled": {
            "failure": "Failed to cancel the form. Please try again.",
            "success": "Form successfully canceled!"
          },
          "resentToSigner": {
            "failure": "Failed to resend the form to signer.",
            "success": "Form successfully resent to signer!"
          },
          "resentToSigners": {
            "failure": "Failed to resend the form to pending signers.",
            "success": "Form successfully resent to pending signers!"
          }
        },
        "pageTitle": "eSignatures",
        "placeholders": {
          "location": "Location"
        },
        "tables": {
          "headers": {
            "actions": "Actions",
            "case": "Case",
            "location": "Location"
          }
        }
      },
      "inventory": {
        "edit": {
          "error": {
            "permissions": "You do not have permission to adjust inventory, please ask an administrator.",
            "save": "Inventory change failed to save!"
          },
          "selectFields": {
            "receiveForContract": {
              "label": "Receive for Contract"
            },
            "receiveForStock": {
              "label": "Receive for Stock"
            }
          }
        }
      },
      "kases": {
        "events": {
          "edit": {
            "messages": {
              "deleteEvent": {
                "error": "Something went wrong, please try again.",
                "success": "Successfully removed the event."
              },
              "saveAnotherEvent": {
                "success": "Yay! You saved an event."
              },
              "saveEvent": {
                "error": "There was an error saving this event, please try again.",
                "success": "Yay! You saved an event."
              },
              "unsavedChanges": "You have unsaved changes. Are you sure?"
            },
            "pageTitles": {
              "edit": "Edit",
              "event": "Event",
              "new": "New"
            },
            "secondaryTitles": {
              "case": "Case",
              "location": "Location"
            },
            "warnings": {
              "tcoEmptyPlaceName": "To export to your website, This Event must have a Place with a name.",
              "tcoEventTypeEmpty": "To export to your website, This Event must have an Event Type.",
              "tcoNameInvalid": "To export to your website, the Event Name must be between 2 and 100 characters."
            }
          }
        },
        "manage": {
          "documents": {
            "albums": {
              "manage": {
                "importTributeWall": {
                  "error": "Something went wrong, please try again.",
                  "label": "Import Tribute Wall",
                  "success": "Album successfully imported."
                }
              }
            }
          },
          "edit": {
            "custom-fields": {
              "actions": {
                "create": {
                  "error": "No custom field configuration has been created that can be used with this case."
                },
                "error": "There was an error when saving these custom fields.",
                "save": "Custom fields have been saved!"
              },
              "buttons": {
                "back": "Back to Overview",
                "save": "Save"
              },
              "pageTitle": "Custom Fields",
              "select": {
                "options": {
                  "defaultValue": "Select One"
                }
              }
            },
            "disposition": {
              "checkboxes": {
                "crematedRemainsMailed": {
                  "label": "Cremated Remains Mailed?"
                },
                "crematedRemainsPresentForService": {
                  "label": "Cremated Remains Present for Service?"
                },
                "graveUnknown": {
                  "label": "Grave Unknown?"
                },
                "headstoneDesired": {
                  "label": "Headstone Desired?"
                }
              },
              "headers": {
                "crematedRemainsAuthorizedPickup": "Person(s) Authorized to Pick up Cremated Remains:",
                "graveOwner": "Grave Owner",
                "title": "Disposition"
              },
              "input": {
                "block": {
                  "placeholder": "Block"
                },
                "burialPermitNumber": {
                  "placeholder": "Burial Permit Number"
                },
                "cremationPermitNumber": {
                  "placeholder": "Cremation Permit Number"
                },
                "cremationTagNumber": {
                  "placeholder": "Cremation Tag Number"
                },
                "crypt": {
                  "placeholder": "Crypt"
                },
                "discNumber": {
                  "placeholder": "Disc Number"
                },
                "grave": {
                  "placeholder": "Grave/Plot"
                },
                "lot": {
                  "placeholder": "Lot"
                },
                "mausoleum": {
                  "placeholder": "Mausoleum"
                },
                "niche": {
                  "placeholder": "Niche"
                },
                "plot": {
                  "placeholder": "Plot"
                },
                "secondaryContainer": {
                  "placeholder": "Secondary Container"
                },
                "section": {
                  "placeholder": "Section"
                },
                "tier": {
                  "placeholder": "Tier"
                },
                "timeOfCremation": {
                  "placeholder": "Time of Cremation"
                },
                "urnTempContainer": {
                  "placeholder": "Urn or Temporary Container"
                }
              },
              "pikaday": {
                "crematedRemainsBackBy": {
                  "placeholder": "Cremated Remains Back By"
                },
                "dateOfCremation": {
                  "placeholder": "Date of Cremation"
                },
                "dateOfDisposition": {
                  "placeholder": "Date of Disposition"
                },
                "disintermentDate": {
                  "placeholder": "Date of Disinterment"
                },
                "remainsPickupDate": {
                  "placeholder": "Date of Remains Pick Up/Drop Off"
                }
              },
              "select": {
                "crematedRemainsPickupPerson": {
                  "placeholder": "Cremated Remains Picked Up By"
                },
                "cremationDirector": {
                  "placeholder": "Cremation Director"
                },
                "crematory": {
                  "placeholder": "Crematory"
                },
                "dispositionMethod": {
                  "placeholder": "Disposition Method",
                  "placeholderLabel": "Select One"
                },
                "dispositionPlace": {
                  "placeholder": "Place of Final Disposition"
                },
                "place": {
                  "placeholder": "Place"
                }
              },
              "textareas": {
                "dispositionNotes": {
                  "placeholder": "Disposition Notes"
                },
                "monumentNotes": {
                  "placeholder": "Monument Notes"
                }
              }
            },
            "family-portal": {
              "buttons": {
                "backToOverview": {
                  "text": "Back to Overview"
                },
                "copyInformation": {
                  "errorMessage": "Unable to copy this information to the case",
                  "successMessage": "Successfully copied selected information to case!"
                },
                "createNewInvite": {
                  "errorMessage": "There was an issue creating a Family Portal invite",
                  "successMessage": "Your Family Portal invite has been created. The recipient has been notified.",
                  "text": "Create New Invitation"
                },
                "removeRecipient": {
                  "confirmationMessage": "Are you sure you want to remove this invitation? The action cannot be undone.",
                  "successMessage": "Your Family Portal invite has been removed!"
                }
              },
              "newInvite": {
                "missingEmailText": "Please enter a recipient that has a valid E-mail address.",
                "placeholder": "Recipient's Name",
                "sectionTitle": "New Invite"
              },
              "pageDescription": "Allow family members to submit basic case information through our Family Portal. Once an invite is created, the recipient will receive an E-mail with a link to the Family Portal.",
              "pageTitle": "Family Portal"
            },
            "info": {
              "supportMessage": "If a Location is not listed, please contact us for support.",
              "validations": {
                "archivedLocation": "Assigned to archived location: {location}",
                "archivedUser": "Assigned to archived user: {user}",
                "immutableLocation": "This case has a posted {contractText}. Location cannot be changed.",
                "nonFuneralDirectorUser": "Assigned to user without funeral director role: {user}"
              }
            },
            "navigationItems": {
              "customFields": "Custom Fields",
              "forms": "Forms",
              "settings": "Settings"
            },
            "printables": {
              "categorySelect": {
                "placeholder": "Category",
                "placeholderLabel": "All"
              },
              "noPrintables": "No printables available",
              "pageTitle": "Available Printables",
              "search": "Name",
              "subcategorySelect": {
                "placeholder": "Subcategory",
                "placeholderLabel": "All"
              }
            },
            "service-info": {
              "serviceNotes": {
                "musicNotes": "Music Notes",
                "scriptureNotes": "Scripture Notes"
              }
            },
            "settings": {
              "addError": "Failed to sync custom fields.",
              "addSuccess": "Custom fields synced successfully.",
              "caseSent": "Case Successfully sent to TCO!",
              "deleteDuplicateContract": "Delete Duplicate Contract",
              "deleteDuplicateContractError": "Oh No! There was an error.",
              "idSuccess": "TCO ID saved successfully.",
              "includeInVirginiaVitalStatsReport": "Include in Virginia FD Monthly Vital Statistics Report",
              "jcgReportSendSurveyOptOut": "JCG Report - Send Survey Opt Out",
              "resendError": "Oh No! There was an error while trying to resend this case to TCO.",
              "resetCocError": "Oh No! There was a problem while trying to reset Chain of Custody for this case.",
              "resetCocSuccess": "Chain of Custody reset successfully.",
              "saveButton": "Save",
              "saveKaseError": "Something went wrong, please try again!",
              "saveKaseSuccess": "Case has been updated!",
              "sendCaseButton": "Send Case to TCO",
              "tcoFieldMessge": "Use the TCO ID field to link this case to an existing case in the TCO system.",
              "tcoSendCaseMessage": "Use the Send Case to TCO button for creating a new case in the TCO system and linking it to this case. This button should only ever be clicked once to avoid creating duplicate cases in the TCO system.",
              "title": "Settings",
              "unlockChainOfCustody": "Unlock Chain of Custody"
            },
            "tfe": {
              "forms": {
                "filled": {
                  "button": {
                    "refillAll": "Refill All Forms"
                  },
                  "formLibrary": "Form Library",
                  "messages": {
                    "delete": {
                      "success": "Form successfully deleted!"
                    },
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "refillAllEsignForm": "Refilling all forms will cancel eSignature requests for all forms. Are you sure?"
                  },
                  "text": {
                    "noFormsFilled": "No Forms Filled",
                    "pleaseSelectAForm": "Please select a form from the list of available forms to fill."
                  },
                  "title": "Filled Forms"
                },
                "index": {
                  "downloadTestForm": {
                    "messages": {
                      "failure": "An error occurred while downloading the form with test data. Please try again."
                    }
                  },
                  "filledForms": "Filled Forms",
                  "messages": {
                    "error": "Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.",
                    "success": "Filling process has begun!"
                  },
                  "text": {
                    "dontSeeForm": "Don't see a form you are looking for?",
                    "enableFormsFirst": "Please be sure to select forms to enable in the admin area.",
                    "noFormsEnabled": "No Forms Enabled",
                    "requestForm": "Request one."
                  },
                  "title": "Form Library"
                }
              }
            },
            "veteran-info": {
              "alertMessages": {
                "removeRecordSuccess": "Successfully Removed.",
                "saveError": "Oh No! There was an error.",
                "saveRecordSuccess": "Great job! You saved a Service Record.",
                "updateCaseSuccess": "Great job! You updated the case information."
              },
              "buttons": {
                "addNewServiceRecord": "Add New Service Record",
                "clickToAddRecordLink": "Click here",
                "onDelete": "Remove Record",
                "onSave": "Save Record"
              },
              "checkboxes": {
                "airForceCrossAward": "Air Force Cross",
                "bronzeStarAward": "Bronze Star",
                "dischargePapersReceived": "Discharge Papers Received?",
                "dstFlyingCrossAward": "DST Flying Cross",
                "dstServiceCrossAward": "DST Service Cross",
                "flag": "Flag?",
                "headstoneDesired": "Headstone Desired?",
                "isVeteran": "Veteran?",
                "medalOfHonorAward": "Medal of Honor",
                "militaryHonors": "Military Honors?",
                "navyCrossAward": "Navy Cross",
                "purpleHeartAward": "Purple Heart",
                "receivedAwards": "Received Awards?",
                "remainsNotBuried": "Remains Not Buried?",
                "requestedPresence": "Requested Presence?",
                "selectedMonument": "Selected Monument?",
                "silverStarAward": "Silver Star",
                "temporaryMarker": "Temporary Marker?"
              },
              "completableHeaderLabels": {
                "federalFormInfo": "Federal Form Info",
                "generalInfo": "General Info",
                "headstoneInfo": "Headstone Info",
                "serviceRecords": "Service Records"
              },
              "dateTimeSelectors": {
                "labels": {
                  "dischargedDate": "Date Discharged",
                  "enteredDate": "Date Entered",
                  "headstoneOrderDate": "Date Headstone Ordered",
                  "honorsEventDate": "Event Start Date",
                  "honorsEventTime": "Start Time"
                }
              },
              "descriptionText": {
                "noRecordsAvailableText": "No Service Record Information Available.",
                "toAddTextForAfterAddRecordLink": "to add",
                "vaFormText": "Some of these values come from the VA Form 40-1330"
              },
              "dropdowns": {
                "placeholderLabel": "Select One",
                "placeholders": {
                  "branch": "Military Branch",
                  "headstoneEmblem": "Emblem",
                  "headstoneMaterial": "Type of Stone",
                  "rank": "Highest Rank / Rate Attained",
                  "rating": "Rating / Job / Specialty",
                  "war": "War Fought In"
                }
              },
              "federalFormQuestions": {
                "actualBurialCost": "If Yes, Provide Actual Burial Cost",
                "burialContributionAmount": "If Yes, Provide Amount",
                "hasGovernmentOrEmployerBurialContribution": "Did a Federal/State Government or the Veteran's Employer Contribute to the Burial?",
                "hasIncurredBurialExpenses": "Did you Incur Expenses for the Veteran's Burial or Interment?",
                "hasIncurredExpensesForRemainsTransportation": "Were Expenses Incurred for the Transportation of the Veteran's Remains from the Place of Death to the Final Resting Place?",
                "hasPreviouslyReceivedAllowance": "If Deceased Veteran's Spouse, Did You Previously Receive a VA Burial Allowance?",
                "incurredExpensesForRemainsTransportationAmount": "If Yes, Provide Amount",
                "isBuriedInNationalCemetery": "Was Veteran Buried in a National Cemetery or One Owned by the Federal Government?",
                "isSeekingNonServiceConnectedBenefits": "Are You Seeking Burial Benefits for a Veteran's Non-Service-Connected Death Occurring at a VA Medical Center, Nursing Home under VA Contract, or Other VA Facility?",
                "isServiceConnectedDeath": "Is This Service-Connected Death?"
              },
              "inputFields": {
                "branchRequested": "Branch",
                "dischargeType": "Discharge Type",
                "flagInstructions": "Flag Instructions",
                "markerNotes": "Marker Notes",
                "otherBranch": "Other Branch",
                "otherWar": "Other War",
                "placeDischarged": "Place Discharged",
                "placeEntered": "Place Entered",
                "serviceNumber": "Service Number",
                "vaNumber": "VA File Number",
                "xcNumber": "XC Number"
              },
              "personSelectors": {
                "personPresentedTo": "Presented To"
              },
              "placeSelectors": {
                "placeSelector": "Place"
              },
              "textareas": {
                "headstoneEngraving": {
                  "label": "Headstone Engraving",
                  "placeholder": "Engraving"
                },
                "militaryOrganizations": {
                  "label": "Military Organizations",
                  "placeholder": "Organizations"
                },
                "notes": {
                  "label": "Notes",
                  "placeholder": "Notes"
                }
              },
              "title": "Veteran Info"
            }
          },
          "financials": {
            "adjustments": {
              "errors": {
                "postingPeriodAdd": "Cannot add an adjustment in a locked posting period. You must unlock the posting period first."
              }
            },
            "discounts": {
              "errors": {
                "postingPeriodAdd": "Cannot add a discount in a locked posting period. You must unlock the posting period first."
              }
            },
            "payments": {
              "buttons": {
                "collectionAttempt": {
                  "close": "Close",
                  "sendEmail": "Send Email"
                }
              },
              "dropdownSelection": {
                "insurancePolicy": {
                  "label": "Insurance Policy",
                  "selectionLabel": "Select a Policy"
                }
              },
              "edit": {
                "errors": {
                  "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
                  "postingPeriodAdd": "Cannot add a payment in a locked posting period. You must unlock the posting period first."
                }
              },
              "headers": {
                "paymentEmailLinkHistory": "Payment Email Link History",
                "paymentLinkEmail": "Payment Link Email",
                "personSelector": "Recipient(s)",
                "recipients": "Recipients"
              },
              "labels": {
                "personSelector": {
                  "placeholder": "Name"
                }
              },
              "messages": {
                "paymentLink": {
                  "error": "Error sending payment link email.",
                  "success": "Payment link email sent successfully."
                },
                "receiptEmail": {
                  "notSend": "A receipt will not be emailed to the payer.",
                  "send": "A receipt will be emailed to the payer."
                }
              },
              "tables": {
                "paymentLinkHistory": {
                  "headers": {
                    "dateSent": "Date Sent",
                    "linkActive": "Link Active",
                    "previousEmailLinkHistory": "Previous Email Link History",
                    "recipientEmail": "Recipient Email",
                    "recipientName": "Recipient Name"
                  },
                  "noPreviousEmailLinkHistory": "No previous payment link emails sent."
                }
              },
              "toolTips": {
                "active": "Active",
                "expired": "Expired"
              },
              "validations": {
                "collectionAttempt": {
                  "disablePaymentPostingPeriod": "Cannot add payment in a locked posting period.",
                  "paymentTypeRequired": "Payment Type is required.",
                  "peopleRequired": "At least one recipient is required.",
                  "recipientEmailRequired": "Email address is required for recipients."
                },
                "emailPaymentLink": {
                  "greaterThanOrEqualTo": "The balance must be greater than $0.50 to send a payment link."
                }
              }
            },
            "refunds": {
              "errors": {
                "postingPeriodAdd": "Cannot add a refund in a locked posting period. You must unlock the posting period first."
              },
              "inputField": {
                "refundDescription": "Refund Description"
              }
            },
            "selection": {
              "buttons": {
                "placeholders": {
                  "backToOverview": "Back to Overview",
                  "cancelUnpost": "Cancel Unposting",
                  "invalidateSignatures": {
                    "invalidate": "Invalidate ",
                    "signatures": " Signatures"
                  },
                  "save": "Save",
                  "saveAndPost": "Save and Post ",
                  "saveUnpostingReason": "Save Unposting Reason",
                  "unpost": "Unpost"
                }
              },
              "errors": {
                "missingReason": "You must provide a reason to unpost",
                "postingPeriodLockedContract": "Cannot post a {name} in a locked posting period. You must unlock the posting period first.",
                "saving": "There was a problem saving. Please contact us for support.",
                "unlockContractPostingPeriod": "Cannot unpost a {name} in a locked posting period. You must unlock the posting period first.",
                "unsavedChanges": "You have unsaved changes. Are you sure?"
              },
              "information": {
                "badDebtAt": "on",
                "badDebtBy": "Marked as bad debt by:",
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite Invoice ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              },
              "inputs": {
                "placeholders": {
                  "purchaserRelationship": "Purchaser Relationship",
                  "reasonForUnposting": "Reason for Unposting"
                }
              },
              "successful": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "unposted": "Selection successfully unposted"
              }
            }
          },
          "integrations": {
            "maintenanceMode": "MAINTENANCE MODE",
            "maintenanceModeLong": "This service is in maintenance mode.  All communication has been paused.",
            "tco": {
              "case": {
                "errors": {
                  "deathDateInFuture": "Date of death cannot be more than 1 day in the future.",
                  "guestbookEmail": {
                    "format": "Must be a valid email address",
                    "presence": "Email cannot be blank"
                  },
                  "missingDateOfDeath": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website."
                },
                "pdfUpload": {
                  "button": "Upload PDF",
                  "label": "PDF File",
                  "viewDocument": "View File"
                },
                "webcast": {
                  "pin": {
                    "label": "Webcast PIN"
                  },
                  "url": {
                    "label": "Webcast URL"
                  }
                }
              },
              "checkboxes": {
                "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                "enableExitPops": "Enable Exit-Pops?",
                "makeGuestbookPrivate": "Make Guestbook Private?",
                "refreshOgImage": "Refresh og:image?",
                "turnOffTributeStore": "Turn Off Tribute Store?"
              },
              "facebook": {
                "errors": {
                  "locationNotSet": "You must first link this case to a service location to post to facebook.",
                  "urlNotSet": "Environment URL not set up, please contact support."
                },
                "warningMessage": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
              },
              "legacyTouch": {
                "addPin": {
                  "error": "There was a problem while adding the pin.",
                  "success": "Pin added successfully."
                },
                "confirm": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, add PIN",
                  "message": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?",
                  "title": "Confirm PIN"
                },
                "deletePin": {
                  "cancelButton": "Cancel",
                  "confirmButton": "Yes, delete PIN",
                  "error": "There was a problem while deleting the pin.",
                  "message": "Are you sure you want to delete this pin?",
                  "removeButtonToolTip": "Remove PIN",
                  "success": "Pin deleted successfully.",
                  "title": "Delete PIN"
                },
                "pinsForCase": "PIN's Avaliable:",
                "title": "Legacy Touch",
                "validate": {
                  "error": "Invalid PIN - please double check for typos, or reach out to ",
                  "helpCenterName": "Legacy Touch Help Center",
                  "validateButton": "Validate PIN"
                }
              },
              "placeholders": {
                "privateGuestbookEmail": "Private Guestbook Email"
              },
              "previewSection": {
                "button": "Preview",
                "description": "See how this page will look on your website before you publish it.",
                "title": "Preview"
              },
              "save": {
                "error": "Oops! There was an error saving this information to your website.",
                "success": "Website information saved successfully!"
              },
              "videoTribute": {
                "label": "Video Tribute URL"
              }
            },
            "tco-v2": {
              "buttons": {
                "backToIntegrations": "Back to Integrations",
                "save": "Save"
              },
              "errors": {
                "description": "Please add the following in order to export.",
                "labels": {
                  "failedExport": "Last Failed Export"
                },
                "list": {
                  "deathDateInTheFuture": "Date of death cannot be more than 1 day in the future.",
                  "missingDeathDate": "Case must have a date of death to export to your website.",
                  "missingFirstName": "Case must have a first name to export to your website.",
                  "missingLastName": "Case must have a last name to export to your website.",
                  "unableToSyncWebsiteProvider": "Unable to sync with website provider. Please try again in a few minutes. If this issue persists, please contact support."
                },
                "save": "Oops! There was an error saving this information to your website."
              },
              "maintenanceMode": {
                "title": "MAINTENANCE MODE"
              },
              "sections": {
                "headerTheme": {
                  "title": "Header Theme"
                },
                "legacyTouch": {
                  "buttons": {
                    "validatePin": "Validate PIN"
                  },
                  "dialogModals": {
                    "confirm": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, add PIN"
                      },
                      "error": "There was a problem while adding the PIN.",
                      "messages": {
                        "matchingPins": "This PIN matches {legacyTouchPinFirstName} {legacyTouchPinLastName}. Is this the correct PIN for {deceasedName}?"
                      },
                      "success": "Pin added successfully.",
                      "title": "Confirm"
                    },
                    "delete": {
                      "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Yes, delete PIN"
                      },
                      "error": "There was a problem while deleting the PIN.",
                      "success": "PIN deleted successfully.",
                      "title": "Delete PIN",
                      "warning": "Are you sure you want to delete this PIN?"
                    }
                  },
                  "errors": {
                    "invalidPin": {
                      "contact": {
                        "legacyTouchHelpCenter": "Legacy Touch Help Center"
                      },
                      "text": "Invalid PIN - Please double check for typos, or reach out to"
                    }
                  },
                  "inputFields": {
                    "pin": "PIN"
                  },
                  "pinsAvailable": {
                    "buttons": {
                      "removePin": "Remove PIN"
                    },
                    "label": "PINs Available:"
                  },
                  "title": "Legacy Touch"
                },
                "pdfFile": {
                  "buttons": {
                    "uploadPdf": "Upload PDF"
                  },
                  "links": {
                    "viewFile": "View File"
                  },
                  "title": "PDF File"
                },
                "preview": {
                  "buttons": {
                    "preview": "Preview"
                  },
                  "description": "See how this page will look on your website before you publish it.",
                  "title": "Preview"
                },
                "socialSharing": {
                  "buttons": {
                    "connectToFacebook": "Connect to Facebook"
                  },
                  "checkboxes": {
                    "postToFacebook": "Post to Facebook?"
                  },
                  "description": "Increase Traffic to your website by sharing your obituaries to social media. Connect to your company accounts now.",
                  "errors": {
                    "environmentUrlNotSet": "Environment URL not set up, please contact support.",
                    "missingServiceLocation": "You must first link this case to a service location to post to Facebook."
                  },
                  "title": "Social Sharing",
                  "warning": "You are about to Publish the obituary without a photo. You can add one later, however, if your website is connected to Facebook you will need to manually delete and recreate the Facebook post for this individual. Do you wish to continue?"
                },
                "tributes": {
                  "title": "Tributes"
                },
                "websiteSettings": {
                  "checkboxes": {
                    "disablePostPurchasePopup": "Disable Post Purchase Popup?",
                    "disableTreeAddOn": "Disable Tree Add On?",
                    "enableExitPops": "Enable Exit-Pops?",
                    "hideBirthDate": "Hide Birth Date?",
                    "hideDeathDate": "Hide Death Date?",
                    "hideDeathNotice": "Hide Death Notice?",
                    "hideObituary": "Hide Obituary on Tribute Archive?",
                    "hideTributeBook": "Hide Tribute Book?",
                    "hideTributeWall": "Hide Tribute Wall?",
                    "makeGuestbookPrivate": "Make Guestbook Private?",
                    "refreshOgImage": "Refresh og:image?",
                    "turnOffTributeArchiveStore": "Turn Off Tribute Archive Store?",
                    "turnOffTributeStore": "Turn Off Tribute Store?"
                  },
                  "inputFields": {
                    "homepagePosition": "Homepage Position",
                    "privateGuestbookEmail": "Private Guestbook Email",
                    "videoTributeUrl": "Video Tribute URL",
                    "webcastPin": "Webcast PIN",
                    "webcastUrl": "Webcast URL"
                  },
                  "title": "Website Settings"
                },
                "websiteStatus": {
                  "subsections": {
                    "publishToWebsite": {
                      "buttons": {
                        "publish": "Publish",
                        "unpublish": "Unpublish"
                      },
                      "lastExport": {
                        "label": "Last Exported:"
                      },
                      "status": {
                        "label": "Status:",
                        "list": {
                          "notPublished": "Not Published",
                          "published": "Published"
                        }
                      },
                      "title": "Publish to Website"
                    }
                  },
                  "title": "Website Status"
                }
              },
              "successes": {
                "save": "Website information saved successfully!"
              },
              "validations": {
                "email": {
                  "cannotBeBlank": "Email cannot be blank.",
                  "mustBeValidEmail": "Must be a valid email address."
                }
              }
            },
            "tribute-print": {
              "pageTitle": "Tribute Print",
              "status": {
                "label": "Tribute Print Status:"
              },
              "storeLink": "Go to store"
            },
            "tribute-video": {
              "buttons": {
                "backToIntegrations": {
                  "label": "Back to Integrations"
                }
              },
              "fields": {
                "select": {
                  "photoAlbums": {
                    "label": "Photo Albums",
                    "options": {
                      "noAlbum": "No Album",
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "instructions": {
                "missingData": {
                  "location": "To export this case, please make sure that the {location} location has address details and an email assigned."
                },
                "noAlbumSelection": "No photos will be sent from Tribute Management Software to Tribute Video. Photos added through Tribute Video will still sync back into Tribute Management Software.",
                "videoDownload": {
                  "browserOptions": {
                    "chromeEdgeFirefox": {
                      "label": "Chrome, Edge, and Firefox:",
                      "optionText": "Save Link As..."
                    },
                    "safari": {
                      "label": "Safari:",
                      "optionText": {
                        "option1": "Download Linked File",
                        "option2": "Download Linked File As...",
                        "or": "or"
                      }
                    }
                  },
                  "label": "Video Download Instructions:",
                  "step1": "Right-click on \"Download Video\"",
                  "step2": "Choose the download option presented by your browser:"
                }
              },
              "links": {
                "downloadVideo": {
                  "label": "Download Video"
                },
                "editVideo": {
                  "errorMessage": "Oops! There was an error when attempting to edit the video.",
                  "label": "Edit Video"
                },
                "export": {
                  "label": "Export"
                },
                "getVideoStatus": {
                  "label": "Get Video Status"
                },
                "viewVideo": {
                  "label": "View Video"
                }
              },
              "pageTitle": "Tribute Video",
              "statuses": {
                "export": {
                  "label": "Status:",
                  "listOfStatuses": {
                    "exported": "Exported",
                    "notExported": "Not Exported"
                  }
                },
                "lastExported": {
                  "label": "Last Exported:"
                },
                "video": {
                  "label": "Video Status:",
                  "listOfStatuses": {
                    "error": "Error",
                    "noStatus": "Please click the link below to update the status.",
                    "published": "Published"
                  }
                }
              }
            },
            "tukios-v2": {
              "backLabel": "Back to Integrations",
              "coverPhotos": {
                "label": "Select a Tukios Cover Photo",
                "placeholder": "Tukios Cover Photo (Optional)"
              },
              "errors": {
                "emptyKaseDates": "Birth date and Death date are required.",
                "invalidContacts": "Family & friends must have a last name.",
                "invalidDeathDate": "Error: Death date is in the future."
              },
              "eventsLabel": "Events:",
              "exportButton": {
                "export": "Export",
                "resync": "Resync"
              },
              "status": {
                "exported": "Exported",
                "label": "Status:",
                "lastExported": "Last Exported:",
                "notExported": "Not Exported"
              },
              "title": "Tukios V2"
            }
          }
        },
        "overview": {
          "pendingEsignatures": {
            "mutlipleDeclinedSigners": "signers have declined to sign.",
            "noPendingEsignatures": "No pending eSignatures",
            "oneDeclinedSigner": "1 signer has declined to sign.",
            "status": "Status",
            "title": "Pending eSignatures"
          }
        }
      },
      "misc-sales": {
        "create": {
          "errors": {
            "gplNotConfigured": "This location does not have a configured GPL. Please contact us for support."
          },
          "pageTitle": "New Misc Sale"
        },
        "edit": {
          "details": {
            "errors": {
              "postingPeriodLocked": "Cannot post a sale in a locked posting period. You must unlock the posting period first."
            },
            "inputs": {
              "placeholders": {
                "save": "Save",
                "saveAndPostSale": "Save and Post Sale",
                "unpostSale": "Unpost Sale"
              }
            },
            "messages": {
              "success": {
                "beaconfpPullFromInventoryChecked": "Purchase Order will not be created in Netsuite",
                "beaconfpPullFromInventoryNotChecked": "Purchase Order will be created in Netsuite",
                "miscellaneousSaleUpdated": "Misc Sale has been updated.",
                "pulledFromInventory": "Product pulled from Inventory!",
                "returnedFromInventory": "Product returned to Inventory!"
              }
            },
            "pageTitle": "Misc Sale",
            "sections": {
              "location": {
                "disclaimer": "If a Location is not listed, please contact us for support."
              }
            },
            "selection": {
              "information": {
                "netsuite": {
                  "customerID": "Netsuite Customer ID:",
                  "invoiceID": "Netsuite ID:"
                },
                "reasonForUnposting": "Reason for Unposting",
                "tmsID": "TMS ID:",
                "unpostedAt": "Unposted At",
                "unpostedBy": "Unposted By"
              }
            }
          },
          "payments": {
            "disclaimers": {
              "receiptEmail": {
                "willSend": "A receipt will be emailed to the payer.",
                "wontSend": "A receipt will not be emailed to the payer."
              }
            },
            "errors": {
              "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us."
            },
            "sections": {
              "totals": {
                "header": "Totals",
                "subsections": {
                  "adjustments": "Adjustments Total",
                  "payments": "Payments Total",
                  "refunds": "Refunds Total",
                  "remaining": "Remaining Balance",
                  "selection": "Selection Total"
                }
              }
            }
          },
          "refunds": {
            "inputField": {
              "refundDescription": "Refund Description"
            }
          }
        }
      },
      "online-planner-plans": {
        "actions": {
          "archive": {
            "error": "There was a problem archiving that plan, please try again.",
            "success": "Plan archived successfully.",
            "text": "Archive"
          },
          "convert": "Promote to Case",
          "unarchive": {
            "error": "There was a problem unarchiving that plan, please try again.",
            "success": "Plan unarchived successfully.",
            "text": "Unarchive"
          },
          "view": "View Plan Details"
        },
        "clearSearch": "Clear",
        "dropdowns": {
          "atneedOption": "At Need",
          "caseOption": "Promoted to Case",
          "inProgressOption": "In Progress",
          "placeholder": "All",
          "plannerPlaceholder": "All Planners",
          "preneedOption": "Preneed",
          "statusPlaceholder": "All Statuses",
          "submittedOption": "Submitted",
          "typePlaceholder": "All Types"
        },
        "edit": {
          "backButton": "Back to Plans",
          "convertCaseButton": {
            "action": "Promote to Case",
            "ask": "Are you sure you want to promote this plan to a case?",
            "cancelButton": "Cancel",
            "chooseLocation": "Which location do you want assigned to this case?",
            "dropdown": {
              "label": "Location",
              "placeholder": "Select One"
            },
            "promoted": "This plan has been promoted to a case.",
            "submitButton": "Submit"
          },
          "documents": {
            "promotedCaseDocumentMessage": "This plan has already been promoted and any documents uploaded are only viewable in the case."
          },
          "exportPdfButton": "Export Plan to PDF",
          "payments": {
            "account": "Account",
            "amountPaid": "Amount Paid",
            "chargeId": "Charge ID",
            "endingIn": "ending in",
            "madeBy": "Payment Made By",
            "notCollected": "$0.00 - A payment was not collected.",
            "type": "Payment Type"
          },
          "people": {
            "nextOfKinLabel": "Next of kin",
            "otherContactsLabel": "Other contacts",
            "primaryContactLabel": "Primary contact"
          },
          "selections": {
            "discountLabel": "Discounts",
            "package": "Package",
            "products": "Products",
            "quantity": "Quantity",
            "shippingLabel": "Shipping Total",
            "subtotalAfterDiscountsLabel": "Subtotal",
            "subtotalLabel": "Selections Total",
            "taxLabel": "Taxes",
            "totalLabel": "Total",
            "totalPaid": "Total Paid"
          },
          "title": "Plan Details"
        },
        "promote": {
          "cancel": "Cancel",
          "error": "There was a problem promoting that plan to a case, please try again.",
          "location": "Which location do you want assigned to this case?",
          "locationDropOption": "Location",
          "submit": "Submit",
          "success": "Plan promoted to case successfully.",
          "toCase": "Are you sure you want to promote this plan to a case?"
        },
        "table": {
          "headers": {
            "actions": "Actions",
            "created": "Created By",
            "planner": "Planner",
            "planningFor": "Planning For",
            "status": "Status",
            "type": "Type",
            "updated": "Last Updated"
          }
        },
        "title": "Plans",
        "tooltips": {
          "case": "Promoted to Case",
          "inProgress": "In Progress",
          "submitted": "Submitted"
        }
      },
      "payments": {
        "adjustments": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "pageTitle": "Adjustments",
          "table": {
            "headers": {
              "adjustedBy": "Adjusted By",
              "amount": "Amount",
              "date": "Date",
              "kase": "Case",
              "lineItem": "Line Item",
              "type": "Type"
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addNew": "Add New",
            "cancelBatchPayment": "Cancel Batch Payment",
            "cancelBatchPaymentEdit": "Cancel Batch Payment Edit",
            "clear": "Clear",
            "clearLocation": "Clear Location",
            "saveBatchPayment": "Save Batch Payment",
            "submitBatchPayment": "Submit Batch Payment",
            "submitPayment": "Submit Payment"
          },
          "checkboxes": {
            "batchPayment": "Is this a Batch Payment?"
          },
          "errors": {
            "insuranceAssignmentsUnsopported": "Batch Payments currently do not support insurance assignments.",
            "missingInfo": "Make sure all of the information is correct and/or inputted",
            "selectDifferentPaymentType": "Please select a different Payment Type."
          },
          "labels": {
            "batchPaymentAmountRemaining": "Batch Payment Amount Remaining",
            "createNewBatchPayment": "Create New Batch Payment",
            "createNewPayment": "Create New Payment",
            "editBatchPayment": "Edit Batch Payment",
            "editPayment": "Edit Payment",
            "location": "Location",
            "paymentInfo": "Payment Information"
          },
          "pageTitle": {
            "batchPayments": "Batch Payments",
            "payments": "Payments"
          },
          "placeholders": {
            "amount": "Amount",
            "checkNumber": "Check Number",
            "endDate": "End Date",
            "filterByLocation": "Filter by Organization or Location",
            "name": "Name",
            "note": "Note",
            "otherPaymentSource": "Other Payment Source",
            "payer": "Payer Name",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type",
            "selectOne": "Select One",
            "startDate": "Start Date"
          },
          "table": {
            "headers": {
              "payer": "Payer"
            }
          }
        },
        "pageTitle": "Payments",
        "payouts": {
          "dateFields": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "inputFields": {
            "maximumAmount": "Maximum Amount",
            "minimumAmount": "Minimum Amount"
          },
          "links": {
            "downloadExcelReport": "Download Excel Report",
            "hideDetails": "Hide Details",
            "viewDetails": "View Details"
          },
          "pageTitle": "Payouts",
          "selectFields": {
            "tributePayAccount": {
              "label": "Tribute Pay Account",
              "options": {
                "all": "All"
              }
            }
          },
          "tables": {
            "details": {
              "data": {
                "charge": "Charge",
                "refund": "Refund",
                "transfer": "Transfer"
              },
              "headers": {
                "tpsId": "TPS ID",
                "transactionAmount": "Transaction Amount",
                "type": "Type"
              }
            },
            "main": {
              "headers": {
                "actions": "Actions",
                "bankAccount": "Bank Account",
                "date": "Date",
                "totalPayout": "Total Payout",
                "tributePayAccount": "Tribute Pay Account"
              },
              "missingData": {
                "payouts": "There are no payouts."
              }
            }
          }
        },
        "sideNav": {
          "batchPayments": "Batch Payments",
          "payments": "Payments",
          "payouts": "Payouts",
          "transactions": "Transactions"
        },
        "transactions": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "downloadCSV": "Download CSV"
          },
          "pageTitle": "Transactions",
          "selectFields": {
            "label": "Payment Type",
            "options": {
              "selectOne": "Select One"
            }
          },
          "statuses": {
            "failed": "Failed",
            "pending": "Pending",
            "successful": "Successful"
          },
          "table": {
            "headers": {
              "actions": "Actions",
              "date": "Date",
              "fee": "Fee",
              "grossAmount": "Gross Amount",
              "netAmount": "Net Amount",
              "paymentBy": "Payment By",
              "paymentFor": "Payment For",
              "saleId": "Sale ID",
              "status": "Status",
              "type": "Type"
            }
          }
        }
      },
      "places": {
        "edit": {
          "fields": {
            "select": {
              "general": {
                "defaultValue": "Select One"
              },
              "placeType": {
                "placeholder": "Place Type"
              }
            }
          }
        }
      },
      "websites": {
        "pageTitle": "Websites",
        "searchField": {
          "label": "Search",
          "resetButton": {
            "label": "Clear"
          }
        },
        "websiteTable": {
          "headers": {
            "website": "Website",
            "websiteDescription": "Description"
          },
          "warnings": {
            "noResults": "No websites found."
          }
        }
      }
    },
    "components": {
      "client-link-asd": {
        "buttons": {
          "linkAccount": "Link Account"
        },
        "checkboxes": {
          "disableImport": "Disable Import",
          "syncCasesAutomatically": "Sync Cases Automatically?"
        },
        "dateSelector": {
          "importAfterDate": "Import After Date (Optional)"
        },
        "descriptionWarning": "Required if multiple of the same integration",
        "disclaimer": "This client is linked with ASD. Please visit the ASD portal, retreive the location remote IDs, and enter them into the appropriate locations in Tribute Management Software.",
        "inputFields": {
          "asdPassword": "ASD Password",
          "mailboxID": "Mailbox ID"
        },
        "pageTitle": "Link ASD Account"
      },
      "contact-history": {
        "caseTable": {
          "headers": {
            "actions": "Actions",
            "caseStatus": "Case Status",
            "caseSubject": "Case Subject",
            "relationship": "Relationship"
          },
          "link": "View Case"
        },
        "headers": {
          "cases": "Cases",
          "inquiries": "Inquiries"
        },
        "inquiryTable": {
          "headers": {
            "actions": "Actions",
            "createdAt": "Created At",
            "createdBy": "Created By",
            "inquiryType": "Inquiry Type"
          },
          "link": "View Inquiry"
        }
      },
      "crakn-event-scheduler": {
        "checkboxes": {
          "export": {
            "bassMollett": "Export to Bass-Mollett?",
            "cfs": "Export to CFS?",
            "frazer": "Export to Frazer?",
            "frontRunner": "Export to Frontrunner?",
            "funeralOne": "Export to FuneralOne?",
            "funeralTech": "Export to FuneralTech?",
            "messenger": "Export to Messenger?",
            "tukios": "Export to Tukios?"
          },
          "hideTcoEndTime": "Hide End Time from Website?"
        },
        "warnings": {
          "addressPlace": "The selected place does not have an address or is missing required fields (i.e., address line 1, city, state/providence, zip/postal code). This could impact any flower or associated deliveries, including cancelation or inability to complete deliveries.",
          "bassMollett": "To export this event to Bass-Mollett, the Event Name and Place Name must be filled in.",
          "cfs": "To export to CFS, this Event must have a name.",
          "frazer": "To export to Frazer, this event must have a place with a valid address, phone number, and postal code.",
          "frontRunner": "To export this event to FrontRunner, this event must have a place with a valid address and FrontRunner Event Type must be filled in.",
          "funeralOne": "To export this event to FuneralOne, the Event Name and Place Name must be filled in.",
          "funeralTech": "To export this event to FuneralTech, this event must have a place with a valid address (Country, City, State, Zip code, Address), and the FuneralTech Event Type must be filled in.",
          "messenger": "To export to Messenger, the Event Place and Messenger Event Type must be filled in.",
          "tukios": "To export to Tukios, this Event must have a place name, full address, and duration."
        },
        "webcast": {
          "pin": {
            "label": "Webcast PIN"
          },
          "url": {
            "label": "Webcast URL"
          }
        }
      },
      "crakn-kase-contact-card": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "contactDeceased": "Deceased",
        "messages": {
          "relationship": {
            "save": {
              "error": "There was a problem saving this relationship.",
              "success": "Relationship saved!"
            }
          }
        },
        "tooltips": {
          "currentNextOfKin": "Current Next of Kin",
          "currentPrimaryContact": "Current Primary Contact",
          "editContact": "Edit Contact",
          "editRelationship": "Edit Relationship",
          "makeNextOfKin": "Make Next of Kin",
          "makePrimaryContact": "Make Primary Contact",
          "removeContact": "Remove Contact"
        }
      },
      "crakn-kase-form": {
        "errors": {
          "formProcessing": {
            "contactEmail": "support@tributetms.com",
            "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
          }
        }
      },
      "crakn-place-editor": {
        "fields": {
          "select": {
            "placeType": {
              "options": {
                "selectOne": "Select One"
              },
              "placeholder": "Place Type"
            }
          }
        }
      },
      "dashboard": {
        "dash-icon-nav": {
          "icons": {
            "placeholders": {
              "calendar": "Calendar",
              "cases": "Cases",
              "contacts": "Contacts",
              "inquiries": "Inquiries",
              "whiteboard": "Whiteboard"
            }
          }
        }
      },
      "esignatures": {
        "dashboard": {
          "form-table-row": {
            "actions": {
              "cancelForm": "Cancel Request",
              "resendToPendingSigners": "Resend to Pending Signers",
              "resendToSigner": "Resend to Signer"
            }
          },
          "kase-table": {
            "noResultsFound": "No results found",
            "table": {
              "headers": {
                "actions": "Actions",
                "case": "Case",
                "location": "Location"
              }
            }
          },
          "kase-table-row": {
            "actions": {
              "hidePendingForms": "Hide Forms",
              "showPendingForms": "Show Forms"
            }
          },
          "search": {
            "clear": "Clear",
            "placeholders": {
              "allLocations": "All",
              "placeholder": "Location",
              "searchCases": "Search Cases",
              "searchSigners": "Search Signers"
            }
          }
        }
      },
      "g": {
        "admin": {
          "ar-adjustment": {
            "create": {
              "add": "Add Adjustment",
              "cancel": "Cancel Adjustment",
              "confirm": "This will zero out all remaining balances for selections with a contract date between {start} and {end}. Are you sure?",
              "new": "New",
              "placeholders": {
                "endDate": "End date",
                "gainType": "Gain adjustment type",
                "location": "Select location",
                "lossType": "Loss adjustment type",
                "note": "Note",
                "requested": "Requested by",
                "selectOne": "Select one",
                "startDate": "Start date"
              },
              "saveError": "There was an error saving the adjustment. Please try again.",
              "saveSuccess": "The adjustment has been successfully saved.",
              "title": "Add new adjustment"
            }
          },
          "custom-field-config": {
            "create-edit-field": {
              "addOption": "+ Add Option",
              "fieldTextInput": {
                "placeholder": "Field Text",
                "validationMessages": "Field text is required"
              },
              "fieldTypeSelect": {
                "options": {
                  "boolean": "Checkbox",
                  "date": "Date Picker",
                  "select": "Drop Down",
                  "text": "Text"
                },
                "placeholder": "Field Type",
                "placeholderLabel": "Select One",
                "validationMessage": "A field type is required."
              },
              "footerButtons": {
                "cancel": "Cancel",
                "delete": "Delete",
                "save": "Save"
              },
              "noOptions": "No options created."
            }
          },
          "general-price-lists": {
            "edit": {
              "packages": {
                "package-contents": {
                  "banners": {
                    "info": "A package may consist of items and/or flexible items. Items are specific products that can be removed, but not changed during the selection process. Flexible items allow for custom selections from a set product category."
                  },
                  "buttons": {
                    "cancel": "Cancel",
                    "delete": "Delete",
                    "editProducts": "Edit Products",
                    "save": "Save Item"
                  },
                  "checkboxes": {
                    "preventRemovalOfItem": "Prevent removal of item from package?",
                    "setAllowanceAmount": "Set allowance amount?",
                    "subtractFromDiscount": "If removed, subtract item value from package discount before package total?"
                  },
                  "fields": {
                    "input": {
                      "allowanceAmount": {
                        "placeholder": "Allowance Amount ($)",
                        "validationMessage": "Please enter a valid allowance amount."
                      }
                    },
                    "select": {
                      "product": {
                        "options": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product"
                      },
                      "productCategory": {
                        "disclaimers": {
                          "alreadyInPackage": "(Already in Package)"
                        },
                        "placeholder": "Product Category"
                      },
                      "type": {
                        "options": {
                          "flexible_item": "Flexible Item",
                          "item": "Item"
                        },
                        "placeholder": "Type"
                      },
                      "unspentAllowanceAccount": {
                        "placeholder": "Unspent Allowance Account",
                        "validationMessage": "Please select an unspent allowance account."
                      }
                    }
                  },
                  "lists": {
                    "products": {
                      "all": {
                        "label": "Note:",
                        "text": "All products in this category will be enabled. You can edit products after saving the item."
                      },
                      "enabled": "{amount} products enabled in this category."
                    }
                  },
                  "sections": {
                    "products": "Products"
                  },
                  "title": {
                    "edit": "Edit Item",
                    "new": "Add New Item"
                  },
                  "tooltips": {
                    "allowanceAmount": "An allowance is a set amount customers can spend on products in this category. If the allowance is exceeded, the difference will be added to their package total."
                  },
                  "validations": {
                    "item": {
                      "delete": {
                        "success": "Item successfully removed from package!"
                      },
                      "save": {
                        "success": {
                          "exisitingItem": "Item saved!",
                          "newItem": "Item successfully added to package!"
                        }
                      }
                    }
                  }
                }
              }
            },
            "statement-categories": {
              "list": {
                "buttons": {
                  "text": {
                    "cancel": "Cancel",
                    "save": "Save"
                  }
                },
                "deleteDialog": {
                  "promptMessage": "Archive {name}?"
                },
                "fields": {
                  "placeholders": {
                    "name": "Name"
                  }
                },
                "messages": {
                  "delete": {
                    "success": "{statementCategoryName} was archived successfully!"
                  },
                  "error": "Something went wrong.",
                  "save": {
                    "success": "Statement Category successfully saved!"
                  }
                }
              }
            }
          },
          "integrations": {
            "disclaimers": {
              "ciclops": {
                "disclaimer": {
                  "list": {
                    "caseEvents": "Case Events",
                    "caseFamilyAndFriends": "Case Family & Friends",
                    "caseImage": "Case Image",
                    "deceasedName": "Deceased name",
                    "deceasedVitalStatistics": "Deceased vital statistics",
                    "obituary": "Obituary",
                    "pallbearers": "Pallbearer(s)",
                    "veteranInfo": "Veteran Info"
                  },
                  "mainText": "The following information may be sent to Ciclops:"
                }
              },
              "tribute-print": {
                "disclaimer": "The following information may be sent to Tribute Print:",
                "informationList": {
                  "caseEvents": "Case Events",
                  "caseFamilyAndFriends": "Case Family & Friends",
                  "caseImage": "Case Image",
                  "deceasedName": "Deceased Name",
                  "deceasedVitalStatistics": "Deceased Vital Statistics",
                  "obituary": "Obituary",
                  "pallbearers": "Pallbearer(s)",
                  "veteranInfo": "Veteran Info"
                }
              },
              "tribute-video": {
                "informationSentToTributeVideo": {
                  "disclaimer": "La siguiente información puede ser enviada a Tribute Video:",
                  "list": {
                    "casePhotos": "Fotos del expediente",
                    "deceasedName": "Nombre del difunto",
                    "deceasedVitalStatistics": "Estadísticas vitales del difunto",
                    "locationAddress": "Dirección de ubicación"
                  }
                },
                "privacyNoticeAndConditionsOfUse": {
                  "disclaimer": "Al exportar a Tribute Video usted acepta las siguientes politicas y condiciones:",
                  "list": {
                    "conditionsOfUse": "Condiciones de uso",
                    "privacyNotice": "Aviso de privacidad"
                  }
                }
              }
            }
          },
          "online-planner": {
            "user-notification-list": {
              "alerts": {
                "remove": {
                  "error": "Oops! There was an error when removing the recipient.",
                  "success": "Success! The recipient was removed successfully."
                }
              }
            }
          },
          "tribute-pay": {
            "notification-settings": {
              "locations": {
                "buttons": {
                  "addLocation": "Add Location for Notifications"
                },
                "sections": {
                  "locationsList": {
                    "title": "Locations"
                  }
                }
              },
              "user-list": {
                "alerts": {
                  "location": {
                    "add": {
                      "error": "Oops! There was an error when adding the location.",
                      "success": "Success! The location was added successfully."
                    },
                    "remove": {
                      "error": "Oops! There was an error when removing the location.",
                      "success": "Success! The location was removed successfully."
                    }
                  },
                  "recipient": {
                    "remove": {
                      "error": "Oops! There was an error when removing the recipient.",
                      "success": "Success! The recipient was removed successfully."
                    }
                  }
                },
                "buttons": {
                  "delete": {
                    "tooltip": "Delete Recipient"
                  },
                  "edit": {
                    "tooltip": "Edit"
                  }
                },
                "list": {
                  "details": {
                    "locations": "Location(s):"
                  }
                }
              }
            }
          },
          "tribute-pay-payments": {
            "accounts": {
              "edit": {
                "locations": {
                  "alerts": {
                    "missingData": {
                      "locations": {
                        "noFinancialConfiguration": "To add a location, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no locations assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addLocation": "Add Location"
                  },
                  "list": {
                    "locations": {
                      "mainText": "Locations assigned to this Tribute Pay Account:"
                    }
                  },
                  "sectionTitle": "Locations",
                  "selectFields": {
                    "location": "Location",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                },
                "payment-types": {
                  "alerts": {
                    "missingData": {
                      "paymentTypes": {
                        "noFinancialConfiguration": "To add a payment type, please assign a Financial Configuration.",
                        "noneAssigned": "There are currently no payment types assigned to this Tribute Pay account."
                      }
                    }
                  },
                  "buttons": {
                    "addPaymentType": "Add Payment Type"
                  },
                  "inputFields": {
                    "paymentType": "Payment Type"
                  },
                  "list": {
                    "paymentTypes": {
                      "account": "Account:",
                      "mainText": "Payment Types assigned to this Tribute Pay Account:",
                      "unsavedPaymentTypes": "Unsaved Payment Types:"
                    }
                  },
                  "sectionTitle": "Payment Types",
                  "selectFields": {
                    "glAccount": "GL Account",
                    "options": {
                      "selectOne": "Select One"
                    }
                  }
                }
              },
              "table-row": {
                "links": {
                  "archive": "Archive",
                  "edit": "Edit"
                },
                "messages": {
                  "error": "There was an error when attempting to archive the account.",
                  "success": "The account was successfully archived."
                },
                "statuses": {
                  "archived": "Archived"
                },
                "table": {
                  "body": {
                    "noAccounts": "No Tribute Pay accounts to display."
                  }
                }
              }
            }
          }
        },
        "batch-payments": {
          "buttons": {
            "addPayments": "Add Payment(s)",
            "clearCaseAndPayments": "Clear Case & Payments",
            "closeCaseAndPayments": "Close Case & Payments"
          },
          "errors": {
            "greaterThan": "Payment amount must be greater than 0.",
            "mustNotExceed": "Payment amount must not exceed the remaining batch payment amount."
          },
          "headers": {
            "amount": "Amount",
            "payer": "Payer",
            "paymentDate": "Payment Date",
            "paymentType": "Payment Type"
          },
          "labels": {
            "addPayments": "Add Payments",
            "balanceDue": "Balance Due",
            "individualPayments": "Individual Payments",
            "theFollowingPaymentsWillBeDeleted": "The following payments will be deleted:",
            "totalCaseBalance": "Total Case Balance"
          },
          "placeholders": {
            "isThisMiscSale": "Is it for a Misc Sale?",
            "paymentAmount": "Payment Amount",
            "selectACase": "Select a Case"
          },
          "summary": {
            "dialogBoxes": {
              "areYouSure": "Are you sure?"
            },
            "headers": {
              "paymentDetails": "Payment Details"
            },
            "labels": {
              "customField1": "Custom Field 1",
              "customField2": "Custom Field 2",
              "note": "Note",
              "payerEmail": "Payer Email",
              "paymentFor": "Payment For"
            }
          },
          "text": {
            "thereAreNoMiscSales": "There are no Misc Sales."
          }
        },
        "crakn": {
          "autocomplete": {
            "blank-form": {
              "placeholder": "Form"
            },
            "poem": {
              "placeholder": "Poem Or Prayer"
            }
          },
          "field": {
            "quill": {
              "hyperlink": {
                "termsOfUse": {
                  "accept": "Accept and Continue",
                  "deny": "Decline",
                  "error": "There was a problem agreeing to the hyperlink terms of use. If this continues please contact support.",
                  "terms": "This feature gives you the ability to hyperlink to third-party content (the “Hyperlink Feature”). By accessing the Hyperlink Feature, you (and the entity that you represent) agree that: (a) your use of the Hyperlink Feature shall not infringe upon any third party's intellectual property rights and (b) you shall not and will not permit any third party to use the Hyperlink Feature to connect to or direct individuals to a third-party e-commerce website that has not been previously approved by us, in our sole discretion. The foregoing sentence does not apply to websites that direct individuals to a website for charitable giving. We may at any time restrict or suspend your use of the Hyperlink Feature.",
                  "title": "Obituary Hyperlink Terms of Use"
                }
              }
            },
            "select": {
              "location": {
                "selectField": {
                  "options": {
                    "selectOne": "Select One"
                  },
                  "placeholder": "Locations"
                }
              },
              "organization-location": {
                "fields": {
                  "select": {
                    "organizationLocation": {
                      "options": {
                        "all": "All"
                      },
                      "placeholder": "Organization / Location"
                    }
                  }
                },
                "groups": {
                  "locations": "Locations",
                  "organizations": "Organizations"
                }
              },
              "statement-category": {
                "fields": {
                  "select": {
                    "statementCategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Category"
                    },
                    "statementSubcategory": {
                      "options": {
                        "default": "Select One"
                      },
                      "placeholder": "Statement Subcategory"
                    }
                  }
                }
              }
            }
          },
          "financials": {
            "beaconfp-resend": {
              "buttons": {
                "confirm": "Force Resend will clear all NetSuite ID's. Do you wish to continue?",
                "resend": "Resend To Netsuite"
              },
              "lists": {
                "on": "on",
                "resendListTitle": "Netsuite Resend History",
                "resentBy": "Resent by"
              }
            }
          },
          "integrations": {
            "edit-integration": {
              "buttons": {
                "cancel": "Cancel",
                "generate": "Generate",
                "regenerate": "Regenerate",
                "save": "Save",
                "updateIntegration": "Update Integration"
              },
              "sections": {
                "locationBased": {
                  "missingLocations": "Please save the integration before modifying its locations."
                },
                "websitePaymentCredentials": {
                  "key": "Key: {key}",
                  "secret": "Secret: {secret}",
                  "title": "Website Payment Credentials",
                  "warnings": {
                    "canOnlyBeViewedOnce": "Credentials can only be viewed once.",
                    "noCredentials": "No Credentials",
                    "writeThisDown": "Write this down!"
                  }
                },
                "websitePaymentType": {
                  "placeholder": "Website Payment Type",
                  "placeholderLabel": "Select a Payment Type",
                  "title": "Website Payment Type"
                },
                "websitePayments": {
                  "missingInfo": "You must have at least one tribute pay account approved and general payment enabled to set up website payments."
                }
              }
            },
            "form-fields": {
              "field": {
                "select": {
                  "user": {
                    "importantInformation": "*Si el usuario que busca no aparece aquí, asegúrese de que tenga un número de teléfono válido.",
                    "placeholder": "Usuario"
                  }
                }
              },
              "inputs": {
                "precoaNumberOfDays": {
                  "description": {
                    "defaultAmount": "Default = 7",
                    "main": "How many days do you want Precoa to wait before contact is initiated with exported TMS contacts?"
                  },
                  "placeholder": "Number of Days"
                }
              },
              "selectors": {
                "user": {
                  "importantInformation": "*If the desired user does not show up here, make sure they have a valid phone number.",
                  "placeholder": "User"
                }
              }
            },
            "integration-service-information": {
              "confirmDelete": {
                "prompt": "Are you sure you want to remove this integration?"
              },
              "sections": {
                "locationBased": "Location Based: {locationBased}",
                "locationNames": "Location(s): {locationNames}",
                "logo": "Logo: {logo}"
              }
            }
          },
          "list": {
            "packages": {
              "flexible-items": {
                "details": {
                  "numberOfProducts": "{amount} products"
                }
              },
              "item": {
                "disclaimers": {
                  "archived": "(Archived)",
                  "notInPackage": "(Not in Package)"
                }
              }
            }
          },
          "pagination-buttons": {
            "buttons": {
              "next": "Next",
              "previous": "Previous"
            },
            "text": {
              "currentPage": "Page",
              "currentPageOf": "of"
            }
          },
          "payments": {
            "transactions": {
              "table-row": {
                "alerts": {
                  "refund": {
                    "fail": "Oh no! There was an issue processing this refund.",
                    "success": "Success! The payment was refunded."
                  }
                },
                "buttons": {
                  "refund": "Refund",
                  "viewDetails": "View Details"
                },
                "sections": {
                  "refund": {
                    "buttons": {
                      "cancel": "Cancel",
                      "refund": "Refund"
                    },
                    "fields": {
                      "input": {
                        "refundAmount": {
                          "placeholder": "Refund Amount"
                        }
                      }
                    }
                  },
                  "transactionDetails": {
                    "data": {
                      "labels": {
                        "address": "Address",
                        "city": "City",
                        "country": "Country",
                        "email": "Email",
                        "name": "Name",
                        "phone": "Phone",
                        "state": "State",
                        "tpsId": "TPS ID",
                        "zipCode": "Zip"
                      }
                    },
                    "header": "Transaction Details",
                    "statuses": null
                  }
                }
              }
            }
          },
          "selector": {
            "account": {
              "placeholderLabel": "Select an Account"
            }
          },
          "v2": {
            "kase-typeahead": {
              "listHeading": "Case",
              "placeholder": "Case"
            },
            "person-selector": {
              "buttons": {
                "cancelContact": "Cancel Contact",
                "saveContact": "Save Contact"
              },
              "inputs": {
                "placeholders": {
                  "email": "Email",
                  "firstName": "First Name",
                  "lastName": "Last Name",
                  "middleName": "Middle Name",
                  "phonePrimary": "Phone Primary",
                  "phoneSecondary": "Phone Secondary"
                }
              },
              "saveWarning": "Please type an existing person, or create a new person by clicking the add button and then saving.",
              "validations": {
                "email": "Email is required.",
                "firstName": "First Name is required.",
                "lastName": "Last Name is required."
              }
            }
          }
        },
        "kases": {
          "create": {
            "check-duplicate-kase": {
              "description": "The deceased information matches previously created records. Please verify case is not a duplicate.",
              "table": {
                "header": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "labelsAtSmallerScreens": {
                  "birthDate": "Birth Date",
                  "caseStatus": "Case Status",
                  "deathDate": "Death Date",
                  "deceasedName": "Deceased Name",
                  "socialSecurityNumber": "SSN"
                },
                "link": {
                  "viewCase": "View Case"
                }
              },
              "title": "Duplicate Case Warning"
            },
            "kase": {
              "button": {
                "createCase": "Create Case"
              },
              "checkbox": {
                "deathNotice": {
                  "label": "Hide Death Notice?",
                  "tooltip": "Death notices post deceased info to your website before the obituary is published."
                },
                "preneed": {
                  "label": "Is Preneed?"
                }
              },
              "input": {
                "socialInsuranceNumber": {
                  "label": "Social Insurance Number"
                },
                "socialSecurityNumber": {
                  "label": "Social Security Number"
                }
              },
              "link": {
                "backToCases": "Back to Cases"
              },
              "selector": {
                "caseName": {
                  "errorMessage": "This field can't be blank",
                  "label": "Case Full Name"
                },
                "datePicker": {
                  "birthDate": {
                    "label": "Birth Date"
                  },
                  "deathDate": {
                    "errorMessage": "Please enter a date on or after birth date",
                    "label": "Death Date"
                  }
                },
                "location": {
                  "errorMessage": "This field can't be blank",
                  "label": "Select One"
                }
              },
              "title": "Case Information"
            }
          },
          "manage": {
            "edit": {
              "deceased": {
                "death-info": {
                  "checkboxes": {
                    "saveToPlaces": {
                      "disclaimer": "Place Name is required to save this Place.",
                      "label": "Save To Places?"
                    }
                  },
                  "fields": {
                    "input": {
                      "placeName": {
                        "placeholder": "Place Name"
                      }
                    }
                  }
                }
              },
              "documents": {
                "album-edit": {
                  "album": {
                    "buttons": {
                      "add": {
                        "label": "Add Photo"
                      },
                      "cancel": {
                        "label": "Cancel"
                      },
                      "private": {
                        "label": "Private"
                      },
                      "public": {
                        "label": "Public"
                      },
                      "save": {
                        "label": "Save Album"
                      }
                    },
                    "description": {
                      "label": "Description"
                    },
                    "file-upload": {
                      "uploadText": "Uploading"
                    },
                    "name": {
                      "label": "Album Name"
                    },
                    "photos": {
                      "label": "Album Photos",
                      "message": "Select or upload photos."
                    }
                  },
                  "availablePhotos": {
                    "archived": "Archived",
                    "buttons": {
                      "add": "Add to album",
                      "expand": "View full size image"
                    },
                    "label": "All Photos",
                    "warning": "Select or upload photos."
                  },
                  "title": {
                    "edit": "Edit Album",
                    "new": "New Album"
                  }
                }
              },
              "printables": {
                "list-item": {
                  "personSelect": {
                    "placeholder": "Person",
                    "placeholderLabel": "Select One"
                  },
                  "poemSearch": {
                    "placeholder": "Poem or Prayer"
                  },
                  "useCustomPoem": "Custom Poem or Prayer?"
                }
              }
            },
            "financials": {
              "selection": {
                "package": {
                  "page": {
                    "backToSelection": {
                      "notSelectedPrompt": "Are you sure you want go to back? You have unsaved selections.",
                      "selectedPrompt": "Are you sure?"
                    }
                  },
                  "summary": {
                    "header": "Package Summary",
                    "itemSubtotal": "Subtotal",
                    "noSelection": "No selection made.",
                    "packageDiscount": "Package Discount",
                    "packageSubtotal": "Package Subtotal",
                    "packageTotal": "Package Total",
                    "save": {
                      "label": "Save package",
                      "notSelectedPrompt": "You are missing required selections that must be made before posting. Are you sure you want to save this package now?",
                      "selectedPrompt": "Are you sure?"
                    },
                    "tax": {
                      "label": "Tax",
                      "tbd": "TBD",
                      "tooltip": "Tax will be calculated after saving this package."
                    }
                  }
                }
              }
            }
          }
        },
        "legal": {
          "acknowledgement": {
            "button": {
              "accept": {
                "error": "To use this application, please accept Terms of Use and Privacy Policy.",
                "label": "Accept and Continue"
              },
              "decline": {
                "label": "Decline"
              }
            },
            "privacyPolicy": {
              "checkbox": {
                "error": "Must accept the Privacy Policy to use this application.",
                "label": "Accept Privacy Policy*"
              },
              "title": "Privacy Policy"
            },
            "termsOfUse": {
              "checkbox": {
                "error": "Must accept the Terms of Use to use this application.",
                "label": "Accept Terms of Use*"
              },
              "title": "Terms Of Use"
            }
          }
        },
        "migrations": {
          "new-migration": {
            "buttons": {
              "addNew": "Add New",
              "cancel": "Cancel",
              "start": "Start Migration"
            },
            "checkboxes": {
              "archiveImportedUsers": "Auto Archive Users",
              "autoArchivePlaces": "Auto Archive Places",
              "autoCompleteKases": "Auto Complete Cases",
              "autoPost": "Auto Post Contracts",
              "caseDataImported": "Has case data already been imported?",
              "duplicateCheck": "Check for Duplicate Contacts (Multiple Imports Only)"
            },
            "datePickers": {
              "autoCompleteKasesCutOffDate": "All Cases With a Date Before will be set to Complete",
              "cutOffDate": "All Contracts With a Date Before (leaving date blank will post ALL contracts)"
            },
            "fileUploader": {
              "placeholder": "Drag and drop documents here to upload them"
            },
            "input": {
              "placeholder": "Migration Nickname"
            },
            "list": {
              "file": {
                "importing": "Importing",
                "ofTotal": "of 13",
                "step": "Step"
              },
              "finishedIn": "Finished In",
              "lastUpdated": "Last Updated",
              "noMigrations": "No migrations found.",
              "status": {
                "failed": "Failed",
                "label": "Status",
                "pending": "Pending ...",
                "successful": "Successful"
              }
            },
            "messages": {
              "allSourceLocationsRequireMapping": "All Source Locations require a mapping to a TMS Location",
              "autoCompleteKasesDateRequired": "If you have selected to auto complete cases, you must provide a cut off date",
              "caseDataImportedMustBeVerified": "You have chosen Import Type of Files Only. Please verify data has been imported by checking the box",
              "error": "Oops, something went wrong! Please try again.",
              "fileUploaded": "File uploaded:",
              "importTypeRequired": "Please select an Import Type",
              "multipleSourceLocationsMappedToOneTMSLocation": "Mapping multiple source locations to one TMS location may result in duplicate Case IDs.",
              "notificationUserRequired": "Please select a Notification User",
              "processingFilePleaseWait": "Processing file:",
              "restartMigrationFailed": "Error occurred restarting migration",
              "saveMigrationFailed": "Error occurred saving migration",
              "success": "Document successfully uploaded!"
            },
            "pageTitle": "Migrations for",
            "rules": {
              "howTo": "How to migrate client data",
              "one": "Upload a file below. This may be a .json file, or a .zip file containing a single .json file.",
              "three": "Click Start Migration to begin the migration.",
              "two": "Map the source location to a TMS location."
            },
            "selects": {
              "noSelection": "Select One"
            },
            "tables": {
              "locationMappings": {
                "sourceLocation": "Source Location",
                "tmsLocation": "TMS Location"
              }
            },
            "waiting": {
              "error": "Oops, processing timed out. If this continues, please contact support.",
              "first": "This may take a few minutes, hang tight!",
              "second": "This is a larger file, we're still working on it!",
              "third": "We're still formatting your data, it shouldn't be too much longer!"
            }
          }
        },
        "online-planner": {
          "documents": {
            "actions": {
              "addForm": {
                "error": "Something went wrong, please try again.",
                "success": "Document has been added to the online planner!"
              },
              "remove": {
                "error": "Something went wrong removing this document.",
                "success": "Document successfully removed from configuration!"
              },
              "update": {
                "error": "Something went wrong updating this document.",
                "success": "Document successfully updated!"
              }
            },
            "addForm": {
              "addDocumentButton": "Add Document",
              "placeholder": "Form",
              "title": "Add Existing Form"
            },
            "documents": {
              "edit": {
                "placeholder": "Name"
              },
              "empty": "There are no documents assigned to this configuration.",
              "title": "Documents Added To This Planner"
            },
            "title": "Add Documents",
            "uploadDocument": {
              "fileUploadInstructions": "Drag and drop documents here to upload them",
              "title": "Upload New Document"
            }
          },
          "icon-select": {
            "options": {
              "bible-color": "Bible",
              "book": "Book",
              "bookColor": "Book 2",
              "buddhismColor": "Buddhism",
              "building": "Building",
              "canadaColor": "Canada",
              "candle": "Candle",
              "candleColor": "Candle 2",
              "car": "Car",
              "cashAdvance": "Cash Advance",
              "cashAdvanceColor": "Cash Advance 2",
              "casket": "Casket",
              "casket2": "Casket 2",
              "casketColor": "Casket 3",
              "cross": "Cross",
              "embalming": "Embalming",
              "facilitiesStaff": "Facilities and Staff",
              "flag": "Flag",
              "grave": "Grave",
              "graveColor": "Grave 2",
              "hearseColor": "Hearse Black",
              "hearseWhiteColor": "Hearse White",
              "hinduismColor": "Hinduism",
              "islamColor": "Islam",
              "judaismColor": "Judaism",
              "merchandise": "Merchandise",
              "musicColor": "Music",
              "necklaceColor": "Necklace",
              "obituaryColor": "Obituary",
              "paper": "Paper",
              "personHoldingBook": "Person Holding Book",
              "pictureFrameColor": "Picture Frame",
              "serviceColor": "Services 2",
              "services": "Services",
              "soldierColor": "Soldier",
              "standardCremation": "Standard Cremation Service",
              "stationary": "Stationary",
              "traditonalGraveside": "Traditional Graveside Service",
              "traditonalService": "Traditional Service at the Funeral Home or Church",
              "transport": "Transport",
              "urn": "Urn",
              "urn2": "Urn 2",
              "urnColor": "Urn 2",
              "usFlagColor": "US Flag",
              "vault": "Vault",
              "videoColor": "Video"
            },
            "removeIcon": "Remove Icon"
          },
          "media": {
            "video-display": {
              "buttons": {
                "delete": "Delete"
              },
              "details": {
                "uploadedBy": "Uploaded By: {name} on {date}",
                "videoTitle": {
                  "defaultTitle": "No name"
                }
              },
              "fields": {
                "input": {
                  "altText": {
                    "placeholder": "Alt Text"
                  },
                  "description": {
                    "disclaimer": "This description will display in the planner to inform families about the video content.",
                    "placeholder": "Video Description"
                  }
                }
              },
              "uploader": {
                "text": "Drag and drop a video or click to select one"
              },
              "validation": {
                "delete": {
                  "success": "Video removed."
                },
                "upload": {
                  "success": "Video successfully uploaded!"
                }
              }
            }
          },
          "packages": {
            "actions": {
              "addPackage": {
                "error": "Something went wrong, please try again.",
                "success": "Package has been added to the online planner!"
              },
              "deletePackage": {
                "error": "Something went wrong removing this Package.",
                "success": "Package successfully removed from configuration!"
              },
              "updatePackage": {
                "error": "Something went wrong updating this Package.",
                "success": "Package successfully updated!"
              }
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add packages to the planner.",
            "gplMissing": "A general price list is required to add packages to the planner.",
            "packageSearch": {
              "placeholder": "Package"
            },
            "packages": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "description": {
                "placeholder": "Description"
              },
              "empty": "There are no packages assigned to this configuration.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "price": {
                "description": "Package price set in the GPL (pricing method applied).",
                "placeholder": "Package Price"
              },
              "save": "Save",
              "sort": "Sort Packages",
              "value": {
                "description": "Total of all items included in the package (pricing method not applied).",
                "placeholder": "Package Value"
              }
            },
            "subtitle": "Add packages from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add packages to the planner.",
            "title": "Add Packages"
          },
          "payment-collection": {
            "pageCantBeHidden": "This page cannot be hidden if a payment is required.",
            "payLater": {
              "hide": "Hide this option?",
              "title": "Pay Later"
            },
            "payToday": {
              "enrollNow": "Enroll now",
              "enrollWarning": "Tribute Pay enrollment is required to use this payment option.",
              "gplRequired": "A general price list is required to setup payments for this online planner.",
              "hide": "Hide this option?",
              "hideWarning": "This option cannot be hidden if a payment is required.",
              "requireFullPayment": "Require full payment in order to submit a plan?",
              "requireFullPaymentWarning": "The Payments page, and the Pay Today option must be visible if a payment is required.",
              "title": "Pay Today",
              "tributePay": {
                "paymentTypeLabel": "Tribute Pay Payment Type",
                "placeholderLabel": "Select One"
              }
            },
            "title": "Payment Options"
          },
          "products": {
            "actions": {
              "addCategory": {
                "error": "Something went wrong, please try again.",
                "success": "Product category has been added to the online planner!"
              },
              "deleteCategory": {
                "error": "Something went wrong removing this product category.",
                "success": "Product category successfully removed from the online planner!"
              },
              "updateCategory": {
                "error": "Something went wrong updating this product category.",
                "success": "Product category successfully updated!"
              }
            },
            "categories": {
              "cancel": "Cancel",
              "category": {
                "options": {
                  "all": "All",
                  "burial": "Burial",
                  "cremation": "Cremation",
                  "other": "Other"
                },
                "placeholder": "Disposition Category"
              },
              "empty": "There are no product categories assigned to this the online planner.",
              "icon": {
                "placeholder": "Icon"
              },
              "name": {
                "placeholder": "Name"
              },
              "save": "Save",
              "sort": "Sort Product Categories"
            },
            "categorySearch": {
              "placeholder": "Product Category"
            },
            "gplAndTaxLocationMissing": "A general price list and default tax location are required to add product categories to the planner.",
            "gplMissing": "A general price list is required to add product categories to the planner.",
            "products": {
              "editButton": "Edit Products",
              "itemTotal": "in planner",
              "name": "Products"
            },
            "subtitle": "Add product categories from your general price list to include in the planner.",
            "taxLocationMissing": "A default tax location is required to add product categories to the planner.",
            "title": "Add Product Categories"
          }
        },
        "price-list": {
          "import": {
            "fileUploader": {
              "accept": "Accept",
              "cancel": "Cancel",
              "error": "There was an error while uploading the file. Correct the errors, and please try again!",
              "fileErrors": "File errors found",
              "placeholder": "Drag and drop documents here to upload them",
              "review": "Review and accept the updates below to apply them to this GPL."
            }
          },
          "product-update": {
            "columns": {
              "cogs": "Track COGS?",
              "cost": "Cost",
              "current": "Current",
              "desc": "Description",
              "discretionary": "Is Discretionary?",
              "id": "ID",
              "invoiceCategory": "Invoice Category",
              "item": "Item",
              "local": "Local Tax?",
              "name": "Name",
              "preneedCategory": "Preneed Category",
              "price": "Price",
              "productCategory": "Product Category",
              "shipTotal": "Shipping Total",
              "shipping": "Include Shipping?",
              "short": "Short Description",
              "state": "State Tax?",
              "taxed": "Is Taxed?",
              "update": "Update"
            }
          }
        },
        "reports": {
          "detailed-items-report": {
            "dropdowns": {
              "reportDate": {
                "label": "Report Date",
                "optionContractDate": "Contract Date",
                "optionTransactionDate": "Transaction Date"
              }
            }
          }
        },
        "tco": {
          "comment": {
            "api": {
              "commentsFailed": "There was a problem retrieving comments.",
              "genericError": "Something went wrong, please try again."
            },
            "buttons": {
              "approve": {
                "label": "Approve"
              },
              "approved": {
                "label": "Approved"
              },
              "cancel": {
                "label": "Cancel"
              },
              "deleteMedia": {
                "label": "Delete media"
              },
              "deny": {
                "label": "Deny"
              },
              "edit": {
                "label": "Edit"
              },
              "newTab": {
                "label": "Open photo in new tab"
              },
              "play": {
                "label": "Play video in new tab"
              },
              "private": {
                "label": "Hide comment from Tribute Wall",
                "message": "Comment is hidden on Tribute Wall!"
              },
              "public": {
                "label": "Show comment on Tribute Wall",
                "message": "Comment is visible on Tribute Wall!"
              },
              "save": {
                "label": "Save"
              }
            },
            "fields": {
              "comment": {
                "label": "Message"
              },
              "name": {
                "label": "Name"
              }
            },
            "prompts": {
              "approve": {
                "message": {
                  "success": "Comment approved!"
                }
              },
              "delete": {
                "buttons": {
                  "confirm": "Yes",
                  "deny": "No"
                },
                "label": "Are you sure you want to delete this comment?",
                "message": {
                  "success": "Comment deleted!"
                }
              },
              "media": {
                "delete": {
                  "buttons": {
                    "confirm": "Yes",
                    "deny": "No"
                  },
                  "label": "Are you sure you want to delete this photo?",
                  "message": {
                    "success": "Photo has been removed successfully."
                  }
                }
              },
              "save": {
                "message": {
                  "success": "Comment saved successfully!"
                }
              }
            }
          },
          "custom-theme": {
            "prompts": {
              "fileUpload": {
                "dropbox": "Drag and drop an image or click to select one.",
                "error": "There was an error while saving the theme, please try again.",
                "message": "Upload an image!"
              },
              "hide": {
                "message": "Hide Custom Theme Uploader"
              },
              "show": {
                "message": "Upload Custom Theme"
              },
              "themeName": {
                "message": "1. Enter a theme name!",
                "textbox": {
                  "label": "Theme Name",
                  "warning": "Theme name can only contain letters, numbers, spaces, and underscores."
                }
              }
            }
          }
        },
        "tfe": {
          "filled-forms-list": {
            "esignature-requests": {
              "bannerMessages": {
                "cancel": {
                  "error": "eSignature request could not be canceled.",
                  "success": "eSignature request successfully canceled!"
                },
                "multipleSigners": {
                  "resendError": "Failed to resend the eSignature request to pending signers.",
                  "resendSuccess": "eSignature request successfully resent to pending signers!"
                },
                "singleSigner": {
                  "resendError": "Failed to resend the eSignature request to the signer.",
                  "resendSuccess": "eSignature request successfully resent to signer!"
                }
              },
              "button": {
                "cancel": "Cancel Request",
                "hide": "Hide",
                "hideSignerActivity": "Hide Recent Signer Activity",
                "resendAll": "Resend to Pending Signers",
                "resendSigner": "Resend to Signer",
                "viewSignerActivity": "View Recent Signer Activity"
              },
              "status": {
                "canceled": "The eSignature request was canceled.",
                "completed": " has completed the eSignature request.",
                "declined": " has declined the eSignature request.",
                "pending": "The eSignature request was sent to ",
                "resent": "The eSignature request was resent to "
              },
              "text": {
                "title": "eSignature Requests"
              }
            },
            "version-history": {
              "button": {
                "hide": "Hide"
              },
              "text": {
                "current": "Current",
                "title": "Version History"
              },
              "tooltipText": {
                "download": "Download"
              }
            }
          },
          "forms": {
            "admin-list-item": {
              "buttons": {
                "cancel": "Cancel",
                "createCopy": "Create Copy"
              },
              "placeholders": {
                "name": "Name"
              },
              "prompt": {
                "copyGlobalForm": "You cannot edit a global form. Would you like to make a copy?"
              },
              "text": {
                "copyForm": "(Copy)",
                "globalForm": "(Global Form)",
                "nameCopy": "Please enter a name for the copied form."
              },
              "tooltips": {
                "pending": "Form is currently processing and will be available shortly",
                "remove": "Remove",
                "setupEsign": "Setup eSign"
              },
              "validationMessage": {
                "name": "Form name can't be blank"
              }
            },
            "filled-list": {
              "actionsMenu": {
                "deleteForm": "Delete Filled Form",
                "hideEsignatureRequests": "Hide eSignature Requests",
                "hideVersionHistory": "Hide Version History",
                "refillForm": "Refill Form",
                "viewEsignatureRequests": "View eSignature Requests",
                "viewVersionHistory": "View Version History"
              },
              "cancel": "Are you sure you want to cancel this request?",
              "deleteEsignFormPrompt": "Deleting this form will cancel and remove eSignature requests for this form. Are you sure?",
              "deleteFormPrompt": "Are you sure?",
              "refillEsignFormPrompt": "Refilling this form will cancel eSignature requests for this form. Are you sure?",
              "signersTooltip": {
                "completed": "Completed Request with {signerCount} {signerPluralization}",
                "default": "Saved Request with {signerCount} {signerPluralization}",
                "pending": "Pending Request with {signerCount} {signerPluralization}",
                "signerCount": {
                  "one": "Signer",
                  "other": "Signers"
                }
              },
              "text": {
                "formProcessingError": {
                  "contactEmail": "support@tributetms.com.",
                  "mainText": "Form processing was unsuccessful. The Tribute Management Software technical team has been notified. Please try refilling the form or contact"
                }
              },
              "tooltipText": {
                "canceledEsignatures": "This form has been canceled.",
                "completedEsignatures": "This form has a completed eSignature request.",
                "download": "Download",
                "moreActions": "More Actions",
                "pendingEsignatures": "This form has a pending eSignature request.",
                "requestEsignatures": "Request eSignatures"
              }
            },
            "list": {
              "buttons": {
                "cancel": "Cancel",
                "fill": "Fill Form"
              },
              "selectBox": {
                "placeholder": {
                  "event": "Event",
                  "familyOrFriend": "Family or Friend",
                  "inquiry": "Inquiry",
                  "location": "Location",
                  "selectOne": "Select One"
                }
              },
              "text": {
                "alreadyFilled": "(already filled)",
                "chooseEvent": "Please choose the event for this form:",
                "chooseInquiry": "Please choose the inquiry for this form:",
                "enterInfo": "Please enter the information below",
                "noName": "No name"
              },
              "tooltipText": {
                "download": "Download Blank Form",
                "favorite": "Favorite",
                "fillForm": "Fill Form",
                "unfavorite": "Unfavorite"
              }
            }
          }
        },
        "whiteboard": {
          "modal": {
            "whiteboard-details": {
              "fields": {
                "select": {
                  "caseStatus": {
                    "placeholder": "Case Status"
                  }
                }
              }
            }
          }
        }
      },
      "header-nav": {
        "clientMenu": {
          "viewAll": "View All"
        },
        "inquiry": {
          "active": "Solicitud abierta",
          "new": "Nueva solicitud"
        },
        "userMenu": {
          "admin": "Administración",
          "logOut": "Cerrar sesión",
          "settings": "Ajustes"
        }
      },
      "kase": {
        "information": {
          "badDebtBy": {
            "hasBeenMarked": "has been marked as bad debt",
            "this": "This"
          }
        },
        "inputs": {
          "labels": {
            "isBadDebt": "Mark as Bad Debt?"
          }
        }
      },
      "list": {
        "adjustment-summary": {
          "errors": {
            "postingPeriod": "Cannot delete an adjustment in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:"
          }
        },
        "discount-summary": {
          "errors": {
            "paymentPostingPeriod": "Cannot delete a discount in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "tmsID": "TMS ID:",
            "transactionId": "Discount Id:"
          }
        },
        "payment-summary": {
          "buttons": {
            "cancel": "Cancel",
            "refund": "Refund"
          },
          "disclaimers": {
            "receiptEmail": {
              "notSend": "There is no email for {firstName} {lastName}.",
              "send": "A receipt will be emailed to {email}."
            }
          },
          "errors": {
            "paymentPostingPeriod": "Cannot delete a payment in a locked posting period. You must unlock the posting period first.",
            "paymentStatus": "Unable to get the status of the payment. Please try refreshing it later, or get in touch with us.",
            "refund": {
              "amount": "Refund amount must be less than the payment total.",
              "creation": "There was a problem processing the refund."
            }
          },
          "information": {
            "accountNumberLast4": "Account Number Last 4:",
            "accountType": "Account Type:",
            "bankName": "Bank Name:",
            "enteredBy": {
              "emailPaymentLink": "Email Payment Link",
              "label": "Entered By:",
              "onlinePlanner": "Online Planner"
            },
            "netsuiteID": "Netsuite ID:",
            "paymentProcessorStatus": {
              "label": "Payment Processor Status:",
              "statuses": {
                "awaitingClientFeedback": "Awaiting Client Feedback",
                "awaitingInternalFeedback": "Awaiting Internal Feedback",
                "failed": "Failed",
                "initiated": "Initiated",
                "pending": "Pending",
                "successful": "Successful"
              }
            },
            "paymentTotal": "Payment Total:",
            "processingFee": "Processing Fee:",
            "refundedBy": "Refunded By:",
            "routingNumber": "Routing Number:",
            "tmsID": "TMS ID:",
            "transactionId": "Transaction ID:"
          },
          "inputFields": {
            "refundAmount": "Refund Amount"
          },
          "successes": {
            "refund": {
              "creation": "The refund was created successfully!"
            }
          },
          "toolTips": {
            "printReceipt": "Print Receipt",
            "refund": "Refund"
          }
        },
        "refund-summary": {
          "errors": {
            "postingPeriod": "Cannot delete a refund in a locked posting period. You must unlock the posting period first."
          },
          "information": {
            "netsuiteID": "Netsuite ID:",
            "tmsID": "TMS ID:",
            "transactionId": "Refund Id:"
          }
        },
        "selection-package": {
          "packageContents": "Package Contents: ",
          "pills": {
            "selectionsComplete": "Selections Complete",
            "selectionsRequired": "Selections Required"
          }
        },
        "selection-product": {
          "label": {
            "pullFromBeaconfpInventory": "Pull from Inventory?"
          }
        }
      },
      "quick-add-user": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "inputFields": {
          "email": "Email",
          "embalmerLicense": "Embalmer License #",
          "firstName": "First Name",
          "funeralDirectorLicense": "Funeral Director License #",
          "lastName": "Last Name",
          "phone": "Mobile Phone"
        },
        "note": {
          "label": "Note:",
          "text": "In order for a user to be able to log in to Tribute Management Software, a user must have a valid email."
        },
        "pageTitle": "Add User"
      },
      "reports": {
        "ar-aging": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "ar-aging-by-kase": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging By Case Report"
          }
        },
        "ar-aging-by-location-and-case": {
          "additionalDateFilter": {
            "label": "Additional Contract Date Filters"
          },
          "dateSelectors": {
            "asOfDate": {
              "placeholder": "As of Date"
            },
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "funeralDirectorSelector": {
            "placeholder": "Funeral Director"
          },
          "kaseSelector": {
            "placeholder": "Case"
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "A/R Aging Report - by Location and Case",
            "withCreditBalances": "- with Credit Balances Included"
          }
        },
        "beacon-monthly-reconciliation-report": {
          "checkboxes": {
            "includePostedDateRange": {
              "label": "Also include Selections by Posting Date"
            }
          },
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "postedDateEndsAt": {
              "placeholder": "End Date"
            },
            "postedDateStartsAt": {
              "placeholder": "Start Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "messages": {
            "missingEndDate": "Please enter an End Date",
            "missingPostedEndDate": "Please enter a Posted End Date",
            "missingPostedStartDate": "Please enter a Posted Start Date",
            "missingStartDate": "Please enter a Start Date"
          },
          "pageTitle": {
            "mainText": "Beacon Monthly Reconciliation Report"
          }
        },
        "case-count-by-case-tag-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Case Count By Case Tag Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "graystone-case-revenue": {
          "checkboxes": {
            "unpostedCases": "Include Unposted Cases?"
          },
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Case Revenue Report"
        },
        "graystone-survey": {
          "datePickers": {
            "endDate": "End Date",
            "startDate": "Start Date"
          },
          "links": {
            "generateReport": "Generate Report"
          },
          "title": "Graystone Survey Report"
        },
        "inventory-as-of": {
          "datePicker": {
            "asOfDate": "As Of Date"
          },
          "link": {
            "generateReport": "Generate Report"
          },
          "title": "Inventory As Of Report"
        },
        "jcg-altmeyer": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG Report - Altmeyer",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "jcg-preneed": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "JCG PN Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "merchandise-profit": {
          "checkboxes": {
            "displayProductCategory": {
              "label": "Display Product Category?"
            },
            "displayProductNotes": {
              "label": "Display Product Notes?"
            }
          },
          "dateSelectors": {
            "endsAt": {
              "placeholder": "End Date"
            },
            "startsAt": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": "Merchandise Profit Report"
        },
        "precoa-decedent-report": {
          "dateSelectors": {
            "endDate": {
              "placeholder": "End Date"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          },
          "links": {
            "generateReport": {
              "label": "Generate Report"
            }
          },
          "pageTitle": {
            "mainText": "Precoa Decedent Report"
          }
        },
        "preneed-to-atneed-conversion-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Conversion Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        },
        "preneed-to-atneed-ratio-report": {
          "buttons": {
            "generateReport": "Generate Report"
          },
          "header": "Preneed to At-Need Ratio Report",
          "select": {
            "endDate": {
              "placeholder": "End Date"
            },
            "organizationLocation": {
              "placeholder": "Organization or Location(s)",
              "placeholderLabel": "All Locations"
            },
            "startDate": {
              "placeholder": "Start Date"
            }
          }
        }
      },
      "sidebar-nav": {
        "accounting": "Contabilidad",
        "calendar": "Calendario",
        "cases": "Expedientes",
        "contacts": "Contactos",
        "dashboard": "Panel de control",
        "esignatures": "eSignatures",
        "financialDetail": "Detalles financieros",
        "growthEngine": "Growth Engine",
        "inquiries": "Solicitudes",
        "inventory": "Inventario",
        "legal": "Legal",
        "liveHelp": "Ayuda en vivo",
        "miscSales": "Ventas misceláneas",
        "ohioEdrs": "Ohio EDRS",
        "payments": "Pagos",
        "places": "Lugares",
        "plans": "planes",
        "reports": "Reportes",
        "websites": "Websites",
        "whiteboard": "Pizarra"
      }
    },
    "debug": {
      "copy": {
        "clipboard": "Copy to Clipboard",
        "message": "The debug info was successfully copied to your clipboard!"
      },
      "info": {
        "browser": "Browser",
        "client": "Client",
        "ipAddress": "IP Address",
        "notLoggedIn": "Not logged in",
        "user": "User"
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      }
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "login": {
      "browserIsPrivate": {
        "message": {
          "notice": {
            "boldText": "private browsing window",
            "text": "It appears you are trying to access this app from a"
          },
          "request": {
            "additionalText": ", so that we can ensure an",
            "boldText": "standard browsing window",
            "italicText": "optimal experience",
            "text": "Please try again from a"
          },
          "thankYou": {
            "text": "Thank you."
          },
          "title": "Notice"
        }
      },
      "form": {
        "button": {
          "continue": "Continue",
          "submit": "Log in!"
        },
        "email": {
          "error": "Valid email is required",
          "label": "Email"
        },
        "link": {
          "cancelLogin": "Cancel",
          "forgotPassword": "Forgot Password?"
        },
        "messages": {
          "announcementMessage": "Your account safety is our top priority. We’ve implemented additional measures to protect your information.",
          "announcementTitle": "We’ve updated your log in experience!",
          "invalidCredentials": "Login credentials are invalid"
        },
        "password": {
          "error": "This field can't be blank",
          "label": "Password"
        }
      },
      "logo": {
        "alternativeText": "Tribute Management Software"
      },
      "migrationResult": {
        "actionRequired": "Action Required",
        "failed": "Your user migration failed, please contact support.",
        "failedUserExisted": "This username already has a Tribute Technology Single Sign On (SSO) account.  To complete this upgrade and use your existing SSO login, please verify your account by clicking the link in the email we are sending you.  Once your email is verified, return to this application and start the login process over.",
        "migrationContinue": "Continue",
        "migrationStartOver": "Start Over",
        "success": "Your account is being upgraded to the new Tribute Technology login experience!",
        "successNoPassword": "To complete this upgrade, you must reset your password. Please check your email for a password reset link. Once complete, return to this page and click Continue to login with your updated credentials.",
        "successPassword": "To complete this upgrade, please click continue and log in again."
      },
      "successfulLogin": {
        "title": "Login Successful"
      },
      "tmsLearnMore": {
        "link": "Learn More",
        "text": "Curious about the logo change?"
      }
    },
    "model": {
      "tfe": {
        "generated-document": {
          "signingStatus": {
            "awaitingInitialization": "Awaiting Initialization",
            "canceled": "Canceled",
            "completed": "Completed",
            "notApplicable": "Not Applicable",
            "pending": "Pending"
          }
        },
        "signer": {
          "role": {
            "beneficiary": "Beneficiary",
            "executor": "Executor",
            "funeralDirector": "Funeral Director",
            "informant": "Informant",
            "nextOfKin": "Next of Kin",
            "primaryContact": "Primary Contact",
            "purchaser": "Purchaser"
          },
          "status": {
            "canceled": "Canceled",
            "completed": "Completed",
            "declined": "Declined",
            "pending": "Pending"
          }
        }
      }
    },
    "models": {
      "integration-service": {
        "displayName": {
          "asd": "ASD",
          "bassMollett": "Bass-Mollett",
          "cemSites": "CemSites",
          "cfs": "CFS",
          "domaniCare": "DomaniCare",
          "frontRunner": "Front Runner",
          "funeralOne": "FuneralOne",
          "funeralTech": "Funeral Tech",
          "ohioEdrs": "Ohio EDRS",
          "tms": "TMS",
          "tukiosV2": "Tukios V2",
          "unknown": "Unknown"
        }
      }
    },
    "pay-now": {
      "buttons": {
        "next": "Next"
      },
      "disclaimers": {
        "amountDue": {
          "label": "Amount Due:"
        },
        "payment": {
          "error": "We were unable to process your payment. Please try again."
        }
      },
      "errors": {
        "payment": {
          "numberValidation": {
            "withBalance": "This field must be less than or equal to ${lte}, but greater than or equal to $0.50.",
            "withoutBalance": "This field must be greater than $0.50."
          }
        }
      },
      "expired": {
        "bannerText": "This payment link has expired.",
        "mainText": {
          "contactFuneralHome": "This payment link is no longer available. Please contact {funeralHomeName} for a new link.",
          "visitWebsite": "This payment link is no longer available. Please visit the {funeralHomeName} website to initiate a new payment."
        },
        "pageTitle": "Expired Link"
      },
      "inputFields": {
        "amount": "Amount",
        "firstName": "First Name",
        "lastName": "Last Name"
      },
      "mainText": "To make a payment to {funeralHome}, please enter your name and the payment amount below.",
      "pageTitle": "Pay Now",
      "success": {
        "banners": {
          "balancePaid": "This balance has been paid in full.",
          "successfulPayment": "This payment was successful!"
        },
        "mainText": {
          "balancePaid": "This balance is currently $0.00. There is no need to make additional payments at this time.",
          "successfulPayment": "Thank you for submitting this payment to {funeralHomeName}. Your payment was successful. You may close this window."
        },
        "pageTitle": {
          "balancePaid": "Balance Paid",
          "successfulPayment": "Successful Payment"
        }
      }
    },
    "services": {
      "client-config": {
        "checkboxes": {
          "admin": {
            "canViewFinancialConfigurations": "Can View Financial Configurations?",
            "canViewGroups": "Can View Groups?",
            "canViewLocations": "Can View Locations?",
            "canViewOnlinePlanner": "Can View Online Planner?",
            "canViewOrganizations": "Can View Organizations?",
            "canViewSmsPaymentLink": "Enable SMS Payment Links",
            "canViewTributePay": "Can View Tribute Pay?",
            "canViewUsers": "Can View Users?",
            "financialConfiguration": {
              "accounts": "Can View Accounts?",
              "adjustments": "Can View Adjustments?",
              "discounts": "Can View Discounts?",
              "interests": "Can View Interests?",
              "payments": "Can View Payments?",
              "postingPeriods": "Can View Posting Periods?",
              "specialTaxes": "Can View Special Taxes?",
              "sync": "Can View Sync?",
              "taxItems": "Can View Tax Items?"
            }
          },
          "case": {
            "autoKaseNumbering": "Has Auto Case Numbering?",
            "blockEsignForUnpostedContracts": "Block eSignature for Unposted Contracts?",
            "canManageCaseTags": "Can Manage Case Tags?",
            "canSetKaseStatusFilter": "Users Can Set Case Status Filter?",
            "canUseSingleKaseTag": "One Case Tag Per Case?",
            "canViewCaseDispositionIcons": "Can View Disposition Icons on Cases?",
            "canViewCaseFinancial": "Can View Case Financial?",
            "canViewCaseInquiries": "Can View Case Inquiries?",
            "canViewCaseTags": "Can View Case Tags?",
            "canViewChainOfCustody": "Can View Chain of Custody?",
            "canViewContactManager": "Can View Contact Manager?",
            "canViewCremationAuthorization": "Can View Cremation Authorization?",
            "canViewCustomFields": "Can View Custom Fields?",
            "canViewDigitalSignatures": "Can View Digital Signature?",
            "canViewDisposition": "Can View Disposition?",
            "canViewDocuments": "Can View Documents?",
            "canViewEsignature": "Can View eSignature?",
            "canViewEvents": "Can View Events?",
            "canViewFamilyFriends": "Can View Family & Friends?",
            "canViewFamilyPortal": "Can View Family Portal?",
            "canViewForms": "Can View Forms?",
            "canViewIsCovid19Case": "Can View Is COVID-19 Case?",
            "canViewLinks": "Can View Links?",
            "canViewMimsStationary": "Can View MIMS Stationary?",
            "canViewObituary": "Can View Obituary?",
            "canViewObituaryGenerator": "Can View Obituary Generator?",
            "canViewPreneed": "Can View Preneed?",
            "canViewPreneedCategories": "Can View Preneed Categories?",
            "canViewPreneedInfoMessage": "Can View Preneed Info Message?",
            "canViewPreparations": "Can View Preparations?",
            "canViewPrintables": "Can View Printables?",
            "canViewReferralInfo": "Can View Referral Info?",
            "canViewServiceInfo": "Can View Service Info?",
            "canViewTasks": "Enable Task List",
            "canViewTasksAdmin": "Enable Task List Admin",
            "canViewTransportation": "Can View Transportation?",
            "canViewVeteranInfo": "Can View Veteran Info?",
            "forceAutoKaseNumbering": "Force Automatic Case Numbering?",
            "isEsignFieldTest": "Is eSign Field Test?",
            "onlyFuneralDirectorsCanBeLeadStaffMembers": "Only Funeral Directors Can Be Lead Staff Members",
            "sendFamilyPortalEmailInvite": "Automatically Send Family Portal Invite Email?"
          },
          "caseFinancial": {
            "canAssignInsuranceToPayments": "Can Assign Insurance Policies to Payments?",
            "canLockPostingPeriods": "Can Lock Posting Periods?",
            "canMarkAsBadDebt": "Can Mark as Bad Debt?",
            "canViewAdjustmentGains": "Can View Adjustment Gains?",
            "canViewBatchPayments": "Can View Batch Payments?",
            "canViewDiscounts": "Can View Discounts?",
            "canViewDoNotExport": "Can View Do Not Export?",
            "canViewGeneralPayments": "Can View General Payments?",
            "canViewGenerateSfgsButton": "Can View Generate SFGS Button?",
            "canViewInterests": "Can View Interests?",
            "canViewLineItemAdjustments": "Can View Line Item Adjustments?",
            "canViewMiscSales": "Can View Misc Sales?",
            "canViewSpecialTax": "Can View Special Tax?",
            "defaultDueDate": "Default Due Date?",
            "enforcePostingPeriodLocking": "Enforce Posting Period Locking?",
            "relabelContractToInvoice": "Relabel Contract to Invoice?",
            "setContractDateToPostedDateIfNoEvents": "If No Events, Contract Date Is Set To Posted Date?"
          },
          "events": {
            "canViewEmployeeArrivalTime": "Can View Employee Arrival Time?",
            "canViewFamilyArrivalTime": "Can View Family Arrival Time?",
            "canViewVehicles": "Can View Vehicles?",
            "eventStaffMemberInvites": "Event Staff Member Notification Accept / Decline?",
            "sendResponsesToCaseLead": "Send Responses to Case Lead?",
            "sendResponsesToOriginalAssigner": "Send Responses to Original Assigner?"
          },
          "globalNavigation": {
            "canViewAccounting": "Can View Accounting?",
            "canViewCalendar": "Can View Calendar?",
            "canViewCases": "Can View Cases?",
            "canViewContacts": "Can View Contacts?",
            "canViewFinancialDetail": "Can View Financial Detail?",
            "canViewInquiries": "Can View Inquiries?",
            "canViewPlaces": "Can View Places?",
            "canViewReports": "Can View Reports?",
            "canViewWhiteboard": "Can View Whiteboard?"
          },
          "integrations": {
            "canHideDeathNotice": "Can Hide Death Notice?",
            "canViewCemsites": "Can View CemSites?",
            "canViewGrowthEngine": "Can View Growth Engine?",
            "canViewOhioEdrs": "Can View Ohio EDRS?"
          },
          "postingValidations": {
            "caseTag": "Enable Case Tag Validator?",
            "disposition": "Enable Disposition Validator?",
            "enablePostingValidations": "Enable Posting Validations?",
            "postingPeriod": "Enable Posting Period Validator?",
            "purchaser": "Enable Purchaser Validator?",
            "purchaserRelationship": "Enable Purchaser Relationship Validator?",
            "staffMember": "Enable Staff Member Validator?"
          },
          "productAndInventory": {
            "canViewAdditionalProductInfo": "Can View Additional Product Info?",
            "canViewCogsCustomProducts": "Can View COGS on Custom Products?",
            "canViewInventory": "Can View Inventory?",
            "canViewPackageSlotAllowances": "Can View Package Slot Allowances?",
            "canViewPackageSlotFlexibleItems": "Can View Flexible Item(s) Package Slots?",
            "canViewPriceListDates": "Can View Price List Dates?",
            "canViewPullFromBeaconfpInventory": "Can View Pull From Beaconfp Inventory?",
            "canViewPullFromInventory": "Can View Pull From Inventory?",
            "canViewVendors": "Can View Vendors?",
            "hasDiscretionaryItems": "Has Discretionary Items?",
            "useCustomProducts": "Use Custom Products?"
          }
        },
        "disclaimers": {
          "packageSlotAllowances": "Verify client statement of account and statement of funeral goods and services have support for allowances before enabling."
        }
      }
    }
  }]];
  _exports.default = _default;
});